import { useState } from 'react';
import {  Tabs, Tab, } from "react-bootstrap";

import DeviceInformationManager from './DeviceInformationManager/DeviceInformationManager';
import FirmwareUpdateManager from './FirmwareUpdateManager/FirmwareUpdateManager';
import './DeviceManagement.scss';
import { useUserState } from '../../../context/user/context/user.context';
import { hasPermissions } from '../../../common/utils/helperFunctions';

interface IMenuOption {
  key: string;
  title: string;
};

const MENU_OPTIONS: IMenuOption[] = [
  {
    key: 'DeviceInfo',
    title: 'Information',
  },
  {
    key: 'DeviceFirmware',
    title: 'Firmware',
  }
];

const DeviceManagement = () => {
  const [selectedMenu, setSelectedMenu] = useState<string>(MENU_OPTIONS[0].key);
  const { permissions } = useUserState();

   return (
        <div className="device-management">
            <h1>
                <span className="fas fa-water mr-3"></span>Device Management
            </h1>
            <div className="container-fluid flex-container ">
              <Tabs
                transition={false}
                id="controlled-tab-example"
                activeKey={selectedMenu}
                onSelect={(k: any) => setSelectedMenu(k)}
              >
                <Tab eventKey={ MENU_OPTIONS[0].key } title={ MENU_OPTIONS[0].title }>
                  <div className="tab-view">
                    <DeviceInformationManager />
                  </div>
                </Tab>
                { hasPermissions(permissions, ["UPDATE:DEVICE:FIRMWARE"]) ?
                  <Tab eventKey={ MENU_OPTIONS[1].key } title={ MENU_OPTIONS[1].title }>
                    <div className="tab-view">
                      <FirmwareUpdateManager />
                    </div>
                  </Tab> : null
                }
              </Tabs>
            </div>
        </div>
    )
};

export default DeviceManagement;

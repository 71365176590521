import React from 'react'
import {
  getAlertWaterUsageAction,
  getSingleDeviceWaterUsageAction,
  getMultipleDeviceWaterUsageAction,
  clearSingleDeviceWaterUsageAction,
  WaterUsageQuery,
  WaterUsageQueryMultipleDevices,
  refreshSingleDeviceWaterUsageAction,
  getAggUsageAction,
} from './waterUsage.action'
import { initialState, reducer } from './waterUsage.reducer'
import { AggUsageQuery } from './model/usageStats.model'

export default function useWaterUsage() {
  const [
    {
      alertWaterUsage,
      singleDeviceUsage,
      multipleDeviceUsage,
      aggStats,
      singleDeviceTotalUsage,
      loading,
      error,
    },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  const loadForDevice = React.useCallback(
    async (query: WaterUsageQuery) => {
      if (!alertWaterUsage[JSON.stringify(query)]) await getAlertWaterUsageAction(query, dispatch)
    },
    [alertWaterUsage],
  )

  const loadForSingleDevice = React.useCallback(async (query: WaterUsageQuery) => {
    await getSingleDeviceWaterUsageAction(query, dispatch)
  }, [])

  const loadForMultipleDevices = React.useCallback(
    async (query: WaterUsageQueryMultipleDevices) => {
      await getMultipleDeviceWaterUsageAction(query, dispatch)
    },
    [],
  )

  const loadAggUsageAllDevices = React.useCallback(async (query: AggUsageQuery) => {
    await getAggUsageAction(query, dispatch)
  }, [])

  const refreshForSingleDevice = React.useCallback(async (query: WaterUsageQuery) => {
    await refreshSingleDeviceWaterUsageAction(query, dispatch)
  }, [])

  const clearForSingleDevice = React.useCallback(async () => {
    clearSingleDeviceWaterUsageAction(dispatch)
  }, [])

  return {
    alertWaterUsage,
    singleDeviceUsage,
    multipleDeviceUsage,
    aggStats,
    singleDeviceTotalUsage,
    loading,
    error,
    loadForDevice,
    loadForSingleDevice,
    loadForMultipleDevices,
    refreshForSingleDevice,
    clearForSingleDevice,
    loadAggUsageAllDevices,
  }
}

import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/Dropdown'
import { useAlertState } from '../../../context/alert/context/alert.context'

import { Outlet, Link, useParams, useNavigate } from 'react-router-dom'

function AlertQueue() {
  const [alertIndex, setAlertIndex] = useState(1)
  const [isAlertNotFound, setIsAlertNotFound] = useState(false)
  const { queue, filteredQueue, isLoading } = useAlertState()

  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const { id } = params

    if (!isLoading && filteredQueue.length > 0) {
      if (!id) {
        navigate(`${filteredQueue[0].deviceId}`)
        setAlertIndex(1)
      } else {
        const currentIndex = filteredQueue.findIndex((queue) => queue.deviceId === id)

        if (currentIndex === -1) {
          setIsAlertNotFound(true)
        } else {
          setAlertIndex(currentIndex + 1)
        }
      }
    }
  }, [params, queue, filteredQueue])

  function updateAlertIndex(newIndex: number) {
    if (newIndex >= 1 && newIndex <= filteredQueue.length) {
      navigate(`${filteredQueue[newIndex - 1].deviceId}`)
    }
  }

  return (
    <div className="alert-queue-container">
      {isLoading ? (
        <div className="spinner-border m-auto mb-5 mt-5 d-flex text-primary" />
      ) : !isAlertNotFound && !isLoading ? (
        <>
          <h2 className="mt-3">Alert Queue</h2>
          <div className="row p-1">
            {filteredQueue.length === 0 ? (
              <div className="d-flex flex-column text-center mt-5 pt-5">
                <h2>No Alerts Active</h2>

                <Link to={'/home'}> Go back to home page</Link>
              </div>
            ) : (
              <div className="col-sm-6 ">
                <h5>
                  # {alertIndex} of {filteredQueue.length}
                </h5>
              </div>
            )}
            <div className="col-sm-6 text-right">
              {filteredQueue.length !== 0 ? (
                <ButtonToolbar aria-label="Toolbar with button groups" className="mb-3">
                  <ButtonGroup className="mr-2" aria-label="First ">
                    <Button
                      variant="outline-secondary"
                      disabled={alertIndex === 1}
                      onClick={() => updateAlertIndex(alertIndex - 1)}
                    >
                      Previous Alert
                    </Button>
                  </ButtonGroup>

                  <ButtonGroup aria-label="Second">
                    <Button
                      variant="outline-secondary"
                      disabled={alertIndex >= filteredQueue.length}
                      onClick={() => updateAlertIndex(alertIndex + 1)}
                    >
                      Next Alert
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              ) : null}
            </div>

            <Outlet />
          </div>
        </>
      ) : (
        <div className="d-flex flex-column text-center mt-5 pt-5">
          <h2>Alert not found</h2>

          <Link to={'/home'}> Go back to home page</Link>
        </div>
      )}
    </div>
  )
}

export default AlertQueue

import React from 'react';

import './Divider.scss';

const Divider: React.FC = () => {
    return (
        <hr className="solid"/>
    )
};

export default Divider;
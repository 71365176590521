export const COLUMNS = [
    {
        name: '00:00',
        key: 'hour_0',
    },
    {
        name: '01:00',
        key: 'hour_1',
    },
    {
        name: '02:00',
        key: 'hour_2',
    },
    {
        name: '03:00',
        key: 'hour_3',
    },
    {
        name: '04:00',
        key: 'hour_4',
    },
    {
        name: '05:00',
        key: 'hour_5',
    },
    {
        name: '06:00',
        key: 'hour_6',
    },
    {
        name: '07:00',
        key: 'hour_7',
    },
    {
        name: '08:00',
        key: 'hour_8',
    },
    {
        name: '09:00',
        key: 'hour_9',
    },
    {
        name: '10:00',
        key: 'hour_10',
    },
        {
        name: '11:00',
        key: 'hour_11',
    },
    {
        name: '12:00',
        key: 'hour_12',
    },
    {
        name: '13:00',
        key: 'hour_13',
    },
    {
        name: '14:00',
        key: 'hour_14',
    },
    {
        name: '15:00',
        key: 'hour_15',
    },
    {
        name: '16:00',
        key: 'hour_16',
    },
    {
        name: '17:00',
        key: 'hour_17',
    },
    {
        name: '18:00',
        key: 'hour_18',
    },
    {
        name: '19:00',
        key: 'hour_19',
    },
    {
        name: '20:00',
        key: 'hour_20',
    },
        {
        name: '21:00',
        key: 'hour_21',
    },
        {
        name: '22:00',
        key: 'hour_22',
    },
    {
        name: '23:00',
        key: 'hour_23',
    },
];

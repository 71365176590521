import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Info } from 'lucide-react'

import backend from '../../../../api/backend'
import Account from '../../../../context/account/model/Account'
import CustomerLocation from '../../../../context/account/model/CustomerLocation'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '../../../../context/customerManager/customer-manager.context'
import {
  InviteLocation,
  UserLocation,
} from '../../../../context/customerManager/model/customer-manager'
import User, { Group, UserAccountAssoc } from '../../../../context/user/model/user'
import { mapUser } from '../../../../context/customerManager/customer-manager.reducer'

import {
  popoverAccountActive,
  popoverAccountAdmin,
  popoverAutoAddAlerts,
  popoverAutoAddLocations,
  popoverLocationAdmin,
} from './Popovers'

import './Modal.scss'

export interface IModalProps {
  show: boolean
  user: User
  account?: Account
  location?: CustomerLocation
  onHide: () => void
}

const EditModal: React.FC<IModalProps> = ({
  show,
  account,
  user,
  location,
  onHide,
}: IModalProps) => {
  const { groups, accounts, locationsByAccount } = useCustomerManagerState()
  const {
    updateUser,
    addUserToGroups,
    removeUserFromGroups,
    deleteUserLocation,
    updateUserLocation,
    addUserLocation,
    updateUserAccount,
  } = useCustomerManagerDispatch()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([])
  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([])
  const [selectedLocations, setSelectedLocations] = useState<UserLocation[]>([])
  const [selectedUser, setSelectedUser] = useState<User>()
  const [isAccountAdmin, setIsAccountAdmin] = useState(false)
  const [autoAddLocations, setAutoAddLocations] = useState(false)
  const [autoAddAlerts, setAutoAddAlerts] = useState(false)
  const [accountActive, setAccountActive] = useState(false)
  const [accountInvoice, setAccountInvoice] = useState(false)
  const accountUpdate = useRef(false)
  const userUpdate = useRef(false)
  const locationUpdate = useRef(false)
  const [dataChange, setDataChange] = useState(false)

  useEffect(() => {
    if (locationsByAccount[account!.id]) {
      setAccountLocations(locationsByAccount[account!.id])
    }
  }, [locationsByAccount])

  useEffect(() => {
    if (user && user.id) {
      const fetchData = async () => {
        const data = (await backend.get(`users/${user.id}`)).data
        setSelectedUser(mapUser(data))
      }
      // call the function
      fetchData()
        // make sure to catch any error
        .catch(console.error)
    } else {
      setSelectedUser(undefined)
    }
  }, [user, show])

  useEffect(() => {
    setFirstname(selectedUser?.firstname || '')
    setLastname(selectedUser?.lastname || '')
    setMobile(selectedUser?.mobile || '')
    setEmail(selectedUser?.email || '')
    setAccountActive(selectedUser?.active || false)

    if (groups && selectedUser?.groups) {
      setSelectedGroups(mapGroups(selectedUser.groups))
    } else {
      setSelectedGroups([])
    }

    if (
      account &&
      locationsByAccount &&
      locationsByAccount[account.id] &&
      selectedUser?.customerLocations
    ) {
      setSelectedLocations(mapLocations(selectedUser.customerLocations))
    } else {
      setSelectedLocations([])
    }

    if (accounts && selectedUser && selectedUser.accountIds) {
      setSelectedAccounts(mapAccounts(selectedUser.accountIds))
    } else {
      setSelectedAccounts([])
    }

    if (account && selectedUser?.adminAccountIds) {
      setIsAccountAdmin(!!selectedUser.accounts.find((a) => a.accountId === account.id && a.admin))
    }

    if (account && selectedUser?.viewAllLocationsAccountIds) {
      setAutoAddLocations(!!selectedUser.viewAllLocationsAccountIds.find((i) => i === account.id))
    }

    if (account && selectedUser?.accounts) {
      setAutoAddAlerts(
        !!selectedUser.accounts.find((a) => a.accountId === account.id && a.autoAddAlerts),
      )
      setAccountInvoice(
        !!selectedUser.accounts.find((a) => a.accountId === account.id && a.invoice),
      )
    }
  }, [selectedUser, groups, accounts, accountLocations, account])

  // useEffect(() => {
  //   if (!selectedUser || !account) return
  //   let viewAll = selectedUser.viewAllLocationsAccountIds
  //   if (!viewAll.includes(account.id)) {
  //     viewAll.push(account.id)
  //   } else {
  //     viewAll = viewAll.filter((accId) => accId !== account.id)
  //   }
  //   setSelectedUser({
  //     ...selectedUser,
  //     viewAllLocationsAccountIds: viewAll,
  //   })
  // }, [viewAllLocations])

  const mapAccounts = (selectedAccounts: string[]): Account[] => {
    return selectedAccounts
      .map((g) => accounts.find((ggg) => ggg.id === g))
      .filter((g) => !!g)
      .map((g) => g!)
  }

  const mapLocations = (selectedLocations: UserLocation[]): UserLocation[] => {
    return selectedLocations.filter((loc) => {
      if (accountLocations.find((accLoc) => accLoc.id === loc.locationId)) return loc
    })
  }

  const mapGroups = (selectedGroups: string[]): Group[] => {
    return selectedGroups
      .map((g) => groups.find((ggg) => ggg.name === g))
      .filter((g) => !!g)
      .map((g) => g!)
  }

  const handleSave = () => {
    const updatedUser: any = {
      id: user.id,
      active: accountActive,
      firstname,
      lastname,
      mobile,
      email,
    }

    if (selectedUser && updatedUser.id) {
      if (userUpdate.current) {
        updateUser(updatedUser)
      }
      updateAccounts()
      updateGroups()
      updateLocations()
    }
    onHide()
  }

  useEffect(() => {
    if (selectedUser) {
      if (selectedUser.firstname !== firstname) {
        userUpdate.current = true
        setDataChange(true)
      } else if (selectedUser.lastname !== lastname) {
        userUpdate.current = true
        setDataChange(true)
      } else if (selectedUser.mobile !== mobile) {
        userUpdate.current = true
        setDataChange(true)
      } else if (selectedUser.active !== accountActive) {
        userUpdate.current = true
        setDataChange(true)
      } else if (selectedGroups.length) {
        if (selectedGroups.map((groups) => selectedUser.groups.includes(groups.name))) {
          userUpdate.current = true
          setDataChange(true)
        }
      } else {
        userUpdate.current = false
        setDataChange(false)
      }
    }
  }, [firstname, lastname, mobile, accountActive, selectedGroups])

  useEffect(() => {
    if (selectedUser && account) {
      if (
        !!selectedUser.accounts.find(
          (a) => a.accountId === account.id && a.admin !== isAccountAdmin,
        )
      ) {
        accountUpdate.current = true
        setDataChange(true)
      } else if (
        !!selectedUser.accounts.find(
          (a) => a.accountId === account.id && a.autoAddLocations !== autoAddLocations,
        )
      ) {
        accountUpdate.current = true
        setDataChange(true)
      } else if (
        !!selectedUser.accounts.find(
          (a) => a.accountId === account.id && a.autoAddAlerts !== autoAddAlerts,
        )
      ) {
        accountUpdate.current = true
        setDataChange(true)
      } else if (
        !!selectedUser.accounts.find(
          (a) => a.accountId === account.id && a.invoice !== accountInvoice,
        )
      ) {
        accountUpdate.current = true
        setDataChange(true)
      } else {
        accountUpdate.current = false
        setDataChange(false)
      }
    }
  }, [isAccountAdmin, autoAddLocations, autoAddAlerts, accountInvoice])

  const getDifference = (array1: any, array2: any) => {
    return array1.filter(
      (object1: any) =>
        !array2.some(
          (object2: any) =>
            object1.locationId === object2.locationId && object1.admin === object2.admin,
        ),
    )
  }

  useEffect(() => {
    if (selectedUser) {
      const diff = [
        ...getDifference(selectedLocations, selectedUser.customerLocations),
        ...getDifference(selectedUser.customerLocations, selectedLocations),
      ].length
      if (
        diff !== 0 ||
        (account &&
          !!selectedUser.accounts.find(
            (a) => a.accountId === account.id && a.admin !== isAccountAdmin,
          ))
      ) {
        locationUpdate.current = true
        setDataChange(true)
      } else {
        locationUpdate.current = false
        setDataChange(false)
      }
    }
  }, [selectedLocations])

  const updateAccounts = () => {
    const accountOptions: UserAccountAssoc[] = [
      {
        accountId: account!.id,
        admin: isAccountAdmin,
        autoAddAlerts: autoAddAlerts,
        autoAddLocations: autoAddLocations,
        invoice: accountInvoice,
      },
    ]

    if (selectedUser && accountUpdate.current) {
      updateUserAccount(selectedUser.id, accountOptions)
    }
  }

  const updateGroups = () => {
    const removedGroups = mapGroups(selectedUser!.groups)
      .filter((g) => selectedGroups.findIndex((gg) => gg.id === g.id) === -1)
      .map((g) => g.id)

    const addedGroups = selectedGroups
      .filter((g) => !selectedUser?.groups.includes(g.name))
      .map((g) => g.id)

    if (addedGroups.length) {
      addUserToGroups(selectedUser!, addedGroups)
    }
    if (removedGroups.length) {
      removeUserFromGroups(selectedUser!, removedGroups)
    }
  }

  const updateLocations = () => {
    if (!locationUpdate.current) return

    if (selectedUser && selectedUser.customerLocations) {
      const allowedLocations: UserLocation[] = selectedUser!.customerLocations.filter(
        (loc) => accountLocations.findIndex((accLoc) => accLoc.id === loc.locationId) !== -1,
      )
      const removedLocations = allowedLocations.filter(
        (loc2) => selectedLocations.findIndex((l) => l.locationId === loc2.locationId) === -1,
      )

      if (removedLocations.length) {
        deleteUserLocation(selectedUser.id, removedLocations)
      }

      const addedLocations = selectedLocations.filter(
        (loc) => !selectedUser?.customerLocations.find((l) => l.locationId === loc.locationId),
      )
      if (addedLocations.length) {
        addUserLocation(selectedUser.id, addedLocations)
      }

      const updatedLocations = selectedLocations.filter((loc) =>
        selectedUser.customerLocations.find(
          (l) => l.locationId === loc.locationId && l.admin !== loc.admin,
        ),
      )
      if (updatedLocations.length) {
        updateUserLocation(selectedUser.id, updatedLocations)
      }
    }
  }

  const handleAccountAdmin = (val: boolean) => {
    setIsAccountAdmin(val)
    if (!val) {
      setSelectedLocations([])
      // setSelectAllLocations(false);
    } else {
      const locations: InviteLocation[] = accountLocations.map((l: CustomerLocation) => {
        return { locationId: l.id, admin: true, alerts: false } as InviteLocation
      })
      setSelectedLocations(locations)
      // setSelectAllLocations(true);
    }
  }

  const handleLocations = (location: string, admin: boolean) => {
    // if (location === "allLoc") {
    //   if (!selectAllLocations) {
    //     const locations: InviteLocation[] = accountLocations.map((l: CustomerLocation) => {
    //       return {locationId: l.id, admin: false, alerts: false} as InviteLocation
    //     })
    //     setSelectedLocations(locations);
    //   } else {
    //     setSelectedLocations([]);
    //   }
    //   setSelectAllLocations(!selectAllLocations);
    //   return;
    // } else {
    //   setSelectAllLocations(false);
    // }
    //
    const locationIndex = selectedLocations.findIndex((x) => x.locationId === location)
    if (locationIndex !== -1) {
      const locations = [...selectedLocations]
      if (admin) {
        locations[locationIndex] = {
          ...locations[locationIndex],
          admin: !locations[locationIndex].admin,
        }
      } else {
        locations.splice(locationIndex, 1)
      }
      setSelectedLocations(locations)
    } else {
      setSelectedLocations([...selectedLocations, { locationId: location, admin: false }])
    }
  }
  // noinspection RequiredAttributes
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{`${firstname} ${lastname}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                disabled={!!selectedUser?.id}
                placeholder="Enter a Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className={'mt-3'}>
            <Form.Group as={Col} controlId="accountOptions">
              <Form.Label>Account Options</Form.Label>
              <div>
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => handleAccountAdmin(!isAccountAdmin)}
                  checked={isAccountAdmin}
                  label={
                    <>
                      Account Admin
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAccountAdmin}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-1`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAccountInvoice(!accountInvoice)}
                  checked={accountInvoice}
                  label={
                    <>
                      Account Invoices
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAccountActive}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-2`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddLocations(!autoAddLocations)}
                  checked={autoAddLocations || isAccountAdmin}
                  disabled={isAccountAdmin}
                  label={
                    <>
                      Auto Add New Locations
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddLocations}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-3`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddAlerts(!autoAddAlerts)}
                  checked={autoAddAlerts}
                  label={
                    <>
                      Auto Add Alerts
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddAlerts}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-4`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAccountActive(!accountActive)}
                  checked={accountActive}
                  label={
                    <>
                      Active
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAccountActive}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-5`}
                />
              </div>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Groups</Form.Label>
              <Select
                className="basic-multi-select"
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                menuPosition={'fixed'}
                isDisabled={!selectedUser}
                value={selectedGroups}
                onChange={(options: any) => setSelectedGroups([...options])}
                options={groups}
                isMulti
                getOptionValue={(option) => option.name}
                getOptionLabel={(option) => option.description}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row className={'mt-3'}>
            <Form.Group as={Col} controlId="formGridLocations">
              <Form.Label>Locations</Form.Label>
              <div style={{ width: '100%', height: '300px', overflowY: 'auto' }}>
                <Table striped>
                  <thead
                    style={{
                      width: '15%',
                      position: 'sticky',
                      top: -1,
                      zIndex: 1,
                    }}
                  >
                    <tr>
                      <th>Name</th>
                      <th className={'text-center'}>
                        Admin
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          trigger={['hover', 'focus']}
                          overlay={popoverLocationAdmin}
                          // containerPadding={20}
                          placement="bottom"
                        >
                          <Info size={15} className={'ml-1 text-info'} />
                        </OverlayTrigger>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountLocations.map((l) => (
                      <tr key={l.id}>
                        <td>
                          <Form.Check
                            type={'checkbox'}
                            label={l.name}
                            value={l.id}
                            id={`default-${l.id}`}
                            onChange={(e) => handleLocations(e.target.value, false)}
                            className={'ml-2'}
                            checked={!!selectedLocations.find((loc) => loc.locationId === l.id)}
                            disabled={isAccountAdmin}
                          />
                        </td>
                        <td className={'text-center'}>
                          <Form.Check
                            type="checkbox"
                            value={l.id}
                            label={''}
                            disabled={
                              !selectedLocations.find((loc) => loc.locationId === l.id) ||
                              isAccountAdmin
                            }
                            onChange={(e) => handleLocations(e.target.value, true)}
                            checked={
                              !!selectedLocations.find((i) => i.locationId === l.id && i.admin) ||
                              isAccountAdmin
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!dataChange}>
          {'Save Changes & Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal

import { useState } from 'react'
import Select from 'react-select'
import { Form, Col, Button, Row, Spinner } from 'react-bootstrap'
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  TimeScale,
} from 'chart.js'
import { useDeviceState } from '../../../context/device/context/device.context'
import Device from '../../../context/device/model/device'
import use30DaysReport from '../../../context/reporting/30days/30days.hook'
import ReportDetail from './ReportDetail'

import 'chartjs-adapter-moment'
import './30daysReport.scss'

ChartJS.register(LinearScale, BarElement, Title, Tooltip, Legend, PointElement, TimeScale)

export default function Report30Days() {
  const { report30days, get30DaysReport, loading } = use30DaysReport()
  const { devices } = useDeviceState()
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [offPeakFromHour, setOffPeakFromHour] = useState<number>()
  const [offPeakToHour, setOffPeakToHour] = useState<number>()
  const [expectedBaseline, setExpectedBaseline] = useState<number>()
  const [expectedPercentageSaving, setExpectedPercentageSaving] = useState<number>(30)

  const generateReport = () => {
    if (!selectedDevice) return

    get30DaysReport({
      deviceId: selectedDevice?.deviceId,
      expectedBaseline,
      expectedPercentageSaving,
      offPeakFromHour,
      offPeakToHour,
    })
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    generateReport()
  }

  return (
    <div className="mt-5 ml-3">
      <h1>
        <span className="fas fa-dollar-sign mr-3"></span>Water Wastage Report
      </h1>
      <div className="container-fluid flex-container ">
        <div className="multiselect-container w-25">
          <Select
            classNamePrefix="select"
            className="basic-multi-select"
            value={selectedDevice}
            onChange={(device: any) => setSelectedDevice(device)}
            options={devices}
            name="device"
            getOptionValue={(option) => option.deviceId}
            getOptionLabel={(option) => `${option.deviceName}`}
            isClearable={false}
            placeholder="Select a device..."
          />
        </div>
        <Form className="device-information-form w-25" onSubmit={handleSubmit}>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="off-peak-start">
              <Form.Label>Off Peak Start Hour</Form.Label>
              <Form.Control
                type="number"
                min={0}
                max={23}
                value={offPeakFromHour}
                onChange={(v) => setOffPeakFromHour(parseInt(v.target.value))}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="off-peak-stop">
              <Form.Label>Off Peak End Hour</Form.Label>
              <Form.Control
                autoComplete="off"
                type="number"
                min={0}
                max={23}
                value={offPeakToHour}
                onChange={(v) => setOffPeakToHour(parseInt(v.target.value))}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="expected-percentage">
              <Form.Label>Expected Baseline</Form.Label>
              <Form.Control
                autoComplete="off"
                type="number"
                value={expectedBaseline}
                onChange={(v) => setExpectedBaseline(parseInt(v.target.value))}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="expected-percentage-savings">
              <Form.Label>Expected Percentage Saving</Form.Label>
              <Form.Control
                autoComplete="off"
                type="number"
                min={0}
                max={100}
                value={expectedPercentageSaving}
                onChange={(v) => setExpectedPercentageSaving(parseInt(v.target.value))}
              />
            </Form.Group>
          </Row>

          <Button variant="primary" type="submit" className="mb-3" disabled={loading}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Loading...</span>
              </>
            ) : (
              <>Generate Report</>
            )}
          </Button>
        </Form>
        {selectedDevice ? (
          <div className="rtf-view-container">
            {report30days ? (
              <div>
                <ReportDetail device={selectedDevice} data={report30days} />
              </div>
            ) : null}
          </div>
        ) : (
          <div className="no-device-message">Select a device</div>
        )}
      </div>
    </div>
  )
}

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment-timezone'

import Device from '../../../context/device/model/device'
import {
  blueSmartflow,
  smartflowHeaderImg,
  SMARTFLOW_FOOTER_IMAGE,
} from '../../../common/utils/constants'
import { ChartType, PeriodType } from '../../../common/types/chart-types'
import type { ChartData } from 'chart.js'
import { roundToTwo } from '../../../common/utils/helperFunctions'

const DATA_SET_DEFAULTS = {
  backgroundColor: [blueSmartflow],
  borderColor: ['#000000'],
}

export const buildPDFReport = (
  selectedDevice: Device,
  fromDate: Date,
  toDate: Date,
  chartElement: HTMLElement,
  tableElement: HTMLTableElement,
  periodTotalFlow: number | null,
): Promise<jsPDF> => {
  return new Promise((res, rej) => {
    if (chartElement && tableElement) {
      html2canvas(chartElement, { scrollX: -window.screenX })
        .then((canvas) => {
          const from = moment(fromDate).format('DD-MM-YYYY')
          const to = moment(toDate).format('DD-MM-YYYY')

          const chartImgData = (chartElement.firstElementChild as HTMLCanvasElement).toDataURL(
            'image/png',
          )
          const pdf = new jsPDF('landscape', 'mm', [297, 210])

          const pdfWidth = pdf.internal.pageSize.getWidth()
          const headerPosX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
          const headerImgProps = pdf.getImageProperties(smartflowHeaderImg)
          const headerRatio = pdfWidth / headerImgProps.width

          const X_OFFSET = 35
          let posXStart = 10
          let posYStart = 60

          if (tableElement) {
            let posY = posYStart
            let posX = posXStart

            pdf.setFont('Nunito', 'bold')
            pdf.setFontSize(12)
            pdf.setTextColor(25, 106, 154)
            pdf.text('Water Usage Report: ', posX, posY)
            posY += 7

            pdf.setFontSize(10)
            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(25, 106, 154)
            pdf.text('Device Name: ', posX, posY)

            pdf.setFont('Nunito', 'normal')
            pdf.setTextColor(0, 0, 0)
            pdf.text(`${selectedDevice.deviceName}`, posX + X_OFFSET, posY)

            pdf.setFont('Nunito', 'bold')
            posX += 200
            pdf.text('Total Usage on period: ', posX, posY)
            posY += 5
            pdf.setFont('Nunito', 'normal')
            pdf.text(`${periodTotalFlow} L`, posX, posY)
            posY += 5
            pdf.text(`${roundToTwo(periodTotalFlow ? periodTotalFlow / 1000 : 0)} m3`, posX, posY)
            posY -= 5
            posX -= 200

            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(25, 106, 154)
            pdf.text('Period: ', posX, posY)

            pdf.setFont('Nunito', 'normal')
            pdf.setTextColor(0, 0, 0)
            pdf.text(`${from} to ${to}`, posX + X_OFFSET, posY)
            posY += 5

            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(25, 106, 154)
            pdf.text('Address: ', posX, posY)

            pdf.setFont('Nunito', 'normal')
            pdf.setTextColor(0, 0, 0)
            const deviceAddress = [
              // selectedDevice.deviceInfo.addressLine1,
              // selectedDevice.deviceInfo.addressLine2,
              // selectedDevice.deviceInfo.town,
              // selectedDevice.deviceInfo.postcode,
              // selectedDevice.deviceInfo.county,
            ]
            // for (let i = 0; i < deviceAddress.length; i += 1) {
            //   if (deviceAddress[i] && deviceAddress[i] !== '') {
            //     pdf.text(deviceAddress[i], posX + X_OFFSET, posY)
            //     if (i !== deviceAddress.length - 1) {
            //       posY += 5
            //     }
            //   }
            // }

            // Add Chart
            const imgProps = pdf.getImageProperties(chartImgData)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const ratio = pdfWidth / imgProps.width
            pdf.addImage(
              chartImgData,
              'PNG',
              posX,
              posY - 2,
              pdfWidth - posX * 2,
              imgProps.height * ratio,
            )

            pdf.addPage()
            // posY = headerHeight

            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(25, 106, 154)
            pdf.text('Detailed water usage history with hourly breakdown per day:', 3, posYStart)

            autoTable(pdf, {
              html: tableElement,
              startY: posYStart + 5,
              tableLineColor: [25, 106, 154],
              styles: {
                fontSize: 7,
              },
              margin: {
                top: 60,
                bottom: 40,
                left: 3,
                right: 3,
              },
              columnStyles: {
                0: { cellWidth: 'wrap' },
                1: { cellWidth: 'wrap' },
                2: { cellWidth: 'wrap' },
                3: { cellWidth: 'wrap' },
                4: { cellWidth: 'wrap' },
                5: { cellWidth: 'wrap' },
                6: { cellWidth: 'wrap' },
                7: { cellWidth: 'wrap' },
                8: { cellWidth: 'wrap' },
                9: { cellWidth: 'wrap' },
                10: { cellWidth: 'wrap' },
                11: { cellWidth: 'wrap' },
                12: { cellWidth: 'wrap' },
                13: { cellWidth: 'wrap' },
                14: { cellWidth: 'wrap' },
                15: { cellWidth: 'wrap' },
                16: { cellWidth: 'wrap' },
                17: { cellWidth: 'wrap' },
                18: { cellWidth: 'wrap' },
                19: { cellWidth: 'wrap' },
                20: { cellWidth: 'wrap' },
                21: { cellWidth: 'wrap' },
                22: { cellWidth: 'wrap' },
                23: { cellWidth: 'wrap' },
                24: { cellWidth: 'wrap' },
                25: { cellWidth: 'wrap' },
              },
            })
          }

          const pageCount = pdf.internal.pages.length // With first element null
          const realPageCount = pageCount - 1
          const docHeight = pdf.internal.pageSize.height
          for (let i = 1; i <= realPageCount; i++) {
            pdf.setPage(i)

            // Header
            pdf.addImage(smartflowHeaderImg, 'png', headerPosX, 0, pdfWidth, 55)

            // Footer
            const footerImgProps = pdf.getImageProperties(SMARTFLOW_FOOTER_IMAGE)
            const pdfWidthFooter = pdf.internal.pageSize.getWidth()
            const footerRatio = pdfWidthFooter / footerImgProps.width
            const footerHeight = footerImgProps.height * footerRatio - 50
            const footerPosY = docHeight - footerHeight
            pdf.addImage(
              SMARTFLOW_FOOTER_IMAGE,
              'png',
              headerPosX,
              pdf.internal.pageSize.getHeight() - 40,
              pdfWidth,
              50,
              undefined,
              'FAST',
            )

            pdf.setFont('Nunito', 'bold')
            pdf.setTextColor(0, 0, 0)
            pdf.setFontSize(8)
            const footerMsg = `Page ${i} of ${realPageCount}`
            const footerMsgPosY = footerPosY - 10
            pdf.text(footerMsg, 5, footerMsgPosY)
          }

          res(pdf)
        })
        .catch((error) => {
          throw new Error(`Something happened while generating PDF: ${error}`)
        })
    }
  })
}

export const getBaseDatasetConfig = () => {
  const data: any = []
  const labels: Array<string> = []

  const datasets: any = [
    {
      label: 'Usage',
      type: 'bar',
      data: data,
      fill: false,
      backgroundColor: [blueSmartflow],
      borderColor: ['#000000'],
      lineTension: 0.1,
    },
  ]

  return {
    labels,
    datasets,
  }
}

export const getChartOptions: any = (periodType: PeriodType, chartType: ChartType = 'bar') => {
  let text: string
  let xAxis: string
  switch (periodType) {
    case 'daily':
      text = 'Daily'
      xAxis = 'Dates'
      break
    case 'weekly':
      text = 'Weekly'
      xAxis = 'Weeks'
      break
    case 'monthly':
      text = 'Monthly'
      xAxis = 'Months'
      break
    case 'annually':
      text = 'Annually'
      xAxis = 'Years'
      break
    default:
      text = 'Daily'
      xAxis = 'Dates'
      break
  }

  return {
    type: chartType,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'nearest',
    },
    plugins: {
      title: {
        display: true,
        text: text,
        position: 'top',
        align: 'left',
        font: {
          size: 18,
        },
        padding: 22,
      },
      legend: {
        display: periodType === 'hourly',
      },
      tooltips: {
        mode: 'x',
        titleFontSize: 18,
        bodyFontSize: 18,
      },
    },
    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'Liters',
        },
        ticks: {
          font: {
            size: 14,
          },
          suggestedMin: 0,
          suggestedMax: 400,
        },
      },
      x: {
        offset: true,
        title: {
          display: true,
          text: xAxis,
        },
        ticks: {
          font: {
            size: 14,
          },
          padding: 8,
          maxRotation: 45,
          minRotation: 45,
        },
      },
    },
  }
}

/* Datasets per time period */
export const getDailyDatasets = (rawWaterUsage: any) => {
  const groupedByDay = rawWaterUsage.reduce((acc: any, dailyUsage: any) => {
    // create a composed key: 'year-week'
    let { flow, date } = dailyUsage
    date = date.split('T')[0]
    // add this key as a property to the result object
    if (!acc[date]) {
      acc[date] = flow
    } else {
      // push the current date that belongs to the year-week calculated before
      acc[date] += flow
    }
    return acc
  }, {})

  const chartData: ChartData = {
    labels: Object.keys(groupedByDay),
    datasets: [
      {
        ...DATA_SET_DEFAULTS,
        label: 'Daily',
        type: 'bar',
        data: Object.values(groupedByDay),
      },
    ],
  }
  return chartData
}

interface DateFlowObject {
  [date: string]: number
}

export const getMultipleDevicesDailyDatasets = (dataArray: any): any => {
  const combinedResult: DateFlowObject = {}

  dataArray.forEach((innerArray: []) => {
    const result = innerArray.reduce((acc: any, dailyUsage: any) => {
      let { flow, date } = dailyUsage
      date = date.split('T')[0]

      if (!acc[date]) {
        acc[date] = flow
      } else {
        acc[date] += flow
      }

      return acc
    }, {} as any)

    for (const date in result) {
      if (result.hasOwnProperty(date)) {
        if (!combinedResult[date]) {
          combinedResult[date] = result[date]
        } else {
          combinedResult[date] += result[date]
        }
      }
    }
  })

  const unsortedArray = Object.entries(combinedResult)

  const sortedArray = unsortedArray.sort(
    ([dateA], [dateB]) => Date.parse(dateA) - Date.parse(dateB),
  )

  const sortedObject = Object.fromEntries(sortedArray)

  const chartData: ChartData = {
    labels: Object.keys(sortedObject),
    datasets: [
      {
        ...DATA_SET_DEFAULTS,
        label: 'Daily',
        type: 'bar',
        data: Object.values(sortedObject),
      },
    ],
  }
  return chartData
}

export const getWeeklyDatasets = (rawWaterUsage: any) => {
  const groupedByWeek = rawWaterUsage.reduce((acc: any, dailyUsage: any) => {
    // create a composed key: 'year-week'
    const { flow, date } = dailyUsage
    const dateTime = moment(date)
    const weekLabel = dateTime.format('YYYY-w')
    // add this key as a property to the result object
    if (!acc[weekLabel]) {
      acc[weekLabel] = flow
    } else {
      // push the current date that belongs to the year-week calculated before
      acc[weekLabel] += flow
    }
    return acc
  }, {})

  const chartData: ChartData = {
    labels: Object.keys(groupedByWeek),
    datasets: [
      {
        ...DATA_SET_DEFAULTS,
        label: 'Weekly',
        type: 'bar',
        data: Object.values(groupedByWeek),
      },
    ],
  }
  return chartData
}

export const getMonthlyDatasets = (rawWaterUsage: any) => {
  const groupedByMonth = rawWaterUsage.reduce((acc: any, dailyUsage: any) => {
    // create a composed key: 'year-week'
    const { date, flow } = dailyUsage
    const monthLabel = moment(date).format('MMM yyyy')
    // add this key as a property to the result object
    if (!acc[monthLabel]) {
      acc[monthLabel] = flow
    } else {
      // push the current date that belongs to the year-week calculated before
      acc[monthLabel] += flow
    }
    return acc
  }, {})

  const chartData: ChartData = {
    labels: Object.keys(groupedByMonth),
    datasets: [
      {
        ...DATA_SET_DEFAULTS,
        label: 'Monthly',
        type: 'bar',
        data: Object.values(groupedByMonth),
      },
    ],
  }
  return chartData
}

export const getAnnuallyDatasets = (rawWaterUsage: any) => {
  const groupedByYear = rawWaterUsage.reduce((acc: any, dailyUsage: any) => {
    // create a composed key: 'year-week'
    const { date, flow } = dailyUsage
    const yearNumber = moment(date).year()
    // add this key as a property to the result object
    if (!acc[yearNumber]) {
      acc[yearNumber] = flow
    } else {
      // push the current date that belongs to the year-week calculated before
      acc[yearNumber] += flow
    }
    return acc
  }, {})
  const chartData: ChartData = {
    labels: Object.keys(groupedByYear),
    datasets: [
      {
        ...DATA_SET_DEFAULTS,
        label: 'Yearly',
        type: 'bar',
        data: Object.values(groupedByYear),
      },
    ],
  }
  return chartData
}

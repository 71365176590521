// interface DeviceInfo {
//   addressLine1: string
//   addressLine2: string
//   town: string
//   county: string
//   postcode: string
//   country: string
//   location: string
// }


export interface DeviceSettings {
  id: number
  dataAcqMode: string
  serverPulseControl: boolean
  pulsesPerLiter: number
  firmwareVersionDesired?: string
  timezone: string
  installDate: string
  usageStartDate: string
  monitoringStartDate: string
  deviceIDActivationDate: string
  installDateEpoch?: number
  cost: number
  currency: string
  active: boolean
  sectorType: string
  report30Day: boolean
  report30DayComplete?: string
  hot: boolean
  type: string
  masterDeviceIdRef?: number
  occupants: number
  deviceName: string
  deviceNameShort?: string
  location?: string
  uploadFreqMins?: number
}

interface DeviceValves {
  V1: string
  V2: string
  V3: string
  V4: string
  V5: string
  V6: string
}

export interface Valve {
  key: ValveKey
  state: ValveState
  name?: string
}

export type ValveState = 0 | 1 | 3 | 7

export type ValveKey = 'V1' | 'V2' | 'V3' | 'V4' | 'V5' | 'V6'

export type ValvesState = { [key in ValveKey]: ValveState }

export interface TestDevice {
  deviceId: string
  deviceName: string
  firmware: string
  binFileName: string
  binFilePath: string
  valves: { [key in ValveKey]: Valve }
  connectionStatus: string
  batteryLevel: number
  batteryLevelExt: number
  batteryStatus: string
  signal: number
  desiredFirmware?: string
  ICCID?: string
  deviceSettings?: DeviceSettings
  lastOnline?: Date
  onBattDate?: Date
  active?: string
  deviceVendor: string
  deviceVendorName?: string
  model?: string
  networkName?: string
  fplmnList?: string
}

export default interface Device extends TestDevice {
  accountId: string
  childDeviceIds: number[]
  deviceSettings: DeviceSettings
  deviceLocationId: string
  dlId?: number
  dUUID?: string
  mvno?: string
}


export interface DeviceMessage {
  key: string
  value: number | string
}

export enum DeviceIDTypes {
    deviceId = "device_id",
    dlID = "dl_id",
    dUUID = "d_uuid",
}


export interface DeviceValvesAPI {
  V1?: string
  V2?: string
  V3?: string
  V4?: string
  V5?: string
  V6?: string
}

interface DeviceValvesStateAPI {
  V1: number
  V2: number
  V3: number
  V4: number
  V5: number
  V6: number
}

export interface DevicePowerAPI {
  HUB_BAT: number
  HUB_STA: string
  CSQ: number
  ext_battery: number
}

export interface DeviceSWAPI {
  HUB_SW: string
  HUB_HW: string
  SW_868: string
  HW_868: string
}

export interface DeviceNetworkAPI {
  IMSI: string
  IMEI: string
  ICCID: string
  network_name?: string
  fplmn_list?: string
}

export interface DeviceStatusAPI {
  device_id?: string
  ping?: string
  valve_state: DeviceValvesStateAPI
  power: DevicePowerAPI
  device_sw: DeviceSWAPI
  network_info: DeviceNetworkAPI
  bin_file_name?: string
  bin_file_path?: string
  on_batt_date?: string
  last_ping?: string
  mnc?:  string
  mcc?:  string
}

export interface DeviceSettingsAPI {
  id: number
  active: boolean
  data_acq_mode: number
  server_pulse_control: boolean
  pulses_per_liter: number
  firmware_version_desired?: string
  timezone: string
  install_date: string
  usage_start_date: string
  monitoring_start_date: string
  device_id_activation_date: string
  currency: string
  cost: number
  report_30_day: boolean
  sector_type: string
  master_device_id_ref?: number
  occupants: number
  hot: boolean
  device_name: string
  device_name_short?: string
  upload_freq_mins?: number
  location?: string
}

export interface DeviceAPI {
  id: number
  dl_id: number
  d_uuid: string
  created_at: string
  updated_at: string
  device_id: string
  valve_names: DeviceValvesAPI
  device_settings: DeviceSettingsAPI
  device_status: DeviceStatusAPI
  state: string
  customer_location_id: number
  account_id: number
  vendor: string,
  child_device_ids: number[] | []
  model?: string
}

export interface DeviceUpdateAPI {
  device_name?: string
  cost?: number
  currency?: string
  location?: string
  sector_type?: string
  report_30_day?: boolean
  install_date?: string
  monitoring_start_date?: string
  usage_start_date?: string
  active?: boolean
  hot?: boolean
  master_device_id_ref?: number | null
  occupants?: number
}
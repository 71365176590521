import Account from '../model/Account'

export enum ActionTypes {
  INIT,
  SELECT_ACCOUNTS,
  LOADING,
  ERROR,
  UPDATE_DEVICES,
  UPDATE_STATISTICS,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
}

interface State {
  accounts: Account[]
  selectedAccounts: Account[]
  loading: boolean
  error: boolean
}

export interface Action {
  type: ActionTypes
  payload?: any
}

export const initialState: State = {
  accounts: [],
  selectedAccounts: [],
  loading: false,
  error: false,
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.INIT:
      const accounts: Account[] = action.payload?.accounts.map((account: any) => {
        return {
          id: `${String(account.id)}`,
          isActive: account.active,
          name: account.account_name,
          addressLine1: account.address_line_1,
          addressLine2: account.address_line_2,
          town: account.town,
          postcode: account.postcode,
          county: account.county,
          country: account.country,
          phone: account.phone_no,
        }
      })

      const activeAccounts = accounts.filter((account) => account.isActive)

      return { accounts, selectedAccounts: [...activeAccounts], loading: false, error: false }
    case ActionTypes.SELECT_ACCOUNTS:
      return { ...state, selectedAccounts: action.payload, loading: false, error: false }
    case ActionTypes.ADD_ACCOUNT:
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
        loading: false,
        error: false,
      }
    case ActionTypes.DELETE_ACCOUNT: {
      const account: Account = action.payload
      return {
        ...state,
        accounts: state.accounts.filter((a) => a.id !== account.id),
        loading: false,
        error: false,
      }
    }
    case ActionTypes.UPDATE_ACCOUNT: {
      const account: Account = action.payload
      const accounts = state.accounts.map((a) => (account.id === a.id ? account : a))
      const selectedAccounts = state.selectedAccounts.map((a) =>
        account.id === a.id ? account : a,
      )
      return { ...state, accounts, selectedAccounts, loading: false, error: false }
    }
    case ActionTypes.LOADING:
      return { ...state, loading: true, error: false }
    case ActionTypes.ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

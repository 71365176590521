import backend from '../../../api/backend'
import { ActionTypes } from './30days.reducer'
import { displayToast } from '../../../common/utils/appToast'

export type ReportQuery = {
  deviceId: string
  expectedBaseline?: number
  offPeakFromHour?: number
  offPeakToHour?: number
  expectedPercentageSaving: number
}

export const get30DaysReportAction = async (reportQuery: ReportQuery, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const report30days = await backend.get(
      `/reports/${reportQuery.deviceId}/initial_30_day_report`,
      {
        params: {
          off_peak_baseline: reportQuery.expectedBaseline,
          off_peak_start_hour: reportQuery.offPeakFromHour,
          off_peak_end_hour: reportQuery.offPeakToHour,
          projected_saving: reportQuery.expectedPercentageSaving,
        },
      },
    )

    const data = report30days.data
    /*
    const data = {
      "est_30_day": [
        {
          "date": "2022-01-25",
          "flow": 0,
          "waste_est": 0,
          "flow_less_waste_est": 0,
          "flow_less_waste_est_cost": 0
        },
        {
          "date": "2022-01-26",
          "flow": 0,
          "waste_est": 0,
          "flow_less_waste_est": 0,
          "flow_less_waste_est_cost": 0
        },
        {
          "date": "2022-01-27",
          "flow": 9069.72,
          "waste_est": 3447.52,
          "flow_less_waste_est": 5622.2,
          "flow_less_waste_est_cost": 12.37
        },
        {
          "date": "2022-01-28",
          "flow": 10526.41,
          "waste_est": 4040.22,
          "flow_less_waste_est": 6486.19,
          "flow_less_waste_est_cost": 14.27
        },
        {
          "date": "2022-01-29",
          "flow": 13260.28,
          "waste_est": 4815.51,
          "flow_less_waste_est": 8444.77,
          "flow_less_waste_est_cost": 18.58
        },
        {
          "date": "2022-01-30",
          "flow": 12541.64,
          "waste_est": 4672.34,
          "flow_less_waste_est": 7869.29,
          "flow_less_waste_est_cost": 17.31
        },
        {
          "date": "2022-01-31",
          "flow": 8958.95,
          "waste_est": 3569.53,
          "flow_less_waste_est": 5389.43,
          "flow_less_waste_est_cost": 11.86
        },
        {
          "date": "2022-02-01",
          "flow": 8105.62,
          "waste_est": 3235.63,
          "flow_less_waste_est": 4869.98,
          "flow_less_waste_est_cost": 10.71
        },
        {
          "date": "2022-02-02",
          "flow": 9188.45,
          "waste_est": 3671.29,
          "flow_less_waste_est": 5517.16,
          "flow_less_waste_est_cost": 12.14
        },
        {
          "date": "2022-02-03",
          "flow": 12571.53,
          "waste_est": 4686.51,
          "flow_less_waste_est": 7885.02,
          "flow_less_waste_est_cost": 17.35
        },
        {
          "date": "2022-02-04",
          "flow": 19217.44,
          "waste_est": 8370.75,
          "flow_less_waste_est": 10846.69,
          "flow_less_waste_est_cost": 23.86
        },
        {
          "date": "2022-02-05",
          "flow": 13040.07,
          "waste_est": 4933.53,
          "flow_less_waste_est": 8106.54,
          "flow_less_waste_est_cost": 17.83
        },
        {
          "date": "2022-02-06",
          "flow": 12398.75,
          "waste_est": 4639.09,
          "flow_less_waste_est": 7759.66,
          "flow_less_waste_est_cost": 17.07
        },
        {
          "date": "2022-02-07",
          "flow": 9997.9,
          "waste_est": 3951.23,
          "flow_less_waste_est": 6046.66,
          "flow_less_waste_est_cost": 13.3
        },
        {
          "date": "2022-02-08",
          "flow": 11378.02,
          "waste_est": 4303.86,
          "flow_less_waste_est": 7074.16,
          "flow_less_waste_est_cost": 15.56
        },
        {
          "date": "2022-02-09",
          "flow": 9263.53,
          "waste_est": 3667.84,
          "flow_less_waste_est": 5595.68,
          "flow_less_waste_est_cost": 12.31
        },
        {
          "date": "2022-02-10",
          "flow": 14914.7,
          "waste_est": 5931.24,
          "flow_less_waste_est": 8983.46,
          "flow_less_waste_est_cost": 19.76
        },
        {
          "date": "2022-02-11",
          "flow": 13389.35,
          "waste_est": 5446.04,
          "flow_less_waste_est": 7943.31,
          "flow_less_waste_est_cost": 17.48
        },
        {
          "date": "2022-02-12",
          "flow": 16117.79,
          "waste_est": 5772.75,
          "flow_less_waste_est": 10345.04,
          "flow_less_waste_est_cost": 22.76
        },
        {
          "date": "2022-02-13",
          "flow": 15234.27,
          "waste_est": 6248.73,
          "flow_less_waste_est": 8985.54,
          "flow_less_waste_est_cost": 19.77
        },
        {
          "date": "2022-02-14",
          "flow": 15182.08,
          "waste_est": 6091.57,
          "flow_less_waste_est": 9090.52,
          "flow_less_waste_est_cost": 20
        },
        {
          "date": "2022-02-15",
          "flow": 15746.46,
          "waste_est": 6113.96,
          "flow_less_waste_est": 9632.5,
          "flow_less_waste_est_cost": 21.19
        },
        {
          "date": "2022-02-16",
          "flow": 14812.2,
          "waste_est": 5733,
          "flow_less_waste_est": 9079.21,
          "flow_less_waste_est_cost": 19.97
        },
        {
          "date": "2022-02-17",
          "flow": 12429.17,
          "waste_est": 4648.84,
          "flow_less_waste_est": 7780.33,
          "flow_less_waste_est_cost": 17.12
        },
        {
          "date": "2022-02-18",
          "flow": 10633.91,
          "waste_est": 4053.12,
          "flow_less_waste_est": 6580.79,
          "flow_less_waste_est_cost": 14.48
        },
        {
          "date": "2022-02-19",
          "flow": 91.15,
          "waste_est": 27.35,
          "flow_less_waste_est": 63.81,
          "flow_less_waste_est_cost": 0.14
        }
      ],
      "est_7_day": [
        {
          "date": "2022-01-25T16:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T17:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T18:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T19:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T20:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T21:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T22:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-25T23:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T00:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T01:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T02:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T03:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T04:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T05:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T06:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T07:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T08:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T09:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T10:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T11:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T12:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T13:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T14:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T15:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T16:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T17:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T18:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T19:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T20:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T21:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T22:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-26T23:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T00:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T01:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T02:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T03:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T04:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T05:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T06:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T07:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T08:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-27T09:00:00",
          "flow_less_waste_est": 85.57152822580647,
          "waste_est": 110.81647177419353
        },
        {
          "date": "2022-01-27T10:00:00",
          "flow_less_waste_est": 616.7266282258065,
          "waste_est": 338.45437177419353
        },
        {
          "date": "2022-01-27T11:00:00",
          "flow_less_waste_est": 465.8815282258065,
          "waste_est": 273.8064717741935
        },
        {
          "date": "2022-01-27T12:00:00",
          "flow_less_waste_est": 433.88522822580654,
          "waste_est": 260.0937717741935
        },
        {
          "date": "2022-01-27T13:00:00",
          "flow_less_waste_est": 405.7200282258065,
          "waste_est": 248.02297177419354
        },
        {
          "date": "2022-01-27T14:00:00",
          "flow_less_waste_est": 376.9290282258065,
          "waste_est": 235.68397177419354
        },
        {
          "date": "2022-01-27T15:00:00",
          "flow_less_waste_est": 461.42392822580655,
          "waste_est": 271.8960717741935
        },
        {
          "date": "2022-01-27T16:00:00",
          "flow_less_waste_est": 519.3006282258065,
          "waste_est": 296.7003717741935
        },
        {
          "date": "2022-01-27T17:00:00",
          "flow_less_waste_est": 445.8951282258065,
          "waste_est": 265.24087177419347
        },
        {
          "date": "2022-01-27T18:00:00",
          "flow_less_waste_est": 458.73452822580646,
          "waste_est": 270.7434717741935
        },
        {
          "date": "2022-01-27T19:00:00",
          "flow_less_waste_est": 397.90942822580655,
          "waste_est": 244.6755717741935
        },
        {
          "date": "2022-01-27T20:00:00",
          "flow_less_waste_est": 409.3859282258065,
          "waste_est": 249.59407177419354
        },
        {
          "date": "2022-01-27T21:00:00",
          "flow_less_waste_est": 381.4601282258065,
          "waste_est": 237.6258717741935
        },
        {
          "date": "2022-01-27T22:00:00",
          "flow_less_waste_est": 150.08002822580647,
          "waste_est": 138.46297177419353
        },
        {
          "date": "2022-01-27T23:00:00",
          "flow_less_waste_est": 13.299299999999999,
          "waste_est": 5.6997
        },
        {
          "date": "2022-01-28T00:00:00",
          "flow_less_waste_est": 0.9296282258064636,
          "waste_est": 74.54137177419354
        },
        {
          "date": "2022-01-28T01:00:00",
          "flow_less_waste_est": 43.600899999999996,
          "waste_est": 18.686100000000003
        },
        {
          "date": "2022-01-28T02:00:00",
          "flow_less_waste_est": 5.323500000000001,
          "waste_est": 2.2814999999999994
        },
        {
          "date": "2022-01-28T03:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-28T04:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-28T05:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-28T06:00:00",
          "flow_less_waste_est": 34.538000000000004,
          "waste_est": 14.802
        },
        {
          "date": "2022-01-28T07:00:00",
          "flow_less_waste_est": 34.6304,
          "waste_est": 14.8416
        },
        {
          "date": "2022-01-28T08:00:00",
          "flow_less_waste_est": 168.53762822580643,
          "waste_est": 146.37337177419357
        },
        {
          "date": "2022-01-28T09:00:00",
          "flow_less_waste_est": 383.8730282258065,
          "waste_est": 238.65997177419354
        },
        {
          "date": "2022-01-28T10:00:00",
          "flow_less_waste_est": 514.7142282258064,
          "waste_est": 294.7347717741935
        },
        {
          "date": "2022-01-28T11:00:00",
          "flow_less_waste_est": 315.3857282258064,
          "waste_est": 209.30827177419354
        },
        {
          "date": "2022-01-28T12:00:00",
          "flow_less_waste_est": 339.84862822580646,
          "waste_est": 219.7923717741935
        },
        {
          "date": "2022-01-28T13:00:00",
          "flow_less_waste_est": 379.7101282258065,
          "waste_est": 236.8758717741935
        },
        {
          "date": "2022-01-28T14:00:00",
          "flow_less_waste_est": 346.8850282258064,
          "waste_est": 222.80797177419356
        },
        {
          "date": "2022-01-28T15:00:00",
          "flow_less_waste_est": 314.9251282258064,
          "waste_est": 209.11087177419353
        },
        {
          "date": "2022-01-28T16:00:00",
          "flow_less_waste_est": 405.77532822580645,
          "waste_est": 248.04667177419356
        },
        {
          "date": "2022-01-28T17:00:00",
          "flow_less_waste_est": 610.5379282258066,
          "waste_est": 335.80207177419345
        },
        {
          "date": "2022-01-28T18:00:00",
          "flow_less_waste_est": 610.5190282258066,
          "waste_est": 335.79397177419344
        },
        {
          "date": "2022-01-28T19:00:00",
          "flow_less_waste_est": 646.6971282258064,
          "waste_est": 351.2988717741936
        },
        {
          "date": "2022-01-28T20:00:00",
          "flow_less_waste_est": 455.9898282258065,
          "waste_est": 269.5671717741935
        },
        {
          "date": "2022-01-28T21:00:00",
          "flow_less_waste_est": 492.6096282258065,
          "waste_est": 285.2613717741935
        },
        {
          "date": "2022-01-28T22:00:00",
          "flow_less_waste_est": 317.24632822580645,
          "waste_est": 210.10567177419352
        },
        {
          "date": "2022-01-28T23:00:00",
          "flow_less_waste_est": 63.90932822580646,
          "waste_est": 101.53267177419355
        },
        {
          "date": "2022-01-29T00:00:00",
          "flow_less_waste_est": 27.206899999999997,
          "waste_est": 11.6601
        },
        {
          "date": "2022-01-29T01:00:00",
          "flow_less_waste_est": 26.064500000000002,
          "waste_est": 11.170499999999997
        },
        {
          "date": "2022-01-29T02:00:00",
          "flow_less_waste_est": 10.3523,
          "waste_est": 4.4367
        },
        {
          "date": "2022-01-29T03:00:00",
          "flow_less_waste_est": 1.5840999999999998,
          "waste_est": 0.6789000000000001
        },
        {
          "date": "2022-01-29T04:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-29T05:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-01-29T06:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 16.839
        },
        {
          "date": "2022-01-29T07:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 44.628
        },
        {
          "date": "2022-01-29T08:00:00",
          "flow_less_waste_est": 139.58072822580644,
          "waste_est": 133.96327177419354
        },
        {
          "date": "2022-01-29T09:00:00",
          "flow_less_waste_est": 385.9919282258064,
          "waste_est": 239.56807177419353
        },
        {
          "date": "2022-01-29T10:00:00",
          "flow_less_waste_est": 784.9800282258063,
          "waste_est": 410.56297177419356
        },
        {
          "date": "2022-01-29T11:00:00",
          "flow_less_waste_est": 557.4863282258065,
          "waste_est": 313.06567177419356
        },
        {
          "date": "2022-01-29T12:00:00",
          "flow_less_waste_est": 566.3469282258066,
          "waste_est": 316.8630717741935
        },
        {
          "date": "2022-01-29T13:00:00",
          "flow_less_waste_est": 835.4150282258065,
          "waste_est": 432.17797177419357
        },
        {
          "date": "2022-01-29T14:00:00",
          "flow_less_waste_est": 561.9628282258064,
          "waste_est": 314.98417177419356
        },
        {
          "date": "2022-01-29T15:00:00",
          "flow_less_waste_est": 571.5416282258064,
          "waste_est": 319.0893717741935
        },
        {
          "date": "2022-01-29T16:00:00",
          "flow_less_waste_est": 512.7066282258065,
          "waste_est": 293.8743717741935
        },
        {
          "date": "2022-01-29T17:00:00",
          "flow_less_waste_est": 519.3748282258065,
          "waste_est": 296.7321717741935
        },
        {
          "date": "2022-01-29T18:00:00",
          "flow_less_waste_est": 596.4273282258065,
          "waste_est": 329.7546717741935
        },
        {
          "date": "2022-01-29T19:00:00",
          "flow_less_waste_est": 654.5441282258065,
          "waste_est": 354.6618717741935
        },
        {
          "date": "2022-01-29T20:00:00",
          "flow_less_waste_est": 581.6727282258065,
          "waste_est": 323.43127177419353
        },
        {
          "date": "2022-01-29T21:00:00",
          "flow_less_waste_est": 564.1734282258064,
          "waste_est": 315.93157177419357
        },
        {
          "date": "2022-01-29T22:00:00",
          "flow_less_waste_est": 330.8774282258065,
          "waste_est": 215.94757177419353
        },
        {
          "date": "2022-01-29T23:00:00",
          "flow_less_waste_est": 96.47682822580649,
          "waste_est": 115.49017177419353
        },
        {
          "date": "2022-01-30T00:00:00",
          "flow_less_waste_est": 7.819028225806463,
          "waste_est": 77.49397177419354
        },
        {
          "date": "2022-01-30T01:00:00",
          "flow_less_waste_est": 45.57209999999999,
          "waste_est": 19.530900000000003
        },
        {
          "date": "2022-01-30T02:00:00",
          "flow_less_waste_est": 16.3016,
          "waste_est": 6.9864
        },
        {
          "date": "2022-01-30T03:00:00",
          "flow_less_waste_est": 10.186399999999999,
          "waste_est": 4.365600000000001
        },
        {
          "date": "2022-01-30T04:00:00",
          "flow_less_waste_est": 8.822799999999999,
          "waste_est": 3.7812
        },
        {
          "date": "2022-01-30T05:00:00",
          "flow_less_waste_est": 17.462200000000003,
          "waste_est": 7.483799999999999
        },
        {
          "date": "2022-01-30T06:00:00",
          "flow_less_waste_est": 29.5092,
          "waste_est": 12.646799999999999
        },
        {
          "date": "2022-01-30T07:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 65.075
        },
        {
          "date": "2022-01-30T08:00:00",
          "flow_less_waste_est": 155.38532822580646,
          "waste_est": 140.73667177419355
        },
        {
          "date": "2022-01-30T09:00:00",
          "flow_less_waste_est": 378.8078282258065,
          "waste_est": 236.48917177419355
        },
        {
          "date": "2022-01-30T10:00:00",
          "flow_less_waste_est": 320.93042822580645,
          "waste_est": 211.68457177419356
        },
        {
          "date": "2022-01-30T11:00:00",
          "flow_less_waste_est": 330.3062282258064,
          "waste_est": 215.7027717741936
        },
        {
          "date": "2022-01-30T12:00:00",
          "flow_less_waste_est": 671.2699282258064,
          "waste_est": 361.83007177419347
        },
        {
          "date": "2022-01-30T13:00:00",
          "flow_less_waste_est": 633.1766282258066,
          "waste_est": 345.5043717741935
        },
        {
          "date": "2022-01-30T14:00:00",
          "flow_less_waste_est": 633.5266282258065,
          "waste_est": 345.6543717741936
        },
        {
          "date": "2022-01-30T15:00:00",
          "flow_less_waste_est": 627.8160282258066,
          "waste_est": 343.20697177419345
        },
        {
          "date": "2022-01-30T16:00:00",
          "flow_less_waste_est": 764.0353282258066,
          "waste_est": 401.5866717741935
        },
        {
          "date": "2022-01-30T17:00:00",
          "flow_less_waste_est": 702.5851282258066,
          "waste_est": 375.25087177419346
        },
        {
          "date": "2022-01-30T18:00:00",
          "flow_less_waste_est": 495.50132822580645,
          "waste_est": 286.5006717741935
        },
        {
          "date": "2022-01-30T19:00:00",
          "flow_less_waste_est": 524.3847282258065,
          "waste_est": 298.8792717741935
        },
        {
          "date": "2022-01-30T20:00:00",
          "flow_less_waste_est": 573.7333282258064,
          "waste_est": 320.0286717741935
        },
        {
          "date": "2022-01-30T21:00:00",
          "flow_less_waste_est": 488.8702282258065,
          "waste_est": 283.6587717741935
        },
        {
          "date": "2022-01-30T22:00:00",
          "flow_less_waste_est": 354.03202822580647,
          "waste_est": 225.87097177419355
        },
        {
          "date": "2022-01-30T23:00:00",
          "flow_less_waste_est": 19.25772822580646,
          "waste_est": 82.39627177419354
        },
        {
          "date": "2022-01-31T00:00:00",
          "flow_less_waste_est": 44.908500000000004,
          "waste_est": 19.246499999999997
        },
        {
          "date": "2022-01-31T01:00:00",
          "flow_less_waste_est": 22.896300000000004,
          "waste_est": 9.8127
        },
        {
          "date": "2022-01-31T02:00:00",
          "flow_less_waste_est": 10.756900000000002,
          "waste_est": 4.610099999999999
        },
        {
          "date": "2022-01-31T03:00:00",
          "flow_less_waste_est": 13.8887,
          "waste_est": 5.952300000000001
        },
        {
          "date": "2022-01-31T04:00:00",
          "flow_less_waste_est": 10.3887,
          "waste_est": 4.452299999999999
        },
        {
          "date": "2022-01-31T05:00:00",
          "flow_less_waste_est": 10.922799999999999,
          "waste_est": 4.6812000000000005
        },
        {
          "date": "2022-01-31T06:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 36.706999999999994
        },
        {
          "date": "2022-01-31T07:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 88.20599999999999
        },
        {
          "date": "2022-01-31T08:00:00",
          "flow_less_waste_est": 183.3664282258065,
          "waste_est": 152.72857177419354
        },
        {
          "date": "2022-01-31T09:00:00",
          "flow_less_waste_est": 203.61042822580646,
          "waste_est": 161.40457177419353
        },
        {
          "date": "2022-01-31T10:00:00",
          "flow_less_waste_est": 353.6638282258064,
          "waste_est": 225.71317177419354
        },
        {
          "date": "2022-01-31T11:00:00",
          "flow_less_waste_est": 273.46062822580643,
          "waste_est": 191.34037177419356
        },
        {
          "date": "2022-01-31T12:00:00",
          "flow_less_waste_est": 373.4108282258065,
          "waste_est": 234.1761717741935
        },
        {
          "date": "2022-01-31T13:00:00",
          "flow_less_waste_est": 428.81932822580643,
          "waste_est": 257.92267177419353
        },
        {
          "date": "2022-01-31T14:00:00",
          "flow_less_waste_est": 335.85092822580646,
          "waste_est": 218.0790717741935
        },
        {
          "date": "2022-01-31T15:00:00",
          "flow_less_waste_est": 333.03272822580647,
          "waste_est": 216.87127177419353
        },
        {
          "date": "2022-01-31T16:00:00",
          "flow_less_waste_est": 390.19192822580646,
          "waste_est": 241.36807177419348
        },
        {
          "date": "2022-01-31T17:00:00",
          "flow_less_waste_est": 475.3308282258065,
          "waste_est": 277.8561717741935
        },
        {
          "date": "2022-01-31T18:00:00",
          "flow_less_waste_est": 468.90202822580653,
          "waste_est": 275.1009717741935
        },
        {
          "date": "2022-01-31T19:00:00",
          "flow_less_waste_est": 564.0439282258064,
          "waste_est": 315.8760717741935
        },
        {
          "date": "2022-01-31T20:00:00",
          "flow_less_waste_est": 305.6228282258065,
          "waste_est": 205.12417177419354
        },
        {
          "date": "2022-01-31T21:00:00",
          "flow_less_waste_est": 201.17862822580645,
          "waste_est": 160.36237177419355
        },
        {
          "date": "2022-01-31T22:00:00",
          "flow_less_waste_est": 253.97192822580644,
          "waste_est": 182.98807177419354
        },
        {
          "date": "2022-01-31T23:00:00",
          "flow_less_waste_est": 11.208428225806461,
          "waste_est": 78.94657177419354
        },
        {
          "date": "2022-02-01T00:00:00",
          "flow_less_waste_est": 28.256899999999998,
          "waste_est": 12.1101
        },
        {
          "date": "2022-02-01T01:00:00",
          "flow_less_waste_est": 0.7363999999999999,
          "waste_est": 0.3156000000000001
        },
        {
          "date": "2022-02-01T02:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-02-01T03:00:00",
          "flow_less_waste_est": 7.3682,
          "waste_est": 3.1578
        },
        {
          "date": "2022-02-01T04:00:00",
          "flow_less_waste_est": 8.547,
          "waste_est": 3.6630000000000003
        },
        {
          "date": "2022-02-01T05:00:00",
          "flow_less_waste_est": 0,
          "waste_est": 0
        },
        {
          "date": "2022-02-01T06:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 5.155000000000001
        },
        {
          "date": "2022-02-01T07:00:00",
          "flow_less_waste_est": 60,
          "waste_est": 82.62700000000001
        },
        {
          "date": "2022-02-01T08:00:00",
          "flow_less_waste_est": 156.89592822580641,
          "waste_est": 141.38407177419356
        },
        {
          "date": "2022-02-01T09:00:00",
          "flow_less_waste_est": 310.5963282258064,
          "waste_est": 207.25567177419356
        },
        {
          "date": "2022-02-01T10:00:00",
          "flow_less_waste_est": 338.94562822580644,
          "waste_est": 219.40537177419355
        },
        {
          "date": "2022-02-01T11:00:00",
          "flow_less_waste_est": 294.49702822580645,
          "waste_est": 200.35597177419356
        },
        {
          "date": "2022-02-01T12:00:00",
          "flow_less_waste_est": 326.6039282258065,
          "waste_est": 214.11607177419353
        },
        {
          "date": "2022-02-01T13:00:00",
          "flow_less_waste_est": 366.6873282258065,
          "waste_est": 231.2946717741935
        },
        {
          "date": "2022-02-01T14:00:00",
          "flow_less_waste_est": 353.92142822580644,
          "waste_est": 225.82357177419357
        },
        {
          "date": "2022-02-01T15:00:00",
          "flow_less_waste_est": 268.69012822580646,
          "waste_est": 189.29587177419353
        },
        {
          "date": "2022-02-01T16:00:00",
          "flow_less_waste_est": 396.5836282258065,
          "waste_est": 244.10737177419355
        }
      ],
      "est_12_month": [
        {
          "month_year": "January 2022",
          "cost_waste_cum": 0,
          "cost_less_waste_est_cum": 119.5854
        },
        {
          "month_year": "February 2022",
          "cost_waste_cum": 77.22385302500011,
          "cost_less_waste_est_cum": 824.6858542083332
        },
        {
          "month_year": "March 2022",
          "cost_waste_cum": 307.6674645000001,
          "cost_less_waste_est_cum": 1394.6909427
        },
        {
          "month_year": "April 2022",
          "cost_waste_cum": 535.6423405300002,
          "cost_less_waste_est_cum": 1959.7349541366666
        },
        {
          "month_year": "May 2022",
          "cost_waste_cum": 768.3359882550001,
          "cost_less_waste_est_cum": 2536.749525695
        },
        {
          "month_year": "June 2022",
          "cost_waste_cum": 992.2362401050001,
          "cost_less_waste_est_cum": 3090.5149294616667
        },
        {
          "month_year": "July 2022",
          "cost_waste_cum": 1228.6095488150002,
          "cost_less_waste_est_cum": 3676.621921151667
        },
        {
          "month_year": "August 2022",
          "cost_waste_cum": 1458.242930485,
          "cost_less_waste_est_cum": 4245.530645381667
        },
        {
          "month_year": "September 2022",
          "cost_waste_cum": 1685.0017246700002,
          "cost_less_waste_est_cum": 4807.7141155633335
        },
        {
          "month_year": "October 2022",
          "cost_waste_cum": 1920.2936335000002,
          "cost_less_waste_est_cum": 5390.365818566666
        },
        {
          "month_year": "November 2022",
          "cost_waste_cum": 2142.8117060900004,
          "cost_less_waste_est_cum": 5941.354632143333
        },
        {
          "month_year": "December 2022",
          "cost_waste_cum": 2378.7121210050004,
          "cost_less_waste_est_cum": 6525.414918495
        },
        {
          "month_year": "January 2023",
          "cost_waste_cum": 2605.0574960850004,
          "cost_less_waste_est_cum": 7087.616853881667
        }
      ],
      "daily_usage_30_day": [
        {
          "Date": "2022-01-25",
          "DayOfYear": "25",
          "DailyUsage": "0.0",
          "1am": "-",
          "2am": "-",
          "3am": "-",
          "4am": "-",
          "5am": "-",
          "6am": "-",
          "7am": "-",
          "8am": "-",
          "9am": "-",
          "10am": "-",
          "11am": "-",
          "Midday": "-",
          "1pm": "-",
          "2pm": "-",
          "3pm": "-",
          "4pm": "0.0",
          "5pm": "0.0",
          "6pm": "0.0",
          "7pm": "0.0",
          "8pm": "0.0",
          "9pm": "0.0",
          "10pm": "0.0",
          "11pm": "0.0",
          "Midnight": "-"
        },
        {
          "Date": "2022-01-26",
          "DayOfYear": "26",
          "DailyUsage": "0.0",
          "1am": "0.0",
          "2am": "0.0",
          "3am": "0.0",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "0.0",
          "7am": "0.0",
          "8am": "0.0",
          "9am": "0.0",
          "10am": "0.0",
          "11am": "0.0",
          "Midday": "0.0",
          "1pm": "0.0",
          "2pm": "0.0",
          "3pm": "0.0",
          "4pm": "0.0",
          "5pm": "0.0",
          "6pm": "0.0",
          "7pm": "0.0",
          "8pm": "0.0",
          "9pm": "0.0",
          "10pm": "0.0",
          "11pm": "0.0",
          "Midnight": "0.0"
        },
        {
          "Date": "2022-01-27",
          "DayOfYear": "27",
          "DailyUsage": "9069.72",
          "1am": "0.0",
          "2am": "0.0",
          "3am": "0.0",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "0.0",
          "7am": "0.0",
          "8am": "0.0",
          "9am": "196.39",
          "10am": "955.18",
          "11am": "739.69",
          "Midday": "693.98",
          "1pm": "653.74",
          "2pm": "612.61",
          "3pm": "733.32",
          "4pm": "816.0",
          "5pm": "711.14",
          "6pm": "729.48",
          "7pm": "642.58",
          "8pm": "658.98",
          "9pm": "619.09",
          "10pm": "288.54",
          "11pm": "19.0",
          "Midnight": "0.0"
        },
        {
          "Date": "2022-01-28",
          "DayOfYear": "28",
          "DailyUsage": "10526.41",
          "1am": "62.29",
          "2am": "7.6",
          "3am": "0.0",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "49.34",
          "7am": "49.47",
          "8am": "314.91",
          "9am": "622.53",
          "10am": "809.45",
          "11am": "524.69",
          "Midday": "559.64",
          "1pm": "616.59",
          "2pm": "569.69",
          "3pm": "524.04",
          "4pm": "653.82",
          "5pm": "946.34",
          "6pm": "946.31",
          "7pm": "998.0",
          "8pm": "725.56",
          "9pm": "777.87",
          "10pm": "527.35",
          "11pm": "165.44",
          "Midnight": "75.47"
        },
        {
          "Date": "2022-01-29",
          "DayOfYear": "29",
          "DailyUsage": "13260.28",
          "1am": "37.24",
          "2am": "14.79",
          "3am": "2.26",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "76.84",
          "7am": "104.63",
          "8am": "273.54",
          "9am": "625.56",
          "10am": "1195.54",
          "11am": "870.55",
          "Midday": "883.21",
          "1pm": "1267.59",
          "2pm": "876.95",
          "3pm": "890.63",
          "4pm": "806.58",
          "5pm": "816.11",
          "6pm": "926.18",
          "7pm": "1009.21",
          "8pm": "905.1",
          "9pm": "880.1",
          "10pm": "546.83",
          "11pm": "211.97",
          "Midnight": "38.87"
        },
        {
          "Date": "2022-01-30",
          "DayOfYear": "30",
          "DailyUsage": "12541.64",
          "1am": "65.1",
          "2am": "23.29",
          "3am": "14.55",
          "4am": "12.6",
          "5am": "24.95",
          "6am": "42.16",
          "7am": "125.08",
          "8am": "296.12",
          "9am": "615.3",
          "10am": "532.62",
          "11am": "546.01",
          "Midday": "1033.1",
          "1pm": "978.68",
          "2pm": "979.18",
          "3pm": "971.02",
          "4pm": "1165.62",
          "5pm": "1077.84",
          "6pm": "782.0",
          "7pm": "823.26",
          "8pm": "893.76",
          "9pm": "772.53",
          "10pm": "579.9",
          "11pm": "101.65",
          "Midnight": "85.31"
        },
        {
          "Date": "2022-01-31",
          "DayOfYear": "31",
          "DailyUsage": "8958.95",
          "1am": "32.71",
          "2am": "15.37",
          "3am": "19.84",
          "4am": "14.84",
          "5am": "15.6",
          "6am": "96.71",
          "7am": "148.21",
          "8am": "336.1",
          "9am": "365.02",
          "10am": "579.38",
          "11am": "464.8",
          "Midday": "607.59",
          "1pm": "686.74",
          "2pm": "553.93",
          "3pm": "549.9",
          "4pm": "631.56",
          "5pm": "753.19",
          "6pm": "744.0",
          "7pm": "879.92",
          "8pm": "510.75",
          "9pm": "361.54",
          "10pm": "436.96",
          "11pm": "90.16",
          "Midnight": "64.16"
        },
        {
          "Date": "2022-02-01",
          "DayOfYear": "32",
          "DailyUsage": "8105.62",
          "1am": "1.05",
          "2am": "0.0",
          "3am": "10.53",
          "4am": "12.21",
          "5am": "0.0",
          "6am": "65.16",
          "7am": "142.63",
          "8am": "298.28",
          "9am": "517.85",
          "10am": "558.35",
          "11am": "494.85",
          "Midday": "540.72",
          "1pm": "597.98",
          "2pm": "579.74",
          "3pm": "457.99",
          "4pm": "640.69",
          "5pm": "484.22",
          "6pm": "553.61",
          "7pm": "546.14",
          "8pm": "792.0",
          "9pm": "461.62",
          "10pm": "286.31",
          "11pm": "23.32",
          "Midnight": "40.37"
        },
        {
          "Date": "2022-02-02",
          "DayOfYear": "33",
          "DailyUsage": "9188.45",
          "1am": "0.0",
          "2am": "0.39",
          "3am": "5.95",
          "4am": "11.02",
          "5am": "2.21",
          "6am": "12.71",
          "7am": "280.36",
          "8am": "95.16",
          "9am": "451.2",
          "10am": "780.53",
          "11am": "510.72",
          "Midday": "601.69",
          "1pm": "684.58",
          "2pm": "586.8",
          "3pm": "571.25",
          "4pm": "697.72",
          "5pm": "652.93",
          "6pm": "702.82",
          "7pm": "704.08",
          "8pm": "747.74",
          "9pm": "723.79",
          "10pm": "302.36",
          "11pm": "10.03",
          "Midnight": "52.42"
        },
        {
          "Date": "2022-02-03",
          "DayOfYear": "34",
          "DailyUsage": "12571.53",
          "1am": "61.58",
          "2am": "12.66",
          "3am": "10.32",
          "4am": "10.05",
          "5am": "21.08",
          "6am": "80.52",
          "7am": "211.84",
          "8am": "207.41",
          "9am": "488.83",
          "10am": "829.74",
          "11am": "642.48",
          "Midday": "577.74",
          "1pm": "976.52",
          "2pm": "1034.05",
          "3pm": "905.02",
          "4pm": "867.0",
          "5pm": "656.53",
          "6pm": "737.4",
          "7pm": "693.0",
          "8pm": "819.9",
          "9pm": "1096.31",
          "10pm": "908.16",
          "11pm": "684.66",
          "Midnight": "38.74"
        },
        {
          "Date": "2022-02-04",
          "DayOfYear": "35",
          "DailyUsage": "19217.44",
          "1am": "525.59",
          "2am": "520.77",
          "3am": "508.38",
          "4am": "482.01",
          "5am": "506.04",
          "6am": "545.96",
          "7am": "699.64",
          "8am": "717.45",
          "9am": "1154.52",
          "10am": "1422.67",
          "11am": "774.03",
          "Midday": "843.95",
          "1pm": "1047.2",
          "2pm": "1060.6",
          "3pm": "1120.1",
          "4pm": "890.63",
          "5pm": "859.84",
          "6pm": "900.76",
          "7pm": "736.66",
          "8pm": "936.29",
          "9pm": "1083.97",
          "10pm": "841.63",
          "11pm": "468.85",
          "Midnight": "569.9"
        },
        {
          "Date": "2022-02-05",
          "DayOfYear": "36",
          "DailyUsage": "13040.07",
          "1am": "77.63",
          "2am": "49.76",
          "3am": "15.26",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "87.47",
          "7am": "208.68",
          "8am": "273.94",
          "9am": "452.83",
          "10am": "854.32",
          "11am": "930.13",
          "Midday": "939.39",
          "1pm": "1013.71",
          "2pm": "1113.62",
          "3pm": "1050.1",
          "4pm": "924.66",
          "5pm": "756.95",
          "6pm": "810.58",
          "7pm": "722.5",
          "8pm": "799.84",
          "9pm": "987.44",
          "10pm": "659.8",
          "11pm": "219.68",
          "Midnight": "91.79"
        },
        {
          "Date": "2022-02-06",
          "DayOfYear": "37",
          "DailyUsage": "12398.75",
          "1am": "42.74",
          "2am": "33.66",
          "3am": "21.76",
          "4am": "16.39",
          "5am": "16.26",
          "6am": "21.47",
          "7am": "287.1",
          "8am": "238.1",
          "9am": "542.17",
          "10am": "886.68",
          "11am": "599.51",
          "Midday": "697.24",
          "1pm": "931.16",
          "2pm": "967.18",
          "3pm": "1045.84",
          "4pm": "1133.57",
          "5pm": "914.71",
          "6pm": "1071.84",
          "7pm": "956.58",
          "8pm": "1019.84",
          "9pm": "545.56",
          "10pm": "305.04",
          "11pm": "54.16",
          "Midnight": "50.21"
        },
        {
          "Date": "2022-02-07",
          "DayOfYear": "38",
          "DailyUsage": "9997.9",
          "1am": "90.1",
          "2am": "25.45",
          "3am": "6.55",
          "4am": "6.24",
          "5am": "2.34",
          "6am": "73.58",
          "7am": "197.23",
          "8am": "134.84",
          "9am": "404.99",
          "10am": "532.46",
          "11am": "608.35",
          "Midday": "586.9",
          "1pm": "994.23",
          "2pm": "720.11",
          "3pm": "649.08",
          "4pm": "710.32",
          "5pm": "967.79",
          "6pm": "700.24",
          "7pm": "664.32",
          "8pm": "757.32",
          "9pm": "587.27",
          "10pm": "421.38",
          "11pm": "64.73",
          "Midnight": "92.08"
        },
        {
          "Date": "2022-02-08",
          "DayOfYear": "39",
          "DailyUsage": "11378.02",
          "1am": "0.0",
          "2am": "0.0",
          "3am": "0.63",
          "4am": "0.03",
          "5am": "0.0",
          "6am": "21.1",
          "7am": "245.65",
          "8am": "202.15",
          "9am": "404.17",
          "10am": "854.13",
          "11am": "706.79",
          "Midday": "861.24",
          "1pm": "807.21",
          "2pm": "920.16",
          "3pm": "1039.36",
          "4pm": "1037.89",
          "5pm": "1040.55",
          "6pm": "750.08",
          "7pm": "839.66",
          "8pm": "780.48",
          "9pm": "601.82",
          "10pm": "200.02",
          "11pm": "27.84",
          "Midnight": "37.05"
        },
        {
          "Date": "2022-02-09",
          "DayOfYear": "40",
          "DailyUsage": "9263.53",
          "1am": "49.58",
          "2am": "0.84",
          "3am": "0.0",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "78.94",
          "7am": "175.89",
          "8am": "184.34",
          "9am": "299.86",
          "10am": "607.03",
          "11am": "479.85",
          "Midday": "521.43",
          "1pm": "614.59",
          "2pm": "628.77",
          "3pm": "421.59",
          "4pm": "567.17",
          "5pm": "861.26",
          "6pm": "834.13",
          "7pm": "802.98",
          "8pm": "812.16",
          "9pm": "629.3",
          "10pm": "450.49",
          "11pm": "211.2",
          "Midnight": "32.13"
        },
        {
          "Date": "2022-02-10",
          "DayOfYear": "41",
          "DailyUsage": "14914.7",
          "1am": "182.84",
          "2am": "182.23",
          "3am": "187.99",
          "4am": "181.89",
          "5am": "190.18",
          "6am": "250.73",
          "7am": "290.25",
          "8am": "261.7",
          "9am": "558.17",
          "10am": "954.92",
          "11am": "769.42",
          "Midday": "852.11",
          "1pm": "1037.13",
          "2pm": "1070.81",
          "3pm": "1310.86",
          "4pm": "898.26",
          "5pm": "921.5",
          "6pm": "1058.44",
          "7pm": "775.74",
          "8pm": "864.6",
          "9pm": "1053.97",
          "10pm": "503.14",
          "11pm": "311.78",
          "Midnight": "246.04"
        },
        {
          "Date": "2022-02-11",
          "DayOfYear": "42",
          "DailyUsage": "13389.35",
          "1am": "210.07",
          "2am": "173.26",
          "3am": "169.94",
          "4am": "161.57",
          "5am": "169.7",
          "6am": "214.05",
          "7am": "346.36",
          "8am": "449.93",
          "9am": "430.57",
          "10am": "1040.7",
          "11am": "929.13",
          "Midday": "739.08",
          "1pm": "881.0",
          "2pm": "818.92",
          "3pm": "830.03",
          "4pm": "993.02",
          "5pm": "1082.86",
          "6pm": "810.26",
          "7pm": "819.74",
          "8pm": "739.71",
          "9pm": "686.35",
          "10pm": "333.62",
          "11pm": "187.78",
          "Midnight": "171.68"
        },
        {
          "Date": "2022-02-12",
          "DayOfYear": "43",
          "DailyUsage": "16117.79",
          "1am": "58.31",
          "2am": "8.24",
          "3am": "0.0",
          "4am": "6.0",
          "5am": "7.42",
          "6am": "114.81",
          "7am": "209.49",
          "8am": "471.28",
          "9am": "830.79",
          "10am": "692.69",
          "11am": "739.79",
          "Midday": "805.87",
          "1pm": "1005.97",
          "2pm": "1314.46",
          "3pm": "1353.35",
          "4pm": "1298.62",
          "5pm": "1556.85",
          "6pm": "1187.65",
          "7pm": "994.39",
          "8pm": "1045.92",
          "9pm": "1028.68",
          "10pm": "869.13",
          "11pm": "450.22",
          "Midnight": "67.87"
        },
        {
          "Date": "2022-02-13",
          "DayOfYear": "44",
          "DailyUsage": "15234.27",
          "1am": "265.96",
          "2am": "253.97",
          "3am": "233.91",
          "4am": "222.73",
          "5am": "233.02",
          "6am": "265.94",
          "7am": "462.04",
          "8am": "659.56",
          "9am": "522.4",
          "10am": "841.11",
          "11am": "737.4",
          "Midday": "800.24",
          "1pm": "919.76",
          "2pm": "1149.75",
          "3pm": "1144.81",
          "4pm": "879.16",
          "5pm": "1061.76",
          "6pm": "654.95",
          "7pm": "1001.6",
          "8pm": "872.29",
          "9pm": "892.87",
          "10pm": "613.09",
          "11pm": "272.52",
          "Midnight": "273.44"
        },
        {
          "Date": "2022-02-14",
          "DayOfYear": "45",
          "DailyUsage": "15182.08",
          "1am": "289.99",
          "2am": "229.73",
          "3am": "226.97",
          "4am": "216.15",
          "5am": "233.12",
          "6am": "285.31",
          "7am": "253.94",
          "8am": "504.64",
          "9am": "695.56",
          "10am": "627.82",
          "11am": "717.74",
          "Midday": "711.32",
          "1pm": "965.63",
          "2pm": "770.13",
          "3pm": "882.95",
          "4pm": "990.65",
          "5pm": "1109.84",
          "6pm": "874.71",
          "7pm": "1395.69",
          "8pm": "1031.1",
          "9pm": "645.3",
          "10pm": "828.58",
          "11pm": "437.62",
          "Midnight": "257.6"
        },
        {
          "Date": "2022-02-15",
          "DayOfYear": "46",
          "DailyUsage": "15746.46",
          "1am": "231.65",
          "2am": "192.86",
          "3am": "178.99",
          "4am": "171.73",
          "5am": "178.28",
          "6am": "196.99",
          "7am": "279.6",
          "8am": "487.83",
          "9am": "725.22",
          "10am": "807.08",
          "11am": "892.68",
          "Midday": "874.68",
          "1pm": "900.6",
          "2pm": "958.08",
          "3pm": "881.71",
          "4pm": "917.45",
          "5pm": "1147.99",
          "6pm": "879.89",
          "7pm": "928.42",
          "8pm": "1026.68",
          "9pm": "968.44",
          "10pm": "847.74",
          "11pm": "866.37",
          "Midnight": "205.49"
        },
        {
          "Date": "2022-02-16",
          "DayOfYear": "47",
          "DailyUsage": "14812.2",
          "1am": "473.75",
          "2am": "421.67",
          "3am": "183.78",
          "4am": "153.31",
          "5am": "160.92",
          "6am": "185.05",
          "7am": "178.7",
          "8am": "384.67",
          "9am": "646.93",
          "10am": "917.42",
          "11am": "619.9",
          "Midday": "771.29",
          "1pm": "788.29",
          "2pm": "983.21",
          "3pm": "855.16",
          "4pm": "631.11",
          "5pm": "1199.88",
          "6pm": "767.0",
          "7pm": "975.79",
          "8pm": "1068.94",
          "9pm": "850.68",
          "10pm": "718.9",
          "11pm": "353.73",
          "Midnight": "522.12"
        },
        {
          "Date": "2022-02-17",
          "DayOfYear": "48",
          "DailyUsage": "12429.17",
          "1am": "164.15",
          "2am": "0.0",
          "3am": "0.37",
          "4am": "0.03",
          "5am": "0.0",
          "6am": "65.55",
          "7am": "52.66",
          "8am": "324.99",
          "9am": "687.43",
          "10am": "637.64",
          "11am": "872.24",
          "Midday": "955.97",
          "1pm": "879.13",
          "2pm": "958.05",
          "3pm": "1127.78",
          "4pm": "909.05",
          "5pm": "906.52",
          "6pm": "920.21",
          "7pm": "889.79",
          "8pm": "798.5",
          "9pm": "581.17",
          "10pm": "328.6",
          "11pm": "74.84",
          "Midnight": "294.52"
        },
        {
          "Date": "2022-02-18",
          "DayOfYear": "49",
          "DailyUsage": "10633.91",
          "1am": "34.76",
          "2am": "0.0",
          "3am": "0.0",
          "4am": "0.0",
          "5am": "0.0",
          "6am": "55.42",
          "7am": "132.21",
          "8am": "280.07",
          "9am": "409.01",
          "10am": "817.19",
          "11am": "645.95",
          "Midday": "589.4",
          "1pm": "830.11",
          "2pm": "921.21",
          "3pm": "851.68",
          "4pm": "1047.84",
          "5pm": "687.66",
          "6pm": "735.08",
          "7pm": "633.88",
          "8pm": "644.95",
          "9pm": "637.88",
          "10pm": "478.35",
          "11pm": "136.65",
          "Midnight": "64.6"
        },
        {
          "Date": "2022-02-19",
          "DayOfYear": "50",
          "DailyUsage": "91.15",
          "1am": "17.71",
          "2am": "0.0",
          "3am": "0.0",
          "4am": "0.37",
          "5am": "0.0",
          "6am": "17.21",
          "7am": "-",
          "8am": "-",
          "9am": "-",
          "10am": "-",
          "11am": "-",
          "Midday": "-",
          "1pm": "-",
          "2pm": "-",
          "3pm": "-",
          "4pm": "-",
          "5pm": "-",
          "6pm": "-",
          "7pm": "-",
          "8pm": "-",
          "9pm": "-",
          "10pm": "-",
          "11pm": "-",
          "Midnight": "55.87"
        }
      ],
      "currency": "GBP",
      "summary_30_day": {
        "cost": 633,
        "waste_est_cost": 387,
        "saving": 246,
        "flow": 288069,
        "flow_less_waste_est": 175997,
        "flow_saving": 112071
      },
      "summary_12_month": {
        "cost": 9692,
        "waste_est_cost": 7087,
        "saving": 2605,
        "flow": 4405761,
        "flow_less_waste_est": 3221644,
        "flow_saving": 1184117
      }
    }*/
    dispatch({ type: ActionTypes.LOADED_30_DAYS_REPORTS, payload: { query: reportQuery, data } })
  } catch (error: any) {
    displayToast({
      type: 'error',
      message: error?.response?.data?.detail || 'Something went wrong',
    })
    dispatch({ type: ActionTypes.ERROR })
  }
}

import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

export const buildDeviceCostsPdfFile = (tableElement: HTMLTableElement): Promise<jsPDF> => {
  return new Promise((res, rej) => {
    const pdf = new jsPDF('landscape', 'mm', [297, 210])

    autoTable(pdf, {
      html: tableElement,
      startY: 10,
      rowPageBreak: 'auto',
      tableWidth: 'wrap',
      tableLineColor: [25, 106, 154],
      styles: {
        fontSize: 8,
      },
      margin: {
        top: 10,
        bottom: 10,
        left: 5,
      },
      columnStyles: {
        0: { cellWidth: 'wrap' },
        1: { cellWidth: 'wrap' },
        2: { cellWidth: 'wrap' },
        3: { cellWidth: 'wrap' },
        4: { cellWidth: 'wrap' },
        5: { cellWidth: 'wrap' },
        6: { cellWidth: 'wrap' },
        7: { cellWidth: 'wrap' },
        8: { cellWidth: 'wrap' },
        9: { cellWidth: 'wrap' },
        10: { cellWidth: 'wrap' },
        11: { cellWidth: 'wrap' },
        12: { cellWidth: 'wrap' },
        13: { cellWidth: 'wrap' },
        14: { cellWidth: 'wrap' },
        15: { cellWidth: 'wrap' },
        16: { cellWidth: 'wrap' },
        17: { cellWidth: 'wrap' },
        18: { cellWidth: 'wrap' },
        19: { cellWidth: 'wrap' },
        20: { cellWidth: 'wrap' },
        21: { cellWidth: 'wrap' },
        22: { cellWidth: 'wrap' },
        23: { cellWidth: 'wrap' },
        24: { cellWidth: 'wrap' },
        25: { cellWidth: 'wrap' },
      },
    })

    res(pdf)
  })
}

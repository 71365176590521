import React from 'react'
import { LayoutContextProvider } from './layout/context/layout.context'
import { UserContextProvider } from './user/context/user.context'

export const MySmartflowContext = ({ children }: any) => {
  return (
    <LayoutContextProvider>
      <UserContextProvider>
        {children}
      </UserContextProvider>
    </LayoutContextProvider>
  )
}

import React, { useState } from 'react'
import { Form, Col, Button, Spinner, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { validateEmailStructure } from '../../../common/utils/helperFunctions'
import { useUserDispatch, useUserState } from '../../../context/user/context/user.context'

import './ForgotPassword.scss'

const ForgotPassword: React.FC = () => {
  const { sendingForgotEmail } = useUserState()
  const { forgotPassword } = useUserDispatch()

  const [email, setEmail] = useState<string>('')
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (email) {
      const isThisEmailValid = validateEmailStructure(email)
      if (isThisEmailValid) {
        setIsEmailValid(true)
        await forgotPassword(email)
      } else {
        setIsEmailValid(false)
      }
    }
  }

  return (
    <div className="background">
      <div className="login-form">
        <img src="/assets/img/logo.svg" alt="Smartflow Logo" width="200" />
        <h2 className="mt-3 mb-3">Forgot password?</h2>
        <Form className="" onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="forgot-email-id" className="form-group">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value.trim())}
                isInvalid={!isEmailValid}
              />
              <Form.Control.Feedback type="invalid">Incorrect email format</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit" disabled={!email}>
            {sendingForgotEmail ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Sending...</span>
              </>
            ) : (
              <>Send Email</>
            )}
          </Button>
          <Link to="/">
            <Button variant="secondary">
              <i className="fas fa-fw  fa-arrow-left" />
              Back to Login
            </Button>
          </Link>
        </Form>
      </div>
    </div>
  )
}

export default ForgotPassword

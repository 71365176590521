import React from 'react'
import { BrowserRouter, Route, Routes, Navigate, useNavigationType, useLocation, createRoutesFromChildren, matchRoutes } from 'react-router-dom'

import SideBar from './components/nav/SideBar/Sidebar'
import TopBar from './components/nav/TopBar/TopBar'
import Login from './components/main/Login/LoginForm'
import MainContent from './components/main/MainContent'
import { useUserState } from './context/user/context/user.context'
import { AccountContextProvider } from './context/account/context/account.context'
import { DeviceContextProvider } from './context/device/context/device.context'
import { AlertContextProvider } from './context/alert/context/alert.context'
import ForgotPassword from './components/main/Login/ForgotPassword'
import ConfirmEmail from './components/main/Login/ConfirmEmail'
import GlobalDetectIdle from './components/general/GlobalDetectIdle/GlobalDetectIdle'
import ResetPassword from './components/main/Login/ResetPassword'
import { WaterUsageStatsContextProvider } from './context/waterUsage/waterUsageStats.context'
import { UsageStatsContextProvider } from './context/waterUsage/context/usageStats.context'
import { ToastContainer } from 'react-toastify'
import Loading from './components/general/Loading/Loading'
import RedirectRoute from './RedirectRoute'
import * as Sentry from "@sentry/react";

import './App.scss'
import './common/styles'
import 'react-toastify/dist/ReactToastify.css'


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || "",
    integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay()
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: process.env.REACT_APP_SENTRY_ENABLED === "true" && process.env.REACT_APP_SENTRY_DSN !== "",
});


export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App: React.FC = () => {
  const { authenticated, loading } = useUserState()

  if (loading) {
    return <Loading />
  }

  if (!authenticated) {
    return (
      <BrowserRouter>
        <ToastContainer />
          <SentryRoutes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/users/confirm_email/:token" element={<ConfirmEmail />} />
            <Route path="/users/register/:token" element={<ConfirmEmail />} />
            <Route path="/users/reset_password/:token" element={<ResetPassword />} />
            <Route
              path="*"
              element={
                <RedirectRoute>
                  <Navigate to="/" replace />
                </RedirectRoute>
              }
            />
        </SentryRoutes>
      </BrowserRouter>
    )
  }

  return (
    <BrowserRouter>
      <GlobalDetectIdle>
        <div id="wrapper">
          <AccountContextProvider>
            <DeviceContextProvider>
              <SideBar />
              <ToastContainer />
              <div id="content-wrapper" className="d-flex flex-column content-wrapper">
                <TopBar />
                <WaterUsageStatsContextProvider>
                  <AlertContextProvider>
                    <UsageStatsContextProvider>
                      <MainContent />
                    </UsageStatsContextProvider>
                  </AlertContextProvider>
                </WaterUsageStatsContextProvider>
              </div>
            </DeviceContextProvider>
          </AccountContextProvider>
        </div>
      </GlobalDetectIdle>
    </BrowserRouter>
  )
}

export default App

import { useEffect, useState } from 'react'

import { Doughnut } from 'react-chartjs-2'

import { useDeviceState } from '../../../../context/device/context/device.context'
import { HistoricalAlert, StatsDevices } from '../../../../context/alert/model/alert.model'

const HistoricalStats = ({ historical }: { historical: HistoricalAlert[] }) => {
  const { devices } = useDeviceState()
  const [historicalStats, setHistoricalStats] = useState<StatsDevices>()

  useEffect(() => {
    const stats = historical.reduce(
      (s, h) => {
        const device = devices.find((d) => d.deviceId === h.deviceId)
        if (!device) return s

        return {
          tier1Total: s.tier1Total + h.tier1.length,
          tier2Total: s.tier2Total + h.tier2.length,
          tier3Total: s.tier3Total + h.tier3.length,
          totalAll: s.totalAll + h.tier1.length + h.tier2.length + h.tier3.length,
          devices: [
            ...s.devices,
            {
              deviceId: device.deviceId,
              deviceName: device.deviceName,
              tier1: h.tier1.length,
              tier2: h.tier1.length,
              tier3: h.tier1.length,
              total: h.tier1.length + h.tier2.length + h.tier3.length,
            },
          ],
        }
      },
      { tier1Total: 0, tier2Total: 0, tier3Total: 0, totalAll: 0, devices: [] } as StatsDevices,
    )

    setHistoricalStats(stats)
  }, [historical, devices])

  return historicalStats ? (
    <>
      <div style={{ display: 'flex', margin: '1em' }}>
        <div style={{ width: '50%', position: 'relative' }}>
          <Doughnut
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom' as const,
                },
              },
            }}
            data={{
              labels: ['Tier 1', 'Tier 2', 'Tier 3'],
              datasets: [
                {
                  label: '# total',
                  data: [
                    historicalStats.tier1Total,
                    historicalStats.tier2Total,
                    historicalStats.tier3Total,
                  ],
                  backgroundColor: ['rgb(255, 99, 132)', 'rgb(255, 205, 86)', 'rgb(54, 162, 235)'],
                  hoverOffset: 4,
                },
              ],
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            Total #<br />
            {historicalStats.tier1Total + historicalStats.tier2Total + historicalStats.tier3Total}
          </span>
        </div>
        <div style={{ width: '50%', position: 'relative' }}>
          <Doughnut
            options={{
              responsive: true,
              aspectRatio: 2,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom' as const,
                },
              },
            }}
            data={{
              labels: historicalStats.devices
                .sort((a, b) => b.total - a.total)
                .slice(0, 5)
                .reverse()
                .map((d) => `${d.deviceName}`),
              // .map(d => `${d.deviceName} (#${d.deviceId}`),
              datasets: [
                {
                  label: 'devices',
                  data: historicalStats.devices
                    .sort((a, b) => b.total - a.total)
                    .slice(0, 5)
                    .reverse()
                    .map((d) => d.total),
                  backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 206, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(153, 102, 255)',
                  ],
                  hoverOffset: 4,
                },
              ],
            }}
          />
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            Top 5
          </span>
        </div>
      </div>
    </>
  ) : null
}

export default HistoricalStats

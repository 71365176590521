import React, { useEffect } from 'react'

import { Action, ActionTypes, initialState, reducer } from './usageStats.reducer'
import { AggUsageQuery, MonthlyLeakQuery } from '../model/usageStats.model'
import { getMonthlyAggUsageAction, getMonthlyLeakAction } from '../action/usageStats.action'
import { useDeviceState } from '../../device/context/device.context'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const UsageStatsContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const { devices, loadingDevices } = useDeviceState()

  useEffect(() => {
    if (loadingDevices) return

    dispatch({ type: ActionTypes.UPDATING, payload: { updating: 'updatingMonthlyLeakStats' } })
    dispatch({ type: ActionTypes.FILTER_MONTHLY_AGG_STATS, payload: { devices: devices } })
    dispatch({ type: ActionTypes.FILTER_MONTHLY_LEAK_STATS, payload: { devices: devices } })
  }, [devices])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useUsageStatsState = () => {
  return React.useContext(StateContext)
}

export const useUsageStatsDispatch = () => {
  const dispatch = React.useContext(DispatchContext) as (action: Action) => any

  if (dispatch === undefined) {
    throw new Error('useUsageStatsDispatch must be used within a UsageStatsContextProvider')
  }

  const testData = React.useCallback(async (query: AggUsageQuery) => {
    await getMonthlyAggUsageAction(query, dispatch)
  }, [])

  const loadMonthlyLeakStats = React.useCallback(async (query: MonthlyLeakQuery) => {
    await getMonthlyLeakAction(query, dispatch)
  }, [])

  return React.useMemo(
    () => ({
      testData,
      loadMonthlyLeakStats,
    }),
    [testData, loadMonthlyLeakStats],
  )
}

import { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { FlaskConical } from 'lucide-react'
import { useDeviceDispatch, useDeviceState } from '../../../context/device/context/device.context'
import { TestDevice } from '../../../context/device/model/device'
import CustomSearchTable from '../../general/CustomSearchTable/CustomSearchTable'

const COLUMNS = [
  {
    name: 'Name',
    key: 'deviceName',
  },
  {
    name: 'ID',
    key: 'deviceId',
  },
  {
    name: 'Firmware',
    key: 'firmware',
  },
]

function Dashboard() {
  const { testDevices, stagedDevices } = useDeviceState()
  const { promoteToStaged } = useDeviceDispatch()

  const [tabKey, setTabKey] = useState<string>('staged_devices_tab')

  const testDeviceTableColumns = [
    ...COLUMNS,
    {
      name: 'Actions',
      key: (testDevice: TestDevice) => (
        <button className="btn btn-primary" onClick={() => promoteToStaged(testDevice.deviceId)}>
          Stage Device
        </button>
      ),
    },
  ]

  return (
    <div>
      <h1 className="mt-4 mb-4  ">
        <FlaskConical size={35} className="mb-2 mr-3" />
        Smartflow Test Bench
      </h1>
      <div className="container-fluid">
        <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k: any) => setTabKey(k)}>
          <Tab eventKey="staged_devices_tab" title="Staged Devices">
            <h2 className="mt-3 mb-3">Staged Devices</h2>
            <CustomSearchTable
              dataArray={stagedDevices}
              columns={COLUMNS}
              rowKeys={COLUMNS.map((column) => column.key)}
            />
          </Tab>
          <Tab eventKey="test_devices_tab" title="Test Devices">
            <h2 className="mt-3 mb-3">Test Devices</h2>
            <CustomSearchTable
              dataArray={testDevices}
              columns={testDeviceTableColumns}
              rowKeys={testDeviceTableColumns.map((column) => column.key)}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default Dashboard

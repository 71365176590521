import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import Select from 'react-select'
import { sortObjectsByKey } from '../../../common/utils/helperFunctions'
import { useAlertDispatch, useAlertState } from '../../../context/alert/context/alert.context'
import { useDeviceState } from '../../../context/device/context/device.context'
import DateRangeComponent from '../../main/Components/Calendar/DateRangeSelector'
import {
  Alert,
  AlertGroup,
  AlertStatus,
  HistoricalAlert,
  Stats,
} from '../../../context/alert/model/alert.model'
import AlertQueueItem from './AlertQueueItem'
import api from '../../../api/backend'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import HistoricalStats from '../Components/HistoricalAlertsStats/HistoricalAlertsStats'

ChartJS.register(ArcElement, Tooltip, Legend)

export default function HistoricalAlertView() {
  const { devices, loadingDevices } = useDeviceState()
  const { historical, isLoading } = useAlertState()
  const { loadHistoricalAlerts } = useAlertDispatch()
  const [selectedHistoricalGroups, setSelectedHistoricalGroups] = useState<any[]>([])
  const [selectedHistoricalGroup, setSelectedHistoricalGroup] = useState<any>()
  const [selectedAlertGroup, setSelectedAlertGroup] = useState<AlertGroup>()
  const [sortingConf, setSortingConf] = useState({ key: '', order: '' })
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  const [searchTerm, setSearchTerm] = useState('')
  const [filtered, setFiltered] = useState<HistoricalAlert[]>([])
  const [statsTotal, setStatsTotal] = useState<Stats>({
    tier1Total: 0,
    tier2Total: 0,
    tier3Total: 0,
    totalAll: 0,
  })

  useEffect(() => {
    const sortedObjects = sortObjectsByKey(
      historical.filter((h) => {
        const device = devices.find((d) => d.deviceId === h.deviceId)
        return (
          device &&
          (searchTerm.length === 0 ||
            h.deviceId.includes(searchTerm) ||
            device.deviceName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
        )
      }),
      sortingConf.key,
      sortingConf.order,
    )

    if (fromDate && toDate) {
      const dateFiltered = filterObjectsByDateRange(sortedObjects, fromDate, toDate)

      setFiltered(dateFiltered)
    } else {
      setFiltered(sortedObjects)
    }
  }, [devices, searchTerm, historical, sortingConf, fromDate, toDate])

  useEffect(() => {
    setStatsTotal(
      filtered
        .filter((h) => !!devices.find((d) => d.deviceId === h.deviceId))
        .reduce(
          (accumulator, historical: HistoricalAlert) => {
            return {
              tier1Total: accumulator.tier1Total + historical.tier1.length,
              tier2Total: accumulator.tier2Total + historical.tier2.length,
              tier3Total: accumulator.tier3Total + historical.tier3.length,
              totalAll:
                accumulator.totalAll +
                historical.tier1.length +
                historical.tier2.length +
                historical.tier3.length,
            }
          },
          { tier1Total: 0, tier2Total: 0, tier3Total: 0, totalAll: 0 } as Stats,
        ),
    )
  }, [devices, historical, filtered])

  useEffect(() => {
    if (!selectedHistoricalGroup) return
    const device = devices.find((d) => d.deviceId === selectedHistoricalGroup.deviceId)
    if (!device) return

    api
      .get(
        `usage_alerts/${selectedHistoricalGroup.deviceId}/history/${selectedHistoricalGroup.label}`,
      )
      .then((res: any) => res.data)
      .then((res: any[]) => {
        const alerts = res.map((r) => {
          return {
            id: r.id,
            time: r.alert_time,
            priorityTier: r.ack_result,
            alert_score: r.alert_score,
            status: AlertStatus.CLOSED,
            triggerType: r.trigger_type,
            estimatedLeak: 0,
          } as Alert
        })
        setSelectedAlertGroup({
          deviceLocation: device.deviceName,
          priorityTier: selectedHistoricalGroup.priorityTier,
          status: AlertStatus.CLOSED,
          triggerType: res[0].trigger_type,
          id: String(res[0].id),
          hourlyAlertsSentDate: res[0].hourly_alerts_sent_date,
          alertCloseDate: selectedHistoricalGroup.label,
          startAlertTimeUTC: res[alerts.length - 1].alert_time_utc,
          startAlertTime: res[alerts.length - 1].alert_time,
          endAlertTime: res[0].alert_time,
          endAlertTimeUTC: res[0].alert_time_utc,
          alerts,
          deviceId: device.deviceId,
          dlId: res[0].dl_id,
          estimatedLeakTotal: 0,
        })
      })
  }, [devices, selectedHistoricalGroup])

  useEffect(() => {
    loadHistoricalAlerts()
  }, [])

  function onColumnHeaderClick(columnKey: string) {
    let order = ''
    if (sortingConf.key === columnKey) {
      if (sortingConf.order === '') {
        order = 'DESC'
      } else if (sortingConf.order === 'DESC') {
        order = 'ASC'
      } else {
        order = ''
      }
    } else {
      order = 'DESC'
    }
    setSortingConf({ key: columnKey, order })
  }

  function getSortingClass(columnKey: string) {
    return sortingConf.key === columnKey && sortingConf.order !== ''
      ? `fas fa-arrow-${sortingConf.order === 'ASC' ? 'down' : 'up'}`
      : ''
  }

  function filterTiersByDateRange(tiers: string[], start: Date, end: Date): string[] {
    return tiers.filter((date) => {
      const dateInTier = new Date(date)
      return dateInTier >= start && dateInTier <= end
    })
  }

  function filterObjectsByDateRange(alerts: HistoricalAlert[], start: Date, end: Date) {
    return alerts
      .map((obj) => ({
        ...obj,
        tier1: filterTiersByDateRange(obj.tier1, start, end),
        tier2: filterTiersByDateRange(obj.tier2, start, end),
        tier3: filterTiersByDateRange(obj.tier3, start, end),
      }))
      .filter((obj) => obj.tier1.length > 0 || obj.tier2.length > 0 || obj.tier3.length > 0)
  }

  return isLoading || loadingDevices ? (
    <div className="spinner-border m-auto mb-5 mt-5 d-flex text-primary" />
  ) : (
    <>
      <h1>Alert History</h1>

      <div className="search-section">
        <div className="form-group search-bar">
          <span className="fa fa-search search-icon"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search by device name or ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <span className="fa fa-times clear-icon" onClick={() => setSearchTerm('')}></span>
        </div>
        <div className="result-number">
          <span>( {filtered.length} )</span>
        </div>
      </div>
      <div style={{ width: '30%' }}>
        <DateRangeComponent
          from={fromDate}
          to={toDate}
          fromCallback={setFromDate}
          toCallback={setToDate}
          label={'Date Range'}
          isClearable
        />
      </div>
      {historical.length > 0 ? (
        <div>
          <HistoricalStats historical={filtered} />
          <div>
            <Table>
              <thead>
                <tr>
                  <th>Device Location</th>
                  <th
                    className="clickable"
                    style={{ width: '15%' }}
                    onClick={() => onColumnHeaderClick('tier1')}
                  >
                    <i className={getSortingClass('tier1')}></i>Tier 1
                  </th>
                  <th
                    className="clickable"
                    style={{ width: '15%' }}
                    onClick={() => onColumnHeaderClick('tier2')}
                  >
                    <i className={getSortingClass('tier2')}></i>Tier 2
                  </th>
                  <th
                    className="clickable"
                    style={{ width: '15%' }}
                    onClick={() => onColumnHeaderClick('tier3')}
                  >
                    <i className={getSortingClass('tier3')}></i>Tier 3
                  </th>
                </tr>
              </thead>
              <tbody>
                {filtered
                  .filter((h) => !!devices.find((d) => d.deviceId === h.deviceId))
                  .map((h) => {
                    const device = devices.find((d) => d.deviceId === h.deviceId)!
                    return (
                      <tr key={h.deviceId}>
                        <td>
                          {device.deviceName} (#{h.deviceId})
                        </td>
                        <td>
                          <button
                            className="btn btn-link te text-decoration-none px-1"
                            onClick={() => {
                              setSelectedHistoricalGroups(
                                h.tier1.map((g) => {
                                  return {
                                    deviceId: h.deviceId,
                                    deviceLocation: device.deviceName,
                                    priorityTier: 1,
                                    label: g,
                                  }
                                }),
                              )
                            }}
                          >
                            {h.tier1.length}
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-link te text-decoration-none px-1"
                            onClick={() => {
                              setSelectedHistoricalGroups(
                                h.tier2.map((g) => {
                                  return {
                                    deviceId: h.deviceId,
                                    deviceLocation: device.deviceName,
                                    priorityTier: 2,
                                    label: g,
                                  }
                                }),
                              )
                            }}
                          >
                            {h.tier2.length}
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-link te text-decoration-none px-1"
                            onClick={() => {
                              setSelectedHistoricalGroups(
                                h.tier3.map((g) => {
                                  return {
                                    deviceId: h.deviceId,
                                    deviceLocation: device.deviceName,
                                    priorityTier: 3,
                                    label: g,
                                  }
                                }),
                              )
                            }}
                          >
                            {h.tier3.length}
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <b>Total Alerts ({statsTotal.totalAll})</b>
                  </td>
                  <td>
                    <b>{statsTotal.tier1Total}</b>
                  </td>
                  <td>
                    <b>{statsTotal.tier2Total}</b>
                  </td>
                  <td>
                    <b>{statsTotal.tier3Total}</b>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      ) : (
        <div>
          <h5>No Historical Alerts For Selected Accounts</h5>
        </div>
      )}
      <Modal
        show={selectedHistoricalGroups && selectedHistoricalGroups.length > 0}
        onHide={() => {
          setSelectedAlertGroup(undefined)
          setSelectedHistoricalGroups([])
          setSelectedHistoricalGroup(undefined)
        }}
        backdrop="static"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="modal-header" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Device Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedHistoricalGroups && selectedHistoricalGroups.length ? (
            <div>
              <Select
                value={selectedHistoricalGroup}
                onChange={(group: any) => setSelectedHistoricalGroup(group)}
                options={selectedHistoricalGroups}
                classNamePrefix="select"
                getOptionValue={(option: any) => option.label}
                getOptionLabel={(option: any) =>
                  `Tier ${option.priorityTier} - ${moment
                    .utc(option.label)
                    .tz(moment.tz.guess())
                    .format('dddd, DD/MM/YYYY LT')}`
                }
                isClearable={true}
              />
              <div className="mt-1">
                <AlertQueueItem alertGroupItem={selectedAlertGroup} historical={true} />
              </div>
            </div>
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  )
}

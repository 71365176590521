import { useState } from 'react'
import moment from 'moment-timezone'
import { Bar } from 'react-chartjs-2'
import html2canvas from 'html2canvas'
import { Button, Row, Col } from 'react-bootstrap'
import { CSVLink } from 'react-csv'
import { degrees, PageSizes, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { Report30Days as Report } from '../../../context/reporting/30days/30days.model'
import Device from '../../../context/device/model/device'
import { formatCurrency } from '../../../common/utils/helperFunctions'
import { waterWastageReportIntroMarkdown } from '../../../common/utils/constants'
import WaterWastageEmailModal from './WaterWastageEmailModal'

const ReportDetail = ({ data, device }: { data: Report; device: Device }) => {
  const [include7DayReport, setInclude7DayReport] = useState<boolean>(false)
  const [csvData, setCsvData] = useState<any>([])
  const [introMarkdown, setIntroMarkdown] = useState<string>(waterWastageReportIntroMarkdown)
  const [displaySendEmailModal, setDisplaySendEmailModal] = useState(false)
  const [pdfBase64, setPdfBase64] = useState('')

  function formatLiters(number: number): string {
    return new Intl.NumberFormat('en-GB', { maximumFractionDigits: 0 }).format(number) + ' litres'
  }

  async function textReport(text: string) {
    const pdf = await PDFDocument.create()

    const helveticaFont = await pdf.embedFont(StandardFonts.Helvetica)
    const header = await pdf.embedPng(
      await fetch('assets/img/report-header.png').then((res) => res.arrayBuffer()),
    )
    const footer = await pdf.embedPng(
      await fetch('assets/img/report-footer.png').then((res) => res.arrayBuffer()),
    )

    var firstPage = pdf.addPage(PageSizes.A4)

    const lineWidth = firstPage.getWidth() - 80
    const maxY = 90
    const fontSize = 11
    const lineSpacing = 1.5
    firstPage.setFont(helveticaFont)
    firstPage.drawImage(header, {
      x: 0,
      y: firstPage.getHeight() - 150,
      width: firstPage.getWidth(),
      height: 150,
    })
    firstPage.drawImage(footer, {
      x: 0,
      y: 0,
      width: firstPage.getWidth(),
      height: 60,
    })
    const paragraphs = text.split('\n')
    firstPage.moveTo(40, firstPage.getHeight() - 200)
    paragraphs.forEach((p) => {
      if (p === '') p = ' '
      const lines = Math.ceil(helveticaFont.widthOfTextAtSize(p, fontSize) / lineWidth)

      const { y } = firstPage.getPosition()
      const marginBottom =
        helveticaFont.heightAtSize(fontSize * lineSpacing) - helveticaFont.heightAtSize(fontSize)
      const pHeight = lines * helveticaFont.heightAtSize(fontSize * lineSpacing) + marginBottom

      if (y - pHeight <= maxY) {
        firstPage = pdf.addPage(PageSizes.A4)
        firstPage.moveTo(40, firstPage.getHeight() - 200)
        firstPage.setFont(helveticaFont)
        firstPage.drawImage(header, {
          x: 0,
          y: firstPage.getHeight() - 150,
          width: firstPage.getWidth(),
          height: 150,
        })
        firstPage.drawImage(footer, {
          x: 0,
          y: 0,
          width: firstPage.getWidth(),
          height: 60,
        })
      }

      firstPage.drawText(p, {
        maxWidth: lineWidth,
        size: fontSize,
        lineHeight: fontSize * lineSpacing,
        color: rgb(0.2, 0.2, 0.2),
      })
      firstPage.moveDown(pHeight)
    })

    var a = document.createElement('a') //Create <a>
    a.href = 'data:application/pdf;base64,' + (await pdf.saveAsBase64()) //Image Base64 Goes here
    a.download = `${device.deviceName} 10 Steps Report.pdf`
    a.click() //Downloaded file
  }

  async function capture(sendReportByEmail = false) {
    const pdf = await PDFDocument.create()
    const pages = document.getElementsByClassName('report-page')

    for (var i = 0; i < pages.length; i++) {
      const page = pages.item(i) as HTMLElement
      const canvas = await html2canvas(page, {
        scale: 1,
      })
      const pdfPage = pdf.addPage(PageSizes.A4)
      pdfPage.setRotation(page.classList.contains('landscape') ? degrees(90) : degrees(0))
      const imgData = await pdf.embedPng(canvas.toDataURL('image/png', 1.0))
      pdfPage.drawImage(imgData, {
        x: page.classList.contains('landscape') ? pdfPage.getWidth() : 0,
        y: 0,
        width: page.classList.contains('landscape') ? pdfPage.getHeight() : pdfPage.getWidth(),
        height: page.classList.contains('landscape') ? pdfPage.getWidth() : pdfPage.getHeight(),
        rotate: page.classList.contains('landscape') ? degrees(90) : degrees(0),
      })
    }

    const pdfFileBase64 = await pdf.saveAsBase64({ dataUri: true })

    const a = document.createElement('a') //Create <a>
    a.href = pdfFileBase64
    if (!sendReportByEmail) {
      a.download = `${device.deviceName} Water Wastage Report.pdf`
      a.click() //Downloaded file
    } else {
      generateCsvFile()
      const pdfFile = pdfFileBase64
      setPdfBase64(pdfFile)
      setDisplaySendEmailModal(true)
    }
  }

  const generateCsvFile = () => {
    const tableData = data.actualUsage.map((usage) => [usage.date, usage.total, ...usage.hours])

    const csvData = [
      ['WATER WASTAGE REPORT'],
      [''],
      [device.deviceName],
      // [device.deviceInfo.addressLine1],
      // [device.deviceInfo.town],
      // [device.deviceInfo.postcode],
      // [device.deviceInfo.country],
      [''],
      [''],
      ['30 Day Water Usage Report'],
      ['Your current Water Bill', 'Estimated Waste', 'Potential SMART FLOW Savings'],
      [
        `${formatCurrency(data.stats.monthlyCost, data.currency)} per month`,
        `${data.stats.monthlyFlow - data.stats.monthlyWaste} litres per month`,
        `${formatCurrency(
          data.stats.yearlyEstimatedSaving,
          data.currency,
        )} over the next 12 months`,
      ],
      [''],
      [''],
      ['12 Month Forecasted Usage / Costs'],
      ['(over the next 12 months)'],
      ['Your current Water Bill*', 'Water Bill post SMART FLOW adjustments*', 'Total Savings*'],
      [
        `${formatCurrency(data.stats.yearlyCost, data.currency)}`,
        `${formatCurrency(data.stats.yearlyCostEstimated, data.currency)}`,
        `${formatCurrency(data.stats.yearlyEstimatedSaving, data.currency)}`,
      ],
      [
        `${formatLiters(data.stats.yearlyFlow)}`,
        `${formatLiters(data.stats.yearlyWaste)}`,
        `${formatLiters(data.stats.yearlySaving)}`,
      ],
      [''],
      ['30 Days - Estimated Waste'],
      ['Your current Water Bill*', 'Water Bill post SMART FLOW adjustments*', 'Total Savings*'],
      [
        `${formatCurrency(data.stats.monthlyCost, data.currency)} /month`,
        `${formatCurrency(data.stats.monthlyWasteCost, data.currency)} /month`,
        `${formatCurrency(
          data.stats.monthlyCost - data.stats.monthlyWasteCost,
          data.currency,
        )} /month`,
      ],
      [
        `${formatLiters(data.stats.monthlyFlow)} /month`,
        `${formatLiters(data.stats.monthlyWaste)} /month`,
        `${formatLiters(data.stats.monthlyFlow - data.stats.monthlyWaste)} /month`,
      ],
      [''],
      ['* as estimated based on the last 30 days of usage'],
      [''],
      [''],
      [''],
      ['Date', 'Total', ...data.tableHeaders],
      ...tableData,
    ]

    setCsvData(csvData)
  }

  const stackBarOptions: any = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        ticks: {
          autoSkip: false,
          maxTicksLimit: 200,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    },
  }

  const report30daysUsage = {
    labels: data.actualUsage.map((u) => u.date),
    datasets: [
      {
        label: '#' + data.deviceId,
        data: data.actualUsage.map((u) => u.total),
        backgroundColor: '#1689CA',
      },
    ],
  }

  const reportProjectedYearlyCost = {
    labels: data.projectedYearlyCost.map((u) => u.date),
    datasets: [
      {
        label: 'SMART FLOW',
        data: data.projectedYearlyCost.map((u) => u.costLessWaste),
        backgroundColor: '#1689CA',
        stack: 'Stack 0',
      },
      {
        label: 'Estimated Waste',
        data: data.projectedYearlyCost.map((u) => u.cost),
        backgroundColor: 'rgba(247, 30, 30, 0.55)',
        borderColor: '#f71e1e',
        stack: 'Stack 0',
      },
    ],
  }

  const report30daysHourlyUsage = {
    labels: data.projected30DayHourly.map((u, i) =>
      i % 24 === 0 ? moment(u.date).format('D MMM YYYY') : '',
    ),
    datasets: [
      {
        label: 'SMART FLOW',
        data: data.projected30DayHourly.map((u) => u.flowLessWasteEstimated),
        backgroundColor: '#1689CA',
        stack: 'Stack 0',
      },
      {
        label: 'Estimated Waste',
        data: data.projected30DayHourly.map((u) => u.wasteEstimated),
        backgroundColor: 'rgba(247, 30, 30, 0.55)',
        borderColor: '#f71e1e',
        stack: 'Stack 0',
      },
    ],
  }

  const report30DaysWasteData = {
    labels: data.projected30Day.map((u) => u.date),
    datasets: [
      {
        label: 'SMART FLOW',
        data: data.projected30Day.map((u) => u.flowLessWasteEstimated),
        backgroundColor: '#1689CA',
        stack: 'Stack 0',
      },
      {
        label: 'Estimated Waste',
        data: data.projected30Day.map((u) => u.wasteEstimated),
        backgroundColor: 'rgba(247, 30, 30, 0.55)',
        borderColor: '#f71e1e',
        stack: 'Stack 0',
      },
    ],
  }

  return (
    <div id="report">
      Include 7 day report{' '}
      <input
        type="checkbox"
        checked={include7DayReport}
        onChange={(v) => setInclude7DayReport(v.target.checked)}
      />
      <Row>
        <Col>
          {/* <Row className="col-sm-2">
        <Button
          variant="primary"
          className="mb-3 mt-3 fon"
          onClick={() => textReport(introMarkdown)}
        >
          <small>Generate PDF Text Report </small>
        </Button>
      </Row> */}
          <CSVLink
            data={csvData}
            onClick={generateCsvFile}
            className="btn btn-primary mr-lg-2"
            style={{ color: '#454545', backgroundColor: '#fff' }}
            filename={`${device.deviceName} Water Wastage Report.csv`}
          >
            Generate CSV Report
          </CSVLink>
          <Button
            className="mb-3 mt-3"
            onClick={() => capture(false)}
            style={{ color: '#454545', backgroundColor: '#fff' }}
          >
            Generate PDF Report
          </Button>
          <Button
            onClick={() => capture(true)}
            style={{ color: '#454545', backgroundColor: '#fff' }}
            className="ml-2"
          >
            Send Reports by email
          </Button>
        </Col>
      </Row>
      {/* <textarea
        onChange={(event) => setIntroMarkdown(event.target.value)}
        rows={50}
        cols={50}
        defaultValue={introMarkdown}
      ></textarea> */}
      <div className="report-page">
        <img className="header-img" src="assets/img/report-header.png" alt="report-header" />
        <div className="address">
          <span className="first-line">{device.deviceName}</span>
          {/*<br />*/}
          {/*{device.deviceInfo.addressLine1}*/}
          {/*<br />*/}
          {/*{device.deviceInfo.addressLine2}*/}
          {/*{device.deviceInfo.addressLine2 ? <br /> : null}*/}
          {/*{device.deviceInfo.town}*/}
          {/*<br />*/}
          {/*{device.deviceInfo.postcode}*/}
          {/*<br />*/}
          {/*{device.deviceInfo.country}*/}
          {/*<br />*/}
        </div>
        <h2>30 Day Water Usage Report</h2>
        <Bar data={report30daysUsage} options={stackBarOptions} />
        {
          <table style={{ margin: 'auto', marginTop: '2em' }}>
            <tbody>
              <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
                <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                  Your current Water Bill
                </th>
                <th style={{ borderRight: '1px solid white', padding: '.6em' }}>Estimated Waste</th>
                <th style={{ padding: '.6em' }}>Potential SMART FLOW Savings</th>
              </tr>
              <tr>
                <td style={{ padding: '.6em', backgroundColor: '#eee' }}>
                  {formatCurrency(data.stats.monthlyCost, data.currency)}
                  <div style={{ fontSize: '.8em' }}>/ month</div>
                </td>
                <td style={{ padding: '.6em', backgroundColor: 'rgba(247, 30, 30, 0.2)' }}>
                  {data.stats.monthlyFlow - data.stats.monthlyWaste} l
                  <div style={{ fontSize: '.8em' }}>/ month</div>
                </td>
                <td
                  style={{
                    padding: '.6em',
                    color: 'white',
                    backgroundColor: 'rgba(22, 137, 202, .6)',
                  }}
                >
                  <span style={{ fontWeight: 'bolder' }}>
                    {formatCurrency(data.stats.yearlyEstimatedSaving, data.currency)}
                  </span>
                  <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
                </td>
              </tr>
            </tbody>
          </table>
        }
        <img
          className="footer-img"
          src="assets/img/report-footer.png"
          alt="report-footer"
          height={100}
          width={'80%'}
        />
      </div>
      <div className="report-page landscape">
        <div
          className="header-img"
          style={{
            display: 'flex',
            marginBottom: '1em',
          }}
        >
          <img
            style={{ marginRight: 'auto' }}
            src="assets/img/report-header.png"
            alt="report header left"
            height={120}
          />
        </div>

        <table style={{ width: '100%', fontSize: '.7em' }}>
          <tbody>
            <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
              <th>Date</th>
              <th>Total</th>
              {data.tableHeaders.map((h, index) => (
                <th key={index}>{h}</th>
              ))}
            </tr>
            {data.actualUsage.map((u, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#eee' : '#fff' }}>
                <td>{u.date}</td>
                <td>{u.total}</td>
                {u.hours.map((h, index) => (
                  <td key={index}>{h}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <img
          alt="footer"
          className="footer-img"
          src="assets/img/report-footer.png"
          height={100}
          width={'60%'}
        />
      </div>
      <div className="report-page">
        <img className="header-img" src="assets/img/report-header.png" alt="report header" />
        <h2>12 Month Forecasted Usage / Costs</h2>
        <br />
        <Bar data={reportProjectedYearlyCost} options={stackBarOptions} />
        <table style={{ margin: 'auto', marginTop: '2em' }}>
          <tbody>
            <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Your current Water Bill*
              </th>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Water Bill post SMART FLOW adjustments*
              </th>
              <th style={{ padding: '.6em' }}>Total Savings*</th>
            </tr>
            <tr>
              <td style={{ padding: '.6em', backgroundColor: '#eee' }}>
                {formatCurrency(data.stats.yearlyCost, data.currency)}
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                  borderRight: '1px solid white',
                }}
              >
                {formatCurrency(data.stats.yearlyCostEstimated, data.currency)}
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                }}
              >
                <span style={{ fontWeight: 'bolder' }}>
                  {formatCurrency(data.stats.yearlyEstimatedSaving, data.currency)}
                </span>
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ margin: 'auto', marginTop: '2em' }}>
          <tbody>
            <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Your current Water Bill*
              </th>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Water Bill post SMART FLOW adjustments*
              </th>
              <th style={{ padding: '.6em' }}>Total Savings*</th>
            </tr>
            <tr>
              <td style={{ padding: '.6em', backgroundColor: '#eee' }}>
                {formatLiters(data.stats.yearlyFlow)}
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                  borderRight: '1px solid white',
                }}
              >
                {formatLiters(data.stats.yearlyWaste)}
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                }}
              >
                <span style={{ fontWeight: 'bolder' }}>
                  {formatLiters(data.stats.yearlySaving)}
                </span>
                <div style={{ fontSize: '.8em' }}>over the next 12 months</div>
              </td>
            </tr>
          </tbody>
        </table>
        <span style={{ marginLeft: '6rem', fontSize: '.9rem' }}>
          * as estimated based on the last 30 days of usage
        </span>
        <img
          className="footer-img"
          src="assets/img/report-footer.png"
          alt="report footer"
          height={100}
          width={'80%'}
        />
      </div>
      <div className="report-page">
        <img className="header-img" src="assets/img/report-header.png" alt="report header" />
        <h2>30 Days - Estimated Waste</h2>
        <Bar data={report30DaysWasteData} options={stackBarOptions} />
        <table style={{ margin: 'auto', marginTop: '2em' }}>
          <tbody>
            <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Your current Water Bill*
              </th>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Water Bill post SMART FLOW adjustments*
              </th>
              <th style={{ padding: '.6em' }}>Total Savings*</th>
            </tr>
            <tr>
              <td style={{ padding: '.6em', backgroundColor: '#eee' }}>
                {formatCurrency(data.stats.monthlyCost, data.currency)}
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                  borderRight: '1px solid white',
                }}
              >
                {formatCurrency(data.stats.monthlyWasteCost, data.currency)}
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                }}
              >
                <span style={{ fontWeight: 'bolder' }}>
                  {formatCurrency(
                    data.stats.monthlyCost - data.stats.monthlyWasteCost,
                    data.currency,
                  )}
                </span>
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ margin: 'auto', marginTop: '2em' }}>
          <tbody>
            <tr style={{ backgroundColor: '#1689CA', color: '#fff' }}>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Your current Water Bill*
              </th>
              <th style={{ borderRight: '1px solid white', padding: '.6em' }}>
                Water Bill post SMART FLOW adjustments*
              </th>
              <th style={{ padding: '.6em' }}>Total Savings*</th>
            </tr>
            <tr>
              <td style={{ padding: '.6em', backgroundColor: '#eee' }}>
                {formatLiters(data.stats.monthlyFlow)}
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                  borderRight: '1px solid white',
                }}
              >
                {formatLiters(data.stats.monthlyWaste)}
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
              <td
                style={{
                  padding: '.6em',
                  color: 'white',
                  backgroundColor: 'rgba(22, 137, 202, .6)',
                }}
              >
                <span style={{ fontWeight: 'bolder' }}>
                  {formatLiters(data.stats.monthlyFlow - data.stats.monthlyWaste)}
                </span>
                <div style={{ fontSize: '.8em' }}>/ month</div>
              </td>
            </tr>
          </tbody>
        </table>
        <span style={{ marginLeft: '6rem', fontSize: '.9rem' }}>
          * as estimated based on the last 30 days of usage
        </span>

        <img
          className="footer-img"
          src="assets/img/report-footer.png"
          alt="report footer"
          height={100}
          width={'80%'}
        />
      </div>
      {include7DayReport ? (
        <div className="report-page landscape">
          <div
            className="header-img"
            style={{ display: 'flex', height: '100px', marginBottom: '2em' }}
          >
            <img
              style={{ marginRight: 'auto' }}
              src="assets/img/report-header-left.png"
              alt="report header left"
            />
            <img src="assets/img/report-header-right.png" alt="report header right" />
          </div>
          <h2>7 Days - Estimated Waste</h2>
          <div style={{ maxHeight: '450px' }}>
            <Bar data={report30daysHourlyUsage} options={stackBarOptions} />
          </div>

          <img
            className="footer-img"
            src="assets/img/report-footer.png"
            alt="report footer"
            height={100}
            width={'60%'}
          />
        </div>
      ) : null}
      {displaySendEmailModal && (
        <WaterWastageEmailModal
          selectedDevice={device}
          onHide={() => setDisplaySendEmailModal(false)}
          pdfAttachment={pdfBase64}
          csvAttachment={csvData}
          devicesDueTo30DaysReport={[]}
        />
      )}
    </div>
  )
}

export default ReportDetail

import React from 'react';

import ValvesManagementComponent from '../../Components/ValvesManagement/ValvesManagementComponent';
import { useDeviceState } from '../../../../context/device/context/device.context';

const DeviceStatus: React.FC = () => {

  const { testDevices } = useDeviceState();

  return (
    <div id="test-valves-management">
      <ValvesManagementComponent devices={ testDevices }/>
    </div>
  )
};

export default DeviceStatus;
import { Button, Modal } from "react-bootstrap";

export default function DeleteModal({ action, show, onHide, data, toString, onDelete, name="Object" }: any) {
    if (!action) action = "Delete"
    return (
      <>
        <Modal show={show} onHide={onHide} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{action} {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you really want to {action} {data ? toString(data) : "nothing selected"}?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button variant="danger" onClick={() => { onDelete(data); onHide(); }}>
              {action}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
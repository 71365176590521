import backend from '../../../api/backend'
import { ActionTypes } from '../context/device.reducer'
import { Permission } from '../../user/model/user'
import Device, { DeviceMessage, ValvesState, DeviceIDTypes, DeviceUpdateAPI } from '../model/device'
import { displayToast } from '../../../common/utils/appToast'

export const loadDevicesAction = async (dispatch: any, permissions: Permission[]) => {
  try {
    dispatch({ type: ActionTypes.LOADING_DEVICES })
    const devices = (await backend.get('/devices')).data
    dispatch({ type: ActionTypes.INIT, payload: { devices, permissions: permissions } })
  } catch (error: any) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
  }
}
export const updateDevice = (deviceId: string, idType: DeviceIDTypes, device: Device, permissions: Permission[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      const deviceUpdate: DeviceUpdateAPI = {
        device_name: device.deviceName,
        cost: device.deviceSettings.cost,
        currency: device.deviceSettings.currency,
        location: device.deviceSettings.location,
        sector_type: device.deviceSettings.sectorType,
        report_30_day: device.deviceSettings.report30Day,
        install_date: device.deviceSettings.installDate,
        monitoring_start_date: device.deviceSettings.monitoringStartDate,
        usage_start_date: device.deviceSettings.usageStartDate,
        active: device.deviceSettings.active,
        hot: device.deviceSettings.hot,
        master_device_id_ref: device.deviceSettings.masterDeviceIdRef || null,
        occupants: device.deviceSettings.occupants
      }
      let resp = await backend.put(
          `/devices/${deviceId}/`,
          {device_update: deviceUpdate},
          {params: {id_type: idType}}
      )
      dispatch({
        type: ActionTypes.UPDATE_DEVICE,
        payload: {updatedDevice: resp.data, permissions: permissions, idType: idType, deviceId: deviceId}
      })
      displayToast({
        type: 'success',
        message: 'Device updated successfully',
      })
      return resp
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}
export const updateValvesAction = (deviceId: string, newValvesState: ValvesState) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      await backend.put(`devices/${deviceId}/change_valve`, newValvesState)
      dispatch({
        type: ActionTypes.VALVES_UPDATED,
        payload: {
          deviceId: deviceId,
          newValvesState: newValvesState,
        },
      })
      displayToast({
        type: 'success',
        message: 'Valves state updated successfully!',
      })
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}

export const sendMessageToDevice = (deviceId: string, message: DeviceMessage) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      const body = {
        payload: {
          value: message.value,
        },
      }
      await backend.put(`/devices/${deviceId}/send_device_message/${message.key}`, body)
      dispatch({
        type: ActionTypes.SEND_MESSAGE,
        payload: message,
        deviceId: deviceId,
      })
      if (message.key === 'SetPulses') {
        const payload = {
          pulsePerLiter: message.value,
          deviceId: deviceId,
        }
        dispatch({
          type: ActionTypes.UPDATE_DEVICE,
          payload,
        })
        displayToast({
          type: 'success',
          message: 'Update sent to device successfully',
        })
      }
    } catch (error: any) {
      console.log('error ', error)
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}

export const setPulses = (deviceId: string, idType: DeviceIDTypes, pulsePerLiter: number, permissions: Permission[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      const body = {
        pulses: pulsePerLiter,
      }
      const resp = await backend.patch(`/devices/${deviceId}/set_pulses`, body, {params: {id_type: idType}})
      dispatch({
        type: ActionTypes.UPDATE_DEVICE,
        payload: {updatedDevice: resp.data, permissions: permissions, idType: idType, deviceId: deviceId}
      })
      displayToast({
        type: 'success',
        message: 'Device pulse updated successfully',
      })
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}


export const resetDeviceAction = (deviceId: string, idType: DeviceIDTypes, message: DeviceMessage) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      await backend.patch(`/devices/${deviceId}/reset`, message, {params: {id_type: idType}})
      dispatch({
        type: ActionTypes.SEND_MESSAGE,
        payload: message,
        deviceId: deviceId,
        idType: idType
      })
      displayToast({
        type: 'success',
        message: 'Device reset sent successfully',
      })
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}

export const updateFirmwareAction = (deviceId: string, idType: DeviceIDTypes, newFirmwareVersion: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      const body = {
        bin_file_name: newFirmwareVersion,
      }
      await backend.patch(`/devices/${deviceId}/firmware_update`, body, {params: {id_type: idType}})
      dispatch({
        type: ActionTypes.SEND_MESSAGE,
        payload: {"bin_file_name": newFirmwareVersion},
        deviceId: deviceId,
        idType: idType
      })
      displayToast({
        type: 'success',
        message: 'Device Firmware update sent successfully',
      })
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}

export const loadDeviceAction = (deviceId: string, idType: DeviceIDTypes, permissions: Permission[]) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      const resp = await backend.get(`devices/${deviceId}/`, {params: {id_type: idType}});
      // dispatch({ type: ActionTypes.LOADING_DEVICES })
      dispatch({ type: ActionTypes.LOAD_SINGLE_DEVICE, payload: {device: resp.data, permissions: permissions} })
      displayToast({
        type: 'success',
        message: 'Device data refreshed successfully',
      })
    } catch (error: any) {
      dispatch({ type: ActionTypes.ERROR, payload: { errorMsg: error.response.data } })
    }
  }
}

export const COLUMNS = [
    {
        name: 'Location',
        key: 'deviceName',
    },
    {
        name: 'Device ID',
        key: 'deviceId',
    },
    {
        name: 'Battery %',
        key: 'batteryLevel',
    },
    {
        name: 'Signal',
        key: 'signal',
    },
    {
        name: 'CSQ',
        key: 'signal',
    },
    {
        name: 'Power',
        key: 'batteryStatus',
    },
    {
        name: 'Status',
        key: 'connectionStatus',
    },
    {
        name: 'State',
        key: 'deviceSettings.active',
    },
    {
        name: 'Vendor',
        key: 'deviceVendor',
    }
];
    
export const DEVICE_CONNECTION_STATUS_OPTIONS = [
    {
        label: 'All',
        key: 'all',
    },
    {
        label: 'Online',
        key: 'connected',
    },
    {
        label: 'Offline',
        key: 'disconnected',
    },
];
import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Button, Col, Row } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  PointElement,
} from 'chart.js'

import moment, { Moment } from 'moment'

import { WaterUsageQuery } from '../../../../context/waterUsage/waterUsage.action'
import useWaterUsage from '../../../../context/waterUsage/waterUsage.hook'
import Device from '../../../../context/device/model/device'
import { hasPermissions } from '../../../../common/utils/helperFunctions'

import './UsageNavigator.scss'
import {
  deviceWaterUsage,
  SingleDeviceWaterUsage,
} from '../../../../context/waterUsage/waterUsage.model'
import { useUserState } from '../../../../context/user/context/user.context'
// import {useWaterUsageState} from "../../../../context/waterUsage/waterUsageStats.context";

ChartJS.register(PointElement, Tooltip, Legend, Title, LinearScale, BarElement)

interface IUsageNavigatorProps {
  show: boolean
  deviceSelected: Device
  onHide: () => void
}

const buildData = (deviceInfo: Device, data: SingleDeviceWaterUsage) => {
  const labels = data.usage?.map((u: deviceWaterUsage) => {
    return u.hour < 10 ? `0${u.hour}:00` : `${u.hour}:00`
  })
  const datasets = [
    {
      label: deviceInfo.deviceName,
      data: data.usage?.map((usage) => usage.flow),
      fill: false,
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    },
  ]
  return {
    labels,
    datasets,
  }
}

const options: any = {
  plugins: {
    tooltips: {
      mode: 'label',
      titleFontSize: 18,
      bodyFontSize: 18,
    },
  },
  scales: {
    x: {
      offset: true,
      title: {
        display: true,
        text: 'Hours',
        color: 'black',
        padding: 8,
        font: {
          size: 12,
        },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Liters',
        color: 'black',
        font: {
          size: 12,
        },
      },
      ticks: {
        beginAtZero: true,
        suggestedMin: 0,
      },
    },
  },
}

const TODAY = moment().startOf('day')
const UsageNavigator: React.FC<IUsageNavigatorProps> = ({
  show,
  deviceSelected,
  onHide,
}: IUsageNavigatorProps) => {
  const [currentDate, setCurrentDate] = useState<Moment | null>(null)
  const [totalUsage, setTotalUsage] = useState<null | number>(null)
  const { permissions } = useUserState()
  const chartRef = useRef()
  const {
    singleDeviceUsage,
    loadForSingleDevice,
    refreshForSingleDevice,
    clearForSingleDevice,
    loading,
  } = useWaterUsage()

  useEffect(() => {
    if (deviceSelected.deviceId) {
      askForData()
    }
  }, [currentDate])

  useEffect(() => {
    if (show) {
      setCurrentDate(TODAY)
    } else {
      clearForSingleDevice()
      setCurrentDate(null)
    }
  }, [show])

  useEffect(() => {
    setTotalUsage(singleDeviceUsage.totalUsage)
    // if (deviceSelected.deviceId in waterUsageStats) {
    //     console.log(waterUsageStats[deviceSelected.deviceId].hourlyUsage);
    // }
  }, [singleDeviceUsage])

  const onNextButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (currentDate) {
      const followingDay = currentDate.clone().add(1, 'days')
      setCurrentDate(followingDay)
    }
  }

  const onRefreshButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (TODAY.isSame(currentDate)) {
      refreshForSingleDevice({
        deviceId: deviceSelected.deviceId,
        from: currentDate!.format('YYYY-MM-DD'),
        to: '',
      })
    }
  }

  const onPreviousButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (currentDate) {
      const previousDay = currentDate.clone().add(-1, 'days')
      setCurrentDate(previousDay)
    }
  }

  const askForData = () => {
    if (currentDate) {
      const query: WaterUsageQuery = {
        deviceId: deviceSelected.deviceId,
        from: currentDate.format('YYYY-MM-DDTHH:00:00'),
        to: currentDate.format('YYYY-MM-DDT23:59:59'),
      }
      loadForSingleDevice(query)
    }
  }

  function downloadWaterGraph() {
    const dailyChart = document.getElementById('daily-usage-chart') as HTMLCanvasElement

    if (dailyChart && currentDate) {
      const img = dailyChart.toDataURL('image/png')
      const fileName = `${deviceSelected.deviceName}-${currentDate.format('DD-MM-YYYY')}`
      downloadImage(img, `${fileName}.jpeg`)
    }
  }

  // Save | Download image
  function downloadImage(data: any, filename = 'untitled.jpeg') {
    const a = document.createElement('a')
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
    // Q. Probably should removeChild?
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {deviceSelected.deviceName} ({deviceSelected.deviceId})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>{currentDate && currentDate.format('dddd, MMMM Do YYYY')}</Form.Label>
              <Form.Group as={Col} controlId="navigation-buttons">
                <div className="navigation-buttons-container">
                  <Button
                    className="btn btn-secondary"
                    onClick={onPreviousButtonClick}
                    disabled={
                      loading ||
                      (currentDate !== null &&
                        currentDate.startOf('day') <=
                          moment(deviceSelected.deviceSettings.usageStartDate).startOf('day'))
                    }
                  >
                    Previous Day
                  </Button>
                  {!TODAY.isSame(currentDate) ? (
                    <Button onClick={onNextButtonClick} disabled={loading}>
                      Next Day
                    </Button>
                  ) : hasPermissions(permissions, ['READ:DEVICE:DLF']) && deviceSelected.deviceVendor === 'SMARTFLOW' ? (
                    <Button onClick={onRefreshButtonClick} disabled={loading}>
                      Refresh
                    </Button>
                  ) : null}
                </div>
              </Form.Group>
            </Form.Group>
          </Row>
        </Form>
        <div className="chart-container">
          <Bar
            id="daily-usage-chart"
            data={buildData(deviceSelected, singleDeviceUsage)}
            options={options}
            ref={chartRef}
          />
        </div>
        {loading && <div>Loading...</div>}
        <div className="total-usage">
          <b>Total Usage: </b>
          {totalUsage} L
        </div>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button id="download" onClick={() => downloadWaterGraph()} variant="secondary">
          <span className="fas fa-download fa-lg mr-2"></span>
          Download
        </Button>
        <Button id="close" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UsageNavigator

import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import moment from 'moment-timezone'
import {
  hasPermissions,
  itemFromPath,
  formatCurrency,
  getBatteryIconClass,
  getBatteryPercentage,
  getSignalStatus,
  sortObjectsByKey,
  getBatteryStatus,
  getDeviceStatus,
} from '../../../common/utils/helperFunctions'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import Device from '../../../context/device/model/device'
import { useDeviceState } from '../../../context/device/context/device.context'
import { useAccountState } from '../../../context/account/context/account.context'
import { useUserState } from '../../../context/user/context/user.context'
import { CSVLink } from 'react-csv'
import DateRangeComponent from '../../main/Components/Calendar/DateRangeSelector'
import { FaBan } from 'react-icons/fa'
import { Pagination } from '../Pagination/Pagination'

import './CustomSearchTable.scss'

interface IColumn {
  name: string
  key: string | Function
  sortable?: boolean
  width?: string
  order?: number
  isFixed?: boolean
  checked?: boolean
}

interface ICustomSearchTable {
  dataArray: any[]
  columns: IColumn[]
  rowKeys: (string | Function)[]
  onRowClickHandler?: (rowData: any) => void
  filterByInstallDate?: boolean
  exportToCSV?: boolean
  allowColumnPicker?: boolean
  displayPagination?: boolean
  itemsPerPage?: number
}

const CustomSearchTable: React.FC<ICustomSearchTable> = ({
  dataArray,
  columns,
  rowKeys,
  onRowClickHandler,
  filterByInstallDate = false,
  exportToCSV = false,
  allowColumnPicker = false,
  displayPagination = false,
  itemsPerPage = 30,
}: ICustomSearchTable) => {
  const { accounts } = useAccountState()
  const { devices } = useDeviceState()
  const { permissions } = useUserState()

  const [searchTerm, setSearchTerm] = useState('')
  const [sortingConf, setSortingConf] = useState({ key: '', order: '' })
  const [originalData, setOriginalData] = useState(dataArray)
  const [csvData, setCsvData] = useState<any>([])
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  const [columnsArr, setColumnsArr] = useState([...columns])
  const [visibleColumns, setVisibleColumns] = useState<IColumn[]>([])

  const [searchParams] = useSearchParams()
  const currentPage = searchParams.get('page')

  useEffect(() => {
    if (columns.some((column) => column.checked)) {
      setVisibleColumns(columnsArr.filter((column) => column.checked))
    } else {
      setVisibleColumns([...columns])
    }
  }, [])

  useEffect(() => {
    if (dataArray && dataArray.length >= 0) {
      setOriginalData(dataArray)
    }
  }, [dataArray])

  let filteredData: Device[] =
    searchTerm.length > 0
      ? originalData.filter(
          (device) =>
            device.deviceName.toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
            String(device.deviceId).includes(searchTerm.trim().toLowerCase()),
        )
      : originalData

  filteredData =
    sortingConf.order !== ''
      ? sortObjectsByKey([...filteredData], sortingConf.key, sortingConf.order)
      : filteredData

  if (filterByInstallDate) {
    if (fromDate && !toDate) {
      filteredData = filteredData.filter((device) =>
        moment(device.deviceSettings.installDate).isAfter(moment(fromDate)),
      )
    }

    if (!fromDate && toDate) {
      filteredData = filteredData.filter((device) =>
        moment(device.deviceSettings.installDate).isBefore(moment(toDate)),
      )
    }

    if (fromDate && toDate) {
      filteredData = filteredData.filter((device) =>
        moment(device.deviceSettings.installDate).isBetween(moment(fromDate), moment(toDate)),
      )
    }
  }

  function getSortingClass(columnKey: string) {
    return sortingConf.key === columnKey && sortingConf.order !== ''
      ? `fas fa-arrow-${sortingConf.order === 'ASC' ? 'down' : 'up'}`
      : ''
  }

  function onSearchTermChange(e: React.FormEvent<HTMLInputElement>) {
    setSearchTerm(e.currentTarget.value)
  }

  function clearSearchTerm() {
    setSearchTerm('')
  }

  function generateCsvFile() {
    let exportData: any = []

    if (filterByInstallDate && fromDate && toDate) {
      exportData = [
        ['Filtered Results', ''],
        ['Install Date', ''],
        ['from', moment(fromDate).format('DD-MM-YYYY')],
        ['to', moment(toDate).format('DD-MM-YYYY')],
      ]
    }

    const totalDevicesInstalled = [['Total Devices Installed'], [`${devices.length}`], ['']]

    const groupedByAccount: { [accountId: string]: Device[] } = {}

    filteredData.forEach((data) => {
      if (!groupedByAccount[data.accountId]) {
        groupedByAccount[data.accountId] = []
      }
      groupedByAccount[data.accountId].push(data)
    })

    for (const groupAccount in groupedByAccount) {
      const accountDevices = groupedByAccount[groupAccount]
      const accountName = accounts.filter((account) => groupAccount === account.id)[0]?.name

      exportData = [
        ...exportData,
        [' '],
        [' '],
        ['Account Name', ''],
        [
          `${accountName} (${accountDevices.length} ${
            accountDevices.length > 1 ? 'devices' : 'device'
          })`,
          '',
        ],
        [''],
        ['Device', 'ID', 'Sector', 'Cost', 'Type', 'Install Date', 'Monitoring Start Date'],
      ]
      accountDevices.map(
        (device) =>
          (exportData = [
            ...exportData,
            [
              device?.deviceName,
              device.deviceId,
              device?.deviceSettings?.sectorType,
              device.deviceSettings?.cost && device.deviceSettings?.currency
                ? formatCurrency(device.deviceSettings?.cost, device.deviceSettings?.currency)
                : null,
              device.deviceSettings.hot ? 'Hot' : 'Cold',
              moment(device.deviceSettings?.installDate).format('DD-MM-YYYY HH:mm'),
              moment(device.deviceSettings?.monitoringStartDate).format('DD-MM-YYYY HH:mm'),
            ],
          ]),
      )
    }

    const data = [...totalDevicesInstalled, ...exportData].filter((v) => v !== null)

    setCsvData(data)
  }

  let paginatedData: Device[] = []

  if (currentPage) {
    const startIndex = (Number(currentPage) - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    paginatedData = filteredData.slice(startIndex, endIndex)
  }

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)

  function onColumnHeaderClick(columnKey: string, sortable: boolean = true) {
    if (!sortable) return
    let order = ''
    if (sortingConf.key === columnKey) {
      if (sortingConf.order === '') {
        order = 'ASC'
      } else if (sortingConf.order === 'ASC') {
        order = 'DESC'
      } else {
        order = ''
      }
    } else {
      order = 'ASC'
    }
    setSortingConf({ key: columnKey, order })
  }

  const handleOnRowClick = (rowData: any) => {
    if (onRowClickHandler) {
      onRowClickHandler(rowData)
    }
  }

  const tableHeaders = visibleColumns.map((column) => (
    <th
      key={typeof column.key === 'string' ? column.key : column.name}
      className={column.sortable ? 'clickable' : ''}
      style={{
        width: column.width || '20%',
        position: 'sticky',
        top: 0,
      }}
      onClick={() =>
        typeof column.key === 'string' ? onColumnHeaderClick(column.key, column.sortable) : {}
      }
    >
      {column.name}

      {typeof column.key === 'string' && <i className={getSortingClass(column.key)}></i>}
    </th>
  ))

  const tableRows = (paginatedData.length > 0 ? paginatedData : filteredData).map(
    (device: Device, idx) => {
      return (
        <tr key={idx} onClick={() => handleOnRowClick(device)}>
          {visibleColumns.map((row, idxCol) => (
            <td key={idx + '-' + idxCol}>
              <>
                <div>
                  {typeof row.key === 'function' ? (
                    row.key(device)
                  ) : row.key === 'deviceSettings.installDateEpoch' ? (
                    moment.unix(itemFromPath(row.key, device)).format('YYYY-MM-DD')
                  ) : row.key === 'deviceName' ? (
                    <>
                      <SectorIcon
                        sector={device?.deviceSettings?.sectorType}
                        occupants={device?.deviceSettings?.occupants}
                      />{' '}
                      {itemFromPath(row.key, device)}
                      {device?.childDeviceIds && device?.childDeviceIds?.length > 0 && (
                        <>
                          <br />
                          <span className="stamp main-meter"> MAIN METER</span>
                        </>
                      )}
                      {device?.deviceSettings?.masterDeviceIdRef && (
                        <>
                          <br />
                          <span className="stamp sub-meter"> SUB METER</span>
                        </>
                      )}
                    </>
                  ) : row.key === 'batteryLevel' ? (
                      <div className="battery-container">
                        {device.batteryLevel ? (
                          <>
                            <div className="battery-container-int">
                              <span className={getBatteryIconClass(getBatteryPercentage(device.batteryLevel, device.deviceVendor))} />
                              <span className="battery-level"> {getBatteryPercentage(device.batteryLevel, device.deviceVendor)}% (Int)</span>
                            </div>
                            {device.batteryLevelExt ? (
                              <div className="battery-container-ext">
                                <span className={getBatteryIconClass(getBatteryPercentage(device.batteryLevelExt, device.deviceVendor))} />
                                <span className="battery-level"> {getBatteryPercentage(device.batteryLevelExt, device.deviceVendor)}% (Ext)</span>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <div className="battery-container">
                            <FaBan />
                          </div>
                        )}
                      </div>
                  ) : row.key === 'signalStrength' ? (
                    <div className="signal-container">
                      <span>{device.signal ? getSignalStatus(device.signal) : <FaBan />}</span>
                    </div>
                  ) : row.key === 'batteryStatus' ? (
                    <div className="power-container">
                      {device.batteryStatus ? getBatteryStatus(device) : <FaBan />}
                    </div>
                  ) : row.key === 'deviceStatus' ? (
                    device.deviceVendor === 'SMARTFLOW' ||
                    device.deviceVendor === 'METASPHERE' ||
                    device.deviceVendor === 'PLUM' ? (
                      getDeviceStatus(
                        device,
                        hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW']),
                        hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']),
                      )
                    ) : (
                      <FaBan />
                    )
                  ) : row.key === 'deviceSettings.active' ? (
                    device.deviceSettings.active ? (
                      'Active'
                    ) : (
                      'Inactive'
                    )
                  ) : (
                    itemFromPath(row.key, device)
                  )}
                </div>
              </>
            </td>
          ))}
        </tr>
      )
    },
  )

  const handleCheckboxChange = (columnName: string) => {
    const updatedColumns = columnsArr.map((column) =>
      column.name === columnName ? { ...column, checked: !column.checked } : column,
    )

    setColumnsArr(updatedColumns)

    setVisibleColumns(updatedColumns.filter((column) => column.checked))
  }

  return (
    <div className="custom-search-table">
      <div className="container mt-4 mb-4 ml-0 mr-0">
        <div className="search-section row">
          <div className="col-md-9 d-flex align-items-left flex-column">
            <div className="d-flex">
              <div className="form-group search-bar w-75">
                <span className="fa fa-search search-icon"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name or device id"
                  value={searchTerm}
                  onChange={onSearchTermChange}
                />
                <span className="fa fa-times clear-icon" onClick={clearSearchTerm}></span>
              </div>

              <div className="result-number ml-2">{`( ${filteredData.length} )`}</div>
            </div>

            {filterByInstallDate && (
              <div className="form-group date-range mt-3 w-75">
                <DateRangeComponent
                  from={fromDate}
                  to={toDate}
                  fromCallback={setFromDate}
                  toCallback={setToDate}
                  label={'Install Date Range'}
                  isClearable
                />
              </div>
            )}
          </div>

          {exportToCSV && (
            <div className="col-md-3 d-flex justify-content-end ">
              <div className="text-md-right mt-3">
                <CSVLink
                  data={csvData}
                  onClick={generateCsvFile}
                  className="btn btn-primary"
                  filename={`filteredDevices_${moment().format('DD-MM-YYYY_HH:mm')}.csv`}
                >
                  Generate CSV Report
                </CSVLink>
              </div>
            </div>
          )}
        </div>
      </div>

      {allowColumnPicker && (
        <div className="mt-4 mb-4">
          <span>Column Picker</span>
          <div className="container mt-2 border p-2 ml-0">
            <div className="row">
              {columnsArr.map((column) => (
                <div key={column.name} className="col-6 col-md-3 col-lg-2 mb-2">
                  <div>
                    <input
                      type="checkbox"
                      id={column.name}
                      checked={column.checked}
                      disabled={column.isFixed}
                      onChange={() => handleCheckboxChange(column.name)}
                    />
                    <label htmlFor={column.name} className="ml-1">
                      {column.name}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Table responsive>
        <thead>
          <tr>{tableHeaders}</tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>

      {displayPagination && <Pagination totalPages={totalPages} />}
    </div>
  )
}

export default CustomSearchTable

import backend from "../../../api/backend";
import { ActionTypes } from "../context/usageStats.reducer";
import { AggUsageQuery, MonthlyLeakQuery } from "../model/usageStats.model";


export const getMonthlyAggUsageAction = async (query: AggUsageQuery, dispatch: any) => {
    try {
        dispatch({type: ActionTypes.LOADING});
        const response = await backend.get("/water_usage/aggregated/month",
            {
                params: {
                    start_date: query.queryStart,
                    end_date: query.queryEnd,
                    agg_type: query.aggType
                },
            });
        dispatch({
            type: ActionTypes.LOADED_MONTHLY_AGG_STATS,
            // payload: {query: query, data: data}
            payload: {query: query, data: response.data}
        });
    } catch (error) {
        console.log(error);
        dispatch({type: ActionTypes.ERROR});
    }
};


export const getMonthlyLeakAction = async (query: MonthlyLeakQuery, dispatch: any) => {
    try {
        dispatch({type: ActionTypes.LOADING});
        const response = await backend.get("/usage_alerts/stats/leak/month",
            {
                params: {
                    start_year: query.queryStartYear,
                    start_month: query.queryStartMonth,
                    end_year: query.queryEndYear,
                    end_month: query.queryEndMonth,
                    agg_type: query.aggType
                },
            });
        dispatch({
            type: ActionTypes.LOADED_MONTHLY_LEAK_STATS,
            payload: {query: query, data: response.data}
        });
    } catch (error) {
        console.log(error);
        dispatch({type: ActionTypes.ERROR});
    }
};

import backend from '../../../api/backend'
import { displayToast } from '../../../common/utils/appToast'
import { ActionTypes } from '../context/device.reducer'

export const loadTestDevicesAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.LOADING })
    const testDevices = (await backend.get('/test_portal/test_env/devices/')).data
    const testDevicesStatus =
      (await backend.get('/test_portal/test_env/devices/device_status')).data ?? []
    dispatch({ type: ActionTypes.INIT_TEST_DEVICES, payload: { testDevices, testDevicesStatus } })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}
export const loadStagedDevicesAction = () => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.LOADING,
    })
    try {
      // const response = await backend.get(`/customer_manager/accounts/staged_devices`);
      const response = await backend.get(`/devices/staged_devices`)
      dispatch({
        type: ActionTypes.INIT_STAGED_DEVICES,
        payload: response.data,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}
export const promoteToStagedAction = (deviceId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      await backend.put(`/tech_portal/device_swap/test_device_to_staged_devices/${deviceId}`)
      dispatch({
        type: ActionTypes.PROMOTED_TO_STAGED,
        payload: { deviceId },
      })
      displayToast({
        type: 'success',
        message: 'Device staged successfully',
      })
    } catch (error: any) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
      displayToast({
        type: 'error',
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }
}

export const demoteFromStaged = (deviceId: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionTypes.DEVICE_UPDATING,
    })
    try {
      await backend.put(`/tech_portal/device_swap/test_device_to_staged_devices/${deviceId}`)
      dispatch({
        type: ActionTypes.PROMOTED_TO_STAGED,
        payload: { deviceId },
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.ERROR,
        error,
      })
    }
  }
}

import { useState } from 'react'
import moment from 'moment-timezone'
import { Pencil, History } from 'lucide-react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Modal, Button } from 'react-bootstrap'
import { AccountComments } from '../../../context/account/model/Account'
import { hasPermissions } from '../../../common/utils/helperFunctions'
import { useUserState } from '../../../context/user/context/user.context'
import { useCustomerManagerDispatch } from '../../../context/customerManager/customer-manager.context'

import './AccountComment.scss'

const AccountComment = ({
  id,
  author,
  createdAt,
  updatedAt,
  comment,
  history,
  selectedAccount,
}: AccountComments) => {
  const { updateComment, loadAccountComments } = useCustomerManagerDispatch()
  const { permissions } = useUserState()

  const [displayHistoryModal, setDisplayHistoryModal] = useState(false)
  const [editComment, setEditComment] = useState(false)
  const [newComment, setNewComment] = useState(comment)

  const getTimeAgo = (dateTime: string) => {
    const customDate = moment(dateTime)
    const currentDate = moment()
    const timeDifference = currentDate.diff(customDate)

    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    if (hours > 0) {
      if (hours > 23) {
        return `${customDate.format('HH:mm')}h`
      }
      return `${hours} hour${hours > 1 ? 's' : ''} ago`
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    } else {
      return `${seconds} second${seconds > 1 ? 's' : ''} ago`
    }
  }

  const onHide = () => {
    setDisplayHistoryModal(false)
  }

  const handleEditComment = () => {
    setNewComment(comment)
    setEditComment(true)
  }

  const handleUpdateComment = async () => {
    const res = await updateComment(selectedAccount.id, id, newComment)

    if (res) {
      setEditComment(false)
      loadAccountComments(selectedAccount)
    }
  }

  const modalHistory = history.slice()

  return (
    <>
      <div className="comment-container" key={id}>
        <div className="header">
          <div className="timestamp">
            <div className="author">{`Created by  ${
              history.length > 0 ? history[0].author : author
            } - ${moment(createdAt).format('DD-MM-YYYY')} - ${getTimeAgo(createdAt)}`}</div>
            {history.length > 0 && (
              <div className="author">{`last updated by  ${
                history[history.length - 1].author
              } - ${moment(history[history.length - 1].createdAt).format(
                'DD-MM-YYYY',
              )} - ${getTimeAgo(updatedAt)}`}</div>
            )}
          </div>

          <div className="action-buttons">
            {history.length > 0 && (
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props: any) => <Tooltip {...props}>history</Tooltip>}
                placement="top"
              >
                <History
                  role="button"
                  size={20}
                  color={'gray'}
                  className="ml-2 mt-1"
                  onClick={() => setDisplayHistoryModal(true)}
                />
              </OverlayTrigger>
            )}

            {hasPermissions(permissions, ['UPDATE:ACCOUNT:COMMENT']) && (
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props: any) => <Tooltip {...props}>edit</Tooltip>}
                placement="top"
              >
                <Pencil
                  role="button"
                  size={20}
                  color={'gray'}
                  className="ml-2 mt-1"
                  onClick={() => handleEditComment()}
                />
              </OverlayTrigger>
            )}
          </div>
        </div>

        <div className="content">
          {editComment ? (
            <div>
              <textarea
                style={{ resize: 'none', height: '100px' }}
                className="w-100 p-2 mt-3 text-break"
                name="accountComments"
                id="accountComments"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />

              <div className="action-buttons">
                <Button
                  className="ml-3 mb-3 d-flex flex-end"
                  onClick={() => handleUpdateComment()}
                  disabled={!newComment || newComment === comment}
                >
                  Save
                </Button>
                <Button
                  className="ml-3 mb-3 d-flex flex-end"
                  variant="secondary"
                  onClick={() => setEditComment(false)}
                  disabled={!comment}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div className="text">{comment}</div>
          )}
        </div>
      </div>

      {displayHistoryModal && (
        <Modal
          show={displayHistoryModal}
          onHide={onHide}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="device-information-modal"
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Comment History</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalHistory.reverse().map((history, index) => (
              <div className="comment-container" key={index}>
                <div className="header">
                  <div className="timestamp">
                    <div className="author">{`${index !== 0 ? 'Created' : 'Updated'} by  ${
                      history.author
                    } - ${moment(history.createdAt).format('DD-MM-YYYY')} - ${getTimeAgo(
                      history.createdAt,
                    )}`}</div>
                  </div>
                </div>

                <div className="content">
                  <div className="text">{history.comment}</div>
                </div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default AccountComment

import backend from "../../../api/backend";
import { ActionTypes } from "../context/account.reducer";

export const loadAccountsAction = async (dispatch: any) => {

  try {
    dispatch({ type: ActionTypes.LOADING });
    
    const accounts = (await backend.get('/accounts')).data;
    dispatch({ type: ActionTypes.INIT, payload: { accounts } });
  } catch (error) {
    console.log(error);
    dispatch({ type: ActionTypes.ERROR });
  }
};


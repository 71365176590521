import React from 'react';

import ValvesManagementComponent from '../Components/ValvesManagement/ValvesManagementComponent';
import { useDeviceState } from '../../../context/device/context/device.context';

const DeviceStatus: React.FC = () => {

  const { devices } = useDeviceState();

  return (
    <div id="valves-management">
      <ValvesManagementComponent devices={ devices }/>
    </div>
  )
};

export default DeviceStatus;
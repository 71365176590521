import Select, { components } from 'react-select'
import { Form, Dropdown, DropdownButton } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { useUserDispatch, useUserState } from '../../../context/user/context/user.context'
import { useLayoutDispatch } from '../../../context/layout/context/layout.context'
import {
  useAccountDispatch,
  useAccountState,
} from '../../../context/account/context/account.context'

import './TopBar.scss'

const ValueContainer = (containerProps: any) => (
  <components.ValueContainer {...containerProps} className={'d-flex'}>
    Accounts ({containerProps?.children[0]?.length ? containerProps.children[0].length : 0} /{' '}
    {containerProps.options.length}){containerProps.children[1]}
  </components.ValueContainer>
)

const Option = (containerProps: any) => {
  return (
    <components.Option {...containerProps}>
      <Form.Check checked={containerProps.isSelected} readOnly label={containerProps.children} />
    </components.Option>
  )
}

function TopBar() {
  const navigate = useNavigate()
  const { userInfo } = useUserState()
  const { logout } = useUserDispatch()
  const { toggleSideBar } = useLayoutDispatch()

  const { accounts, selectedAccounts, loading } = useAccountState()
  const { setSelectedAccounts } = useAccountDispatch()

  const handleMyProfileClick = () => {
    navigate('/user-profile')
  }

  const url = window.location.hostname
  const isTestEnvironment = url.includes('uat')

  const activeAccounts = accounts.filter((account) => account.isActive)

  const handleLogout = () => {
    navigate('/', { replace: true, state: null })

    logout()
  }

  const optionStyles = (styles: any, { isFocused }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#eee' : null,
      color: '#333',
    }
  }

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {selectedAccounts.length > 0 ? (
          <button className="btn btn-link" onClick={() => setSelectedAccounts([])}>
            deselect all accounts
          </button>
        ) : (
          <button className="btn btn-link" onClick={() => setSelectedAccounts(activeAccounts)}>
            select all accounts
          </button>
        )}
        {props.children}
      </components.MenuList>
    )
  }

  // const firstName = userInfo && userInfo?.firstname[0] && userInfo?.firstname[0].length > 0 ? userInfo?.firstname[0].toUpperCase() : ""
  // const lastName = userInfo && userInfo?.lastname[0] && userInfo?.lastname[0].length > 0 ? userInfo?.lastname[0].toUpperCase() : ""
  // const initials = `${firstName} ${lastName}`;
  return (
    <div
      className="topbar d-flex flex-column"
      style={{ height: `${isTestEnvironment ? '6.5rem' : 'inherit'}` }}
    >
      <nav className="navbar navbar-expand navbar-light topbar static-top shadow">
        <button
          id="sidebarToggleTop"
          className="btn btn-link rounded-circle mr-2 ml-2"
          onClick={toggleSideBar}
        >
          {/*<button id="sidebarToggleTop" className="btn btn-link d-lg-none rounded-circle mr-3" onClick={toggleSideBar}>*/}
          <i className="fa fa-bars"></i>
        </button>
        <div className="account-selector">
          <div className="multiselect-container">
            <Select
              styles={{ option: optionStyles }}
              components={{ MenuList, ValueContainer, Option }}
              className="basic-multi-select "
              value={selectedAccounts}
              onChange={(options: any) => setSelectedAccounts([...options])}
              options={activeAccounts}
              isMulti
              name="accounts"
              classNamePrefix="select"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => `${option.name}`}
              isClearable={false}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isLoading={loading || activeAccounts.length === 0}
              isDisabled={loading || activeAccounts.length === 0}
            />
          </div>
        </div>
        <div className="account-icons-container">
          <div className="sidebar-brand-icon">
            <img alt="Smartflow" src="/logo.png" />
          </div>
          <div className="current-user">
            <div className="topbar-divider d-none d-sm-block"></div>
            <DropdownButton
              className="user-dropdown btn-circle mr-3"
              id="dropdown-item-button"
              title={`${userInfo.initials}`}
              variant="info"
            >
              <Dropdown.Item as="button" onClick={handleMyProfileClick}>
                My Profile
              </Dropdown.Item>
              <Dropdown.Item as="button" onClick={handleLogout}>
                Log out
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </nav>
      {isTestEnvironment && (
        <div className="w-100 bg-danger text-white text-center">TEST ENVIRONMENT</div>
      )}
    </div>
  )
}

export default TopBar

import Device from '../../../../context/device/model/device'
import Account from '../../../../context/account/model/Account'
import { jsPDF } from 'jspdf'
import moment from 'moment/moment'
import { IInvoiceTotalProps, ITenantProps } from './interfaces'
import { formatCurrency } from '../../../../common/utils/helperFunctions'

export const buildPDFInvoice = (
  selectedDevice: Device,
  deviceAccount: Account,
  fromDate: Date,
  toDate: Date,
  tenants: ITenantProps[],
  chartImage: string,
  totals: IInvoiceTotalProps,
  displayTermsAndConditions: boolean,
  termsAndConditions: string,
): Promise<jsPDF> => {
  return new Promise((res, rej) => {
    const from = moment(fromDate).format('MMMM Do YYYY')
    const to = moment(toDate).format('MMMM Do YYYY')
    const pdf = new jsPDF('portrait')

    const pdfWidth = 500

    let docWidth = pdf.internal.pageSize.width
    let docHeight = pdf.internal.pageSize.height

    const colorBlack = '#000000'
    const colorGray = '#4d4e53'
    const colorBlue = '#00bfff'

    let currentHeight = 15

    const pdfConfig = {
      headerTextSize: 20,
      subtitleTextSize: 16,
      labelTextSize: 12,
      fieldTextSize: 10,
      lineHeight: 6,
      subLineHeight: 4,
    }

    pdf.setFontSize(pdfConfig.headerTextSize)
    pdf.setTextColor(colorBlack)

    pdf.text('Water Supply Bill', 10, currentHeight)

    const accountAddress = [
      deviceAccount.addressLine1.replaceAll('\r\n', ' '),
      deviceAccount.addressLine2,
      deviceAccount.town,
      deviceAccount.postcode,
      deviceAccount.county,
    ]

    pdf.text(`${deviceAccount.name}`, docWidth - 10, currentHeight, { align: 'right' })
    pdf.setFontSize(pdfConfig.fieldTextSize)

    pdf.setTextColor(colorGray)
    currentHeight += pdfConfig.subLineHeight
    currentHeight += pdfConfig.subLineHeight

    for (let i = 0; i < accountAddress.length; i += 1) {
      if (accountAddress[i] && accountAddress[i] !== '') {
        pdf.text(accountAddress[i], docWidth - 10, currentHeight, { align: 'right' })
        if (i !== accountAddress.length - 1) {
          currentHeight += pdfConfig.subLineHeight
        }
      }
    }

    currentHeight += pdfConfig.subLineHeight
    pdf.line(10, currentHeight, docWidth - 10, currentHeight)

    // device details
    // const deviceAddress = [
    //   selectedDevice.deviceInfo.addressLine1,
    //   selectedDevice.deviceInfo.addressLine2,
    //   selectedDevice.deviceInfo.town,
    //   selectedDevice.deviceInfo.postcode,
    //   selectedDevice.deviceInfo.county,
    // ]

    currentHeight += pdfConfig.lineHeight
    pdf.setTextColor(colorGray)
    pdf.setFontSize(pdfConfig.fieldTextSize)
    pdf.text('Device Location:', 10, currentHeight)
    currentHeight += pdfConfig.subLineHeight
    pdf.setTextColor(colorBlack)
    pdf.text(`${selectedDevice.deviceName}`, 10, currentHeight)
    currentHeight += pdfConfig.subLineHeight
    // for (let i = 0; i < deviceAddress.length; i += 1) {
    //   if (deviceAddress[i] && deviceAddress[i] !== '') {
    //     pdf.text(deviceAddress[i], 10, currentHeight)
    //     if (i !== deviceAddress.length - 1) {
    //       currentHeight += pdfConfig.subLineHeight
    //     }
    //   }
    // }

    //tenant details
    if (tenants[0]?.name.length > 0) {
      currentHeight -= pdfConfig.subLineHeight
      // for (let i = 0; i < deviceAddress.length; i += 1) {
      //   if (deviceAddress[i] && deviceAddress[i] !== '') {
      //     currentHeight -= pdfConfig.subLineHeight
      //   }
      // }
      pdf.text('Invoice issued for: ', docWidth - 10, currentHeight, { align: 'right' })
      currentHeight += pdfConfig.subLineHeight
      pdf.setTextColor(colorBlack)
      pdf.setFontSize(pdfConfig.fieldTextSize)
      pdf.text(`${tenants[0].name}`, docWidth - 10, currentHeight, { align: 'right' })
      currentHeight += pdfConfig.subLineHeight * 3
    }

    // BILLING PERIOD
    currentHeight += pdfConfig.subLineHeight * 6

    pdf.setFontSize(pdfConfig.subtitleTextSize)
    pdf.text('Billing Period', docWidth / 2, currentHeight, { align: 'center' })
    currentHeight += pdfConfig.subLineHeight * 2
    pdf.text(`${from} to ${to}`, docWidth / 2, currentHeight, { align: 'center' })

    currentHeight += pdfConfig.subLineHeight * 2

    // Add Chart
    if (chartImage) {
      pdf.addImage(chartImage, 'PNG', 0, currentHeight, 130, 100)
    }

    currentHeight += pdfConfig.subLineHeight * 10

    pdf.setTextColor(colorBlack)
    pdf.setFontSize(pdfConfig.labelTextSize)
    pdf.text(
      `Total Spend: ${
        totals.costTotal && totals.currency
          ? `${formatCurrency(totals.costTotal, totals.currency)}`
          : 'N/A'
      }`,
      docWidth - 5,
      currentHeight,
      { align: 'right' },
    )
    currentHeight += pdfConfig.subLineHeight * 2
    pdf.text(
      `Total Water Consumed: ${
        totals.usageTotal ? `${totals.usageTotal.toString()} m${String.fromCharCode(179)}` : 'N/A'
      }`,
      docWidth - 5,
      currentHeight,
      { align: 'right' },
    )
    currentHeight += pdfConfig.subLineHeight * 2
    pdf.text(
      `Cost per m${String.fromCharCode(179)}: ${
        totals.deviceCost && totals.currency
          ? `${formatCurrency(totals.deviceCost, totals.currency)}`
          : 'N/A'
      }`,
      docWidth - 5,
      currentHeight,
      { align: 'right' },
    )
    currentHeight += pdfConfig.subLineHeight * 4
    pdf.setFontSize(pdfConfig.subtitleTextSize)
    pdf.text(
      `Total Due: ${
        totals.costTotal && totals.currency
          ? `${formatCurrency(totals.costTotal, totals.currency)}`
          : 'N/A'
      }`,
      docWidth - 5,
      currentHeight,
      { align: 'right' },
    )
    currentHeight += pdfConfig.subLineHeight - 2
    pdf.line(150, currentHeight, docWidth - 5, currentHeight)

    if (displayTermsAndConditions && termsAndConditions.length > 0) {
      currentHeight += 50
      pdf.setTextColor(colorBlue)
      pdf.setFontSize(pdfConfig.labelTextSize)
      pdf.text('Terms & Conditions', 10, currentHeight)
      currentHeight += 10
      pdf.setTextColor(colorBlack)
      pdf.setFontSize(pdfConfig.fieldTextSize)
      pdf.text(termsAndConditions, 10, currentHeight)
    }

    pdf.setFontSize(pdfConfig.fieldTextSize - 2)
    pdf.setTextColor(colorGray)
    pdf.text('Page 1 / 1', docWidth - 20, pdf.internal.pageSize.height - 6)

    res(pdf)
  })
}

import { Button, Modal } from 'react-bootstrap'

export default function ResendModal({
  action,
  show,
  onHide,
  data,
  toString,
  onSend,
  name = 'Object',
}: any) {
  if (!action) action = 'Resend'
  return (
    <>
      <Modal show={show} onHide={onHide} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {action} {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to {action.toLowerCase()} to{' '}
          {data ? toString(data) : 'nothing selected'}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              onSend(data)
              onHide()
            }}
          >
            {action}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

import React from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'

import routes, { RoutedComponent } from '../../routes'
import { useAccountState } from '../../context/account/context/account.context'
import { useDeviceState } from '../../context/device/context/device.context'
import { useUserState } from '../../context/user/context/user.context'
import { hasPermissions } from '../../common/utils/helperFunctions'

import { PageNotFound } from './Components/PageNotFound'
import RedirectRoute from '../../RedirectRoute'
import { SentryRoutes } from '../../App'

const MainContent: React.FC = () => {
  const deviceState = useDeviceState()
  const accountState = useAccountState()
  const { authenticated, permissions } = useUserState()

  const location = useLocation()

  if (deviceState.loading || accountState.loading) {
    return <div>Loading...</div>
  }

  return (
    <div
      id="main-content"
      style={{ overflowY: 'auto', height: 'calc(100vh - 5.5rem)', marginTop: '0' }}
    >
      <div className="container-fluid">
        <SentryRoutes>
          <Route
            path=""
            element={
              location.state?.from ? (
                <Navigate to={location.state.from.pathname} replace />
              ) : (
                <Navigate
                  to={
                    hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW'])
                      ? '/hourly-usage'
                      : '/home'
                  }
                />
              )
            }
          />
          {routes.map((routedComponent: RoutedComponent) => {
            const Component = routedComponent.component

            return (
              <Route
                key={routedComponent.path}
                path={routedComponent.path}
                element={
                  authenticated &&
                  hasPermissions([...permissions], [...routedComponent.permissions]) ? (
                    <RedirectRoute>
                      <Component />
                    </RedirectRoute>
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              >
                {routedComponent.children?.map((childrenRoute: RoutedComponent) => {
                  const ChildrenComponent = childrenRoute.component
                  return (
                    <Route
                      key={childrenRoute.path}
                      path={childrenRoute.path}
                      element={
                        <RedirectRoute>
                          <ChildrenComponent />
                        </RedirectRoute>
                      }
                    />
                  )
                })}
              </Route>
            )
          })}
          {<Route path="*" element={<PageNotFound />} />},
        </SentryRoutes>
      </div>
    </div>
  )
}

export default MainContent

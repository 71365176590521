import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Card, Col } from 'react-bootstrap'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
} from 'chart.js'

import moment from 'moment-timezone'
import { useAlertDispatch, useAlertState } from '../../../context/alert/context/alert.context'
import { AlertGroup, AlertStatus } from '../../../context/alert/model/alert.model'
import { hasPermissions } from '../../../common/utils/helperFunctions'
import { useUserState } from '../../../context/user/context/user.context'
import { useDeviceState } from '../../../context/device/context/device.context'
import AckAlertModal from './AckAlertModal'
import { FaClock } from 'react-icons/fa'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import WaterUseChart from './WaterUseChart'

import './AlertQueueItem.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LineController,
  PointElement,
  Filler,
)

interface IAlertQueueItemProps {
  alertGroupItem?: AlertGroup
  historical?: boolean
}

interface IAckAlertItem {
  alertGroup: AlertGroup
  priorityTier: number
  status: AlertStatus
}

// const plugin = {
//     id: 'afterRender',
//
//     afterRender: function(chart: any, options: any) {
//         console.log("After render")
//     }
// }S
// ChartJS.register(plugin);

function AlertQueueItem({ alertGroupItem, historical = false }: IAlertQueueItemProps) {
  const { ackAlerts } = useAlertDispatch()
  const { permissions } = useUserState()
  const { devices } = useDeviceState()
  const { queue, filteredQueue } = useAlertState()

  const params = useParams()
  const navigate = useNavigate()
  const { id } = params

  const [estimatedLeak, setEstimatedLeak] = useState<number>(0)
  // const [estimatedLeakCost, setEstimatedLeakCost] = useState<number>(0);
  const [ackAlert, setAckAlert] = useState<IAckAlertItem>()
  const hasUpdateAlertPermission = hasPermissions(permissions, ['UPDATE:ALERTS:USAGE'])
  const chartRef = useRef<ChartJS<'line', number[], string>>(null)
  const [localChartRef, setLocalChartRef] =
    useState<React.RefObject<ChartJS<'line', number[], string>>>(chartRef)
  const [chartAnimationState, setChartAnimationState] = useState<boolean>(false)
  const [chartImage, setChartImage] = useState<string | null>(null)
  const [alertGroup, setAlertGroup] = useState<AlertGroup | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!chartRef) return
    const { current } = localChartRef
    if (!current) return

    if (!chartAnimationState) {
      const base64Img: string = current.toBase64Image()
      setChartImage(base64Img)
    }
  }, [chartAnimationState])

  useEffect(() => {
    if (id) {
      const alert = filteredQueue.find((queue) => queue.deviceId === id)
      setAlertGroup(alert)
      setIsLoading(false)
    }
  }, [params, filteredQueue])

  useEffect(() => {
    if (alertGroupItem) {
      setAlertGroup(alertGroupItem)
      setIsLoading(false)
    }
  }, [alertGroupItem])

  if (!alertGroup) return <div />
  const device = devices.find((d) => d.dlId === alertGroup.dlId)

  function prepareWaterGraph() {
    // well this isn't pretty, but it works. Should use setRef instead
    // let myChart = document.getElementsByClassName(
    //     "chartjs-render-monitor"
    // )[0] as HTMLCanvasElement;
    const myChart = document.getElementById('water-usage-chart') as HTMLCanvasElement
    return myChart.toDataURL('image/png')
  }

  function downloadWaterGraph() {
    // TODO: dynamically name file...
    downloadImage(prepareWaterGraph(), 'water_use_alert.png')
  }

  // Save | Download image
  function downloadImage(data: any, filename = 'untitled.jpeg') {
    let a = document.createElement('a')
    a.href = data
    a.download = filename
    document.body.appendChild(a)
    a.click()
    // Q. Probably should removeChild?
  }

  function canAckAlert(alertGroup: AlertGroup) {
    return hasUpdateAlertPermission && alertGroup.status !== AlertStatus.CLOSED
  }

  const activeAlerts = alertGroup.alerts.filter((alert) => alert.priorityTier == null)
  const ackAlertList = alertGroup.alerts.filter((alert) => alert.priorityTier != null)

  const actionAlert = async (alertGroup: AlertGroup, priorityTier: number, status: AlertStatus) => {
    if (priorityTier !== 0) {
      if (!chartRef) return
      const { current } = localChartRef
      if (!current) return
      current.setDatasetVisibility(0, false)
      current.setDatasetVisibility(3, false)
      current.update('none')
      setChartAnimationState(true)

      setAckAlert({
        alertGroup: alertGroup,
        priorityTier: priorityTier,
        status: status,
      })
    } else {
      await ackAlerts(alertGroup, priorityTier, status)

      redirect()
    }
  }

  const redirect = () => {
    const currentAlertIndex = filteredQueue.findIndex((queue) => queue.deviceId === id)

    if (filteredQueue[currentAlertIndex + 1] !== undefined) {
      navigate(`/alert-queue/${filteredQueue[currentAlertIndex + 1].deviceId}`, { replace: true })
    } else {
      navigate('/alert-queue', { replace: true })
    }
  }

  return isLoading ? (
    <div className="spinner-border m-auto mb-5 mt-5 d-flex text-primary" />
  ) : (
    <>
      <div className="alert-queue-item card shadow mb-4 mx-auto" style={{ width: '95%' }}>
        <div className="card-body">
          <div className="row p-1">
            <div className="col h-100">
              <div className={'d-flex h-100 align-items-center'}>
                <div className={'pr-3 h-100'}>
                  <SectorIcon
                    sector={device?.deviceSettings.sectorType}
                    size={25}
                    color="#000"
                    occupants={device?.deviceSettings.occupants}
                  />
                </div>
                <div className={'h-100 py-2'}>
                  <div>
                    <h5 className="m-0 font-weight-bold text-primary">
                      {device?.deviceName ? device.deviceName : alertGroup.deviceId}
                    </h5>
                    <h6>{alertGroup.deviceId}</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto m-auto">
              {canAckAlert(alertGroup) && alertGroup.priorityTier ? (
                <button
                  onClick={() => {
                    actionAlert(alertGroup, alertGroup.priorityTier, AlertStatus.CLOSED)
                  }}
                  type="button"
                  className="mr-sm-0 mr-md-3 btn btn-primary my-md-0 my-2"
                >
                  Archive Alert
                </button>
              ) : null}
            </div>
            <div className="col-auto m-auto">
              <div className="btn-group " role="group">
                {canAckAlert(alertGroup) ? (
                  <button
                    disabled={!canAckAlert(alertGroup)}
                    onClick={() => {
                      actionAlert(alertGroup, 0, AlertStatus.CLOSED)
                    }}
                    type="button"
                    className={`btn ${
                      alertGroup.priorityTier === 0 ? 'btn-primary' : 'btn-outline-secondary'
                    }`}
                  >
                    Normal Flow
                  </button>
                ) : null}
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 3, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 3 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 3
                </button>
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 2, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 2 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 2
                </button>
                <button
                  disabled={!canAckAlert(alertGroup)}
                  onClick={() => {
                    actionAlert(alertGroup, 1, AlertStatus.IN_PROGRESS)
                  }}
                  type="button"
                  className={`btn ${
                    alertGroup.priorityTier === 1 ? 'btn-primary' : 'btn-outline-secondary'
                  }`}
                >
                  Tier 1
                </button>
              </div>
            </div>
          </div>

          <div className="row p-1">
            <div className="col m-auto">
              <div className={'row'}>
                <div className={'col-auto'}>
                  <div className={'d-flex h-100'}>
                    <div className={'pr-3 h-100'}>
                      <span className={'d-flex h-100 align-items-center'}>
                        <FaClock size={20} style={{ color: 'black' }}></FaClock>
                      </span>
                    </div>
                    <div className={'h-100 py-2'}>
                      <h5>
                        {hasUpdateAlertPermission ? (
                          <span
                            style={{
                              color: 'red',
                              fontWeight: 800,
                            }}
                          >
                            {' '}
                            {alertGroup.alerts.length} alerts ({activeAlerts.length} active /{' '}
                            {ackAlertList.length} acknowledged)
                            <br />
                          </span>
                        ) : null}
                        <span style={{ color: 'red', fontWeight: 400 }}>
                          {moment(alertGroup.startAlertTime).format('ddd, DD/MM/YY LT')}
                        </span>
                        <small> to </small>
                        <span style={{ color: 'red', fontWeight: 400 }}>
                          {moment(alertGroup.endAlertTime).format('ddd, DD/MM/YY LT')}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
                <div className={'col-auto'}>
                  <h6>Most recent: {moment(alertGroup.endAlertTime).fromNow()}</h6>
                  <h6>
                    Duration:{' '}
                    {moment(alertGroup.endAlertTime).diff(
                      moment(alertGroup.startAlertTime),
                      'hours',
                    ) + 1}{' '}
                    hours
                  </h6>
                </div>

                <div className={'col-auto'}>
                  {historical && !hasUpdateAlertPermission ? null : (
                    <h6>
                      {alertGroup.hourlyAlertsSentDate !== null
                        ? 'Alert Sent: ' +
                          moment
                            .utc(alertGroup.hourlyAlertsSentDate)
                            .tz(moment.tz.guess())
                            .format('DD/MM/YYYY LT')
                        : 'No Alert Sent'}
                    </h6>
                  )}

                  <h6>
                    {alertGroup.alertCloseDate &&
                      'Alert Closed: ' +
                        moment
                          .utc(alertGroup.alertCloseDate)
                          .tz(moment.tz.guess())
                          .format('DD/MM/YYYY LT')}
                  </h6>

                  {hasUpdateAlertPermission ? <h6>Acknowledged By: {alertGroup.ackBy}</h6> : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <Card>
              <Card.Body>
                {/*  WaterUseChart Class? and how to get an instance for copy to clipboard ?*/}
                <WaterUseChart
                  alertGroup={alertGroup}
                  setEstimatedLeakCallback={setEstimatedLeak}
                  setChartRef={setLocalChartRef}
                  chartAnimationCallback={setChartAnimationState}
                />
                <Col xs={6}>
                  <Button onClick={() => downloadWaterGraph()} variant="secondary">
                    <span className="fas fa-download fa-lg mr-2"></span>
                    Download
                  </Button>
                </Col>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      {ackAlert && !chartAnimationState ? (
        <AckAlertModal
          alertGroup={ackAlert.alertGroup}
          priorityTier={ackAlert.priorityTier}
          status={ackAlert.status}
          onHide={() => setAckAlert(undefined)}
          chartRef={localChartRef}
          chartImage={chartImage}
          activeAlertId={id || ''}
        />
      ) : null}
    </>
  )
}

export default AlertQueueItem

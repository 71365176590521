import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Form, Modal, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import Select from 'react-select'
import { FaBan } from 'react-icons/fa'
import {
  useDeviceDispatch,
  useDeviceState,
} from '../../../../../context/device/context/device.context'
import Device, { DeviceIDTypes } from '../../../../../context/device/model/device'
import {
  hasPermissions,
  getBatteryIconClass,
  getBatteryPercentage,
  getSignalStatus,
  getBatteryStatus,
  getDeviceStatus,
} from '../../../../../common/utils/helperFunctions'
import { useUserState } from '../../../../../context/user/context/user.context'

import {
  PULSES_OPTIONS,
  SECTOR_TYPES_OPTIONS,
  CURRENCY_OPTIONS,
  FIRMWARE_OPTIONS,
} from '../constants'

import './DeviceModal.scss'

interface IModalProps {
  show: boolean
  deviceData: Device | undefined
  onHide: () => void
}

const DeviceModal: React.FC<IModalProps> = ({ show, deviceData, onHide }: IModalProps) => {
  const [newDeviceName, setNewDeviceName] = useState('')
  const [newDeviceLocation, setNewDeviceLocation] = useState('')
  const [newCost, setNewCost] = useState(2.2)
  const [newCurrency, setNewCurrency] = useState('')
  const [newPulses, setNewPulses] = useState(1)
  const [newFirmware, setNewFirmware] = useState('')
  const [newSectorType, setNewSectorType] = useState('')
  const [newReport30DaySent, setNewReport30DaySent] = useState(false)
  const [newWaterType, setNewWaterType] = useState('')
  const [newDeviceActive, setNewDeviceActive] = useState(false)
  const [newInstallDate, setInstallDate] = useState<Date | null>(null)
  const [newUsageStartDate, setUsageStartDate] = useState<Date | null>(null)
  const [newMonitoringStartDate, setMonitoringStartDate] = useState<Date | null>(null)
  const [newParentDevice, setNewParentDevice] = useState<Device | null>(null)
  const [newOccupants, setNewOccupants] = useState<number>(0)

  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)
  const [didDevicePulsesChange, setDidDevicePulseChange] = useState(false)
  const [didDeviceFirmwareChange, setDidDeviceFirmwareChange] = useState(false)
  const [wasResetSent, setWasResetSent] = useState(false)
  const [wasCommissionValvesSent, setCommissionValvesSent] = useState(false)

  const [wasNewFirmwareSent, setWasNewFirmwareSent] = useState(true)
  const [buttonIdClicked, setButtonIdClicked] = useState('')

  const [tabKey, setTabKey] = useState<string>('device_info_tab')

  const {
    updateDeviceData,
    updateDevicePulses,
    resetDevice,
    updateFirmwareFilename,
    commissionValves,
    loadDevice,
  } = useDeviceDispatch()
  const { deviceUpdating, error: deviceError, devices } = useDeviceState()
  const { permissions } = useUserState()

  let required =
    newDeviceName.length === 0 || newCost === 0 || isNaN(newCost) || newCurrency.length < 3

  useEffect(() => {
    if (show && deviceData && deviceData.deviceSettings) {
      setNewDeviceName(deviceData.deviceSettings.deviceName)
      setNewDeviceLocation(deviceData.deviceSettings.location || '')
      setNewCost(deviceData.deviceSettings.cost || 2.2)
      setNewCurrency(deviceData.deviceSettings.currency)
      setNewPulses(deviceData.deviceSettings.pulsesPerLiter)
      setWasResetSent(false)
      setWasNewFirmwareSent(true)
      setCommissionValvesSent(false)
      setNewSectorType(deviceData.deviceSettings.sectorType)
      setNewReport30DaySent(deviceData.deviceSettings.report30Day || false)
      setNewWaterType(deviceData.deviceSettings.type)
      setNewDeviceActive(deviceData.deviceSettings.active)
      setInstallDate(moment(deviceData.deviceSettings.installDate).toDate()) // Value is currently UTC
      setUsageStartDate(moment(deviceData.deviceSettings.usageStartDate).toDate())
      setMonitoringStartDate(moment(deviceData.deviceSettings.monitoringStartDate).toDate())
      setNewParentDevice(
        devices.find((device) => device.dlId === deviceData.deviceSettings.masterDeviceIdRef) ||
          null,
      )
      setNewOccupants(deviceData.deviceSettings.occupants || 0)
    }
  }, [show, deviceData])

  useEffect(() => {
    if (deviceData && deviceData.deviceSettings) {
      if (
        deviceData.deviceName !== newDeviceName ||
        deviceData.deviceSettings.location !== newDeviceLocation ||
        deviceData.deviceSettings.cost !== newCost ||
        deviceData.deviceSettings.currency !== newCurrency ||
        deviceData.deviceSettings.sectorType !== newSectorType ||
        deviceData.deviceSettings.report30Day !== newReport30DaySent ||
        deviceData.deviceSettings.pulsesPerLiter !== newPulses ||
        deviceData.deviceSettings.type !== newWaterType ||
        deviceData.deviceSettings.masterDeviceIdRef !==
          (newParentDevice ? newParentDevice?.dlId : null) ||
        deviceData.deviceSettings.installDate !==
          moment(newInstallDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.usageStartDate !==
          moment(newUsageStartDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.monitoringStartDate !==
          moment(newMonitoringStartDate).format('YYYY-MM-DDTHH:00:00') ||
        deviceData.deviceSettings.active !== newDeviceActive ||
        deviceData.deviceSettings.occupants !== newOccupants
      ) {
        // Some value has changed
        setDidDeviceInfoChange(true)
      } else {
        setDidDeviceInfoChange(false)
      }
    }
  }, [
    deviceData,
    newDeviceName,
    newDeviceLocation,
    newCost,
    newCurrency,
    newSectorType,
    newReport30DaySent,
    newWaterType,
    newDeviceActive,
    newInstallDate,
    newUsageStartDate,
    newMonitoringStartDate,
    newParentDevice,
    newOccupants,
  ])

  useEffect(() => {
    if (buttonIdClicked && !deviceUpdating) {
      setButtonIdClicked('')
    }
  }, [deviceUpdating, deviceError])

  useEffect(() => {
    if (deviceData && Object.keys(deviceData).length > 0) {
      if (deviceData.deviceSettings.pulsesPerLiter !== newPulses) {
        setDidDevicePulseChange(true)
      } else {
        setDidDevicePulseChange(false)
      }
    }
  }, [newPulses, deviceData])

  useEffect(() => {
    if (deviceData && Object.keys(deviceData).length > 0) {
      if (newFirmware && deviceData.firmware !== newFirmware) {
        setDidDeviceFirmwareChange(true)
        setWasNewFirmwareSent(false)
      } else {
        setDidDeviceFirmwareChange(false)
      }
    }
  }, [newFirmware, deviceData])

  const handleStringToBool = (e: string, setCallback: React.Dispatch<any>) => {
    let updatedValue: boolean
    if (e === 'true' || e === 'false') {
      updatedValue = JSON.parse(e)
      setCallback(updatedValue)
    }
  }

  const boolToStringDefault = (val: boolean) => {
    return val ? { label: 'Yes', value: 'true' } : { label: 'No', value: 'false' }
  }

  const handleOnUpdateDeviceInfo = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!deviceData) return

    setButtonIdClicked(e.currentTarget.id)
    const updatedDevice = { ...deviceData }
    updatedDevice.deviceName = newDeviceName
    updatedDevice.deviceSettings.location = newDeviceLocation
    updatedDevice.deviceSettings.currency = newCurrency
    updatedDevice.deviceSettings.cost = newCost
    updatedDevice.deviceSettings.sectorType = newSectorType
    updatedDevice.deviceSettings.report30Day = newReport30DaySent
    updatedDevice.deviceSettings.active = newDeviceActive
    updatedDevice.deviceSettings.type = newWaterType
    updatedDevice.deviceSettings.hot = newWaterType === 'Hot'
    updatedDevice.deviceSettings.masterDeviceIdRef = newParentDevice?.dlId
    updatedDevice.deviceSettings.occupants = newOccupants
    updatedDevice.deviceSettings.installDate = moment(newInstallDate).format('YYYY-MM-DDTHH:00:00')
    updatedDevice.deviceSettings.usageStartDate =
      moment(newUsageStartDate).format('YYYY-MM-DDTHH:00:00')
    updatedDevice.deviceSettings.monitoringStartDate =
      moment(newMonitoringStartDate).format('YYYY-MM-DDTHH:00:00')
    // await updateDeviceData(updatedDevice)
    if (deviceData?.dUUID) {
      await updateDeviceData(deviceData?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }
  }

  const handleOnUpdateDevicePulses = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      if (deviceData?.dUUID) {
        await updateDevicePulses(deviceData?.dUUID, DeviceIDTypes.dUUID, newPulses)
      }
    }
  }

  const handleOnForceUpdateDeviceFirmware = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      if (deviceData?.dUUID) {
        await updateFirmwareFilename(deviceData?.dUUID, DeviceIDTypes.dUUID, newFirmware)
      }
      setWasNewFirmwareSent(true)
    }
  }

  const handleOnDeviceReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      // const { deviceId } = deviceData
      if (deviceData?.dUUID) {
        resetDevice(deviceData?.dUUID, DeviceIDTypes.dUUID)
      }

      setWasResetSent(true)
    }
  }

  const handleOnCommissionValves = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (deviceData) {
      setButtonIdClicked(e.currentTarget.id)
      const { deviceId } = deviceData
      commissionValves(deviceId)
      setCommissionValvesSent(true)
    }
  }

  const getPossibleParents = () => {
    return devices.filter(
      (device) =>
        device.deviceLocationId === deviceData?.deviceLocationId &&
        device.dUUID !== deviceData.dUUID &&
        !deviceData.childDeviceIds?.includes(device.deviceSettings.id),
    )
  }

  const handleRefreshData = () => {
    if (deviceData?.dUUID) {
      loadDevice(deviceData?.dUUID, DeviceIDTypes.dUUID)
    }
  }

  if (!deviceData) return null

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Device Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {required ? <span className="red">* Required!!</span> : ''}
        <Row>
          <Col>
            <div>
              <h4>{deviceData.deviceName}</h4>
            </div>
          </Col>
          <Col>
            <div>
              <Button
                id="update-device-info"
                className={'float-end'}
                variant="primary"
                type="button"
                // disabled={!didDeviceInfoChange || deviceUpdating}
                onClick={handleRefreshData}
              >
                Refresh
              </Button>
            </div>
          </Col>
        </Row>
        <hr />

        <Tabs id="controlled-tab" activeKey={tabKey} onSelect={(k: any) => setTabKey(k)}>
          <Tab eventKey="device_info_tab" title="Info">
            <Form className="device-information-form">
              <Row className="mb-2 mt-4">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Device Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a name"
                    value={newDeviceName}
                    onChange={(e) => setNewDeviceName(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Location Reference</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Pole B12"
                    value={newDeviceLocation}
                    onChange={(e) => setNewDeviceLocation(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridId">
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    defaultValue={deviceData.deviceId}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridCurrency">
                  <Form.Label>
                    Cost <span className="red">{newCost === 0 || isNaN(newCost) ? '*' : ''}</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0.01"
                    placeholder="e.g. 1.93"
                    value={newCost}
                    onChange={(e) =>
                      isNaN(parseFloat(e.target.value)) ? 0 : setNewCost(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCurrency">
                  <Form.Label>
                    Currency <span className="red">{newCurrency.length !== 3 ? '*' : ''}</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={newCurrency}
                    onChange={(e) => setNewCurrency(e.target.value)}
                  >
                    {CURRENCY_OPTIONS.map((currency) => (
                      <option key={currency}>{currency}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formSectorType">
                  <Form.Label>
                    Sector <span className="red">{!newSectorType ? '*' : ''}</span>
                  </Form.Label>
                  <Select
                    classNamePrefix="select"
                    defaultValue={{
                      value: deviceData.deviceSettings.sectorType,
                      label: deviceData.deviceSettings.sectorType,
                    }}
                    onChange={(d: any) => setNewSectorType(d.value)}
                    options={SECTOR_TYPES_OPTIONS.map((sector) => {
                      return { value: sector, label: sector }
                    })}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGrid30DayReport">
                  <Form.Label>30 Day Report Completed</Form.Label>
                  <Select
                    classNamePrefix="select"
                    defaultValue={boolToStringDefault(
                      deviceData.deviceSettings?.report30Day || false,
                    )}
                    onChange={(d: any) => handleStringToBool(d.value, setNewReport30DaySent)}
                    options={[
                      { value: 'false', label: 'No' },
                      { value: 'true', label: 'Yes' },
                    ]}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formWaterType">
                  <Form.Label>Water Type</Form.Label>
                  <Select
                    classNamePrefix="select"
                    defaultValue={{
                      value: deviceData.deviceSettings.type,
                      label: deviceData.deviceSettings.type,
                    }}
                    onChange={(d: any) => setNewWaterType(d.value)}
                    options={['Hot', 'Cold'].map((sector) => {
                      return { value: sector, label: sector }
                    })}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formDeviceActive">
                  <Form.Label>Device Active</Form.Label>
                  <Select
                    classNamePrefix="select"
                    defaultValue={boolToStringDefault(deviceData.deviceSettings?.active || false)}
                    onChange={(d: any) => handleStringToBool(d.value, setNewDeviceActive)}
                    options={[
                      { value: 'false', label: 'No' },
                      { value: 'true', label: 'Yes' },
                    ]}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => `${option.label}`}
                    isClearable={false}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="formGridOccupants">
                  <Form.Label>Occupants</Form.Label>
                  <Form.Control
                    type="number"
                    value={newOccupants}
                    min={0}
                    onChange={(e) => setNewOccupants(parseInt(e.target.value))}
                  />
                </Form.Group>
                <Form.Group as={Col} />
              </Row>

              <Row className="mb-2 mt-1">
                {getPossibleParents().length > 0 && (
                  <Form.Group as={Col} controlId="formWaterType">
                    <Form.Label>Main Meter Device</Form.Label>
                    <Select
                      classNamePrefix="select"
                      onChange={(d: any) => setNewParentDevice(d)}
                      options={getPossibleParents()}
                      placeholder="Select.."
                      getOptionValue={(option: any) => option.dlId}
                      getOptionLabel={(option: any) => `${option.deviceName}`}
                      isClearable={true}
                      value={newParentDevice}
                    />
                  </Form.Group>
                )}

                {deviceData.childDeviceIds?.length > 0 ? (
                  <Form.Group as={Col} controlId="formWaterType">
                    <Form.Label>
                      {`Sub meter(s) (${deviceData.childDeviceIds.length} total)`}
                    </Form.Label>
                    <Form.Control
                      value={devices
                        .filter((device) =>
                          deviceData.childDeviceIds.includes(device.deviceSettings.id || 0),
                        )
                        .map((child) => child.deviceName)
                        .join(', ')}
                      readOnly
                    ></Form.Control>
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} controlId="formGridNetInfo"></Form.Group>
                )}
              </Row>

              <Row className="mb-2">
                <Form.Group as={Col} controlId="formInstallDate">
                  <Form.Label>Install Date</Form.Label>
                  <div className="date-picker">
                    <DatePicker
                      selected={newInstallDate}
                      onChange={(date: Date) => setInstallDate(date)}
                      placeholderText="Select Install date"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:00"
                      timeFormat="HH:mm"
                    />
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId="formUsageDate">
                  <Form.Label>Usage Start Date</Form.Label>
                  <div>
                    <DatePicker
                      selected={newUsageStartDate}
                      onChange={(date: Date) => setUsageStartDate(date)}
                      placeholderText="Select Usage Start Date"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:00"
                      timeFormat="HH:mm"
                    />
                  </div>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formMonitoringDate">
                  <Form.Label>Monitoring Start Date</Form.Label>
                  <div>
                    <DatePicker
                      selected={newMonitoringStartDate}
                      onChange={(date: Date) => setMonitoringStartDate(date)}
                      placeholderText="Select Monitoring Start Date"
                      showTimeSelect
                      dateFormat="dd/MM/yyyy HH:00"
                      timeFormat="HH:mm"
                    />
                  </div>
                </Form.Group>
              </Row>

              <Button
                id="update-device-info"
                variant="primary"
                type="button"
                disabled={!didDeviceInfoChange || deviceUpdating}
                onClick={handleOnUpdateDeviceInfo}
              >
                {deviceUpdating && buttonIdClicked === 'update-device-info' ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span>Loading...</span>
                  </>
                ) : (
                  <>Update</>
                )}
              </Button>
            </Form>

            {hasPermissions(permissions, ['UPDATE:DEVICE:PULSE']) ? (
              <>
                <hr />
                <Form className="device-information-form">
                  <Row className="mb-2">
                    <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                      <Form.Label>Pulses</Form.Label>
                      <Select
                        classNamePrefix="select"
                        value={{
                          value: newPulses.toString(),
                          label:
                            newPulses > 1
                              ? `${newPulses} - ${newPulses} Pulses/liter`
                              : `${newPulses} - ${1 / newPulses} Liters/pulse`,
                        }}
                        onChange={(e: any) => setNewPulses(Number(e.value))}
                        options={PULSES_OPTIONS}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => `${option.label}`}
                        isClearable={false}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="exampleForm.ControlSelect1"></Form.Group>
                  </Row>
                  <Button
                    id="update-device-pulses"
                    variant="primary"
                    type="button"
                    disabled={!didDevicePulsesChange || deviceUpdating}
                    onClick={handleOnUpdateDevicePulses}
                  >
                    {deviceUpdating && buttonIdClicked === 'update-device-pulses' ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span>Loading...</span>
                      </>
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                </Form>
              </>
            ) : null}
            {deviceData.deviceVendor === 'SMARTFLOW' &&
            hasPermissions(permissions, ['DEVICE:FIRMWARE:UPDATE']) ? (
              <>
                <hr />
                <Form className="device-information-form">
                  <Row className="mb-2">
                    <Form.Group as={Col} controlId="fwForm.ControlSelect">
                      <Form.Label>Firmware Update</Form.Label>
                      <Select
                        classNamePrefix="select"
                        onChange={(d: any) => setNewFirmware(d.value)}
                        options={FIRMWARE_OPTIONS.map((fw) => {
                          return { value: fw, label: fw }
                        })}
                        getOptionValue={(option) => option.value}
                        getOptionLabel={(option) => `${option.label}`}
                        isClearable={false}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formCurrentBinfile">
                      <Form.Label>Current Bin File</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={deviceData.binFileName}
                        disabled
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    id="update-device-firmware"
                    variant="primary"
                    type="button"
                    disabled={!didDeviceFirmwareChange && wasNewFirmwareSent}
                    onClick={handleOnForceUpdateDeviceFirmware}
                  >
                    {deviceUpdating && buttonIdClicked === 'update-device-firmware' ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span>Loading...</span>
                      </>
                    ) : (
                      <>Send Update</>
                    )}
                  </Button>
                  <div className="side-note">
                    * Remember to Reset the device (at least 1 minute) after this operation
                  </div>
                </Form>
              </>
            ) : null}
            {deviceData.deviceVendor === 'SMARTFLOW' &&
            hasPermissions(permissions, ['DEVICE:RESET']) ? (
              <>
                <hr />
                <Form className="device-information-form">
                  <Row>
                    <Form.Label>Reset</Form.Label>
                  </Row>
                  <Button
                    id="reset-device"
                    variant="primary"
                    type="button"
                    disabled={wasResetSent}
                    onClick={handleOnDeviceReset}
                  >
                    {deviceUpdating && buttonIdClicked === 'reset-device' ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span>Loading...</span>
                      </>
                    ) : (
                      <>Reset Device</>
                    )}
                  </Button>
                </Form>
              </>
            ) : null}
            {deviceData.deviceVendor === 'SMARTFLOW' &&
            hasPermissions(permissions, ['DEVICE:VALVE:COMM']) ? (
              <>
                <hr />
                <Form className="device-information-form">
                  <Row>
                    <Form.Label>Commission Valves</Form.Label>
                  </Row>
                  <Button
                    id="commission-valves"
                    variant="primary"
                    type="button"
                    disabled={wasCommissionValvesSent}
                    onClick={handleOnCommissionValves}
                  >
                    {deviceUpdating && buttonIdClicked === 'commission-valves' ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span>Commissioning...</span>
                      </>
                    ) : (
                      <>Commission Valves</>
                    )}
                  </Button>
                </Form>
              </>
            ) : null}
            <hr />
          </Tab>
          <Tab eventKey="device_network_tab" title="Network">
            <Form className="device-network-form">
              <Row className="mb-2 mt-4">
                <Form.Group as={Col} controlId="formGridICCID">
                  <Form.Label>
                    ICCID
                    {deviceData.ICCID && deviceData.mvno === 'Onomondo' ? (
                      <span className={'ml-2'}>
                        <Link
                          target="_blank"
                          to={`https://app.onomondo.com/sims/${deviceData.ICCID.slice(
                            10,
                            19,
                          )}?tab=usage`}
                        >
                          (Go to SIM)
                        </Link>
                      </span>
                    ) : null}
                  </Form.Label>
                  <Form.Control type="text" placeholder="" value={deviceData.ICCID} disabled />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridMVNO">
                  <Form.Label>MVNO</Form.Label>
                  <Form.Control type="text" placeholder="" value={deviceData.mvno} disabled />
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridNetName">
                  <Form.Label>Network Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={deviceData.networkName}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCSQ">
                  <Form.Label>CSQ</Form.Label>
                  <Form.Control type="text" placeholder="" value={deviceData.signal} disabled />
                </Form.Group>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="device_status_tab" title="Status">
            <Form className="device-network-form">
              <Row className="mb-2 mt-4">
                <Form.Group as={Col} controlId="formGridICCID">
                  <Form.Label>Battery %</Form.Label>
                  <div className="battery-container">
                    <span
                      className={getBatteryIconClass(
                        getBatteryPercentage(deviceData.batteryLevel, deviceData.deviceVendor),
                      )}
                    />
                    <span className="battery-level ml-2">
                      {getBatteryPercentage(deviceData.batteryLevel, deviceData.deviceVendor)}%
                      (Int)
                    </span>
                  </div>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridMVNO">
                  <Form.Label>Signal</Form.Label>
                  <div className="signal-container">
                    <span>
                      {deviceData.signal ? getSignalStatus(deviceData.signal) : <FaBan />}
                    </span>
                  </div>
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridNetName">
                  <Form.Label>Power</Form.Label>
                  {deviceData.batteryStatus ? (
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={getBatteryStatus(deviceData)}
                      disabled
                    />
                  ) : (
                    <div>
                      <FaBan />
                    </div>
                  )}
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCSQ">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={deviceData.deviceSettings.active ? 'Active' : 'Inactive'}
                    disabled
                  />
                </Form.Group>
              </Row>
              <Row className="mb-2">
                <Form.Group as={Col} controlId="formGridCSQ">
                  <Form.Label>Status</Form.Label>
                  {deviceData.deviceVendor === 'SMARTFLOW' ||
                  deviceData.deviceVendor === 'METASPHERE' ||
                  deviceData.deviceVendor === 'PLUM' ? (
                    <div>
                      {getDeviceStatus(
                        deviceData,
                        hasPermissions(permissions, ['DASHBOARD:STATUS:VIEW']),
                        hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']),
                      )}
                    </div>
                  ) : (
                    <div>
                      <FaBan />
                    </div>
                  )}
                </Form.Group>
              </Row>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button id="close-modal" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeviceModal

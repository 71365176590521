import React, { useCallback, useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { useDeviceDispatch, useDeviceState } from '../../../context/device/context/device.context'
import Device from '../../../context/device/model/device'
import { rtfWebSocketURI } from '../../../common/utils/constants'
import './RealTimeFlow.scss'

let maxValue = 30

const updateCanvas = (value?: number) => {
  const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
  let ctx = canvas?.getContext('2d')
  if (!ctx) return

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  const PI = Math.PI
  const PI2 = PI * 2
  let cx = canvas.width / 2
  let cy = canvas.width / 2
  let r = canvas.width / 2 - 16
  const min = PI * 0.75
  const max = PI2 + PI * 0.25

  if (value && value > maxValue) maxValue = value! * 1.2

  let highBreakoff = maxValue

  let percent =
    value === undefined ? 0 : value > 0 ? (Math.min(highBreakoff, value) / maxValue) * 100 : 1

  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = 'gray'
  ctx.imageSmoothingEnabled = true

  ctx.beginPath()
  ctx.arc(cx, cy, r, min, max)
  ctx.strokeStyle = 'lightgray'
  ctx.lineWidth = 14
  ctx.stroke()

  ctx.beginPath()
  ctx.arc(cx, cy, r, min, min + ((max - min) * percent) / 100)
  ctx.strokeStyle = '#1689CA'
  ctx.lineWidth = 15
  ctx.stroke()

  ctx.font = '26px verdana'
  if (value !== undefined) ctx.fillStyle = '#1689CA'

  ctx.fillText(value !== undefined ? value.toFixed(2) : '0', cx, cy - 15)
  //ctx.fillText(c.width + " " + c.height + " " + cx + " " + cy, cx, cy)
  ctx.fillStyle = 'gray'
  ctx.font = '12px verdana'
  ctx.fillText('l/min', cx, cy + 15)
}

function Dashboard() {
  const [socketUrl, setSocketUrl] = useState('wss://example.com')
  const { devices } = useDeviceState()
  const { startRTF } = useDeviceDispatch()
  const { lastMessage, readyState, getWebSocket } = useWebSocket(socketUrl)
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [rtfRunning, setRtfRunning] = useState<boolean>(false)

  const canvasRef = useCallback((canvas: HTMLCanvasElement) => {
    if (!canvas) return
    canvas.style.height = `${canvas.offsetWidth}px`
    canvas.width = canvas.offsetWidth
    canvas.height = canvas.offsetHeight
    updateCanvas()
  }, [])

  useEffect(() => {
    const jsonString = lastMessage?.data
    const message = jsonString && JSON.parse(jsonString)
    const deviceId = String(message?.deviceId)
    if (
      deviceId === selectedDevice?.deviceId &&
      (message?.messageType === 'RTF' || message?.messageType === 'END_RTF')
    ) {
      const flow = message?.body?.Flow
      if (flow) updateCanvas(flow / 1000)
      if (message?.messageType === 'END_RTF') {
        setRtfRunning(false)
        getWebSocket()?.close()
      }
    }
  }, [lastMessage])

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const canvas = document.getElementById('myCanvas') as HTMLCanvasElement
        if (!canvas) return
        canvas.style.height = `${canvas.offsetWidth}px`
        canvas.width = canvas.offsetWidth
        canvas.height = canvas.offsetHeight
        updateCanvas()
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (selectedDevice) {
      setRtfRunning(false)
      updateCanvas()
    }
    getWebSocket()?.close()
  }, [selectedDevice])

  useEffect(() => {
    if ((readyState === ReadyState.OPEN || readyState === ReadyState.CLOSED) &&
      selectedDevice && rtfRunning && process.env.REACT_APP_ENV_STATE !== 'DEMO') {
        startRTF(selectedDevice.deviceId)
    }
  }, [readyState, selectedDevice, rtfRunning])

  const go = useCallback(() => {
    updateCanvas()
    if (!selectedDevice) return
    const newSocketUrl: string = process.env.REACT_APP_ENV_STATE === 'DEMO' && process.env.REACT_APP_API_URI ?
      `${process.env.REACT_APP_API_URI.includes('localhost') ? 
        'ws' : 
        'wss'}://${process.env.REACT_APP_API_URI.replace(/^https?:\/\//, '')}/devices/${selectedDevice.deviceId}/rtf_demo` :
      `${rtfWebSocketURI}/${selectedDevice.deviceId}/events`;

    if (newSocketUrl === socketUrl) getWebSocket()?.close()
    setSocketUrl(newSocketUrl)
    setRtfRunning(true)
    window.setTimeout(() => {
      setRtfRunning(false)
      getWebSocket()?.close()
    }, 25000)
  }, [selectedDevice])

  return (
    <div className="rtf-management">
      <h1>Real Time Flow</h1>
      <div className="container-fluid flex-container ">
        <div className="row">
          <div className="section-header">
            <div className="">Device:</div>
          </div>
          <div className="col-md-6">
            <div className="multiselect-container">
              <Select
                isDisabled={rtfRunning}
                classNamePrefix="select"
                className="basic-multi-select"
                value={selectedDevice}
                onChange={(device: any) => setSelectedDevice(device)}
                options={devices}
                name="device"
                getOptionValue={(option) => option.deviceId}
                getOptionLabel={(option) => `${option.deviceName}`}
                isClearable={false}
              />
            </div>
          </div>
        </div>
        {selectedDevice ? (
          <div className="rtf-view-container">
            <canvas ref={canvasRef} id="myCanvas" />
            <Button disabled={rtfRunning} onClick={() => go()}>
              {rtfRunning ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : null}
              Start RTF
            </Button>
          </div>
        ) : (
          <div className="no-device-message">Select a device</div>
        )}
      </div>
    </div>
  )
}

export default Dashboard

import axios from 'axios';
import { refreshAction } from '../context/user/action/login.action';
import { Action, ActionTypes } from '../context/user/context/user.reducer';


const backend: any = {
    production_URI: 'https://api.smartflowmonitoring.com/v3',
}

const getUrl = () => {
    if (process.env.REACT_APP_API_URI) {
        return process.env.REACT_APP_API_URI
    } else {
        return backend.production_URI
    }
}

const customAxios = axios.create({
    baseURL: getUrl(),
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
    },
});


// Add a request interceptor
customAxios.interceptors.request.use(
    (config) => {
        // const token = localStorageService.getAccessToken();
        const token = localStorage.getItem('jwt-token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    async (error) => {
        return Promise.reject(error)
});


//Add a response interceptor
customAxios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            const refreshToken = localStorage.getItem('refresh-token');
            originalRequest._retry = true;
            localStorage.clear();
            try {
                if (refreshToken) {
                    await refreshAction(refreshToken, (action: Action) => {
                        if (action.type === ActionTypes.REFRESH) {
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt-token');
                        }
                    })
                    return customAxios(originalRequest);
                }
             } catch (e) {
                console.log(e);
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
)

export default customAxios;

import React, { useState, useEffect, useRef } from 'react'
import { Bar, Line, getElementAtEvent } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  LineController,
  PointElement,
} from 'chart.js'
import { Tabs, Tab } from 'react-bootstrap'
import moment from 'moment-timezone'
import Select from 'react-select'

import {
  getRandomDarkColor,
  hasPermissions,
  hexToRgb,
} from '../../../../common/utils/helperFunctions'
import { useDeviceState } from '../../../../context/device/context/device.context'
import Device from '../../../../context/device/model/device'
import UsageNavigator from '../UsageNavigator/UsageNavigator'
import { blueSmartflow } from '../../../../common/utils/constants'

import '../Home.scss'
import './CurrentWaterUsageComponent.scss'
import { useWaterUsageState } from '../../../../context/waterUsage/waterUsageStats.context'
import { useUserState } from '../../../../context/user/context/user.context'

ChartJS.register(
  LineController,
  PointElement,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  Title,
  LinearScale,
  BarElement,
)

const TODAY = moment().startOf('day')
const BEGINNING_OF_WEEK = TODAY.clone().startOf('isoWeek')
const BEGINNING_OF_MONTH = TODAY.clone().startOf('month')
const BEGINNING_OF_YEAR = TODAY.clone().startOf('year')

function buildDataset(label: string, data: number[], color?: string): Dataset {
  const chartType = label.toLowerCase()

  if (
    chartType.includes('weekly') ||
    chartType.includes('monthly') ||
    chartType.includes('yearly')
  ) {
    color = blueSmartflow
  } else {
    if (!color) {
      const colorRGB: any = hexToRgb(getRandomDarkColor())
      color = `rgb(${colorRGB.r}, ${colorRGB.g}, ${colorRGB.b})`
    }
  }

  return { label, data, borderColor: color, backgroundColor: color, fill: false }
}

type Dataset = {
  label: string
  data: number[]
  borderColor: string[] | string
  backgroundColor: string[] | string
  fill?: boolean
  deviceId?: string
}

export function CurrentWaterUsageComponent() {
  const { devices } = useDeviceState()
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([])
  const [singleSelectedDevice, setSingleSelectedDevice] = useState<Device | null>(null)
  const { loading, waterUsageStats } = useWaterUsageState()

  const [hourlyDatasets, setHourlyDatasets] = useState<any>()
  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [weeklyDatasets, setWeeklyDatasets] = useState<any>()
  const [monthlyDatasets, setMonthlyDatasets] = useState<any>()
  const [annuallyDatasets, setAnnuallyDatasets] = useState<any>([])
  const [tabKey, setTabKey] = useState('hourly')
  const [showUsageNavigator, setShowUsageNavigator] = useState<boolean>(false)
  const { permissions } = useUserState()
  const chartRef = useRef<ChartJS<'line', number[], string>>(null)

  const onClick = (event: any) => {
    if (selectedDevices.length === 1) {
      setSingleSelectedDevice(selectedDevices[0])
      setShowUsageNavigator(true)
    } else {
      if (!chartRef) return

      const { current } = chartRef
      if (current) {
        const chartElement = getElementAtEvent(current, event)
        if (!chartElement.length) return
        const { datasetIndex } = chartElement[0]
        const selectedDevice = selectedDevices[datasetIndex]
        if (selectedDevice) {
          setSingleSelectedDevice(selectedDevice)
          setShowUsageNavigator(true)
        }
      }
    }
  }
  function getChartOptions(type: string) {
    let text: string
    let xAxis: string
    switch (type) {
      case 'hourly':
        text = TODAY.format('dddd, MMMM Do YYYY')
        xAxis = 'Hour'
        break
      case 'daily':
        text = TODAY.format('dddd, MMMM Do YYYY')
        xAxis = 'Device'
        break
      case 'weekly':
        text = `From ${BEGINNING_OF_WEEK.format('DD/MM/yyyy')} - To:  Today `
        xAxis = 'Device'
        break
      case 'monthly':
        text = `From: ${BEGINNING_OF_MONTH.format('DD/MM/yyyy')} - To: Today`
        xAxis = 'Device'
        break
      case 'annually':
        text = `From: ${BEGINNING_OF_YEAR.format('DD/MM/yyyy')} - To: Today`
        xAxis = 'Device'
        break
      default:
        text = TODAY.format('dddd, MMMM Do YYYY')
        xAxis = 'Hour'
        break
    }

    const chartOptions: any = {
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
      },
      plugins: {
        title: {
          display: true,
          text,
          position: 'top',
          align: 'center',
          padding: 22,
          font: {
            size: 18,
          },
        },
        legend: {
          display: type === 'hourly',
          position: 'bottom',
          labels: {
            color: '#323130',
            boxWidth: 8,
            padding: 8,
            usePointStyle: true,
            font: {
              size: 10,
            },
          },
        },
        tooltips: {
          mode: 'label',
          titleFont: {
            size: 18,
          },
          bodyFont: {
            Size: 18,
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Liters',
            font: {
              size: 16,
            },
          },
          ticks: {
            suggestedMin: 0,
          },
        },
        x: {
          title: {
            display: true,
            text: xAxis,
            padding: 8,
            font: {
              size: 16,
            },
          },
        },
      },
    }
    return chartOptions
  }

  const hourlyLabels = [
    '0.00',
    '1.00',
    '2.00',
    '3.00',
    '4.00',
    '5.00',
    '6.00',
    '7.00',
    '8.00',
    '9.00',
    '10.00',
    '11.00',
    '12.00',
    '13.00',
    '14.00',
    '15.00',
    '16.00',
    '17.00',
    '18.00',
    '19.00',
    '20.00',
    '21.00',
    '22.00',
    '23.00',
  ]

  // function buildWaterUsageQuery(deviceId: string) {
  //     return {
  //         deviceId,
  //         from: BEGINNING_OF_YEAR.format("YYYY-MM-DDTHH:mm:ss"),
  //         to: moment().format("YYYY-MM-DDT23:59:59"),
  //     }
  // }

  useEffect(() => {
    const hourly_flows: Dataset[] = []
    const daily_flows: Dataset[] = [
      {
        label: 'Total Flow',
        data: [],
        backgroundColor: '#1F77B4',
        borderColor: '#4C1D',
      },
      {
        label: 'Average Flow',
        data: [],
        backgroundColor: '#FF7F0E',
        borderColor: '#4C1D',
      },
    ]
    const weekly_flows: Dataset[] = [
      {
        label: 'Total Flow',
        data: [],
        backgroundColor: '#1F77B4',
        borderColor: '#4C1D',
      },
      {
        label: 'Average Flow',
        data: [],
        backgroundColor: '#FF7F0E',
        borderColor: '#4C1D',
      },
    ]
    const monthly_flows: Dataset[] = [
      {
        label: 'Total Flow',
        data: [],
        backgroundColor: '#1F77B4',
        borderColor: '#4C1D',
      },
      {
        label: 'Average Flow',
        data: [],
        backgroundColor: '#FF7F0E',
        borderColor: '#4C1D',
      },
    ]
    const annually_flows: Dataset[] = [
      {
        label: 'Total Flow',
        data: [],
        backgroundColor: '#1F77B4',
        borderColor: '#4C1D',
      },
    ]

    for (let i = 0; i < selectedDevices.length; i += 1) {
      const currentDeviceId = selectedDevices[i].deviceId
      const waterUsageObj = waterUsageStats[currentDeviceId]

      if (waterUsageObj) {
        const hourlyDSBuilt = buildDataset(
          selectedDevices[i].deviceName,
          waterUsageObj.hourlyUsage.map((hu) => hu.value ?? 0),
        )
        hourly_flows.push(hourlyDSBuilt)

        daily_flows[0].data.push(waterUsageObj.dailyUsage)
        daily_flows[1].data.push(waterUsageObj.avgDaily)

        weekly_flows[0].data.push(waterUsageObj.weeklyUsage)
        weekly_flows[1].data.push(waterUsageObj.avgWeekly)

        monthly_flows[0].data.push(waterUsageObj.monthlyUsage)
        monthly_flows[1].data.push(waterUsageObj.avgMonthly)

        annually_flows[0].data.push(waterUsageObj.yearlyUsage)
      }
    }

    // const [dailyDatasets, setDailyDatasets] = useState<Dataset[]>();
    // const [weeklyDatasets, setWeeklyDatasets] = useState(() => buildDataset("Weekly Flow", []));

    setHourlyDatasets(hourly_flows)
    setDailyDatasets(daily_flows)
    setWeeklyDatasets(weekly_flows)
    setMonthlyDatasets(monthly_flows)
    setAnnuallyDatasets(annually_flows)
  }, [waterUsageStats, selectedDevices])

  return (
    <div className="current-water-usage">
      <div className="card shadow h-100 py-2 mb-4">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col px-3">
              <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                Water Usage
              </div>
              <div className="row no-gutters align-items-center px-2">
                <div className="col">
                  <div className="multiselect_text">
                    Select device(s) (
                    <button
                      className="btn btn-link te text-decoration-none px-1"
                      onClick={() => {
                        if (!loading) setSelectedDevices(devices)
                      }}
                    >
                      select all
                    </button>
                    |
                    <button
                        className="btn btn-link text-decoration-none px-1"
                        onClick={() => setSelectedDevices([])}
                    >
                      clear all
                    </button>
                    ):
                  </div>
                  <div className="multiselect-container">
                    <Select
                      className="basic-multi-select"
                      value={selectedDevices}
                      onChange={(devices: any) => setSelectedDevices(devices)}
                      options={devices}
                      isMulti
                      name="devices"
                      classNamePrefix="select"
                      getOptionValue={(option: Device) => option.deviceId}
                      getOptionLabel={(option: Device) => `${option.deviceName}`}
                      isClearable={false}
                      isLoading={loading || devices.length === 0}
                      isDisabled={loading || devices.length === 0}
                    />
                  </div>
                  <br />
                  <div>
                    <Tabs
                      // transition={false}
                      id="controlled-tab-example"
                      activeKey={tabKey}
                      onSelect={(k: any) => setTabKey(k)}
                    >
                      <Tab eventKey="hourly" title="Hourly" disabled={!selectedDevices.length}>
                        <article className="canvas-container col-lg-12">
                          {selectedDevices && selectedDevices.length ?
                            <Line
                              data={{ labels: hourlyLabels, datasets: hourlyDatasets }}
                              ref={chartRef}
                              onClick={onClick}
                              options={getChartOptions('hourly')}
                            />
                           :
                            'Select a device'
                          }
                        </article>
                      </Tab>
                      {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                        <Tab eventKey="daily" title="Daily" disabled={!selectedDevices.length}>
                          <article className="canvas-container col-lg-12">
                            {dailyDatasets ? (
                              <Bar
                                data={{
                                  labels: selectedDevices.map(
                                    (device: Device) => device.deviceName,
                                  ),
                                  datasets: dailyDatasets,
                                }}
                                options={getChartOptions('daily')}
                              />
                            ) : null}
                          </article>
                        </Tab>
                      ) : null}
                      {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                        <Tab eventKey="weekly" title="Weekly" disabled={!selectedDevices.length}>
                          <article className="canvas-container col-lg-12">
                            {weeklyDatasets ? (
                              <Bar
                                data={{
                                  labels: selectedDevices.map(
                                    (device: Device) => device.deviceName,
                                  ),
                                  datasets: weeklyDatasets,
                                }}
                                options={getChartOptions('weekly')}
                              />
                            ) : null}
                          </article>
                        </Tab>
                      ) : null}
                      {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                        <Tab eventKey="monthly" title="Monthly" disabled={!selectedDevices.length}>
                          <article className="canvas-container col-lg-12">
                            {monthlyDatasets ? (
                              <Bar
                                data={{
                                  labels: selectedDevices.map(
                                    (device: Device) => device.deviceName,
                                  ),
                                  datasets: monthlyDatasets,
                                }}
                                options={getChartOptions('monthly')}
                              />
                            ) : null}
                          </article>
                        </Tab>
                      ) : null}
                      {hasPermissions(permissions, ['READ:USAGE:STATS']) ? (
                        <Tab eventKey="yearly" title="Yearly" disabled={!selectedDevices.length}>
                          <article className="canvas-container col-lg-12">
                            {annuallyDatasets ? (
                              <Bar
                                data={{
                                  labels: selectedDevices.map(
                                    (device: Device) => device.deviceName,
                                  ),
                                  datasets: annuallyDatasets,
                                }}
                                options={getChartOptions('annually')}
                              />
                            ) : null}
                          </article>
                        </Tab>
                      ) : null}
                    </Tabs>
                  </div>
                  <div>
                    {singleSelectedDevice && (
                      <UsageNavigator
                        show={showUsageNavigator}
                        deviceSelected={singleSelectedDevice}
                        onHide={() => setShowUsageNavigator(false)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useState, useEffect, useRef, useCallback } from 'react'

import { Button, Form, Modal, Row, Col } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import Handlebars from 'handlebars'
import { displayToast } from '../../../common/utils/appToast'

import backend from '../../../api/backend'
import Device, {DeviceIDTypes} from '../../../context/device/model/device'
import { useUserState } from '../../../context/user/context/user.context'
import { useDeviceDispatch } from '../../../context/device/context/device.context'
import { hasPermissions } from '../../../common/utils/helperFunctions'

interface Props {
  onHide: () => void
  selectedDevice: Device
  devicesDueTo30DaysReport: Device[]
  pdfAttachment: string
  csvAttachment: []
}

interface Recipient {
  id: number
  email: string
}

interface EmailObject {
  to: Recipient[]
  cc: Recipient[]
  bcc: Recipient[]
}

const WATER_WASTAGE_REPORT_EMAIL_TEMPLATE = 'd-f3046ff32dd6410697cf1921841fee99'

const WaterWastageEmailModal = ({
  onHide,
  selectedDevice,
  devicesDueTo30DaysReport,
  pdfAttachment,
  csvAttachment,
}: Props) => {
  const { updateDeviceData } = useDeviceDispatch()
  const { permissions } = useUserState()

  const [allRecipients, setAllRecipients] = useState<{
    to: Recipient[]
    bcc: Recipient[]
    cc: Recipient[]
  }>({ to: [], cc: [], bcc: [] })
  const [toRecipients, setToRecipients] = useState<Recipient[]>([])
  const [ccRecipients, setCcRecipients] = useState<Recipient[]>([])
  const [bccRecipients, setBccRecipients] = useState<Recipient[]>([])

  const [contactName, setContactName] = useState('Team')
  const [additionalInfo1, setAdditionalInfo1] = useState('')
  const [additionalInfoPre, setAdditionalInfoPre] = useState('')
  const [renderedEmail, setRenderedEmail] = useState('')

  const renderTemplate = (template: any) => setRenderedEmail(template(mapDynamicProperties()))
  const [templateContent, setTemplateContent] = useHookWithRefCallback(renderTemplate)

  const mapDynamicProperties = () => {
    return {
      device_name: selectedDevice.deviceName,
      additional_info_pre: additionalInfoPre,
      additional_info_1: additionalInfo1,
      contact_name: contactName,
    }
  }

  useEffect(() => {
    backend
      .get(`/mail_templates/${WATER_WASTAGE_REPORT_EMAIL_TEMPLATE}`)
      .then((response) => response.data)
      .then(function (response) {
        Handlebars.registerHelper('helperMissing', function (/* dynamic arguments */) {
          let options = arguments[arguments.length - 1]
          return new Handlebars.SafeString(`{{${options.name}}}`)
        })
        Handlebars.registerHelper('equals', function (this: any, lvalue, rvalue, options) {
          if (arguments.length < 3) throw new Error('Handlebars Helper equal needs 2 parameters')
          if (lvalue !== rvalue) {
            return options.inverse(this)
          } else {
            return options.fn(this)
          }
        })
        const template = Handlebars.compile(response.content.html_content)
        setTemplateContent(template)
      })
  }, [])

  useEffect(() => {
    if (!templateContent.current) return
    renderTemplate(templateContent.current)
  }, [contactName, additionalInfo1, additionalInfoPre])

  useEffect(() => {
    if (!selectedDevice) return

    getAllRecipients()
  }, [])

  useEffect(() => {
    setToRecipients(allRecipients.to)
    setCcRecipients(allRecipients.cc)
    setBccRecipients(allRecipients.bcc)
  }, [allRecipients])

  function useHookWithRefCallback(fn: (node: any) => void) {
    const ref = useRef<any>()
    const setRef = useCallback((node: any) => {
      if (node) {
        fn(node)
      }
      ref.current = node
    }, [])

    return [ref, setRef] as any[]
  }

  const getAllRecipients = async () => {
    const res: EmailObject[] = []

    const response = await backend.get(`/usage_alerts/${selectedDevice.deviceId}/alert_recipients`)
    res.push(response.data)

    const combinedData = res.reduce(
      (result, currentObject) => {
        currentObject.to.forEach((recipient) => {
          const email = recipient.email

          if (!result.to.find((r) => r.email === email)) {
            result.to.push(recipient)
          }
        })

        currentObject.cc.forEach((recipient) => {
          const email = recipient.email

          if (!result.cc.find((r) => r.email === email)) {
            result.cc.push(recipient)
          }
        })

        currentObject.bcc.forEach((recipient) => {
          const email = recipient.email

          if (!result.bcc.find((r) => r.email === email)) {
            result.bcc.push(recipient)
          }
        })

        return result
      },
      { to: [], cc: [], bcc: [] },
    )

    setAllRecipients(combinedData)
  }

  const handleCompleteReport = async (devices: Device[]) => {
    devices.map(async (device) => {
      const updatedDevice = { ...device }
      updatedDevice.deviceSettings.report30Day = true
      if (updatedDevice.dUUID) {
        await updateDeviceData(updatedDevice?.dUUID, DeviceIDTypes.dUUID, updatedDevice);
      }
    })
  }

  const btoa_utf8 = (value: string) => {
    try {
      const encodedValue = btoa(String.fromCharCode(...new TextEncoder().encode(value)))
      return {
        data: encodedValue,
        mime: 'application/csv',
        file_name: `${selectedDevice.deviceName}_Water_Wastage_report.csv`,
        disposition: 'inline',
        cid: 'image_1',
      }
    } catch (e: any) {
      console.error(e)
      return null
    }
  }

  const sendEmail = async () => {
    if (toRecipients.length === 0) return

    const csvString =
      csvAttachment &&
      csvAttachment
        .map((row: any) => row.map((cell: any) => (cell === null ? '' : cell)).join(','))
        .join('\n')

    const encodedCsvFile = btoa_utf8(csvString)

    const request = {
      template_id: WATER_WASTAGE_REPORT_EMAIL_TEMPLATE,
      recipients: {
        to: toRecipients.map((r) => r.email),
        cc: ccRecipients.map((r) => r.email),
        bcc: bccRecipients.map((r) => r.email),
      },
      dynamic_template_data: mapDynamicProperties(),
      attachments: [
        {
          data: pdfAttachment
            ? pdfAttachment.substring(pdfAttachment.indexOf(';base64,') + ';base64,'.length)
            : null,
          mime: 'application/pdf',
          file_name: `${selectedDevice.deviceName}_Water_Wastage_report.pdf`,
          disposition: 'inline',
          cid: 'image_1',
        },
      ],
    }

    if (encodedCsvFile) {
      request.attachments = [...request.attachments, encodedCsvFile]
    }

    try {
      const btn = document.getElementById('send-email-button')! as HTMLButtonElement
      btn.disabled = true
      await backend.post(`/reports/send_esg_report`, request)

      const deviceTobeMarkedAsCompleted = devicesDueTo30DaysReport.filter((device) =>
        selectedDevice.deviceId.includes(device.deviceId),
      )

      if (deviceTobeMarkedAsCompleted && hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW'])) {
        await handleCompleteReport(deviceTobeMarkedAsCompleted)
      }

      displayToast({
        type: 'success',
        message: 'Email sent successfully',
      })

      onHide()
    } catch (e) {
      console.error(e, 'errorr')
    }
  }

  return (
    <Modal show={true} onHide={onHide} fullscreeen="true" size="xl">
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Send Water Wastage Report by email
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <Row>
            <Col sm={4}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Recipient To:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={toRecipients}
                    onChange={(options: any) => setToRecipients(options)}
                    options={allRecipients.to}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setToRecipients([...toRecipients, { id: -1, email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient CC:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={ccRecipients}
                    onChange={(options: any) => setCcRecipients(options)}
                    options={allRecipients.cc}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setCcRecipients([...ccRecipients, { id: -1, email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Recipient BCC:</Form.Label>
                  <CreatableSelect
                    isMulti
                    value={bccRecipients}
                    onChange={(options: any) => setBccRecipients(options)}
                    options={allRecipients.bcc}
                    getOptionValue={(option: any) =>
                      option.__isNew__ ? option.value : String(option.id)
                    }
                    getOptionLabel={(option: any) =>
                      option.__isNew__ ? option.label : String(option.email)
                    }
                    onCreateOption={(input) => {
                      setBccRecipients([...bccRecipients, { id: -1, email: input }])
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={contactName}
                    onChange={(e) => setContactName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Additional Info Pre</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={additionalInfoPre}
                    onChange={(e) => setAdditionalInfoPre(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Additional Info 1</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={additionalInfo1}
                    onChange={(e) => setAdditionalInfo1(e.target.value)}
                    rows={3}
                  />
                </Form.Group>

                <Button
                  id="send-email-button"
                  style={{ marginRight: '1em', marginBottom: '1em' }}
                  variant="primary"
                  type="button"
                  onClick={() => sendEmail()}
                >
                  Send Email
                </Button>
              </Form>
            </Col>
            <Col sm={8}>
              <div dangerouslySetInnerHTML={{ __html: renderedEmail }}></div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default WaterWastageEmailModal

import React, { useState, useEffect } from 'react'
import { Modal, Form, Button, Col, Row } from 'react-bootstrap'
import Account from '../../../../context/account/model/Account'
import { useCustomerManagerDispatch } from '../../../../context/customerManager/customer-manager.context'

import './Modal.scss'
import Select from 'react-select'

import { COUNTRY_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants'

interface IModalProps {
  show: boolean
  account: Account | undefined | null
  onHide: () => void
  onSave: () => void
}

const EditModal: React.FC<IModalProps> = ({ show, account, onHide, onSave }: IModalProps) => {
  const [newAccountName, setNewAccountName] = useState('')
  const [newAddressLine1, setNewAddressLine1] = useState('')
  const [newAddressLine2, setNewAddressLine2] = useState('')
  const [newTown, setNewTown] = useState('')
  const [newPostcode, setNewPostcode] = useState('')
  const [newCounty, setNewCounty] = useState('')
  const [newCountry, setNewCountry] = useState('')
  const [newPhone, setNewPhone] = useState('')

  const { createAccount, updateAccount } = useCustomerManagerDispatch()
  useEffect(() => {
    if (show) {
      setNewAccountName(account?.name || '')
      setNewAddressLine1(account?.addressLine1 || '')
      setNewAddressLine2(account?.addressLine2 || '')
      setNewTown(account?.town || '')
      setNewPostcode(account?.postcode || '')
      setNewCounty(account?.county || '')
      setNewCountry(account?.country || '')
      setNewPhone(account?.phone || '')
    }
  }, [show, account])

  function handleSave() {
    const updatedAccount: Account = {
      isActive: true,
      id: account?.id || '',
      name: newAccountName,
      addressLine1: newAddressLine1,
      addressLine2: newAddressLine2,
      town: newTown,
      postcode: newPostcode,
      county: newCounty,
      country: newCountry,
      phone: newPhone,
    }
    if (account) updateAccount(account, updatedAccount)
    else createAccount(updatedAccount)
    onSave()
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {account ? `Edit Account ${account.name}` : 'New Account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a name"
                value={newAccountName}
                onChange={(e) => setNewAccountName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridAddressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g.   1234 Main St"
                value={newAddressLine1}
                onChange={(e) => setNewAddressLine1(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridAddressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Apartment, studio, or floor"
                value={newAddressLine2}
                onChange={(e) => setNewAddressLine2(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridTown">
              <Form.Label>Town</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin 9  "
                value={newTown}
                onChange={(e) => setNewTown(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPostCode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. D09 P5X4  "
                value={newPostcode}
                onChange={(e) => setNewPostcode(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridCounty">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin"
                value={newCounty}
                onChange={(e) => setNewCounty(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>
                Country <span className="red">{!newCountry ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                defaultValue={{ value: newCountry, label: newCountry }}
                onChange={(d: any) => setNewCountry(d.value)}
                options={COUNTRY_OPTIONS.map((country) => {
                  return { value: country, label: country }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridCounty">
              <Form.Label>Phone No.</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. 0845 ..."
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={!newAccountName || !newCountry} onClick={() => handleSave()}>
          {account ? 'Update' : 'Activate Account'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditModal

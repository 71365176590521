import React, { useState } from 'react'
import { Button } from 'react-bootstrap';

import ChangePassword from './ChangePassword/ChangePassword';
import UserInfo from './UserInfo/UserInfo';
import Divider from '../../general/Divider/Divider';

import './UserProfile.scss';

const UserProfile: React.FC = () => {
    const [showChangePasswordModal, setShowChangePasswordModal] = useState<boolean>(false);
    const [showUserInfoModal, setShowUserInfoModal] = useState<boolean>(false);

    return (
        <div className="user-profile">
            <h2>User Info </h2>
            <Button
                id="user-info-modal-button"
                onClick={(e) => setShowUserInfoModal(true)}
            >
                Update Personal Information
            </Button>
            <UserInfo show={showUserInfoModal} onHide={() => setShowUserInfoModal(false)} />
            <Divider />

            <h2>Password Management </h2>
            <Button
                id="change-password-modal-button"
                onClick={(e) => setShowChangePasswordModal(true)}
            >
                Change Password
            </Button>
            <ChangePassword show={showChangePasswordModal} onHide={() => setShowChangePasswordModal(false)} />
            <Divider />

        </div>
    );
};

export default UserProfile;
import React, { useState, useEffect } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { useDeviceState } from '../../../../context/device/context/device.context'
import Device from '../../../../context/device/model/device'

import CustomSearchTable from '../../../general/CustomSearchTable/CustomSearchTable'

import './FirmwareUpdateManager.scss'

const FirmwareUpdateManager = () => {
  const { devices } = useDeviceState()
  const [devicesAvailable, setDevicesAvailable] = useState<Device[]>([])
  const [devicesToUpdate, setDevicesToUpdate] = useState<Device[]>([])
  const [desiredFirmware, setDesiredFirmware] = useState<string>('')

  useEffect(() => {
    setDevicesAvailable(devices.filter((device) => device.firmware.includes('SM_V3')))
    setDevicesToUpdate([])
  }, [devices])

  useEffect(() => {
    // Validate
  }, [desiredFirmware])

  const columHeaders = [
    {
      name: 'Name',
      key: 'deviceName',
    },
    {
      name: 'ID',
      key: 'deviceId',
    },
    {
      name: 'Desired',
      key: 'desired_firmware',
    },
  ]

  const rowKeys = columHeaders.map((column) => column.key)

  function onAvailableRowClick(deviceData: Device) {
    const availables = [...devicesAvailable]
    const toUpdate = [...devicesToUpdate]
    const index = availables.findIndex((device) => device.deviceId === deviceData.deviceId)
    if (index >= 0) {
      availables.splice(index, 1)
      toUpdate.push(deviceData)
      setDevicesAvailable(availables)
      setDevicesToUpdate(toUpdate)
    }
  }

  function onToUpdateRowClick(deviceData: Device) {
    const availables = [...devicesAvailable]
    const toUpdate = [...devicesToUpdate]
    const index = toUpdate.findIndex((device) => device.deviceId === deviceData.deviceId)
    if (index >= 0) {
      toUpdate.splice(index, 1)
      availables.push(deviceData)
      setDevicesAvailable(availables)
      setDevicesToUpdate(toUpdate)
    }
  }

  function handleOnSetDesiredFirmwareClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    // Send SET FIRMWARE UPDATE
  }

  return (
    <div className="firmware-udpate-manager">
      <div className="tables-container">
        <div className="device-list">
          <h2> Available </h2>
          <CustomSearchTable
            dataArray={devicesAvailable}
            columns={columHeaders}
            rowKeys={rowKeys}
            onRowClickHandler={(rowData: any) => onAvailableRowClick(rowData)}
          />
        </div>
        <div className="device-list">
          <h2> To be Updated </h2>
          <CustomSearchTable
            dataArray={devicesToUpdate}
            columns={columHeaders}
            rowKeys={rowKeys}
            onRowClickHandler={(rowData: any) => onToUpdateRowClick(rowData)}
          />
        </div>
        <div className="actions">
          <h2> Actions </h2>
          <Form className="device-information-form">
            <Row>
              <Form.Group as={Col} controlId="exampleForm.ControlSelect1">
                <Form.Label>Firmware</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="e.g. SM_V3_016"
                  onChange={(e) => setDesiredFirmware(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Button
              variant="primary"
              type="submit"
              disabled={devicesToUpdate.length === 0 || desiredFirmware.length === 0}
              onClick={handleOnSetDesiredFirmwareClick}
            >
              Set Desired Firmware
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default FirmwareUpdateManager

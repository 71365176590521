import React, { useEffect, useRef, useState } from 'react'
import Select, { components } from 'react-select'
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Form,
  useAccordionButton,
  CardGroup,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import moment, { Moment } from 'moment'
import { CSVLink } from 'react-csv'
import { BsBarChartLine, BsTable } from 'react-icons/bs'

import Device, {DeviceIDTypes} from '../../../../context/device/model/device'
import useWaterUsage from '../../../../context/waterUsage/waterUsage.hook'
import {
  hasPermissions,
  roundToTwo,
  filterDevicesDueTo30DaysReport,
  formatCurrency,
} from '../../../../common/utils/helperFunctions'
import { useAlertDispatch, useAlertState } from '../../../../context/alert/context/alert.context'
import { AlertStat } from '../../../../context/alert/model/alert.model'
import { useUserState } from '../../../../context/user/context/user.context'
import DateRangeComponent from '../Calendar/DateRangeSelector'
import { Bar } from 'react-chartjs-2'
import { CheckCircle2 } from 'lucide-react'
import { getMultipleDevicesDailyDatasets, getChartOptions } from '../../WaterUsage/helper-functions'
import { COLUMNS } from '../../WaterUsage/constants'
import jsPDF from 'jspdf'
import { Chart as ChartJS } from 'chart.js'
import { buildPDFReport } from './helper-functions'
import { IReportTotalProps, IWaterUsageProps, IUsageDay, IUsageGroupedByDay } from './interfaces'
import {
  useDeviceState,
  useDeviceDispatch,
} from '../../../../context/device/context/device.context'
import { useAccountState } from '../../../../context/account/context/account.context'
import ESGEmailModal from './ESGEmailModal'

import 'react-datepicker/dist/react-datepicker.css'
import './ESG.scss'

const TODAY: Moment = moment()
const BACK_30_DAYS = TODAY.clone().subtract(30, 'days').toDate()
const MAX_SELECTABLE_DEVICES = 5
const COLD_CO2: number = 10.6
// const HOT_CO2: number = 33.1;

/*
  0: Not asked
  1: Asked, not ready
  2: Ready
*/
// type GenerateReportState = 0 | 1 | 2;

const ESGReportingComponent: React.FC<IWaterUsageProps> = ({ devices }: IWaterUsageProps) => {
  const { permissions } = useUserState()
  const { selectedDevices } = useDeviceState()
  const { selectedAccounts } = useAccountState()
  const { setSelectedDevices, updateDeviceData } = useDeviceDispatch()
  const { filteredQueue, alertStatsMultipleDevices, historical } = useAlertState()
  const { loadMultipleDevicesStats, loadHistoricalAlerts } = useAlertDispatch()
  const { loading, loadForMultipleDevices, multipleDeviceUsage } = useWaterUsage()

  const [csvData, setCsvData] = useState<any>([])
  const [fileName, setFileName] = useState('esg_report')
  const [dailyDatasets, setDailyDatasets] = useState<any>()
  const [activeAlerts, setActiveAlerts] = useState<number>(0)
  const [generateReportState, setGenerateReportState] = useState(0)
  const [totalUsage, setTotalUsage] = useState<null | number>(null)
  const [fromDate, setFromDate] = useState<Date | null>(TODAY.clone().subtract(30, 'days').toDate())
  const [toDate, setToDate] = useState<Date | null>(TODAY.clone().toDate())
  const [didSomethingChange, setDidSomethingChange] = useState(true)
  const [alertStatsAllDevice, setAlertStatsAllDevice] = useState<number>(0)
  const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>([])
  const [alertStatsPeriodDevice, setAlertStatsPeriodDevice] = useState<number>(0)
  const [selectedDevicesEarliestInstallDate, setSelectedDevicesEarliestInstallDate] = useState<
    string | null
  >(null)
  const [totalUsageByCurrency, setTotalUsageByCurrency] = useState<
    Record<string, { totalUsage: number; totalCost: number; devices: number }>
  >({})

  const [displaySendEmailModal, setDisplaySendEmailModal] = useState(false)
  const [pdfBase64, setPdfBase64] = useState('')

  const alertsShowInProgress = hasPermissions(permissions, ['READ:ALERTS:USAGE:IN_PROGRESS'])

  const tableRef = useRef(null)
  const chartRef = useRef<ChartJS<'bar', number[], string>>(null)

  const devicesDueTo30DaysReport = filterDevicesDueTo30DaysReport(devices)

  useEffect(() => {
    setSelectedDevices([])
  }, [selectedAccounts, devices])

  useEffect(() => {
    loadHistoricalAlerts()
  }, [])

  useEffect(() => {
    if (selectedDevices.length > 0) {
      const multipleDevicesDailyDatasets = getMultipleDevicesDailyDatasets(
        multipleDeviceUsage.usage,
      )
      setDailyDatasets(multipleDevicesDailyDatasets)

      if (multipleDeviceUsage.totalUsage) {
        setTotalUsage(roundToTwo(multipleDeviceUsage.totalUsage / 1000))
      }

      const totalUsageByCurrency = calculateTotalUsageByCurrency(
        selectedDevices,
        multipleDeviceUsage.totalUsageByDevice,
      )

      setTotalUsageByCurrency(totalUsageByCurrency)
    }
  }, [multipleDeviceUsage])

  useEffect(() => {
    if (Object.keys(alertStatsMultipleDevices).length === 0) return

    const periodAlerts: AlertStat[] = alertStatsMultipleDevices.period
    const statsPeriodTotal = periodAlerts.reduce((acc: number, stat: AlertStat) => {
      return acc + stat.alert_count
    }, 0)
    setAlertStatsPeriodDevice(statsPeriodTotal)
  }, [alertStatsMultipleDevices])

  useEffect(() => {
    setDidSomethingChange(true)
    setTotalUsage(null)

    if (selectedDevices.length > 0) {
      const selectedDeviceIds = selectedDevices.map((obj) => obj.deviceId)
      setSelectedDevicesIds(selectedDeviceIds)
      const selectedDevicesDlId = selectedDevices.map((alert) => alert.dlId)
      const selectedDevicesAlerts = filteredQueue.filter((alert) =>
        selectedDevicesDlId.includes(alert.dlId),
      )
      setActiveAlerts(selectedDevicesAlerts.length)

      let earliestDate = Infinity

      for (const item of selectedDevices) {
        const installDate = new Date(item.deviceSettings.installDate).getTime()

        if (installDate < earliestDate) {
          earliestDate = installDate
        }
      }

      setSelectedDevicesEarliestInstallDate(
        earliestDate !== Infinity ? moment(earliestDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      )

      const from = moment(fromDate).format('DD-MM-YYYY')
      const to = moment(toDate).format('DD-MM-YYYY')

      if (selectedDevices.length === 1) {
        setFileName(`${selectedDevices[0].deviceName}-${from}_${to}_esg_report`)
      } else {
        setFileName(`ESG_REPORT_multiple_devices-${from}_${to}`)
      }
    }
  }, [selectedDevices])

  useEffect(() => {
    setDidSomethingChange(true)
  }, [fromDate, toDate])

  const askForData = () => {
    const from = moment(fromDate).format('YYYY-MM-DDT00:00:00')
    const to = moment(toDate).format('YYYY-MM-DDT23:59:59')

    if (selectedDevices.length > 0 && selectedDevices.length <= 5) {
      const queryPeriod = {
        deviceIds: selectedDevicesIds,
        from: from,
        to: to,
      }
      loadForMultipleDevices(queryPeriod)

      const statsQuery = [{ ...queryPeriod, type: 'period' }]

      loadMultipleDevicesStats(statsQuery)
      sumUsageAlertsTotalForDeviceIds()

      setDidSomethingChange(false)
    }
  }

  const calculateTotalUsageByCurrency = (
    devices: Device[],
    totalUsage: Record<string, number>,
  ): Record<string, { totalUsage: number; totalCost: number; devices: number }> => {
    const totalUsageByCurrency: Record<
      string,
      { totalUsage: number; totalCost: number; devices: number }
    > = {}

    devices.forEach((device) => {
      const { deviceSettings, deviceId } = device
      const { currency, cost } = deviceSettings

      if (totalUsageByCurrency[currency]) {
        totalUsageByCurrency[currency].totalUsage += totalUsage[deviceId] || 0
        totalUsageByCurrency[currency].totalCost += cost
        totalUsageByCurrency[currency].devices += 1
      } else {
        totalUsageByCurrency[currency] = {
          totalUsage: totalUsage[deviceId],
          totalCost: cost,
          devices: 1,
        }
      }
    })

    return totalUsageByCurrency
  }

  const sumUsageAlertsTotalForDeviceIds = () => {
    let totalAlertsOfSelectedDevices = 0

    for (const deviceData of historical) {
      if (selectedDevicesIds.includes(deviceData.deviceId)) {
        const tier1Length = deviceData.tier1.length
        const tier2Length = deviceData.tier2.length
        const tier3Length = deviceData.tier3.length

        totalAlertsOfSelectedDevices += tier1Length + tier2Length + tier3Length
      }
    }

    setAlertStatsAllDevice(totalAlertsOfSelectedDevices)
  }

  const groupUsageByDay = (waterUsageArrays: any) => {
    return waterUsageArrays.reduce((acc: IUsageGroupedByDay, waterUsageArray: any[]) => {
      waterUsageArray.forEach((dailyUsage: any) => {
        const date: string = dailyUsage.date.split('T')[0]
        const flow = dailyUsage.flow

        if (!acc[date]) {
          acc[date] = {
            date: date,
            totalFlow: flow,
          } as IUsageDay
        } else {
          acc[date].totalFlow += flow
        }
        // Add the hourly flow for each hour
        acc[date]['totalFlowHour' + dailyUsage.hour] = flow
      })

      return acc
    }, {})
  }

  const getRows = (waterUsage: any) => {
    const groupedByDay = groupUsageByDay(waterUsage)

    return Object.values(groupedByDay).map((usage: any, idx: number) => {
      return (
        <tr key={idx}>
          <td style={{ position: 'sticky', left: 0 }}> {usage.date} </td>
          <td> {roundToTwo(usage.totalFlow)}</td>
          <td> {usage.totalFlowHour0}</td>
          <td> {usage.totalFlowHour1}</td>
          <td> {usage.totalFlowHour2}</td>
          <td> {usage.totalFlowHour3}</td>
          <td> {usage.totalFlowHour4}</td>
          <td> {usage.totalFlowHour5}</td>
          <td> {usage.totalFlowHour6}</td>
          <td> {usage.totalFlowHour7}</td>
          <td> {usage.totalFlowHour8}</td>
          <td> {usage.totalFlowHour9}</td>
          <td> {usage.totalFlowHour10}</td>
          <td> {usage.totalFlowHour11}</td>
          <td> {usage.totalFlowHour12}</td>
          <td> {usage.totalFlowHour13}</td>
          <td> {usage.totalFlowHour14}</td>
          <td> {usage.totalFlowHour15}</td>
          <td> {usage.totalFlowHour16}</td>
          <td> {usage.totalFlowHour17}</td>
          <td> {usage.totalFlowHour18}</td>
          <td> {usage.totalFlowHour19}</td>
          <td> {usage.totalFlowHour20}</td>
          <td> {usage.totalFlowHour21}</td>
          <td> {usage.totalFlowHour22}</td>
          <td> {usage.totalFlowHour23}</td>
        </tr>
      )
    })
  }

  const getHeaders = () => {
    const periodColumns: Array<any> = [
      {
        name: 'Date',
        key: 'date',
        width: '120px',
      },
      {
        name: 'Usage',
        key: 'usage',
        width: '120px',
      },
    ]

    const headersObj = [...periodColumns, ...COLUMNS]

    return headersObj.map((header: any) => (
      <th style={{ width: header.width ?? '80px' }} key={header.key}>
        {header.name}
      </th>
    ))
  }

  const generateCsvFile = () => {
    if (!selectedDevices.length) return
    const groupedByDayArray = Object.values(groupUsageByDay(multipleDeviceUsage.usage)).map(
      (usage: any, idx: number) => {
        return Object.values(usage)
      },
    )

    const selectedDevicesData = []

    for (const device of selectedDevices) {
      const deviceData = [
        ['Device Name', device.deviceName],
        ['Device ID', device.deviceId],
        // device.deviceInfo.addressLine1 ? ['Address Line 1', device.deviceInfo.addressLine2] : [],
        // device.deviceInfo.addressLine2 ? ['Address Line 2', device.deviceInfo.addressLine2] : null,
        // device.deviceInfo.town ? ['Town', device.deviceInfo.town] : null,
        // device.deviceInfo.county ? ['County', device.deviceInfo.county] : null,
        // device.deviceInfo.postcode ? ['Postcode', device.deviceInfo.postcode] : null,
        // device.deviceInfo.country ? ['Country', device.deviceInfo.country] : null,
        [''],
      ]

      selectedDevicesData.push(deviceData)
    }

    const totalCostData = Object.entries(totalUsageByCurrency).map(([currency, data]) => {
      return [
        `Total Cost in ${currency} ${
          selectedDevices.length > 1 ? `(${data.devices} devices)` : ''
        }`,
        formatCurrency(roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost), currency),
      ]
    })

    const data = [
      [
        'Date Range',
        `${moment(fromDate).format('DD/MM/YYYY')} - ${moment(toDate).format('DD/MM/YYYY')}`,
      ],
      [''],
      ...selectedDevicesData.flat(1),
      ['Total Water Consumed', totalUsage ? totalUsage : null, 'unit', 'm3'],
      ['Total Co2 Produced', totalUsage ? roundToTwo(totalUsage * COLD_CO2) : null, 'unit', 'kg'],
      ...totalCostData,
      [''],
      ['Usage breakdown'],
      ['Date', 'Usage', ...Object.values(COLUMNS).map((c) => c.name)],
      ...groupedByDayArray,
    ].filter((v) => v !== null)
    setCsvData(data)
  }

  const downloadPdfDocument = (sendReportByEmail = false) => {
    if (selectedDevices.length) {
      const tableHTML = tableRef.current
      if (!chartRef) return
      if (!chartRef.current) return
      if (!tableHTML) return
      const chartImage = chartRef.current.toBase64Image()

      const totalCostByCurrency = Object.entries(totalUsageByCurrency).map(([currency, data]) => {
        return `${formatCurrency(
          roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost),
          currency,
        )} ${selectedDevices.length > 1 ? `(${data.devices} devices)` : ''}`
      })

      const reportTotals: IReportTotalProps = {
        usageTotal: totalUsage ? totalUsage : null,
        co2Total: totalUsage ? roundToTwo(totalUsage * COLD_CO2) : null,
        activeAlerts: activeAlerts,
        periodAlerts: alertStatsPeriodDevice,
        totalAlerts: alertStatsAllDevice,
        totalCostByCurrency,
      }
      buildPDFReport(selectedDevices, fromDate, toDate, chartImage, tableHTML, reportTotals).then(
        (pdf: jsPDF) => {
          if (!sendReportByEmail) {
            setGenerateReportState(2)
            pdf.save(fileName)
          } else {
            generateCsvFile()
            setPdfBase64(pdf.output('datauristring'))
            setDisplaySendEmailModal(true)
          }
        },
      )
    }
  }

  const ValueContainer = (containerProps: any) => {
    if (loading) {
      return <> Loading...</>
    }

    if (selectedAccounts.length === 0) {
      return <span className="ml-3">Please select an account first...</span>
    }

    return (
      <components.ValueContainer {...containerProps} className={'d-flex'}>
        Devices ({selectedDevices.length} / {devices.length}){containerProps.children}
      </components.ValueContainer>
    )
  }

  const Option = (containerProps: any) => {
    return (
      <components.Option {...containerProps}>
        <Form.Check checked={containerProps.isSelected} readOnly label={containerProps.children} />
      </components.Option>
    )
  }

  const optionStyles = (styles: any, { isFocused, isDisabled }: any) => {
    return {
      ...styles,
      backgroundColor: isFocused ? '#eee' : null,
      color: isDisabled ? '#dedede' : '#333',
      cursor: 'pointer',
    }
  }

  const MenuList = (props: any) => {
    return (
      <components.MenuList {...props}>
        {devices.length > 5 && (
          <div className="ml-3 mt-2 mb-2">
            **{' '}
            <span className="font-weight-bold text-decoration-underline">
              You can select up to 5 devices
            </span>{' '}
            **
          </div>
        )}

        {props.children}
      </components.MenuList>
    )
  }
  const CustomToggle = ({ children, eventKey, callback }: any) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey))

    return <Button onClick={decoratedOnClick}>{children}</Button>
  }

  const handleCompleteReport = async (device: Device) => {
    const updatedDevice = { ...device }
    updatedDevice.deviceSettings.report30Day = true
    if (device.dUUID) {
      await updateDeviceData(device?.dUUID, DeviceIDTypes.dUUID, updatedDevice);
    }
  }

  return (
    <div className="esg-report">
      <Row className="main-content">
        <Col xs={6}>
          <h1>ESG Summary</h1>
          <Row>
            <div className="section-header">
              <div className="">Device:</div>
            </div>
            <Select
              styles={{ option: optionStyles }}
              components={{ ValueContainer, MenuList, Option }}
              className="basic-multi-select"
              value={selectedDevices}
              onChange={(options: any) => setSelectedDevices([...options])}
              options={devices}
              isMulti
              isOptionDisabled={() => selectedDevices.length === MAX_SELECTABLE_DEVICES}
              name="devices"
              classNamePrefix="select"
              getOptionValue={(option) => option.deviceId}
              getOptionLabel={(option) => `${option.deviceName}`}
              isClearable={false}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              isLoading={loading || devices.length === 0}
              isDisabled={loading || devices.length === 0}
            />
          </Row>
          <Row>
            <DateRangeComponent
              from={BACK_30_DAYS}
              to={TODAY.clone().toDate()}
              fromCallback={setFromDate}
              toCallback={setToDate}
              minDate={
                selectedDevicesEarliestInstallDate
                  ? moment(selectedDevicesEarliestInstallDate).toDate()
                  : null
              }
            />
          </Row>
          <div className="get-data-button">
            <Button
              variant="primary"
              onClick={askForData}
              disabled={selectedDevices.length === 0 || !didSomethingChange}
            >
              {loading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                  <span>Getting Data...</span>
                </>
              ) : (
                <>Get Data</>
              )}
            </Button>
          </div>
        </Col>
        {hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']) &&
        devicesDueTo30DaysReport.length > 0 ? (
          <Col xs={6} className="devicesDue d-flex flex-column align-items-center">
            <div className="h4 mt-3">
              <strong>
                {`${devicesDueTo30DaysReport.length} devices are due to a 30 day report`}
              </strong>
            </div>
            <ul>
              {devicesDueTo30DaysReport.map((device) => (
                <div className="d-flex align-items-top-center" key={device.deviceId}>
                  <li>{`${device.deviceName} (${moment(
                    device.deviceSettings.monitoringStartDate,
                  ).format('DD-MM-YYYY')} monitoring start date)`}</li>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props: any) => <Tooltip {...props}>Mark as completed</Tooltip>}
                    placement="bottom"
                  >
                    <CheckCircle2
                      role="button"
                      size={20}
                      color={'green'}
                      className="ml-2 mt-1"
                      onClick={() => handleCompleteReport(device)}
                    />
                  </OverlayTrigger>
                </div>
              ))}
            </ul>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <div className="data-container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          totalUsage &&
          !loading &&
          !didSomethingChange && (
            <Col className="data">
              <Row>
                <Col lg={10}>
                  <Row>
                    <CardGroup className="gap-3">
                      {selectedDevices.length > 0 &&
                        selectedDevices.map((device) => {
                          return (
                            <Card className="shadow" key={device.deviceId}>
                              <Card.Body>
                                {device?.deviceName ? <h4>{device?.deviceName}</h4> : null}
                                {/*{device?.deviceInfo.addressLine1 ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.addressLine1}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                                {/*{device?.deviceInfo.addressLine2 ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.addressLine2}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                                {/*{device?.deviceInfo.town ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.town}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                                {/*{device?.deviceInfo.county ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.county}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                                {/*{device?.deviceInfo.postcode ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.postcode}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                                {/*{device?.deviceInfo.country ? (*/}
                                {/*  <span>*/}
                                {/*    {device?.deviceInfo.country}*/}
                                {/*    <br />*/}
                                {/*  </span>*/}
                                {/*) : null}*/}
                              </Card.Body>
                            </Card>
                          )
                        })}
                    </CardGroup>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <Row>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-dollar-sign mr-3"></i>Total Cost
                          </h4>
                          <div>
                            {Object.entries(totalUsageByCurrency).map(([currency, data]) => {
                              return (
                                <b className="ml-5 d-flex" key={currency}>
                                  {formatCurrency(
                                    roundToTwo(roundToTwo(data.totalUsage / 1000) * data.totalCost),
                                    currency,
                                  )}
                                  {`${
                                    selectedDevices.length > 1 ? `(${data.devices} devices)` : ''
                                  }`}
                                </b>
                              )
                            })}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className={'fas fa-fw fa-water mr-3'}></i>Total Water Consumed
                          </h4>
                          <b>
                            <span className="ml-5">
                              {roundToTwo(totalUsage)} m{String.fromCharCode(179)}
                            </span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-cloud mr-3"></i>Total CO<sub>2</sub> Produced
                          </h4>
                          <b>
                            <span className="ml-5">{roundToTwo(totalUsage * COLD_CO2)} Kg</span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Active Usage
                            Alerts
                          </h4>
                          <b>
                            <span className="ml-5">{activeAlerts}</span>
                          </b>
                          {alertsShowInProgress && activeAlerts > 0 ? (
                            <NavLink to="/alert-queue">view</NavLink>
                          ) : null}
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Usage Alerts
                          </h4>
                          <b>
                            <span className="ml-5">{alertStatsPeriodDevice}</span>
                          </b>{' '}
                          ({moment(fromDate).format('DD/MM/YYYY')} to{' '}
                          {moment(toDate).format('DD/MM/YYYY')})
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4}>
                      <Card className="shadow">
                        <Card.Body>
                          <h4>
                            <i className="fas fa-fw fa-exclamation-triangle mr-3"></i>Usage Alerts
                            Total
                          </h4>
                          <b>
                            <span className="ml-5">{alertStatsAllDevice}</span>
                          </b>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={'my-butts'}>
                    <CSVLink
                      data={csvData}
                      onClick={generateCsvFile}
                      className="btn btn-primary mr-lg-2"
                      filename={`${fileName}.csv`}
                    >
                      Generate CSV Report
                    </CSVLink>
                    <Button onClick={() => downloadPdfDocument()}>
                      <div>
                        {generateReportState === 1 ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span>Generating...</span>
                          </>
                        ) : (
                          <>Generate PDF Report</>
                        )}
                      </div>
                    </Button>
                    {selectedDevices.length < 2 && (
                      <Button className="ml-3" onClick={() => downloadPdfDocument(true)}>
                        Send Reports by email
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Card.Header>
                        <CustomToggle eventKey="0">
                          <span className="fas fa-hand-holding-water fa-lg mr-2"></span> Show/Hide
                          Usage
                        </CustomToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="data-visualizations">
                            <Tabs
                              transition={false}
                              id="controlled-tab-example"
                              // activeKey={selectedPeriod}
                              // onSelect={(k: any) => setSelectedPeriod(k)}
                            >
                              <Tab eventKey={'usage'} title={<BsBarChartLine />}>
                                <article
                                  id="canvas-container-id-usage"
                                  className="canvas-container"
                                >
                                  <Bar
                                    width={2500}
                                    height={600}
                                    data={dailyDatasets}
                                    ref={chartRef}
                                    options={getChartOptions('daily')}
                                  />
                                </article>
                              </Tab>
                              <Tab eventKey={'table'} title={<BsTable />}>
                                <article
                                  id="canvas-container-id-table"
                                  className="canvas-container"
                                >
                                  <div className="table-container">
                                    <Table id="usage-table" ref={tableRef} responsive>
                                      <thead>
                                        <tr>{getHeaders()}</tr>
                                      </thead>
                                      <tbody>{getRows(multipleDeviceUsage.usage)}</tbody>
                                    </Table>
                                  </div>
                                </article>
                              </Tab>
                            </Tabs>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </Col>
              </Row>
            </Col>
          )
        )}
      </div>

      {displaySendEmailModal && (
        <ESGEmailModal
          selectedDevice={selectedDevices[0]}
          onHide={() => setDisplaySendEmailModal(false)}
          pdfAttachment={pdfBase64}
          csvAttachment={csvData}
          devicesDueTo30DaysReport={devicesDueTo30DaysReport}
        />
      )}
    </div>
  )
}

export default ESGReportingComponent

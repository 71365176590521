import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import Account from '../../../../context/account/model/Account'
import CustomerLocation from '../../../../context/account/model/CustomerLocation'
import { useCustomerManagerDispatch } from '../../../../context/customerManager/customer-manager.context'

import './Modal.scss'
import { COUNTRY_OPTIONS } from '../../DeviceManagement/DeviceInformationManager/constants';

interface IModalProps {
  show: boolean
  account: Account
  data: CustomerLocation
  onHide: () => void
}

const LocationEditModal: React.FC<IModalProps> = ({ show, account, data, onHide }: IModalProps) => {
  const [newLocationName, setNewLocationName] = useState('')
  const [newAddressLine1, setNewAddressLine1] = useState('')
  const [newAddressLine2, setNewAddressLine2] = useState('')
  const [newTown, setNewTown] = useState('')
  const [newPostcode, setNewPostcode] = useState('')
  const [newCounty, setNewCounty] = useState('')
  const [newCountry, setNewCountry] = useState('')
  const [newCompanyAlias, setNewCompanyAlias] = useState('')

  const { addLocationToAccount } = useCustomerManagerDispatch()
  let required = !newCountry || newLocationName.length === 0
  useEffect(() => {
    if (show) {
      setNewLocationName(data?.name || '')
      setNewAddressLine1(data?.addressLine1 || '')
      setNewAddressLine2(data?.addressLine2 || '')
      setNewTown(data?.town || '')
      setNewPostcode(data?.postcode || '')
      setNewCounty(data?.county || '')
      setNewCountry(data?.country)
      setNewCompanyAlias(data?.companyAlias || '')
    }
  }, [show])

  function handleSave() {
    const updated = {
      id: data.id,
      addressLine1: newAddressLine1,
      addressLine2: newAddressLine2,
      town: newTown,
      postcode: newPostcode,
      county: newCounty,
      country: newCountry,
      name: newLocationName,
      companyAlias: newCompanyAlias,
    }

    addLocationToAccount(updated, account)
    onHide()
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="device-information-modal"
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {data && (Object.keys(data).length > 0 ? 'Edit Location' : 'Add New Location to Account')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {required ? <span className="red">* Required!!</span> : ''}
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>
                Name <span className="red">{newLocationName.length === 0 ? '*' : ''}</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a name"
                value={newLocationName}
                onChange={(e) => setNewLocationName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridCOmpanyAlias">
              <Form.Label>Sub Entity</Form.Label>
              <Form.Control
                type="text"
                placeholder="ABC Management"
                value={newCompanyAlias}
                onChange={(e) => setNewCompanyAlias(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridAddressLine1">
              <Form.Label>Address Line 1</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g.   1234 Main St"
                value={newAddressLine1}
                onChange={(e) => setNewAddressLine1(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridAddressLine2">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Apartment, studio, or floor"
                value={newAddressLine2}
                onChange={(e) => setNewAddressLine2(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridTown">
              <Form.Label>Town</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin 9  "
                value={newTown}
                onChange={(e) => setNewTown(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPostCode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. D09 P5X4  "
                value={newPostcode}
                onChange={(e) => setNewPostcode(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="formGridCounty">
              <Form.Label>County</Form.Label>
              <Form.Control
                type="text"
                placeholder="e.g. Dublin"
                value={newCounty}
                onChange={(e) => setNewCounty(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridCountry">
              <Form.Label>
                Country <span className="red">{!newCountry ? '*' : ''}</span>
              </Form.Label>
              <Select
                classNamePrefix="select"
                defaultValue={{ value: data ? data.country : '', label: data ? data.country : '' }}
                onChange={(d: any) => setNewCountry(d.value)}
                options={COUNTRY_OPTIONS.map((country) => {
                  return { value: country, label: country }
                })}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => `${option.label}`}
                isClearable={false}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button disabled={required} onClick={() => handleSave()}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LocationEditModal

import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap'
import { Info } from 'lucide-react'
import Select from 'react-select'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import CustomerLocation from '../../../../context/account/model/CustomerLocation'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '../../../../context/customerManager/customer-manager.context'
import { Group } from '../../../../context/user/model/user'

import { InviteLocation } from '../../../../context/customerManager/model/customer-manager'
import { ValidateEmail } from '../../../../common/utils/helperFunctions'

import {
  popoverAccountAdmin,
  popoverAutoAddAlerts,
  popoverAutoAddLocations,
  popoverLocationAdmin,
  popoverLocationAlerts,
} from './Popovers'

import { IModalProps } from './UserEditModal'
import { hasPermissions } from '../../../../common/utils/helperFunctions'
import { useUserState } from '../../../../context/user/context/user.context'

import './Modal.scss'

export const InviteModal: React.FC<IModalProps> = ({ show, account, onHide }: IModalProps) => {
  const { groups, locationsByAccount } = useCustomerManagerState()
  const { inviteUser } = useCustomerManagerDispatch()
  const { permissions } = useUserState()

  const [email, setEmail] = useState('')
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([])
  const [autoAddLocations, setAutoAddLocations] = useState(false)
  const [isAccountAdmin, setIsAccountAdmin] = useState(false)
  const [welcomeEmail, setWelcomeEmail] = useState(true)
  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [selectedLocations, setSelectedLocations] = useState<InviteLocation[]>([])
  const [selectAllLocations, setSelectAllLocations] = useState<boolean>(false)
  const [autoAddAlerts, setAutoAddAlerts] = useState(false)
  const [accountInvoice, setAccountInvoice] = useState(false)
  // const [selectAllAdmin, setSelectAllAdmin] = useState<boolean>(false);
  // const [selectAllAlerts, setSelectAllAlerts] = useState<boolean>(false);
  const required = ValidateEmail(email) && selectedLocations.length > 0

  useEffect(() => {
    if (locationsByAccount[account!.id]) {
      setAccountLocations(locationsByAccount[account!.id])
    }
  }, [locationsByAccount])

  const handleSave = () => {
    const updatedUser: any = {
      active: true,
      email,
      // if no group is selected assign the Standard user permission
      groups: selectedGroups.length > 0 ? selectedGroups.map((g) => g.id) : [1],
    }

    if (account) {
      inviteUser(
        updatedUser,
        account,
        selectedLocations,
        welcomeEmail,
        autoAddLocations,
        isAccountAdmin,
        autoAddAlerts,
        accountInvoice,
      )
    }
    onHide()
  }

  const handleLocations = (location: string, admin: boolean, alerts: boolean) => {
    if (location === 'allLoc') {
      if (!selectAllLocations) {
        const locations: InviteLocation[] = accountLocations.map((l: CustomerLocation) => {
          return { locationId: l.id, admin: false, alerts: false } as InviteLocation
        })
        setSelectedLocations(locations)
      } else {
        setSelectedLocations([])
      }
      setSelectAllLocations(!selectAllLocations)
      return
    } else {
      setSelectAllLocations(false)
    }

    const locationIndex = selectedLocations.findIndex((x) => x.locationId === location)
    if (locationIndex !== -1) {
      const locations = [...selectedLocations]
      if (admin) {
        locations[locationIndex] = {
          ...locations[locationIndex],
          admin: !locations[locationIndex].admin,
        }
      } else if (alerts) {
        locations[locationIndex] = {
          ...locations[locationIndex],
          alerts: !locations[locationIndex].alerts,
        }
      } else {
        locations.splice(locationIndex, 1)
      }
      setSelectedLocations(locations)
    } else {
      setSelectedLocations([
        ...selectedLocations,
        { locationId: location, admin: false, alerts: false },
      ])
    }
  }

  const handleAccountAdmin = (val: boolean) => {
    setIsAccountAdmin(val)
    if (!val) {
      setSelectedLocations([])
      setAutoAddLocations(false)
    } else {
      const locations: InviteLocation[] = accountLocations.map((l: CustomerLocation) => {
        return { locationId: l.id, admin: true, alerts: false } as InviteLocation
      })
      setSelectedLocations(locations)
      setAutoAddLocations(true)
    }
  }

  // noinspection RequiredAttributes
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="modal-header" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Invite New User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="device-information-form">
          <Row>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>
                Email{' '}
                <span className="text-danger">
                  {ValidateEmail(email) ? null : '*Enter a valid email'}
                </span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a Email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
              />
            </Form.Group>
          </Row>
          <Row className={'mt-3'}>
            <Form.Group as={Col} controlId="accountOptions">
              <Form.Label>Account Options</Form.Label>
              <div>
                <Form.Check
                  type="checkbox"
                  inline
                  // onChange={(e) => setIsAccountAdmin(!isAccountAdmin)}
                  onChange={() => handleAccountAdmin(!isAccountAdmin)}
                  checked={isAccountAdmin}
                  label={
                    <>
                      Account Admin
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAccountAdmin}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-1`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAccountInvoice(!accountInvoice)}
                  checked={accountInvoice}
                  label={
                    <>
                      Account Invoices
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddAlerts}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-2`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddLocations(!autoAddLocations)}
                  checked={autoAddLocations || isAccountAdmin}
                  disabled={isAccountAdmin}
                  label={
                    <>
                      Auto Add New Locations
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddLocations}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-3`}
                />
                <Form.Check
                  type="checkbox"
                  inline
                  onChange={() => setAutoAddAlerts(!autoAddAlerts)}
                  checked={autoAddAlerts}
                  label={
                    <>
                      Auto Add Alerts
                      <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        trigger={['hover', 'focus']}
                        overlay={popoverAutoAddAlerts}
                        // containerPadding={20}
                        placement="bottom"
                      >
                        <Info size={15} className={'ml-1 text-info'} />
                      </OverlayTrigger>
                    </>
                  }
                  name="accountOptions"
                  id={`inline-account-options-4`}
                />
              </div>
            </Form.Group>
          </Row>
          {hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']) && (
            <Row className={'mt-3'}>
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Groups</Form.Label>
                <Select
                  className="basic-multi-select"
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
                  menuPosition={'fixed'}
                  value={selectedGroups}
                  onChange={(options: any) => setSelectedGroups([...options])}
                  options={groups}
                  isMulti
                  getOptionValue={(option) => option.name}
                  getOptionLabel={(option) => option.description}
                  isClearable={false}
                />
              </Form.Group>
            </Row>
          )}

          {account && (
            <Row className={'mt-3'}>
              <Form.Group as={Col} controlId="formGridLocations">
                <Form.Label>Locations</Form.Label>
                <div style={{ width: '100%', height: '300px', overflowY: 'auto' }}>
                  <Table striped>
                    <thead
                      style={{
                        width: '15%',
                        position: 'sticky',
                        top: -1,
                        zIndex: 1,
                      }}
                    >
                      <tr>
                        <th>Location Name</th>
                        <th className={'text-center'}>
                          Admin
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            trigger={['hover', 'focus']}
                            overlay={popoverLocationAdmin}
                            // containerPadding={20}
                            placement="bottom"
                          >
                            <Info size={15} className={'ml-1 text-info'} />
                          </OverlayTrigger>
                        </th>
                        <th className={'text-center'}>
                          Alerts
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            trigger={['hover', 'focus']}
                            overlay={popoverLocationAlerts}
                            // containerPadding={20}
                            placement="bottom"
                          >
                            <Info size={15} className={'ml-1 text-info'} />
                          </OverlayTrigger>
                        </th>
                        {/* <th>&nbsp;</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/*<tr key={"all"}>*/}
                      {/*  <td>*/}
                      {/*    <Form.Check*/}
                      {/*      type={'checkbox'}*/}
                      {/*      label={"All"}*/}
                      {/*      value={"allLoc"}*/}
                      {/*      id={`default-allLoc`}*/}
                      {/*      onChange={(e) => handleLocations(e.target.value, false, false)}*/}
                      {/*      className={'ml-2'}*/}
                      {/*      checked={selectAllLocations || selectedLocations.length === accountLocations.length}*/}
                      {/*      disabled={isAccountAdmin}*/}
                      {/*    />*/}
                      {/*  </td>*/}
                      {/*  <td>*/}
                      {/*    <Form.Check*/}
                      {/*      type={'checkbox'}*/}
                      {/*      label={"All"}*/}
                      {/*      value={"allAdmin"}*/}
                      {/*      id={`default-allLoc`}*/}
                      {/*      onChange={(e) => handleLocations(e.target.value, false, false)}*/}
                      {/*      className={'ml-2'}*/}
                      {/*      checked={selectAllAdmin}*/}
                      {/*      disabled={isAccountAdmin}*/}
                      {/*    />*/}
                      {/*  </td>*/}
                      {/*  <td>*/}
                      {/*    <Form.Check*/}
                      {/*      type={'checkbox'}*/}
                      {/*      label={"All"}*/}
                      {/*      value={"allAlerts"}*/}
                      {/*      id={`default-allLoc`}*/}
                      {/*      onChange={(e) => handleLocations(e.target.value, false, false)}*/}
                      {/*      className={'ml-2'}*/}
                      {/*      checked={selectAllAlerts}*/}
                      {/*      disabled={isAccountAdmin}*/}
                      {/*    />*/}
                      {/*  </td>*/}
                      {/*</tr>*/}
                      {accountLocations.map((l) => (
                        <tr key={l.id}>
                          <td>
                            <Form.Check
                              type={'checkbox'}
                              label={l.name}
                              value={l.id}
                              id={`default-${l.id}`}
                              onChange={(e) => handleLocations(e.target.value, false, false)}
                              className={'ml-2'}
                              checked={
                                isAccountAdmin ||
                                !!selectedLocations.find((loc) => loc.locationId === l.id)
                              }
                              disabled={isAccountAdmin}
                            />
                          </td>
                          <td className={'text-center'}>
                            <Form.Check
                              type="checkbox"
                              label={''}
                              value={l.id}
                              disabled={
                                !selectedLocations.find((loc) => loc.locationId === l.id) ||
                                isAccountAdmin
                              }
                              onChange={(e) => handleLocations(e.target.value, true, false)}
                              checked={
                                !!selectedLocations.find(
                                  (loc) => loc.admin && loc.locationId === l.id,
                                )
                              }
                            />
                          </td>
                          <td className={'text-center'}>
                            <Form.Check
                              type="checkbox"
                              label={''}
                              value={l.id}
                              disabled={!selectedLocations.find((loc) => loc.locationId === l.id)}
                              onChange={(e) => handleLocations(e.target.value, false, true)}
                              checked={
                                !!selectedLocations.find(
                                  (loc) => loc.alerts && loc.locationId === l.id,
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Form.Group>
            </Row>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={handleSave} disabled={!required}>
          Invite User
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

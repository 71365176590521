// import { formatFirmwareName } from '../../../common/utils/helperFunctions'
import Account from '../../account/model/Account'
import Device, { TestDevice, Valve, ValveKey, ValvesState, ValveState, DeviceSettings, DeviceIDTypes, DeviceSettingsAPI, DeviceAPI } from '../model/device'
import moment from 'moment-timezone'
import { hasPermissions } from "../../../common/utils/helperFunctions";
import { Permission } from "../../user/model/user";
import { displayToast } from "../../../common/utils/appToast";

export enum ActionTypes {
  INIT,
  LOADING,
  ERROR,
  UPDATE_DEVICE,
  SEND_MESSAGE,
  DEVICE_UPDATING,
  FILTER_ACCOUNT_DEVICES,
  VALVES_UPDATED,
  INIT_STAGED_DEVICES,
  INIT_TEST_DEVICES,
  PROMOTED_TO_STAGED,
  LOADING_DEVICES,
  SELECT_DEVICES,
  LOAD_SINGLE_DEVICE
}

interface State {
  devices: Device[]
  selectedDevices: Device[]
  allDevices: Device[]
  stagedDevices: TestDevice[]
  testDevices: TestDevice[]
  loadingDevices: boolean
  loading: boolean
  error: boolean
  deviceUpdating: boolean
}

export interface Action {
  type: ActionTypes
  payload?: any
  device?: any
}

export const initialState: State = {
  devices: [],
  selectedDevices: [],
  allDevices: [],
  testDevices: [],
  stagedDevices: [],
  loading: false,
  loadingDevices: false,
  error: false,
  deviceUpdating: false,
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.INIT:
      // const devicesStatus = action.payload?.devicesStatus
      const permissions = action.payload?.permissions;
      const allDevices = action.payload?.devices.map((device: any) => {
        // const accountId = String(device.account_id)
        return {
          ...mapDevice(device, permissions)
        } as Device
      })
      return {
        ...state,
        devices: allDevices,
        allDevices,
        selectedDevices: [...allDevices],
        loading: false,
        loadingDevices: false,
        error: false,
        deviceUpdating: false,
      }
    case ActionTypes.SELECT_DEVICES:
      return { ...state, selectedDevices: action.payload, loading: false, error: false }
    case ActionTypes.INIT_STAGED_DEVICES:
      const stagedDevices: TestDevice[] = action.payload.map((device: any) => {
        return {
          deviceId: device.device_id,
          deviceName: device.device_name || device.device_id.toString(),
          firmware: device.firmware_version,
        }
      })
      return { ...state, stagedDevices, loading: false, error: false, deviceUpdating: false }
    case ActionTypes.INIT_TEST_DEVICES:
      const testDevicesStatus = action.payload?.testDevicesStatus
      const allTestDevices = action.payload?.testDevices.map((device: any) => {
        // const accountId = '';
        const status = testDevicesStatus.find(
          (status: any) => status.device_id === device.device_id,
        )
        return {
          deviceId: device.device_id,
          deviceName: device?.device_settings?.device_name || device.device_id.toString(),
          valves:
            status?.valve_state &&
            Object.entries(status.valve_state).reduce(
              (acc, v: any) => {
                const key = v[0] as ValveKey
                const state = v[1] as ValveState
                const name = status?.tags?.Valves?.[key]
                acc[key] = { key, state, name }
                return acc
              },
              {} as { [key in ValveKey]: Valve },
            ),
          batteryLevel: status?.power?.HUB_BAT ?? 0,
          batteryStatus: status?.power?.HUB_STA ?? '',
          signal: status?.power?.CSQ ?? 0,
          ICCID: status?.network_info?.ICCID ?? '',
          connectionStatus: status?.connection_state ?? 'Error',
          firmware: status?.device_sw?.HUB_SW ?? '',
          deviceSettings: {
            // ...mapDeviceSettings(device.device_settings)
            dataAcqMode: device.device_settings?.data_acq_mode,
            serverPulseControl: device.device_settings?.server_pulse_control,
            pulsesPerLiter: device.device_settings?.pulses_per_liter,
            firmwareVersionDesired: device.device_settings?.firmware_version_desired,
            timezone: device.device_settings?.timezone,
            installDate: device.device_settings?.install_date,
            currency: device.device_settings?.currency,
            cost: device.device_settings?.cost,
          },
          lastOnline: new Date(status?.last_ping),
        } as TestDevice
      })
      return { ...state, testDevices: allTestDevices, loading: false, error: false }
    case ActionTypes.PROMOTED_TO_STAGED:
      const testDevice = state.testDevices.find((d) => d.deviceId === action.payload.deviceId)
      if (!testDevice) return state

      return {
        ...state,
        stagedDevices: [...state.stagedDevices, testDevice],
        testDevices: state.testDevices.filter((d) => d.deviceId !== action.payload.deviceId),
        loading: false,
        error: false,
        deviceUpdating: false,
      }
    case ActionTypes.FILTER_ACCOUNT_DEVICES:
      const selectedAccounts: Account[] = action.payload
      const devices = state.allDevices.filter(
        (d) => selectedAccounts.findIndex((a) => a.id === d.accountId) > -1,
      )
      return { ...state, devices }
    case ActionTypes.DEVICE_UPDATING:
      return { ...state, deviceUpdating: true }
    case ActionTypes.VALVES_UPDATED:
      const toBeUpdated = state.devices.find((d) => d.deviceId === action.payload.deviceId)
      const newValvesState: ValvesState = action.payload.newValvesState
      if (!toBeUpdated || !newValvesState) return state
      const valves = { ...toBeUpdated.valves }
      Object.values(toBeUpdated.valves).forEach((v) => {
        if (newValvesState[v.key] !== null && newValvesState[v.key] !== undefined)
          valves[v.key].state = newValvesState[v.key]
      })
      const updatedDevice = { ...toBeUpdated, valves }
      return {
        ...state,
        allDevices: state.allDevices.map((d) =>
          d.deviceId === updatedDevice.deviceId ? updatedDevice : d,
        ),
        devices: state.devices.map((d) =>
          d.deviceId === updatedDevice.deviceId ? updatedDevice : d,
        ),
        loading: false,
        error: false,
        deviceUpdating: false,
      }
    case ActionTypes.UPDATE_DEVICE: {
      let idTypeKey: keyof Device = "deviceId"
      const { deviceId, idType, permissions, updatedDevice } = action.payload;
      if (idType) {
        idTypeKey = enumDeviceIdTypeByValue(idType);
      }

      const deviceToBeUpdated = state.devices.find((d) => d[idTypeKey] === deviceId);
      if (!deviceToBeUpdated) return {...state, loading: false, error: false, deviceUpdating: false}
      if (!updatedDevice) return {...state, loading: false, error: false, deviceUpdating: false}
      const device: Device = { ...mapDevice(updatedDevice, permissions) }
      const parentDeviceToBeUpdated = state.devices.find(
        (device) => deviceToBeUpdated.deviceSettings.masterDeviceIdRef === device.dlId,
      )
      const parentDevice: any = { ...parentDeviceToBeUpdated }
      if (device.deviceSettings.masterDeviceIdRef) {
          if (!parentDevice?.childDeviceIds.includes(device.deviceSettings.id)) {
            parentDevice.childDeviceIds.push(device.deviceSettings.id)
          }
        }

      return {
        ...state,
        allDevices: state.devices.map((d) => {
          if (d[idTypeKey] === device[idTypeKey]) {
            return device
          } else if (d[idTypeKey] === parentDevice[idTypeKey]) {
            return parentDevice
          } else {
            return d
          }
        }),
        devices: state.devices.map((d) => {
          if (d[idTypeKey] === device[idTypeKey]) {
            return device
          } else if (d[idTypeKey] === parentDevice[idTypeKey]) {
            return parentDevice
          } else {
            return d
          }
        }),
        loading: false,
        error: false,
        deviceUpdating: false,
        selectedDevices: state.selectedDevices.map((d) => (device.dUUID === d.dUUID ? device : d)),
      }
    }
    case ActionTypes.LOAD_SINGLE_DEVICE: {
      const deviceToBeUpdated = state.devices.find((d) => d.dUUID === action.payload.device.d_uuid)
      const deviceData: DeviceAPI = action.payload.device;
      const permissions = action.payload.permissions;
      if (!deviceToBeUpdated) return state
      if (!deviceData) return state
      const device: Device = { ...deviceToBeUpdated, ...mapDevice(deviceData, permissions) }
      return {
        ...state,
        allDevices: state.allDevices.map((d) =>
          d.deviceId === device.deviceId ? device : d,
        ),
        devices: state.devices.map((d) =>
          d.deviceId === device.deviceId ? device : d,
        ),
        loading: false,
        error: false,
        deviceUpdating: false
      }
    }
    case ActionTypes.SEND_MESSAGE:
      return { ...state, loading: false, error: false, deviceUpdating: false }
    case ActionTypes.LOADING_DEVICES:
      return { ...state, loadingDevices: true, error: false }
    case ActionTypes.LOADING:
      return { ...state, loading: true, error: false }
    case ActionTypes.ERROR:
      const { errorMsg } = action.payload;
      displayToast({
          type: 'error',
          message: errorMsg?.detail || errorMsg?.message || 'Something went wrong',
        })
      return { ...state, loading: false, error: true, deviceUpdating: false }
    default:
      return state
  }
}

const mapMvno = (iccid: string) => {
  if (!iccid) return ''

  if (iccid.startsWith('894453')) {
    return 'EsEye'
  } else if (iccid.startsWith('894573')) {
    return 'Onomondo'
  }
}

const mapVendorName = (vendor: string) => {
  if (!vendor) return ''

  if (vendor === 'SMARTFLOW') {
    return 'SMARTFLOW.v3'
  } else if (vendor === 'METASPHERE') {
    return 'SMARTFLOW.M'
  } else if (vendor === 'PLUM') {
    return 'SMARTFLOW.P'
  } else {
    return vendor
  }
}

export const mapDeviceSettings = (deviceSettings: DeviceSettingsAPI): DeviceSettings => {
   return {
     id: deviceSettings.id,
     active: deviceSettings.active,
     dataAcqMode: deviceSettings.data_acq_mode.toString(),
     serverPulseControl: deviceSettings.server_pulse_control,
     pulsesPerLiter: deviceSettings.pulses_per_liter,
     firmwareVersionDesired: deviceSettings.firmware_version_desired,
     timezone: deviceSettings.timezone,
     installDate: deviceSettings.install_date,
     usageStartDate: deviceSettings.usage_start_date,
     monitoringStartDate: deviceSettings.monitoring_start_date,
     deviceIDActivationDate: deviceSettings.device_id_activation_date,
     installDateEpoch: moment(deviceSettings.install_date).unix(),
     currency: deviceSettings.currency,
     cost: deviceSettings.cost,
     report30Day: deviceSettings.report_30_day,
     report30DayComplete: deviceSettings.report_30_day ? 'Yes' : 'No',
     sectorType: deviceSettings.sector_type,
     type: deviceSettings.hot ? 'Hot' : 'Cold',
     masterDeviceIdRef: deviceSettings.master_device_id_ref,
     occupants: deviceSettings.occupants,
     hot: deviceSettings.hot || false,
     deviceName: deviceSettings.device_name,
     deviceNameShort: deviceSettings.device_name_short,
     uploadFreqMins: deviceSettings.upload_freq_mins,
     location: deviceSettings.location ?? ''
  }
}

export const mapDevice = (deviceData: DeviceAPI, permissions: Permission[]): Device => {
  return {
    deviceId: String(deviceData.device_id),
    childDeviceIds: deviceData.child_device_ids,
    dlId: deviceData.dl_id,
    dUUID: deviceData.d_uuid,
    accountId: String(deviceData.account_id),
    deviceName: deviceData.device_settings.device_name,
    deviceSettings: {...mapDeviceSettings(deviceData.device_settings)},
    batteryLevel: deviceData.device_status.power.HUB_BAT ?? null,
    batteryLevelExt: deviceData.device_status.power.ext_battery ?? null,
    batteryStatus: deviceData.device_status.power.HUB_STA ?? '',
    signal: deviceData.device_status.power.CSQ ?? null,
    ICCID: deviceData.device_status.network_info.ICCID ?? '',
    connectionStatus: mapConnectionState(permissions, deviceData.device_settings.upload_freq_mins, deviceData.device_status.last_ping) ?? 'Error',
    firmware: deviceData.device_status.device_sw.HUB_SW ?? '',
    binFileName: deviceData.device_status.bin_file_name ?? '',
    binFilePath: deviceData.device_status.bin_file_path ?? '',
    deviceLocationId: String(deviceData.customer_location_id),
    onBattDate: deviceData.device_status.on_batt_date ? new Date(deviceData.device_status.on_batt_date) : undefined,
    deviceVendor: deviceData.vendor,
    deviceVendorName: mapVendorName(deviceData.vendor),
    mvno: mapMvno(deviceData.device_status.network_info.ICCID),
    model: deviceData.model,
    lastOnline: deviceData.device_status.last_ping ? new Date(deviceData.device_status.last_ping) : undefined,
    valves: {...mapValves(deviceData)},
    networkName: deviceData.device_status.network_info.network_name || 'Unknown',
    fplmnList: deviceData.device_status.network_info.fplmn_list,
  }
}

const mapValves = (deviceData: DeviceAPI) => {
  return deviceData.device_status.valve_state &&
  Object.entries(deviceData.device_status.valve_state).reduce(
    (acc, v: any) => {
      const key = v[0] as ValveKey
      const state = v[1] as ValveState
      const name = deviceData.valve_names[key]
      acc[key] = { key, state, name }
      return acc
    },
    {} as { [key in ValveKey]: Valve },
  )
}

const mapConnectionState = (permissions: Permission[], uploadFreqMins?: number, lastPing?: string): string => {
  const intervalMultiplierUser: number = 12;
  const intervalMultiplierEng: number = 3;
  if (!lastPing) return "Disconnected"
  if (!uploadFreqMins) return "Error"
  const lastOnline = new Date(lastPing)
  const interval = hasPermissions(permissions, ["DASHBOARD:STATUS:VIEW"]) ? uploadFreqMins * intervalMultiplierEng : uploadFreqMins * intervalMultiplierUser;
  if (moment.utc().diff(lastOnline, 'minutes') >= interval) {
    return "Disconnected"
  } else {
    return "Connected"
  }
}

const enumDeviceIdTypeByValue = (value: string) => {
  const indexEnum = Object.values(DeviceIDTypes).indexOf(value as unknown as DeviceIDTypes);
  return Object.keys(DeviceIDTypes)[indexEnum] as keyof Device
}
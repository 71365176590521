import { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import DeviceModal from './DeviceModal/DeviceModal'
import CustomSearchTable from '../../../general/CustomSearchTable/CustomSearchTable'
import { COLUMNS } from './constants'

import { useDeviceState } from '../../../../context/device/context/device.context'
import Device from '../../../../context/device/model/device'

import './DeviceInformationManager.scss'

const rowKeys = COLUMNS.map((column) => column.key)

const DeviceInformationManager = () => {
  const { devices, loadingDevices } = useDeviceState()
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    setSelectedDevice((sd) => devices.find((d) => d.deviceId === sd?.deviceId))
  }, [devices])

  function onRowClick(rowData: Device) {
    if (rowData) {
      setSelectedDevice(rowData)
      setModalShow(true)
    }
  }

  function onHideDeviceModal() {
    setModalShow(false)
    setSelectedDevice(undefined)
  }

  return (
    <div className="device-information-manager">
      {loadingDevices ? (
        <div className="d-flex justify-content-center mt-5">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        </div>
      ) : (
        <div>
          <CustomSearchTable
            dataArray={devices}
            columns={COLUMNS}
            rowKeys={rowKeys}
            onRowClickHandler={(rowData) => onRowClick(rowData)}
            filterByInstallDate
            exportToCSV
            allowColumnPicker
            displayPagination
          />

          <DeviceModal show={modalShow} onHide={onHideDeviceModal} deviceData={selectedDevice} />
        </div>
      )}
    </div>
  )
}

export default DeviceInformationManager

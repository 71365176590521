import React from 'react';

import DeviceStatusComponent from '../../Components/DeviceStatus/DeviceStatusComponent';
import { useDeviceState } from '../../../../context/device/context/device.context';

const DeviceStatus: React.FC = () => {

  const { testDevices } = useDeviceState();

  return (
    <div>
      <DeviceStatusComponent devices={ testDevices }/>
    </div>
  )
};

export default DeviceStatus;
export const COLUMNS = [
  {
    name: 'Name',
    key: 'deviceName',
    sortable: true,
    width: '10%',
    isFixed: true,
    checked: true,
    order: 1,
  },
  {
    name: 'Device ID',
    key: 'deviceId',
    sortable: true,
    width: '10%',
    isFixed: true,
    checked: true,
    order: 2,
  },
  {
    name: 'Install Date',
    key: 'deviceSettings.installDateEpoch',
    sortable: true,
    width: '10%',
    isFixed: false,
    checked: true,
    order: 3,
  },
  {
    name: 'Type',
    key: 'deviceSettings.type',
    sortable: true,
    width: '2%',
    isFixed: false,
    checked: false,
    order: 4,
  },
  {
    name: '30 Day Report',
    key: 'deviceSettings.report30DayComplete',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: false,
    order: 5,
  },
  {
    name: 'Pulses',
    key: 'deviceSettings.pulsesPerLiter',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 6,
  },
  {
    name: 'Firmware',
    key: 'firmware',
    sortable: true,
    width: '10%',
    isFixed: false,
    checked: true,
    order: 7,
  },
  {
    name: 'ICCID',
    key: 'ICCID',
    sortable: true,
    width: '10%',
    isFixed: false,
    checked: false,
    order: 8,
  },
  {
    name: 'MVNO',
    key: 'mvno',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: false,
    order: 9,
  },
  {
    name: 'Battery %',
    key: 'batteryLevel',
    sortable: true,
    width: '10%',
    isFixed: false,
    checked: true,
    order: 10,
  },
  {
    name: 'Signal',
    key: 'signalStrength',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 11,
  },
  {
    name: 'CSQ',
    key: 'signal',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: false,
    order: 12,
  },
  {
    name: 'Power',
    key: 'batteryStatus',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 13,
  },
  {
    name: 'Status',
    key: 'deviceStatus',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 14,
  },
  {
    name: 'State',
    key: 'deviceSettings.active',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 15,
  },
  {
    name: 'Vendor',
    key: 'deviceVendorName',
    sortable: true,
    width: '5%',
    isFixed: false,
    checked: true,
    order: 16,
  },
]
export const SECTOR_TYPES_OPTIONS: string[] = [
  'Bar',
  'Hotel',
  'Restaurant',
  'Office',
  'Service station',
  'Nursing Home',
  'Gym',
  'Manufacturing',
  'Shopping Center',
  'Cinema',
  'Shop',
  'Construction Site',
  'Pool',
  'Student Accommodation',
  'Real Estate',
]

export const PULSES_OPTIONS: { value: string; label: string }[] = [
  0.01, 0.1, 0.2, 0.5, 1, 2, 10, 38, 60, 119, 210, 1000, 2000, 10000,
].map((pulse) => {
  return {
    value: pulse.toString(),
    label: pulse > 1 ? `${pulse} - ${pulse} Pulses/liter` : `${pulse} - ${1 / pulse} Liters/pulse`,
  }
})

export const FIRMWARE_OPTIONS: string[] = [
  // 'SM_V3_035a_RC09_OCT_22.bin',
  'SM_V3_035a_RC13_Nov_28.bin',
  'SM_V3_035a_RC15_Dec_5_2023.bin',
  'SM_V3_035a_RC18_Dec_22_2023.bin',
  'SM_V3_035a_RC20_Jan_2_2024.bin',
  'SM_V3_035a_RC21_Jan_3_2024.bin',
]
export const COUNTRY_OPTIONS: string[] = ['Ireland', 'Northern Ireland', 'United Kingdom']
export const CURRENCY_OPTIONS: string[] = ['EUR', 'GBP']

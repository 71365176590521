import Device from '../../../../context/device/model/device'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment/moment'
import { SMARTFLOW_FOOTER_IMAGE, smartflowHeaderImg } from '../../../../common/utils/constants'
import { roundToTwo } from '../../../../common/utils/helperFunctions'
import { IReportTotalProps } from './interfaces'

export const buildPDFReport = (
  selectedDevices: Device[],
  fromDate: Date | null,
  toDate: Date | null,
  chartImage: string,
  tableElement: HTMLTableElement,
  totals: IReportTotalProps,
): Promise<jsPDF> => {
  return new Promise((res, rej) => {
    const from = moment(fromDate).format('MMMM Do YYYY')
    const to = moment(toDate).format('MMMM Do YYYY')
    const pdf = new jsPDF('landscape', 'mm', [297, 210])

    const pdfWidth = pdf.internal.pageSize.getWidth()
    const headerPosX = pdf.internal.pageSize.getWidth() / 2 - pdfWidth / 2
    const headerImgProps = pdf.getImageProperties(smartflowHeaderImg)
    const headerRatio = pdfWidth / headerImgProps.width

    const footerImgProps = pdf.getImageProperties(SMARTFLOW_FOOTER_IMAGE)

    const X_OFFSET = 30
    let posXStart = 5
    let posYStart = 65

    // Add Summary
    if (totals) {
      let posY = posYStart
      let posX = posXStart

      pdf.setFont('Nunito', 'bold')
      pdf.setTextColor(25, 106, 154)
      pdf.setFontSize(16)
      pdf.text('ESG Summary', pdf.internal.pageSize.getWidth() / 2, posY, { align: 'center' })
      posY = posY + 7
      pdf.setFontSize(14)
      pdf.text(`${from} to ${to}`, pdf.internal.pageSize.getWidth() / 2, posY, { align: 'center' })
      posY += 20

      for (const device of selectedDevices) {
        pdf.setFontSize(12)
        pdf.setFont('Nunito', 'bold')
        pdf.text('Device Name: ', posX, posY)
        pdf.setFont('Nunito', 'normal')
        pdf.text(`${device.deviceName}`, posX + X_OFFSET, posY)
        posY += 8
        pdf.setFont('Nunito', 'bold')
        pdf.text('Address: ', posX, posY)
        pdf.setFont('Nunito', 'normal')
        // const deviceAddress = [
        //   device.deviceInfo.addressLine1,
        //   device.deviceInfo.addressLine2,
        //   device.deviceInfo.town,
        //   device.deviceInfo.postcode,
        //   device.deviceInfo.county,
        // ]
        // for (let i = 0; i < deviceAddress.length; i += 1) {
        //   if (deviceAddress[i] && deviceAddress[i] !== '') {
        //     pdf.text(deviceAddress[i], posX + X_OFFSET, posY)
        //     if (i !== deviceAddress.length - 1) {
        //       posY += 5
        //     }
        //   }
        // }
      }

      posX += 120
      posY = posYStart + 50
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.text('Total Cost', posX, posY)
      posY += 7
      for (const totalCost of totals.totalCostByCurrency) {
        pdf.text(totalCost, posX, posY)
        posY += 5
      }

      posX += 47
      posY = posYStart + 50
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.setFontSize(10)
      pdf.text('Total Water Consumed', posX, posY)
      posY += 7
      pdf.setFontSize(12)
      pdf.text(
        totals.usageTotal ? `${totals.usageTotal.toString()} m${String.fromCharCode(179)}` : 'N/A',
        posX,
        posY,
      )

      posX += 47
      posY = posYStart + 50
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.text('Total CO2 Produced', posX, posY)
      posY += 7
      pdf.text(totals.co2Total ? `${totals.co2Total.toString()} KG` : 'N/A', posX, posY)

      posX = posXStart + 120
      posY = posYStart + 75
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.text('Active Alerts', posX, posY)
      posY += 7
      pdf.text(totals.activeAlerts.toString(), posX, posY)

      posX += 47
      posY = posYStart + 75
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.text('Alerts', posX, posY)
      pdf.setFontSize(10)
      posY += 7
      pdf.setFontSize(12)
      pdf.text(totals.periodAlerts.toString(), posX, posY)

      posX += 47
      posY = posYStart + 75
      pdf.setFillColor(213, 237, 255)
      pdf.setLineWidth(0.3)
      pdf.setDrawColor(0, 0, 0)
      pdf.roundedRect(posX, posY, 48, 18, 5, 5, 'FD')
      posX += 5
      posY += 5
      pdf.setFont('Nunito', 'bold')
      pdf.text('Total Alerts', posX, posY)
      posY += 7
      pdf.text(totals.totalAlerts.toString(), posX, posY)
      pdf.setFillColor(255, 255, 255)
    }

    // Add Chart
    if (chartImage) {
      let posY = posYStart
      let posX = posXStart

      pdf.addPage()

      pdf.setFont('Nunito', 'bold')
      pdf.setTextColor(25, 106, 154)
      pdf.setFontSize(16)
      pdf.text('Water Usage', pdf.internal.pageSize.getWidth() / 2, posY, { align: 'center' })

      const imgProps = pdf.getImageProperties(chartImage)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const ratio = pdfWidth / imgProps.width
      posY += 20
      pdf.addImage(
        chartImage,
        'PNG',
        posX,
        posY,
        pdfWidth - posX * 2,
        imgProps.height * ratio,
        undefined,
        'FAST',
      )
      posY += 30
      pdf.setFont('Nunito', 'normal')
      pdf.text(
        'Total Usage:',
        pdf.internal.pageSize.getWidth() / 2,
        imgProps.height * ratio + posY,
        { align: 'center' },
      )
      posY += 20
      pdf.setFont('Nunito', 'normal')
      pdf.setFontSize(16)
      pdf.text(
        totals.usageTotal ? `${totals.usageTotal * 1000} L` : '',
        pdf.internal.pageSize.getWidth() / 2,
        imgProps.height * ratio + posY,
        { align: 'center' },
      )
      posY += 20
      pdf.text(
        `${totals.usageTotal ? roundToTwo(totals.usageTotal) : null} m${String.fromCharCode(179)}`,
        pdf.internal.pageSize.getWidth() / 2,
        imgProps.height * ratio + posY,
        { align: 'center' },
      )
    }

    // Add Table
    if (tableElement) {
      pdf.addPage()
      let posY = posYStart

      pdf.setFont('Nunito', 'bold')
      pdf.setTextColor(25, 106, 154)
      pdf.setFontSize(16)
      pdf.text('Detailed Usage Breakdown', pdf.internal.pageSize.getWidth() / 2, posY - 3, {
        align: 'center',
      })

      autoTable(pdf, {
        html: tableElement,
        startY: posY,
        rowPageBreak: 'auto',
        tableWidth: 'wrap',
        tableLineColor: [25, 106, 154],
        styles: {
          fontSize: 8,
        },
        margin: {
          top: 60,
          bottom: 45,
          left: 3,
        },
        columnStyles: {
          0: { cellWidth: 'wrap' },
          1: { cellWidth: 'wrap' },
          2: { cellWidth: 'wrap' },
          3: { cellWidth: 'wrap' },
          4: { cellWidth: 'wrap' },
          5: { cellWidth: 'wrap' },
          6: { cellWidth: 'wrap' },
          7: { cellWidth: 'wrap' },
          8: { cellWidth: 'wrap' },
          9: { cellWidth: 'wrap' },
          10: { cellWidth: 'wrap' },
          11: { cellWidth: 'wrap' },
          12: { cellWidth: 'wrap' },
          13: { cellWidth: 'wrap' },
          14: { cellWidth: 'wrap' },
          15: { cellWidth: 'wrap' },
          16: { cellWidth: 'wrap' },
          17: { cellWidth: 'wrap' },
          18: { cellWidth: 'wrap' },
          19: { cellWidth: 'wrap' },
          20: { cellWidth: 'wrap' },
          21: { cellWidth: 'wrap' },
          22: { cellWidth: 'wrap' },
          23: { cellWidth: 'wrap' },
          24: { cellWidth: 'wrap' },
          25: { cellWidth: 'wrap' },
        },
      })
    }

    const pageCount = pdf.internal.pages.length // With first element null
    const realPageCount = pageCount - 1
    for (let i = 1; i <= realPageCount; i++) {
      pdf.setPage(i)
      // Header
      pdf.addImage(smartflowHeaderImg, 'png', headerPosX, 0, pdfWidth, 55, undefined, 'FAST')

      const footerHeight = footerImgProps.height * headerRatio

      //Footer
      pdf.addImage(
        SMARTFLOW_FOOTER_IMAGE,
        'png',
        headerPosX,
        pdf.internal.pageSize.getHeight() - 40,
        pdfWidth,
        50,
        undefined,
        'FAST',
      )

      pdf.setFontSize(8)
      pdf.setTextColor(0, 0, 0)
      const footerMsg = `Page ${i} of ${realPageCount}`
      const footerMsgPosY = pdf.internal.pageSize.getHeight() - 35
      pdf.text(footerMsg, 5, footerMsgPosY)
    }

    res(pdf)
  })
}

import { KeyRound } from 'lucide-react'
import { CustomerManagerContextProvider } from '../../../context/customerManager/customer-manager.context'

import AccountSelector from './AccountSelector'

import './AccountManagement.scss'

const Dashboard = () => {
  return (
    <CustomerManagerContextProvider>
      <div className="home">
        <h1>
          <KeyRound className={'mr-3'} size={40} />
          Account Management
        </h1>
        <AccountSelector />
      </div>
    </CustomerManagerContextProvider>
  )
}

export default Dashboard

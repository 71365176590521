import { useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import { useEffect, useState } from 'react'

interface PaginationProps {
  totalPages: number
}

export function Pagination({ totalPages }: PaginationProps) {
  const [_, setSearchParams] = useSearchParams()

  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setSearchParams((state) => {
      state.set('page', String(currentPage))

      return state
    })
  }, [currentPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  return (
    <div className="d-flex justify-content-center mt-2 mb-5">
      <Button
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        variant="outline-secondary"
        size="sm"
      >
        <ChevronLeft size={20} />
        Previous
      </Button>
      <span className="d-flex ml-2 mr-2 align-items-center">{`Page ${currentPage} of ${totalPages}`}</span>
      <Button
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        variant="outline-secondary"
        size="sm"
      >
        Next <ChevronRight size={20} />
      </Button>
    </div>
  )
}

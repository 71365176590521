import React, { useState, useEffect } from 'react'
import { Modal, Form, Col, Button, Spinner, Row } from 'react-bootstrap'
import { useUserState, useUserDispatch } from '../../../../context/user/context/user.context'

import './UserInfo.scss'

interface IUserInfoModalProps {
  show: boolean
  onHide: () => void
}

const UserInfo: React.FC<IUserInfoModalProps> = ({ show, onHide }: IUserInfoModalProps) => {
  const { userInfo, updatingProfile, error } = useUserState()
  const { updateProfile } = useUserDispatch()

  const [firstName, setFirstName] = useState<string>(userInfo?.firstname || '')
  const [lastName, setLastName] = useState<string>(userInfo?.lastname || '')
  const [mobile, setMobile] = useState<string>(userInfo?.mobile || '')
  const [didProfileInfoChange, setDidProfileInfoChange] = useState(false)

  const [profileUpdated, setProfileUpdated] = useState<boolean>(false)
  const [wasUpdateRequested, setWasUpdateRequested] = useState<boolean>(false)

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.firstname !== firstName ||
        userInfo.lastname !== lastName ||
        userInfo.mobile ||
        '' !== mobile
      ) {
        // Some value has changed
        setDidProfileInfoChange(true)
      } else {
        setDidProfileInfoChange(false)
      }
    }
  }, [userInfo, firstName, lastName, mobile])

  useEffect(() => {
    if (!show) {
      setProfileUpdated(false)
    }
  }, [show])

  useEffect(() => {
    if (wasUpdateRequested && !updatingProfile && !error) {
      setProfileUpdated(true)
    } else {
      setProfileUpdated(false)
    }
  }, [updatingProfile, error, wasUpdateRequested])

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (userInfo && didProfileInfoChange) {
      updateProfile(firstName, lastName, mobile)
      setWasUpdateRequested(true)
    }
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Update Personal Information</Modal.Title>
      </Modal.Header>
      <Modal.Body className="custom-modal-body">
        <Form className="device-information-form" onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="form-user-email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                readOnly
                autoComplete="off"
                type="text"
                placeholder="Email"
                value={userInfo?.email}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="form-user-firsname">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                isInvalid={!wasUpdateRequested && !firstName}
              />
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">Required</div>
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} controlId="form-user-lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                isInvalid={!wasUpdateRequested && !lastName}
              />
              <Form.Control.Feedback type="invalid">
                <div className="input-feedback_error">Required</div>
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="form-user-mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Mobile"
                value={mobile}
                onChange={(e) => setMobile(e.currentTarget.value)}
              />
            </Form.Group>
          </Row>
          <Button
            variant="primary"
            type="submit"
            disabled={
              !firstName || !lastName || updatingProfile || profileUpdated || !didProfileInfoChange
            }
          >
            {updatingProfile ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span>Updating profile...</span>
              </>
            ) : (
              <>Update Profile</>
            )}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="custom-modal-footer">
        <Button id="close" variant="secondary" disabled={updatingProfile} onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserInfo

import { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Tab, Table, Tabs, Col, Row, Nav, Button } from 'react-bootstrap'
import { RiMailSendFill } from 'react-icons/ri'
import { GiCancel } from 'react-icons/gi'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Account from '../../../context/account/model/Account'
import Device from '../../../context/device/model/device'
import User, { UserInvite } from '../../../context/user/model/user'
import {
  useCustomerManagerDispatch,
  useCustomerManagerState,
} from '../../../context/customerManager/customer-manager.context'
import { useUserDispatch, useUserState } from '../../../context/user/context/user.context'
import CustomerLocation from '../../../context/account/model/CustomerLocation'
import { FaCheck, FaRegClock, FaPlus, FaExchangeAlt, FaTrash, FaCog } from 'react-icons/fa'
import { hasPermissions } from '../../../common/utils/helperFunctions'
import UserEditModal from './UserModal/UserEditModal'
import AddDeviceModal from './AddDeviceModal/AddDeviceModal'
import SwapDeviceModal from './AddDeviceModal/SwapDeviceModal'
import LocationEditModal from './LocationModal/LocationEditModal'
import { InviteModal } from './UserModal/UserInvitationModal'
import ResendModal from './UserModal/ResendModal'
import AlertEditModal from './UserModal/AlertEditModal'
import DeleteModal from '../../general/Modals/DeleteModal'
import AccountComment from './AccountComment'
import { EditInviteModal } from './UserModal/EditUserInviteModal'

const AccountDetails = ({ selectedAccount }: { selectedAccount: Account }) => {
  const {
    devicesByLocation,
    usersByAccount,
    locationsByAccount,
    invitationsByAccount,
    commentsByAccount,
  } = useCustomerManagerState()
  const {
    removeDeviceFromAccount,
    removeUserFromAccount,
    removeLocationFromAccount,
    swapDevice,
    loadUsersByAccount,
    loadLocationsByAccount,
    loadAccountComments,
    loadInvitationsByAccount,
    updateUserInvite,
    resendUserInvite,
    createComment,
  } = useCustomerManagerDispatch()
  const { requestingConfirmEmail, error, permissions } = useUserState()
  const { requestConfirmEmail } = useUserDispatch()

  const [accountLocations, setAccountLocations] = useState<CustomerLocation[]>([])
  const [locationDevices, setLocationDevices] = useState<Device[]>([])
  const [accountUsers, setAccountUsers] = useState<User[]>([])
  const [editModalUser, setEditModalUser] = useState<User>()
  const [inviteUserModal, setInviteUserModal] = useState<User>()
  const [editModalLocation, setEditModalLocation] = useState<CustomerLocation>()
  const [selectedLocation, setSelectedLocation] = useState<CustomerLocation>()
  const [addDeviceModal, setAddDeviceModal] = useState<boolean>(false)
  const [swapDeviceModal, setSwapDeviceModal] = useState<Device>()
  const [deleteUserModal, setDeleteUserModal] = useState<User | undefined>()
  const [deleteDeviceModal, setDeleteDeviceModal] = useState<Device | undefined>()
  const [deleteLocationModal, setDeleteLocationModal] = useState<CustomerLocation | undefined>()
  const [confirmEmailSent, setConfirmEmailSent] = useState<boolean>(false)
  const [tabKey, setTabKey] = useState<string>('users_tab')
  const [editInvitation, setEditInvitation] = useState<any>()
  const [deactivateInviteModal, setDeactivateInviteModal] = useState<any | undefined>()
  const [resendInviteModal, setResendInviteModal] = useState<number | undefined>()
  const [editUserAlertsModal, setEditUserAlertsModal] = useState<User | undefined>()

  const [comment, setComment] = useState('')

  const canUserCreateDevices = hasPermissions(permissions, ['UPDATE:ACCOUNT:DEVICE'])
  const canUserSeeLocations = hasPermissions(permissions, ['READ:CUST_LOCATION'])
  const canUserUpdateLocations = hasPermissions(permissions, ['UPDATE:CUST_LOCATION'])
  const isUserSmartflowAdmin = hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW'])

  useEffect(() => {
    setAccountUsers([])
    setLocationDevices([])
    setAccountLocations([])
    setSelectedLocation(undefined)
    if (selectedAccount.id.length > 0) {
      loadUsersByAccount(selectedAccount)
      loadLocationsByAccount(selectedAccount)
      if (isUserSmartflowAdmin) {
        loadAccountComments(selectedAccount)
      }
      loadInvitationsByAccount(selectedAccount)
    }
  }, [selectedAccount])

  useEffect(() => {
    if (!locationsByAccount) return
    setAccountLocations(locationsByAccount[selectedAccount.id] || [])
  }, [selectedAccount, locationsByAccount])

  useEffect(() => {
    if (!devicesByLocation || !selectedLocation) return
    setLocationDevices(
      (selectedLocation && selectedLocation.id && devicesByLocation[selectedLocation.id]) || [],
    )
  }, [selectedLocation, devicesByLocation])

  useEffect(() => {
    if (!usersByAccount) return
    setAccountUsers(usersByAccount[selectedAccount.id] || [])
  }, [selectedAccount, usersByAccount])

  useEffect(() => {
    if (!requestingConfirmEmail && !error) {
      setConfirmEmailSent(true)
    } else {
      setConfirmEmailSent(false)
    }
  }, [requestingConfirmEmail, error])

  const resendConfirmationEmail = (user: User) => {
    requestConfirmEmail(user.email)
  }

  const handleDeactivateUserInvite = (invite: UserInvite, account: Account) => {
    const updatedInvite: UserInvite = { ...invite, active: false }
    updateUserInvite(updatedInvite, account)
  }

  const inviteStatus = (invite: UserInvite) => {
    if (invite.accountCreated) {
      return (
        <span>
          <FaCheck color={'green'} className={'mr-1'} size={28} />
          Account created
        </span>
      )
    } else if (moment(invite.expiryDate) < moment.utc()) {
      return (
        <span>
          <FaRegClock className={'text-danger mr-1'} size={28} />
          Expired
        </span>
      )
    } else if (invite.active && !invite.accountCreated) {
      return (
        <span>
          <RiMailSendFill color={'green'} className={'mr-1'} size={28} />
          Mail sent
        </span>
      )
    } else {
      return (
        <span>
          <GiCancel className={'text-danger mr-1'} size={28} />
          Inactive
        </span>
      )
    }
  }

  const handleCreateComment = async () => {
    const res = await createComment(selectedAccount.id, comment)

    if (res) {
      loadAccountComments(selectedAccount)
      setComment('')
    }
  }

  return (
    <div>
      <div className="mt-5">
        {!selectedAccount.isActive && (
          <h4 className="text-danger font-weight-bold mb-5">
            <u>*This account is Inactive*</u>
          </h4>
        )}
        <Tab.Container defaultActiveKey="locations_and_devices">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                {canUserSeeLocations && (
                  <Nav.Item>
                    <Nav.Link eventKey="locations_and_devices">Locations & Devices</Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link eventKey="users_and_invitations">Users & Invitations</Nav.Link>
                </Nav.Item>
                {hasPermissions(permissions, ['READ:ACCOUNT:COMMENT']) && (
                  <Nav.Item>
                    <Nav.Link eventKey="account_notes">Account Notes</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                <Tab.Pane eventKey="locations_and_devices">
                  <>
                    <div style={{ display: 'flex' }} className="mb-2">
                      <h2 className="my-auto mr-3">Locations</h2>
                      {canUserUpdateLocations ? (
                        <button
                          onClick={() => setEditModalLocation({} as any)}
                          className="my-auto btn btn-sm btn-primary"
                        >
                          <FaPlus className={'mr-1'} />
                          Add Location to Account
                        </button>
                      ) : null}
                    </div>
                    <Table>
                      <thead>
                        <tr>
                          <th>Location</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountLocations.map((loc: CustomerLocation) => (
                          <tr
                            key={loc.id}
                            className={
                              (selectedLocation?.id === loc.id ? 'selected' : '') + ' selectable'
                            }
                          >
                            <td onClick={() => setSelectedLocation(loc)}>{loc.name}</td>
                            <td>
                              {canUserUpdateLocations ? (
                                <button
                                  className="btn btn-sm btn-info mr-1"
                                  onClick={() => setEditModalLocation(loc)}
                                >
                                  <FaCog className={'mr-1'} />
                                  Edit
                                </button>
                              ) : null}
                              {hasPermissions(permissions, ['DELETE:CUST_LOCATION']) ? (
                                <button
                                  onClick={() => setDeleteLocationModal(loc as any)}
                                  className="btn btn-sm btn-danger mr-1"
                                >
                                  <FaTrash className={'mr-1'} />
                                  Delete
                                </button>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <div>
                      <div style={{ display: 'flex' }} className="mb-2">
                        <h2 className="my-auto mr-3">Devices</h2>
                        {canUserCreateDevices && selectedLocation ? (
                          <button
                            onClick={() => setAddDeviceModal({} as any)}
                            className="my-auto btn btn-sm btn-primary"
                          >
                            <FaPlus className={'mr-1'} />
                            New Device
                          </button>
                        ) : null}
                      </div>
                      {selectedLocation ? (
                        locationDevices.length === 0 ? (
                          <div className={'my-3'}>
                            <h5 className="my-auto mr-3">
                              No devices associated with this location
                            </h5>
                          </div>
                        ) : (
                          <Table>
                            <thead>
                              <tr>
                                <th>Device Name</th>
                                <th>Device Id</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {locationDevices.map((device) => (
                                <tr key={device.deviceId}>
                                  <td>{device.deviceName}</td>
                                  <td>{device.deviceId}</td>
                                  <td>
                                    {hasPermissions(permissions, ['DEVICE:MOVE:STGD_PROD']) ? (
                                      <button
                                        onClick={() => setSwapDeviceModal(device)}
                                        className="btn btn-sm btn-info mr-1"
                                      >
                                        <FaExchangeAlt className={'mr-1'} />
                                        Swap
                                      </button>
                                    ) : null}

                                    {/*{hasPermissions(permissions, ["DELETE:ACCOUNT:DEVICE"]) ?*/}
                                    {/*    <button*/}
                                    {/*        onClick={() => setDeleteDeviceModal(device)}*/}
                                    {/*        className="btn btn-sm btn-danger mr-1"*/}
                                    {/*    >*/}
                                    {/*        <span className="fas fa-trash mr-1"></span>*/}
                                    {/*        Delete*/}
                                    {/*    </button> : null*/}
                                    {/*}*/}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )
                      ) : (
                        <div className={'my-3'}>
                          <h5 className="my-auto mr-3">Click a location to view devices</h5>
                        </div>
                      )}
                    </div>
                  </>
                </Tab.Pane>
                <Tab.Pane eventKey="users_and_invitations">
                  <>
                    <div>
                      <Tabs
                        id="controlled-tab"
                        activeKey={tabKey}
                        onSelect={(k: any) => setTabKey(k)}
                      >
                        <Tab eventKey="users_tab" title="Users">
                          <div className="mb-3 d-flex">
                            <h2 className="my-auto mr-3 mt-3">Users</h2>
                            {hasPermissions(permissions, ['CREATE:USER:INVITE']) ? (
                              <button
                                onClick={() => setInviteUserModal({} as any)}
                                className="my-auto btn btn-sm btn-primary mr-1"
                              >
                                <FaPlus className={'mr-1'} />
                                Invite New User
                              </button>
                            ) : null}
                          </div>
                          <Table>
                            <thead>
                              <tr>
                                <th>Firstname</th>
                                <th>Lastname</th>
                                <th>E-Mail</th>
                                <th>Status</th>
                                <th>Account Admin</th>
                                <th>Locations</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {accountUsers
                                //.filter((user: any) => !selectedLocation && user.customerLocationIds.length === 0)
                                .map((user: any) => (
                                  <tr key={user.id}>
                                    <td>{user.firstname}</td>
                                    <td>{user.lastname}</td>
                                    <td>{user.email}</td>
                                    <td>{user.active ? 'Active' : 'Inactive'}</td>
                                    <td>
                                      {user.adminAccountIds.includes(selectedAccount.id)
                                        ? 'Yes'
                                        : 'No'}
                                    </td>
                                    <td>
                                      {user.viewAllLocationsAccountIds.includes(
                                        selectedAccount.id,
                                      ) ||
                                      accountLocations.every((loc) =>
                                        user.customerLocationIds.includes(loc.id),
                                      ) ? (
                                        'all locations'
                                      ) : (
                                        <ul>
                                          {user.customerLocationIds
                                            .map((lid: string) =>
                                              accountLocations.find((l) => l.id === lid),
                                            )
                                            .filter((loc: any) => !!loc)
                                            .map((location: CustomerLocation) => (
                                              <li key={location.id}>
                                                {location.name}
                                                {user.adminCustomerLocationIds.includes(
                                                  location.id,
                                                ) ? (
                                                  <span className="badge badge-primary ml-1">
                                                    Admin
                                                  </span>
                                                ) : null}
                                              </li>
                                            ))}
                                        </ul>
                                      )}
                                    </td>
                                    <td>
                                      {hasPermissions(permissions, ['UPDATE:USER']) ? (
                                        <>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>Edit User</Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-pencil-alt fa-lg mr-3 text-info"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setEditModalUser(user)}
                                            ></i>
                                          </OverlayTrigger>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>
                                                Edit Alerts Subscriptions
                                              </Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-bell fa-lg mr-3 text-info"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setEditUserAlertsModal(user)}
                                            ></i>
                                          </OverlayTrigger>
                                        </>
                                      ) : null}
                                      {/*{hasPermissions(permissions, ["DELETE:USER"]) ?*/}
                                      {/*  <>*/}
                                      {/*    <OverlayTrigger*/}
                                      {/*      delay={{ hide: 450, show: 300 }}*/}
                                      {/*      overlay={(props: any) => (*/}
                                      {/*        <Tooltip {...props}>Delete User</Tooltip>*/}
                                      {/*      )}*/}
                                      {/*      placement="bottom"*/}
                                      {/*    >*/}
                                      {/*      <i*/}
                                      {/*        className="fa fa-trash fa-lg text-danger"*/}
                                      {/*        style={{ cursor: 'pointer' }}*/}
                                      {/*        onClick={() => setEditUserAlertsModal(user)}*/}
                                      {/*      ></i>*/}
                                      {/*    </OverlayTrigger>*/}
                                      {/*  </> : null}*/}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Tab>
                        {hasPermissions(permissions, ['READ:USER:INVITE']) ? (
                          <Tab
                            eventKey="invite_tab"
                            title="Invitations"
                            // className={'h-100'}
                          >
                            <div
                              // style={{ display: 'flex' }}
                              className="mb-3 mt-3"
                            >
                              <h2 className="my-auto mr-3">User Invitations</h2>
                            </div>
                            <Table>
                              <thead>
                                <tr>
                                  <th>E-Mail</th>
                                  <th>Status</th>
                                  <th>Created Date</th>
                                  <th>Modified Date</th>
                                  <th>Expires</th>
                                  <th>Used</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invitationsByAccount.map((i: any) => (
                                  <tr key={i.id}>
                                    <td>{i.email}</td>
                                    <td>{inviteStatus(i)}</td>
                                    <td>{moment(i.createAt).format('YYYY-MM-DD HH:mm')}</td>
                                    <td>
                                      {i.updatedAt
                                        ? moment(i.updatedAt).format('YYYY-MM-DD HH:mm')
                                        : null}
                                    </td>
                                    <td>{moment(i.expiryDate).format('YYYY-MM-DD HH:mm')}</td>
                                    <td>{i.accountCreated ? 'Yes' : 'No'}</td>
                                    <td>
                                      {i.active ? (
                                        <>
                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>Edit Invitation</Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-pencil-alt fa-lg mr-2 text-info "
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setEditInvitation(i)}
                                            ></i>
                                          </OverlayTrigger>

                                          {moment(i.expiryDate) > moment.utc() ? (
                                            <OverlayTrigger
                                              delay={{ hide: 450, show: 300 }}
                                              overlay={(props: any) => (
                                                <Tooltip {...props}>Resend Invitation</Tooltip>
                                              )}
                                              placement="bottom"
                                            >
                                              <i
                                                className="fa fa-envelope fa-lg mr-2 text-info "
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setResendInviteModal(i)}
                                              ></i>
                                            </OverlayTrigger>
                                          ) : null}

                                          <OverlayTrigger
                                            delay={{ hide: 450, show: 300 }}
                                            overlay={(props: any) => (
                                              <Tooltip {...props}>Deactivate Invitation</Tooltip>
                                            )}
                                            placement="bottom"
                                          >
                                            <i
                                              className="fa fa-minus-circle fa-lg text-danger "
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setDeactivateInviteModal(i)}
                                            ></i>
                                          </OverlayTrigger>
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Tab>
                        ) : null}
                      </Tabs>
                    </div>
                  </>
                </Tab.Pane>
                <Tab.Pane eventKey={'account_notes'}>
                  <div className="ml-5">
                    <div className="border w-50">
                      <div className="p-3">
                        <textarea
                          style={{ resize: 'none', height: '100px' }}
                          className="w-100 p-2 text-break"
                          name="accountComments"
                          id="accountComments"
                          placeholder="Enter your comment here..."
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>

                      {hasPermissions(permissions, ['CREATE:ACCOUNT:COMMENT']) && (
                        <Button
                          className="ml-3 mb-3 d-flex flex-end"
                          onClick={() => handleCreateComment()}
                          disabled={!comment}
                        >
                          Post comment
                        </Button>
                      )}
                    </div>

                    {commentsByAccount.map((comment, index) => (
                      <AccountComment
                        key={index}
                        id={comment.id}
                        author={comment.author}
                        createdAt={comment.createdAt}
                        updatedAt={comment.updatedAt}
                        comment={comment.comment}
                        history={comment.history}
                        selectedAccount={selectedAccount}
                      />
                    ))}
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>

      {selectedAccount && editModalUser !== undefined ? (
        <UserEditModal
          show={true}
          user={editModalUser}
          account={selectedAccount!}
          onHide={() => setEditModalUser(undefined)}
        />
      ) : null}
      {selectedAccount && inviteUserModal ? (
        <InviteModal
          show={true}
          user={inviteUserModal}
          account={selectedAccount}
          onHide={() => setInviteUserModal(undefined)}
        />
      ) : null}
      {selectedAccount && editInvitation ? (
        <EditInviteModal
          show={true}
          invite={editInvitation}
          account={selectedAccount!}
          onHide={() => setEditInvitation(undefined)}
        />
      ) : null}
      <LocationEditModal
        show={editModalLocation !== undefined}
        account={selectedAccount!}
        data={editModalLocation!}
        onHide={() => setEditModalLocation(undefined)}
      />
      <AddDeviceModal
        show={addDeviceModal}
        account={selectedAccount!}
        customerLocation={selectedLocation as any}
        onHide={() => setAddDeviceModal(false)}
      />
      <SwapDeviceModal
        show={swapDeviceModal !== undefined}
        data={swapDeviceModal}
        onHide={() => setSwapDeviceModal(undefined)}
        onSave={(device) => {
          swapDevice(swapDeviceModal!, device)
          setSwapDeviceModal(undefined)
        }}
      />
      <DeleteModal
        show={deleteUserModal !== undefined}
        data={deleteUserModal}
        onHide={() => setDeleteUserModal(undefined)}
        onDelete={(user: User) => removeUserFromAccount(user, selectedAccount.id)}
        toString={(user: User) => `${user.firstname} ${user.lastname}`}
        name={'User'}
      />
      <DeleteModal
        show={deleteDeviceModal !== undefined}
        data={deleteDeviceModal}
        onHide={() => setDeleteDeviceModal(undefined)}
        onDelete={(device: Device) => removeDeviceFromAccount(device, selectedAccount)}
        toString={(device: Device) => `${device.deviceName} (#${device.deviceId})`}
        name={'Device'}
      />
      <DeleteModal
        show={deleteLocationModal !== undefined}
        data={deleteLocationModal}
        onHide={() => setDeleteLocationModal(undefined)}
        onDelete={(loc: CustomerLocation) => removeLocationFromAccount(loc, selectedAccount)}
        toString={(loc: CustomerLocation) => `${loc.name}`}
        name={'Location'}
      />
      <DeleteModal
        show={deactivateInviteModal !== undefined}
        data={deactivateInviteModal}
        onHide={() => setDeactivateInviteModal(undefined)}
        onDelete={(invite: UserInvite) => handleDeactivateUserInvite(invite, selectedAccount)}
        toString={(invite: UserInvite) => `${invite.email}`}
        name={'Invitation'}
        action={'Deactivate'}
      />
      <ResendModal
        show={resendInviteModal !== undefined}
        data={resendInviteModal}
        onHide={() => setResendInviteModal(undefined)}
        onSend={(invite: UserInvite) => resendUserInvite(invite.id)}
        toString={(invite: UserInvite) => `${invite.email}`}
        name={'Invitation'}
        action={'Resend'}
      />
      {selectedAccount && editUserAlertsModal ? (
        <AlertEditModal
          show={true}
          user={editUserAlertsModal}
          account={selectedAccount!}
          onHide={() => setEditUserAlertsModal(undefined)}
        />
      ) : null}
    </div>
  )
}

export default AccountDetails

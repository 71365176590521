import React, { useEffect } from 'react'
import { loadAccountsAction } from '../action/account.action'
import Account from '../model/Account'
import { Action, ActionTypes, initialState, reducer } from './account.reducer'

const StateContext = React.createContext(initialState)
const DispatchContext = React.createContext(undefined as any)

export const AccountContextProvider = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  useEffect(() => {
    loadAccountsAction(dispatch)
  }, [])

  const { selectedAccounts } = state
  useEffect(() => {}, [selectedAccounts])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useAccountState = () => {
  return React.useContext(StateContext)
}

export const useAccountDispatch = () => {
  const dispatch = React.useContext(DispatchContext) as (action: Action) => any

  if (dispatch === undefined) {
    throw new Error('useAccountDispatch must be used within a AccountContextProvider')
  }

  const addAccount = React.useCallback(
    (account: Account) => dispatch({ type: ActionTypes.ADD_ACCOUNT, payload: account }),
    [dispatch],
  )

  const updateAccount = React.useCallback(
    (account: Account) => dispatch({ type: ActionTypes.UPDATE_ACCOUNT, payload: account }),
    [dispatch],
  )

  const deleteAccount = React.useCallback(
    (account: Account) => dispatch({ type: ActionTypes.DELETE_ACCOUNT, payload: account }),
    [dispatch],
  )

  const setSelectedAccounts = React.useCallback(
    (selectedAccounts: Account[]) =>
      dispatch({ type: ActionTypes.SELECT_ACCOUNTS, payload: selectedAccounts }),
    [dispatch],
  )

  return React.useMemo(
    () => ({
      setSelectedAccounts,
      addAccount,
      updateAccount,
      deleteAccount,
    }),
    [setSelectedAccounts, addAccount, updateAccount, deleteAccount],
  )
}

import { useState } from 'react'
import moment from 'moment-timezone'
import Device from '../../../../context/device/model/device'
import SectorIcon from '../../../general/SectorIcon/SectorIcon'
import DeviceModal from '../../DeviceManagement/DeviceInformationManager/DeviceModal/DeviceModal'

interface Props {
  devices: Device[]
}

export function NewlyInstalledDevicesComponent({ devices }: Props) {
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [modalShow, setModalShow] = useState(false)

  const openDeviceModal = (deviceId: string) => {
    const device = devices.find((d) => d.deviceId === deviceId)

    if (device) {
      setSelectedDevice(device)
      setModalShow(true)
    }
  }

  function onHideDeviceModal() {
    setModalShow(false)
    setSelectedDevice(undefined)
  }

  return (
    <div className={'card border-left-primary shadow h-50 py-2 mb-4'}>
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col-auto">
            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
              Newly Installed Devices
            </div>
            <div>
              <small className="ml-2">{`Devices installed in the last 7 days (${devices.length}):`}</small>
              <div
                className="device-list-container"
                style={{ maxHeight: '9em', overflowY: 'auto' }}
              >
                <ul className="device-status-list list-unstyled">
                  {devices
                    .sort(
                      (a, b) =>
                        new Date(b.deviceSettings.installDate).getTime() -
                        new Date(a.deviceSettings.installDate).getTime(),
                    )
                    .map((d) => (
                      <li
                        key={d.deviceId}
                        className="mt-1"
                        style={{ cursor: 'pointer' }}
                        onClick={() => openDeviceModal(d.deviceId)}
                      >
                        {d.deviceSettings.sectorType && (
                          <SectorIcon
                            sector={d.deviceSettings.sectorType}
                            occupants={d.deviceSettings.occupants}
                          />
                        )}{' '}
                        {d.deviceName} (#
                        {d.deviceId}
                        ), installed {moment(d.deviceSettings.installDate).fromNow()}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeviceModal show={modalShow} onHide={onHideDeviceModal} deviceData={selectedDevice} />
    </div>
  )
}

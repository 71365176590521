import { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { GiTap } from 'react-icons/gi'
import {
  BsFillArrowDownCircleFill,
  BsFillArrowRightCircleFill,
  BsFillArrowUpCircleFill,
  BsThermometerSnow,
  BsThermometerSun,
} from 'react-icons/bs'
import moment from 'moment-timezone'

import { useWaterUsageState } from '../../../../context/waterUsage/waterUsageStats.context'
import { WaterUsageStats, YearlyStatsTotal } from '../../../../context/waterUsage/waterUsage.model'
import { roundToTwo } from '../../../../common/utils/helperFunctions'
import Device from '../../../../context/device/model/device'

import { useDeviceState } from '../../../../context/device/context/device.context'
import { Trend, TrendData } from '../../../../context/waterUsage/model/usageStats.model'

import 'bootstrap/dist/css/bootstrap.css'
import './layout.scss'

export function UsageSummary({
  filteredAggStats,
  trendData,
}: {
  filteredAggStats: any
  trendData: TrendData
}) {
  const { filteredWaterUsageStats, mainMetersWaterUsageStats, loading } = useWaterUsageState()
  const { devices, loadingDevices } = useDeviceState()

  const [yearlyUsage, setYearlyUsage] = useState<YearlyStatsTotal>({
    cold: null,
    hot: null,
    total: null,
  })
  const [trendUp, setTrendUp] = useState<Trend>({ hot: 0, cold: 0, total: 0 })

  useEffect(() => {
    const totalYearlyUsage: YearlyStatsTotal = Object.values(mainMetersWaterUsageStats).reduce(
      (acc: YearlyStatsTotal, stat: WaterUsageStats) => {
        const d: Device | undefined = devices.find((d) => d.deviceId === stat.deviceId)
        return {
          cold:
            stat.yearlyUsage && !d?.deviceSettings.hot
              ? acc.cold === null
                ? stat.yearlyUsage
                : acc.cold + stat.yearlyUsage
              : acc.cold,
          hot:
            stat.yearlyUsage && d?.deviceSettings.hot
              ? acc.hot === null
                ? stat.yearlyUsage
                : acc.hot + stat.yearlyUsage
              : acc.hot,
          total: stat.yearlyUsage
            ? acc.total === null
              ? stat.yearlyUsage
              : acc.total + stat.yearlyUsage
            : acc.total,
        }
      },
      { cold: null, hot: null, total: null },
    )

    setYearlyUsage(totalYearlyUsage)
  }, [filteredWaterUsageStats])

  useEffect(() => {
    const currentMonthNoDays: number = moment.utc().daysInMonth()
    const previousMonthNoDays: number = moment.utc().subtract(1, 'months').daysInMonth()

    const currMonthAvg = trendData.currMonth / currentMonthNoDays
    const prevMonthAvg = trendData.prevMonth / previousMonthNoDays
    const trendTotal: number =
      currMonthAvg === prevMonthAvg ? 0 : currMonthAvg > prevMonthAvg ? 2 : 1

    const currMonthAvgHot = trendData.currMonthHot ? trendData.currMonthHot / currentMonthNoDays : 0
    const prevMonthAvgHot = trendData.prevMonthHot
      ? trendData.prevMonthHot / previousMonthNoDays
      : 0
    const trendHot: number =
      currMonthAvgHot === prevMonthAvgHot ? 0 : currMonthAvgHot > prevMonthAvgHot ? 2 : 1

    const currMonthAvgCold = trendData.currMonthCold
      ? trendData.currMonthCold / currentMonthNoDays
      : 0
    const prevMonthAvgCold = trendData.prevMonthCold
      ? trendData.prevMonthCold / previousMonthNoDays
      : 0
    const trendCold: number =
      currMonthAvgCold === prevMonthAvgCold ? 0 : currMonthAvgCold > prevMonthAvgCold ? 2 : 1
    setTrendUp({ total: trendTotal, hot: trendHot, cold: trendCold })
  }, [trendData])

  return (
    <Card className="shadow summary-card card-bg-colour">
      <Card.Body>
        <div>
          <Row className={'pt-1'}>
            <Col md={8}>
              <h4 className={'text-secondary font-weight-bold'}>Consumed</h4>
            </Col>
            <Col md={4}>
              <GiTap size={50} color={'blue'} />
            </Col>
          </Row>
        </div>

        {loading || loadingDevices ? (
          <Container fluid className={'px-0'}>
            <Row className={'no-gutters align-items-center'}>
              <Col>
                <div className="d-flex justify-content-center m-auto h-100">
                  <div className="spinner-border m-auto">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <div>
              <div>
                <h4 className={'text-dark'}>
                  <b>
                    {yearlyUsage.total
                      ? `${roundToTwo(yearlyUsage.total / 1000).toLocaleString(
                          'en-GB',
                        )}m${String.fromCharCode(179)}`
                      : null}
                  </b>
                </h4>
              </div>
              <div className={'border rounded shadow h-100 align-items-center card-div-bg-colour'}>
                <div className={'px-3 pt-2'}>
                  <h6 className={'text-dark'}>
                    <BsThermometerSnow color={'blue'}></BsThermometerSnow>
                    <span className={'px-2'}>
                      <strong>Cold</strong>{' '}
                      {yearlyUsage.cold
                        ? `${roundToTwo(yearlyUsage.cold / 1000).toLocaleString(
                            'en-GB',
                          )}m${String.fromCharCode(179)}`
                        : 'No data available'}
                    </span>
                    {yearlyUsage.cold === null ? null : trendUp.cold === 0 ? (
                      <BsFillArrowRightCircleFill size={20} style={{ color: 'orange' }} />
                    ) : trendUp.cold === 2 ? (
                      <BsFillArrowUpCircleFill size={20} style={{ color: 'red' }} />
                    ) : (
                      <BsFillArrowDownCircleFill size={20} style={{ color: 'green' }} />
                    )}
                  </h6>
                </div>
                <div className={'px-3'}>
                  <h6 className={'text-dark'}>
                    <BsThermometerSun color={'red'}></BsThermometerSun>
                    <span className={'px-2'}>
                      <strong>Hot</strong>{' '}
                      {yearlyUsage.hot
                        ? `${roundToTwo(yearlyUsage.hot / 1000).toLocaleString(
                            'en-GB',
                          )}m${String.fromCharCode(179)}`
                        : 'No data available'}
                    </span>
                    {yearlyUsage.hot === null ? null : trendUp.hot === 0 ? (
                      <BsFillArrowRightCircleFill size={20} style={{ color: 'orange' }} />
                    ) : trendUp.hot === 2 ? (
                      <BsFillArrowUpCircleFill size={20} style={{ color: 'red' }} />
                    ) : (
                      <BsFillArrowDownCircleFill size={20} style={{ color: 'green' }} />
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className={'pt-2'}>
              <h6 className={'text-dark'}>
                {yearlyUsage.total === null ? null : trendUp.total === 0 ? (
                  <div
                    className={
                      'd-flex border rounded shadow h-100 align-items-center card-div-bg-colour'
                    }
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowRightCircleFill size={30} style={{ color: 'orange' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trend unchanged</strong>
                      </div>
                    </div>
                  </div>
                ) : trendUp.total === 2 ? (
                  <div
                    className={
                      'd-flex border rounded shadow h-100 align-items-center card-div-bg-colour'
                    }
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowUpCircleFill size={30} style={{ color: 'red' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending up</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      'd-flex border rounded shadow h-100 align-items-center card-div-bg-colour'
                    }
                  >
                    <div className={'px-3 py-2 h-100'}>
                      <BsFillArrowDownCircleFill size={30} style={{ color: 'green' }} />
                    </div>
                    <div className={'h-100 py-2'}>
                      <div>
                        <strong>Trending down</strong> on{' '}
                      </div>
                      <div>
                        <strong>monthly average</strong>
                      </div>
                    </div>
                  </div>
                )}
              </h6>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

import { Permission } from '../../context/user/model/user'
import Device, { TestDevice } from '../../context/device/model/device'
import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from './constants'
import { FaBan } from 'react-icons/fa'
import moment from 'moment-timezone'

export const filterObjectByArrayOfProp = (
  objToFilter: any,
  arrayOfProps: Array<string>,
  propName: string,
) => {
  const filtered: any = {}
  Object.entries(objToFilter).forEach(([key, value]: any) => {
    for (let i = 0; i < arrayOfProps.length; i += 1) {
      if (value[propName] === arrayOfProps[i]) {
        filtered[key] = value
      }
    }
  })
  return filtered
}

export const filterObjByProps = (objToFilter: any, arrayOfProps: Array<string>) => {
  return Object.keys(objToFilter)
    .filter((key) => arrayOfProps.includes(key))
    .reduce((obj: any, key: string) => {
      obj[key] = objToFilter[key]
      return obj
    }, {})
}

export function roundToTwo(num: number) {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

export const itemFromPath = (path: string, dataObject: any) => {
  return path.split('.').reduce((p, c) => (p && p[c]) || null || false, dataObject)
}

export const sortObjectsByKey = (objArray: any, key: string, order: string = 'asc') => {
  return objArray.sort((a: any, b: any) => {
    // const test = key.split('.').reduce((xs: any, x: any) => (xs && xs[x]) ? xs[x] : null, a)
    const firstItem = itemFromPath(key, a)
    const lastItem = itemFromPath(key, b)

    // const x = a[key]?.length ? a[key].length : a[key];
    // const y = b[key]?.length ? b[key].length : b[key];
    const x = firstItem?.length ? firstItem.length : firstItem
    const y = lastItem?.length ? lastItem.length : lastItem
    if (order.toLowerCase() === 'asc') {
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
    } else if (order.toLowerCase() === 'desc') {
      if (x > y) {
        return -1
      }
      if (x < y) {
        return 1
      }
    } else {
    }
    return 0
    // return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  })
}

export const getRandomDarkColor = () => {
  let letters = '012345'.split('')
  let color = '#'
  color += letters[Math.round(Math.random() * 5)]
  letters = '0123456789ABCDEF'.split('')
  for (let i = 0; i < 5; i++) {
    color += letters[Math.round(Math.random() * 15)]
  }
  return color
}

export const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase()

  return '00000'.substring(0, 6 - c.length) + c
}

export const hexToRgb = (hex: string) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const componentToHex = (c: number) => {
  let hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

// Examples:
// - P01_0005Z_95(REMOTE) Sep  3 2020 15:22:21
// - P01_0005Z_79(210)
// - P01_0005Z_87(2000)_TEST_RTCC Jul 14 2020 09:11:55
// - SM_V3_016 Feb 11 2021 14:50:43 Feb 11 2021 7 2 1
export const formatFirmwareName = (firmware: string) => {
  let formattedFirmware = ''
  if (firmware) {
    formattedFirmware = firmware
    let fw_prefix = ''
    // If it's V2
    if (firmware.includes('P01')) {
      fw_prefix = 'P01_0005Z_'
    } else {
      fw_prefix = 'SM_V3_'
    }
    const substr = firmware.split(fw_prefix)[1]
    const match = substr.match(/\d+/)
    if (match) {
      const versionNumber = match[0]
      formattedFirmware = `${fw_prefix}${versionNumber}`
    }
  }
  return formattedFirmware
}

export const capitalizeFirstLetter = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || null

export const formatPercentage = (value: number) =>
  Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)

export const validateEmailStructure = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePasswordStructure = (password: string) => {
  let isItValid = false
  if (password) {
    // const re = `^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`
    const re = `(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!\\"#$%&'()*+,-./:;<=>?@£¬\\[\\]^_\`{|}~]).{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`
    const regularExp = new RegExp(re)
    isItValid = regularExp.test(password)
  }
  return isItValid
}

export const validatePhoneNumberStructure = (phone: string) => {
  const phoneNumberRegex = /^(?:\+\d{1,3}\s?)?\d{10,14}$/
  const isValidPhoneNumber = phoneNumberRegex.test(phone)

  return isValidPhoneNumber
}

export const isArrayContained = (arr: string[], target: string[]) =>
  target.every((v) => arr.includes(v))

// export const hasPermissions = (permissions: Permission[], requiredPermissions: string[]) => {
//   return requiredPermissions.length === 0 || permissions.findIndex(p => requiredPermissions.includes(p.name)) > -1;
// }

export const hasPermissions = (permissions: Permission[], requiredPermissions: string[]) => {
  // Test if user is assigned correct permission in the requiredPermissions array
  // Permission in the array seperated by '||' are handled as an OR case eg, ['role1||role2', role3], User must have (role1 OR role2) AND role 3
  return (
    requiredPermissions.length === 0 ||
    requiredPermissions.every((e) => {
      let authorized: boolean
      const permissionNames = permissions.map((p) => p.name)
      const arrayOfPermissions = e.split('||')
      if (arrayOfPermissions.length > 1) {
        // User must have at least 1 of the permissions present in arrayOfPermissions
        authorized = arrayOfPermissions.some((p) => permissionNames.includes(p))
      } else {
        // User must have this permission
        authorized = permissionNames.includes(e)
      }
      return authorized
    })
  )
}

export const formatCurrency = (value: number, currency: string) =>
  value.toLocaleString('en-GB', {
    style: 'currency',
    currency,
  })

export const ValidateEmail = (input: string) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !!input.match(validRegex)
}

export const filterDevicesDueTo30DaysReport = (devices: Device[]) => {
  return devices.filter(
    (device) =>
      !device.deviceSettings.report30Day &&
      moment() >= moment(device.deviceSettings.monitoringStartDate).add(30, 'days'),
  )
}

export const filterNewlyInstalledDevices = (devices: Device[]) => {
  const today = moment()
  const lastWeek = today.clone().subtract(7, 'days')

  return devices.filter((device) => {
    const installDate = moment(device.deviceSettings.installDate)
    return installDate.isBetween(lastWeek, today, 'day', '[]')
  })
}

export const getBatteryPercentage = (batteryLevel: number, vendor: string) => {
  let battPct = batteryLevel
  switch (vendor) {
    case 'SMARTFLOW': {
      const maxV = 7000
      const vBatt = batteryLevel > maxV ? 7000 : batteryLevel
      const maxVDelta = 2000
      const vDelta = maxVDelta - (maxV - vBatt)
      battPct = Math.round((vDelta < 0 ? 0 : vDelta / maxVDelta) * 100)
      return battPct
    }
    case 'METASPHERE': {
      const maxV = 7010
      const vBatt = batteryLevel > maxV ? 7010 : batteryLevel
      const maxVDelta = 1500
      const vDelta = maxVDelta - (maxV - vBatt)
      battPct = Math.round((vDelta / maxVDelta) * 100)
      return battPct
    }
    default:
      return battPct
  }
}

export const getBatteryIconClass = (batteryPct: number) => {
  let className = 'fas fa-lg fa-'
  if (batteryPct >= 90) {
    className += 'battery-full'
  } else if (batteryPct >= 75) {
    className += 'battery-three-quarters'
  } else if (batteryPct >= 50) {
    className += 'battery-half'
  } else if (batteryPct >= 25) {
    className += 'battery-quarter'
  } else {
    className += 'battery-empty'
  }
  return className
}

export const getSignalStatus = (signalLevel: number) => {
  let className: string
  if (signalLevel >= 21) {
    className = 'good four-bars'
  } else if (signalLevel >= 15) {
    className = 'ok three-bars'
  } else if (signalLevel >= 10) {
    className = 'bad two-bars'
  } else {
    className = 'bad one-bar'
  }
  return (
    <div className={`signal-bars mt1 sizing-box good four-bars ${className}`}>
      <div className="first-bar bar"></div>
      <div className="second-bar bar"></div>
      <div className="third-bar bar"></div>
      <div className="fourth-bar bar"></div>
      {/* <div className='fifth-bar bar'></div> */}
    </div>
  )
}

export const getBatteryStatus = (device: Device | TestDevice) => {
  let status = ''
  if (device.deviceVendor == 'METASPHERE') {
    if (device.batteryLevel && device.batteryLevelExt) {
      // MetaSphere devices
      if (device.batteryLevelExt > 5500) {
        status = 'External Battery'
      } else {
        status = 'Internal Battery'
      }
    } else {
      status = 'Internal Battery'
    }
  } else if (device.batteryStatus) {
    // Possible status: BATTERY_LOW, BATTERY_OK, BATTERY_FULL, DC_POWER_ON for SF Hubs
    if (device.batteryStatus.toUpperCase() === 'DC_POWER_ON') {
      status = 'Plugged'
    } else {
      status = 'Internal Battery'
    }
  }
  return status
}

export const getDeviceStatus = (
  device: Device | TestDevice,
  hasDashboardStatusViewPermission: boolean,
  hasUserAdminSmartFlowPermission: boolean,
) => {
  if (device.deviceSettings?.uploadFreqMins) {
    if (!device.lastOnline) {
      return <FaBan />
    } else {
      const interval = hasDashboardStatusViewPermission
        ? device.deviceSettings?.uploadFreqMins * 2
        : device.deviceSettings?.uploadFreqMins * 12
      if (moment.utc().diff(device.lastOnline, 'minutes') >= interval) {
        if (hasUserAdminSmartFlowPermission) {
          return (
            <>
              Offline
              <small style={{ fontSize: '0.7em' }}>{` (last seen: ${moment(
                device.lastOnline,
              ).fromNow()})`}</small>
            </>
          )
        } else {
          return 'Offline'
        }
      } else {
        return 'Online'
      }
    }
  }
}

export const compareObjects = (obj1: any, obj2: any) => {
  const changes = []

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        changes.push(`${key} was updated from "${obj1[key]}" to "${obj2[key]}"`)
      }
    }
  }

  return changes
}

import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import moment from 'moment-timezone'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Moment } from 'moment/moment'
import { IDateRange } from './interfaces'

const TODAY: Moment = moment()

const DateRangeComponent: React.FC<IDateRange> = ({
  from,
  to,
  fromCallback,
  toCallback,
  minDate,
  isClearable = false,
  label,
}: IDateRange) => {
  const [fromDate, setFromDate] = useState<Date | null>(from)
  const [toDate, setToDate] = useState<Date | null>(to)

  useEffect(() => {
    const from = moment(fromDate)
    const to = moment(toDate)
    if (from.isSameOrAfter(to)) {
      setFromDate(to.subtract(1, 'days').toDate())
    }
  }, [toDate])

  useEffect(() => {
    fromCallback(fromDate)
  }, [fromDate])

  useEffect(() => {
    toCallback(toDate)
  }, [toDate])

  const handleFromRangeChange = (date: Date) => {
    if (date) {
      if (date.getTime() !== fromDate?.getTime()) {
        setFromDate(date)
      }
    } else {
      setFromDate(null)
    }
  }

  const handleToRangeChange = (date: Date) => {
    if (date) {
      if (date.getTime() !== toDate?.getTime()) {
        setToDate(date)
      }
    } else {
      setToDate(null)
    }
  }

  return (
    <div className="container p-0">
      <div className="date-range-selector">
        <div className="col-md-12 main-content p-0">
          <div className="range">
            <div className="col-md-3 col-sm-12 section-header p-0">
              <div className="">{label || 'Range'}:</div>
            </div>
            <div className="row" style={{ textAlignLast: 'justify' }}>
              <div className="col-md-3 col-sm-6 date-picker mt-2">
                <span>From: </span>
                <DatePicker
                  selected={
                    minDate ? (fromDate && fromDate > minDate ? fromDate : minDate) : fromDate
                  }
                  onChange={handleFromRangeChange}
                  maxDate={TODAY.toDate()}
                  minDate={minDate}
                  dateFormat="dd/MM/yyyy"
                  isClearable={isClearable}
                />
              </div>
              <div className="col-md-3 col-sm-6 date-picker mt-2">
                <span>To: </span>
                <DatePicker
                  selected={toDate}
                  onChange={handleToRangeChange}
                  maxDate={TODAY.toDate()}
                  dateFormat="dd/MM/yyyy"
                  isClearable={isClearable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DateRangeComponent

import React, { useEffect, useState } from 'react'

interface State {
  shouldShowSideBar: boolean;
  screenType: string;
}

const StateContext = React.createContext<State>({screenType: 'desktop', shouldShowSideBar: true})
const DispatchContext = React.createContext(undefined as any)

export const LayoutContextProvider = ({ children }: any) => {
  const [screenType, setScreenType] = useState(getScreenTypeBySize(window.innerWidth));
  const [shouldShowSideBar, setShouldShowSideBar] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", () => {
      const type = getScreenTypeBySize(window.innerWidth)
      setScreenType(type);
    }, false);
  }, [screenType]);

  const toggleSideBar = React.useCallback(() => setShouldShowSideBar(shouldShowSideBar => !shouldShowSideBar), []);

  return (
    <StateContext.Provider value={{screenType, shouldShowSideBar}}>
      <DispatchContext.Provider value={{toggleSideBar}}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useLayoutState = () => {
  return React.useContext(StateContext)
}

export const useLayoutDispatch = () => {
  const { toggleSideBar } = React.useContext(DispatchContext);
  const toggleSideBarCallback = React.useCallback(
    () => toggleSideBar(), [toggleSideBar]);

  return React.useMemo(() => ({
    toggleSideBar: toggleSideBarCallback}
  ), [toggleSideBarCallback])
}


// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV
function getScreenTypeBySize(screenWidth: number) {
  let type = 'desktop';
  if (screenWidth > 1000) {
    type = 'desktop';
  } else if (screenWidth > 700) {
    type = 'laptop';
  } else if (screenWidth > 480) {
    type = 'tablet';
  } else {
    type = 'mobile';
  }
  return type;
}
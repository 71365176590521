import { WaterUsageQuery } from "../waterUsage.action";
import { AggStatByDeviceID } from "../model/usageStats.model"
import Device from "../../device/model/device"

interface State {
    monthlyAggStats: {[key: string]: {}},
    filteredMonthlyAggStats: {[key: string]: {}},
    monthlyLeakStats: {[key: string]: {}},
    updatingMonthlyLeakStats: boolean,
    loadingMonthlyLeakStats: boolean,
    filteredMonthlyLeakStats: {[key: string]: {}},
    loading: boolean,
    updating: boolean,
    error: boolean,
}

export const initialState: State = {
    monthlyAggStats: {},
    filteredMonthlyAggStats: {},
    monthlyLeakStats: {},
    filteredMonthlyLeakStats: {},
    loading: false,
    loadingMonthlyLeakStats: false,
    updatingMonthlyLeakStats: false,
    updating: false,
    error: false,
}


export enum ActionTypes {
    LOADED_MONTHLY_AGG_STATS,
    FILTER_MONTHLY_AGG_STATS,
    LOADED_MONTHLY_LEAK_STATS,
    FILTER_MONTHLY_LEAK_STATS,
    LOADING,
    UPDATING,
    ERROR,
}
export interface Action {
    type: ActionTypes;
    payload?: { query?: WaterUsageQuery, data?: any, devices?: any, updating?: string };
}

export const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionTypes.LOADED_MONTHLY_AGG_STATS: {
            if (!action.payload?.data) return state;
            const {payload: { data }} = action;

            const monthlyAggStats: AggStatByDeviceID = data.reduce((acc: any, currentVal: any) => {
                if (!acc[currentVal.device_id]) {
                    acc[currentVal.device_id] = {};
                }

                if (!acc[currentVal.device_id][currentVal.Year]) {
                    acc[currentVal.device_id][currentVal.Year] = {};
                }
                if (!acc[currentVal.device_id][currentVal.Year][currentVal.time_value]) {
                    acc[currentVal.device_id][currentVal.Year][currentVal.time_value] = {};
                }
                acc[currentVal.device_id][currentVal.Year][currentVal.time_value]["monthlyUsage"] = currentVal.Usage;
                acc[currentVal.device_id][currentVal.Year][currentVal.time_value]["monthlyAverage"] = currentVal.AvgUsage;
                return acc
            }, {})
            return {...state, monthlyAggStats: monthlyAggStats, loading: false, updating: false, error: false}
        }
        case ActionTypes.FILTER_MONTHLY_AGG_STATS: {
            if (!action.payload) return state;
            const { monthlyAggStats } = state;
            // const { devices } = action.payload
            if (Object.keys(monthlyAggStats).length === 0) return  state;

            // const filteredData = Object.keys(aggStats).filter(deviceId => {
            //     const d: Device | undefined = devices.find((d: Device) => d.deviceId === deviceId);
            //     if (d) return deviceId;
            // }).reduce((cur, key) => {
            //     return Object.assign(cur, { [key]: aggStats[key] })
            // }, {});
            return {...state, updating: false}
        }
        case ActionTypes.LOADED_MONTHLY_LEAK_STATS: {
            if (!action.payload?.data) {
                return {...state, loadingMonthlyLeakStats: false};
            }
            const { payload: { data } } = action;
            const monthlyLeakStats: any = data.reduce((acc: any, currentVal: any) => {

                if (!acc[currentVal.dl_id]) {
                    acc[currentVal.dl_id] = {};
                }

                if (!acc[currentVal.dl_id][currentVal.year]) {
                    acc[currentVal.dl_id][currentVal.year] = {};
                }

                if (!acc[currentVal.dl_id][currentVal.year][currentVal.time_value]) {
                    acc[currentVal.dl_id][currentVal.year][currentVal.time_value] = currentVal.value;
                }
                return acc
            }, {})
            return {...state, monthlyLeakStats: monthlyLeakStats, filteredMonthlyLeakStats: monthlyLeakStats, loading: false, loadingMonthlyLeakStats: false}
        }
        case ActionTypes.FILTER_MONTHLY_LEAK_STATS: {
            if (!action.payload) return {...state, updatingMonthlyLeakStats: false};
            const { monthlyLeakStats } = state;
            const { devices } = action.payload
            if (Object.keys(monthlyLeakStats).length === 0) return {...state, updatingMonthlyLeakStats: false};

            // const filteredData = Object.keys(monthlyLeakStats).filter(dlId => {
            //     const d: Device | undefined = devices.find((d: Device) => d.dlId?.toString() === dlId);
            //     if (d) return dlId;
            // })
            // .reduce((acc, key) => {
            //     return Object.assign(acc, { [key]: monthlyLeakStats[key] })
            // }, {});

            const filteredData = Object.keys(monthlyLeakStats).reduce((acc, key) => {
                const d: Device | undefined = devices.find((d: Device) => d.dlId?.toString() === key);
                if (d) {
                    return Object.assign(acc, { [key]: monthlyLeakStats[key] })
                } else {
                    return acc
                }
            }, {});

            return {...state, filteredMonthlyLeakStats: filteredData, loading: false, updating: false, updatingMonthlyLeakStats: false}
        }
        case ActionTypes.LOADING: {
            return {...state, loading: true, loadingMonthlyLeakStats: true, updating: false, error: false};
        }
        case ActionTypes.UPDATING: {
            if (action.payload?.updating) {
                return  {...state, loading: false, updating: true, [action.payload?.updating]: true, error: false};
            } else {
                return state
            }
            // return {...state, loading: false, updating: true, error: false};
        }
        case ActionTypes.ERROR: {
            return {...state, loading: false, error: true};
        }
        default:
            return state
    }
}

// function extractFlow(data: any[], key: string): HourlyUsage[] {
//     return data
//         .map((usage) => {
//             return {
//                 date: usage.date,
//                 value: key === "upper_flow_limit" ? usage[key] : usage[key] / 1,
//             }
//
//         });
// }

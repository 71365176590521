import { WaterUsageQuery } from './waterUsage.action'
import {
  Aggregator,
  HourlyUsage,
  deviceWaterUsage,
  WaterUsage,
  WaterUsageStats,
  WaterUsageCosts,
  SingleDeviceWaterUsage,
  MultipleDevicesWaterUsage,
} from './waterUsage.model'
import { formatCurrency, roundToTwo } from '../../common/utils/helperFunctions'
import Device from '../device/model/device'
import moment from 'moment-timezone'

interface State {
  waterUsageStats: { [key: string]: WaterUsageStats }
  aggStats: { [key: string]: {} }
  filteredMonthlyStats: { [key: string]: {} }
  filteredWaterUsageStats: { [key: string]: WaterUsageStats }
  mainMetersWaterUsageStats: { [key: string]: WaterUsageStats }
  subMetersWaterUsageStats: { [key: string]: WaterUsageStats }
  filteredWaterUsageCosts: { [key: string]: WaterUsageStats }
  mainMetersWaterUsageCosts: { [key: string]: WaterUsageStats }
  subMetersWaterUsageCosts: { [key: string]: WaterUsageStats }
  waterUsageCosts: { [key: string]: WaterUsageCosts }
  alertWaterUsage: { [key: string]: WaterUsage }
  singleDeviceUsage: SingleDeviceWaterUsage
  multipleDeviceUsage: MultipleDevicesWaterUsage
  singleDeviceTotalUsage: number | null
  loading: boolean
  error: boolean
}

export const initialState: State = {
  waterUsageStats: {},
  aggStats: {},
  filteredMonthlyStats: {},
  filteredWaterUsageStats: {},
  mainMetersWaterUsageStats: {},
  subMetersWaterUsageStats: {},
  filteredWaterUsageCosts: {},
  mainMetersWaterUsageCosts: {},
  subMetersWaterUsageCosts: {},
  waterUsageCosts: {},
  alertWaterUsage: {},
  singleDeviceUsage: { usage: [], totalUsage: null },
  multipleDeviceUsage: { usage: [], totalUsageByDevice: {}, totalUsage: null },
  singleDeviceTotalUsage: null,
  loading: false,
  error: false,
}

export enum ActionTypes {
  LOADED_ALERT_WATER_USAGE,
  LOADED_SINGLE_DEVICE_WATER_USAGE,
  LOADED_MULTIPLE_DEVICE_WATER_USAGE,
  LOADED_SINGLE_DEVICE_DAILY_WATER_USAGE,
  CLEAR_SINGLE_DEVICE_WATER_USAGE,
  UPDATING,
  ERROR,
  LOADED_WATER_USAGE_STATS,
  FILTER_WATER_USAGE_STATS,
  FILTER_WATER_USAGE_COST,
  LOADED_AGG_USAGE,
}
export interface Action {
  type: ActionTypes
  payload?: { query?: WaterUsageQuery; data?: any; devices?: any }
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOADED_ALERT_WATER_USAGE: {
      if (!action.payload || !action.payload.query) return state
      const { query } = action.payload
      const newWaterUsage = { ...state.alertWaterUsage }

      newWaterUsage[JSON.stringify(query)] = {
        ...query,
        aggregated: {
          [`${Aggregator.CURRENT.toString()}`]: extractFlow(action.payload.data, 'flow'),
          [`${Aggregator.ACTUAL_BASELINE.toString()}`]: extractFlow(action.payload.data, 'average'),
          [`${Aggregator.EXPECTED_BASELINE.toString()}`]: extractFlow(
            action.payload.data,
            'expected_baseline',
          ),
          [`${Aggregator.ONE_WEEK_PRIOR.toString()}`]: extractFlow(
            action.payload.data,
            'flow_7d_p',
          ),
          [`${Aggregator.BASELINE_UPPER_LIMIT.toString()}`]: extractFlow(
            action.payload.data,
            'upper_flow_limit',
          ),
          [`${Aggregator.NINETY_DAYS_WX.toString()}`]: extractFlow(
            action.payload.data,
            'average_w_excluded',
          ),
        },
      }
      return { ...state, alertWaterUsage: newWaterUsage, loading: false, error: false }
    }
    case ActionTypes.LOADED_WATER_USAGE_STATS: {
      if (!action.payload?.data || action.payload?.data.length !== 3) return state
      const statsArr = action.payload.data[0]
      const hourly = action.payload.data[1]
      const costs = action.payload.data[2]

      const hourlyMap: HourlyUsage[] = hourly.reduce((acc: any, h: any) => {
        if (!acc[h.device_id]) acc[h.device_id] = Array(24)
        acc[h.device_id][h.hour] = {
          date: `${h.date}`,
          value: h.flow,
        }
        return acc
      }, {})
      const waterUsageStatsArray: WaterUsageStats[] = statsArr.map((stats: any) => {
        return {
          deviceId: stats.device_id + '',
          dailyUsage: stats.Daily_Usage,
          avgDaily: stats.Avg_Daily,
          weeklyUsage: stats.Weekly_Usage,
          avgWeekly: stats.Avg_Weekly,
          monthlyUsage: stats.Monthly_Usage,
          avgMonthly: stats.Avg_Monthly,
          yearlyUsage: stats.Yearly_Usage,
          hourlyUsage: hourlyMap[stats.device_id] || Array(24),
        }
      })
      const waterUsageStats = waterUsageStatsArray.reduce(
        (agg: { [key: string]: WaterUsageStats }, curr) => {
          agg[curr.deviceId] = curr
          return agg
        },
        {},
      )
      const waterUsageCostsArray: WaterUsageCosts[] = costs.map((stats: any) => {
        return {
          deviceId: stats.device_id + '',
          dailyUsageFormatted: formatCurrency(stats.Daily_Usage, stats.Currency),
          avgDailyFormatted: formatCurrency(stats.Avg_Daily, stats.Currency),
          weeklyUsageFormatted: formatCurrency(stats.Weekly_Usage, stats.Currency),
          avgWeeklyFormatted: formatCurrency(stats.Avg_Weekly, stats.Currency),
          monthlyUsageFormatted: formatCurrency(stats.Monthly_Usage, stats.Currency),
          avgMonthlyFormatted: formatCurrency(stats.Avg_Monthly, stats.Currency),
          yearlyUsageFormatted: formatCurrency(stats.Yearly_Usage, stats.Currency),
          dailyUsage: stats.Daily_Usage,
          avgDaily: stats.Avg_Daily,
          weeklyUsage: stats.Weekly_Usage,
          avgWeekly: stats.Avg_Weekly,
          monthlyUsage: stats.Monthly_Usage,
          avgMonthly: stats.Avg_Monthly,
          yearlyUsage: stats.Yearly_Usage,
          hourlyUsage: [],
          currency: stats.Currency,
        }
      })
      const waterUsageCosts = waterUsageCostsArray.reduce(
        (agg: { [key: string]: WaterUsageStats }, curr) => {
          agg[curr.deviceId] = curr
          return agg
        },
        {},
      )
      return {
        ...state,
        waterUsageStats: waterUsageStats,
        filteredWaterUsageStats: {},
        waterUsageCosts: waterUsageCosts,
        loading: false,
        error: false,
      }
    }
    case ActionTypes.FILTER_WATER_USAGE_STATS: {
      const selectedDevices = action.payload?.devices
      const { waterUsageStats } = state
      if (!selectedDevices) return state
      if (!waterUsageStats) return state
      const filteredWaterUsageStats: WaterUsageStats | {} = Object.keys(waterUsageStats)
        .filter((deviceId: string) => selectedDevices.find((d: Device) => d.deviceId === deviceId))
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageStats[key] })
        }, {})

      const mainMetersWaterUsageStats: WaterUsageStats | {} = Object.keys(filteredWaterUsageStats)
        .filter((deviceId: string) =>
          selectedDevices.find(
            (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef === null,
          ),
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageStats[key] })
        }, {})

      const subMetersWaterUsageStats: WaterUsageStats | {} = Object.keys(filteredWaterUsageStats)
        .filter((deviceId: string) =>
          selectedDevices.find(
            (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef !== null,
          ),
        )
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageStats[key] })
        }, {})

      return {
        ...state,
        filteredWaterUsageStats: filteredWaterUsageStats,
        mainMetersWaterUsageStats: mainMetersWaterUsageStats,
        subMetersWaterUsageStats: subMetersWaterUsageStats,
      }
    }
    case ActionTypes.FILTER_WATER_USAGE_COST: {
      const selectedDevices = action.payload?.devices
      const { waterUsageCosts } = state
      if (!selectedDevices) return state
      if (!waterUsageCosts) return state

      const filteredWaterUsageCosts: WaterUsageStats | {} = Object.keys(waterUsageCosts)
        .filter((deviceId) => {
          const device: Device = selectedDevices.find((d: Device) => d.deviceId === deviceId)
          if (device) {
            return deviceId
          }
        })
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageCosts[key] })
        }, {})

      const mainMetersWaterUsageCosts: WaterUsageStats | {} = Object.keys(filteredWaterUsageCosts)
        .filter((deviceId) => {
          const device: Device = selectedDevices.find(
            (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef === null,
          )
          if (device) {
            return deviceId
          }
        })
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageCosts[key] })
        }, {})

      const subMetersWaterUsageCosts: WaterUsageStats | {} = Object.keys(filteredWaterUsageCosts)
        .filter((deviceId) => {
          const device: Device = selectedDevices.find(
            (d: Device) => d.deviceId === deviceId && d.deviceSettings.masterDeviceIdRef !== null,
          )
          if (device) {
            return deviceId
          }
        })
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: waterUsageCosts[key] })
        }, {})

      return {
        ...state,
        filteredWaterUsageCosts: filteredWaterUsageCosts,
        mainMetersWaterUsageCosts: mainMetersWaterUsageCosts,
        subMetersWaterUsageCosts: subMetersWaterUsageCosts,
      }
    }

    case ActionTypes.LOADED_SINGLE_DEVICE_WATER_USAGE: {
      if (!action.payload || !action.payload.query) return state
      // const singleDeviceWaterUsage = action.payload.data;
      // const usageInLiters = !singleDeviceWaterUsage.Flow ? JSON.parse(JSON.stringify(singleDeviceWaterUsage)) :
      //     (singleDeviceWaterUsage.Flow as any[]).slice(1).map((f, i) => {
      //         return {
      //             date: moment(action.payload!.query.from).utc().startOf('day').add(i, 'hour').format("YYYY-MM-DDTHH:mm:ss"),
      //             deviceId: action.payload!.query.deviceId,
      //             hour: i,
      //             flow: f,
      //         } as SingleDeviceWaterUsage
      //     });
      // singleDeviceWaterUsage.forEach((usage: deviceWaterUsage) => {
      //     usage.flow = roundToTwo(usage.flow);
      // });
      const singleDeviceWaterUsage = action.payload.data
      const usageInLiters = !singleDeviceWaterUsage.Flow
        ? JSON.parse(JSON.stringify(singleDeviceWaterUsage))
        : (singleDeviceWaterUsage.Flow as any[]).slice(1).map((f, i) => {
            return {
              date: moment(action.payload!.query!.from)
                .utc()
                .startOf('day')
                .add(i, 'hour')
                .format('YYYY-MM-DDTHH:mm:ss'),
              deviceId: action.payload!.query!.deviceId,
              hour: i,
              flow: f,
            }
          })

      usageInLiters.forEach((usage: deviceWaterUsage) => {
        usage.flow = roundToTwo(usage.flow)
      })
      const totalUsage = roundToTwo(
        usageInLiters.reduce((acc: number, usage: deviceWaterUsage) => {
          return acc + usage.flow
        }, 0),
      )
      const singleDeviceUsage: SingleDeviceWaterUsage = {
        usage: usageInLiters,
        totalUsage: totalUsage,
      }
      return { ...state, singleDeviceUsage: singleDeviceUsage, loading: false, error: false }
    }

    case ActionTypes.LOADED_MULTIPLE_DEVICE_WATER_USAGE: {
      if (!action.payload || !action.payload.query) return state
      const multipleDeviceWaterUsage = action.payload.data

      const totalUsageOnDevices = multipleDeviceWaterUsage.map((usage: deviceWaterUsage[]) => {
        return roundToTwo(
          usage.reduce((acc: number, usage) => {
            return acc + usage.flow
          }, 0),
        )
      })

      const totalUsageByDevices: Record<string, number> = {}

      multipleDeviceWaterUsage.forEach((usageArray: deviceWaterUsage[]) => {
        usageArray.forEach((usage: deviceWaterUsage) => {
          const { device_id, flow } = usage
          if (totalUsageByDevices.hasOwnProperty(device_id)) {
            totalUsageByDevices[device_id] += flow
          } else {
            totalUsageByDevices[device_id] = flow
          }
        })
      })

      const totalUsage = totalUsageOnDevices.reduce((acc: number, curr: number) => acc + curr, 0)

      const multipleDeviceUsage: MultipleDevicesWaterUsage = {
        usage: multipleDeviceWaterUsage,
        totalUsageByDevice: totalUsageByDevices,
        totalUsage: totalUsage,
      }

      return { ...state, multipleDeviceUsage: multipleDeviceUsage, loading: false, error: false }
    }
    case ActionTypes.LOADED_AGG_USAGE: {
      if (!action.payload?.data) return state
      const {
        payload: { data },
      } = action

      // interface AggStat {
      //     [key: string]: {
      //         [key: string]: {
      //             [key: string]: {
      //                 [key: string]: {
      //                     [key: string]: {
      //                         "monthlyUsage": number
      //                         "monthlyAverage": number
      //                     }
      //                 }
      //             }
      //         }
      //     };
      // }

      const monthlyAggStats = data.reduce((acc: any, currentVal: any) => {
        if (!acc[currentVal.dl_id]) {
          acc[currentVal.dl_id] = {}
        }

        if (!acc[currentVal.dl_id][currentVal.Year]) {
          acc[currentVal.dl_id][currentVal.Year] = {}
        }

        if (!acc[currentVal.dl_id][currentVal.Year][currentVal.time_value]) {
          acc[currentVal.dl_id][currentVal.Year][currentVal.time_value] = currentVal.Usage
        }

        return acc
      }, {})
      return { ...state, aggStats: monthlyAggStats, loading: false, error: false }
    }
    case ActionTypes.LOADED_SINGLE_DEVICE_DAILY_WATER_USAGE: {
      if (!action.payload) return state
      const singleDeviceDailyWaterUsage = action.payload.data
      const dailyUsageInLiters = JSON.parse(JSON.stringify(singleDeviceDailyWaterUsage))
      dailyUsageInLiters.reverse()

      dailyUsageInLiters.forEach((usage: any) => {
        Object.keys(usage).forEach((key) => {
          if (key.toLowerCase().includes('totalflow')) {
            usage[key] = roundToTwo(usage[key])
          }
        })
      })
      const dailyUsage: deviceWaterUsage[] = dailyUsageInLiters
      return { ...state, singleDeviceDailyUsage: dailyUsage, loading: false, error: false }
    }
    case ActionTypes.CLEAR_SINGLE_DEVICE_WATER_USAGE: {
      return {
        ...state,
        singleDeviceUsage: { usage: [], totalUsage: null },
        loading: false,
        error: false,
      }
    }
    case ActionTypes.UPDATING: {
      return { ...state, loading: true, error: false }
    }
    case ActionTypes.ERROR: {
      return { ...state, loading: false, error: true }
    }
    default:
      return state
  }
}

function extractFlow(data: any[], key: string): HourlyUsage[] {
  return data.map((usage) => {
    return {
      date: usage.date,
      value: key === 'upper_flow_limit' ? usage[key] : usage[key] / 1,
    }
  })
}

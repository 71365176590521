import { Table, Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { useEffect, useState, useRef } from 'react'
import { useDeviceState, useDeviceDispatch } from '../../../context/device/context/device.context'
import { useUserState } from '../../../context/user/context/user.context'
import { useWaterUsageState } from '../../../context/waterUsage/waterUsageStats.context'
import { hasPermissions } from '../../../common/utils/helperFunctions'
import { roundToTwo, formatCurrency } from '../../../common/utils/helperFunctions'
import Device, { DeviceIDTypes } from '../../../context/device/model/device'
import SectorIcon from '../../general/SectorIcon/SectorIcon'
import { buildDeviceCostsPdfFile } from './helper-functions'
import jsPDF from 'jspdf'
import moment from 'moment-timezone'
import { CSVLink } from 'react-csv'
import { COLUMNS } from './constants'

interface CostTotalByCurrency {
  [currency: string]: {
    dailyUsage: number
    avgDaily: number
    weeklyUsage: number
    avgWeekly: number
    monthlyUsage: number
    avgMonthly: number
    yearlyUsage: number
  }
}

export function DeviceCostComponent() {
  const { loading, waterUsageCosts } = useWaterUsageState()
  const { updateDeviceData } = useDeviceDispatch()
  const { devices, deviceUpdating } = useDeviceState()
  const { permissions } = useUserState()

  const [usageTotalByCurency, setUsageTotalByCurrency] = useState<CostTotalByCurrency>()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState<Device>()
  const [selectedDeviceCost, setSelectedDeviceCost] = useState<number | undefined>(undefined)
  const [didDeviceInfoChange, setDidDeviceInfoChange] = useState(false)
  const [csvData, setCsvData] = useState<any>([])

  const tableRef = useRef(null)

  useEffect(() => {
    const usageTotalByCurrency = Object.values(waterUsageCosts).reduce(
      (accumulator: any, deviceData) => {
        const device = devices.find((d) => d.deviceId === deviceData.deviceId)
        if (!device) return accumulator

        const {
          dailyUsage,
          avgDaily,
          weeklyUsage,
          avgWeekly,
          monthlyUsage,
          avgMonthly,
          yearlyUsage,
          currency,
        } = deviceData

        if (currency) {
          if (!accumulator[currency]) {
            accumulator[currency] = {
              dailyUsage: 0,
              avgDaily: 0,
              weeklyUsage: 0,
              avgWeekly: 0,
              monthlyUsage: 0,
              avgMonthly: 0,
              yearlyUsage: 0,
            }
          }
          accumulator[currency].dailyUsage += +dailyUsage
          accumulator[currency].avgDaily += +avgDaily
          accumulator[currency].weeklyUsage += +weeklyUsage
          accumulator[currency].avgWeekly += +avgWeekly
          accumulator[currency].monthlyUsage += +monthlyUsage
          accumulator[currency].avgMonthly += +avgMonthly
          accumulator[currency].yearlyUsage += +yearlyUsage

          return accumulator
        }
      },
      {} as CostTotalByCurrency,
    )

    setUsageTotalByCurrency(usageTotalByCurrency)
  }, [waterUsageCosts, devices])

  useEffect(() => {
    if (selectedDevice && selectedDevice.deviceSettings) {
      if (selectedDevice.deviceSettings.cost !== selectedDeviceCost && selectedDeviceCost !== 0) {
        // Some value has changed
        setDidDeviceInfoChange(true)
      } else {
        setDidDeviceInfoChange(false)
      }
    }
  }, [selectedDeviceCost])

  const onHide = () => {
    setSelectedDeviceCost(undefined)
    setIsModalOpen(false)
  }

  const openModal = (device: Device) => {
    const deviceCost = device.deviceSettings.cost

    setSelectedDeviceCost(deviceCost)
    setSelectedDevice(device)

    setIsModalOpen(true)
  }

  const handleUpdateDeviceInfo = async () => {
    if (!selectedDevice) return

    const updatedDevice = { ...selectedDevice }
    updatedDevice.deviceSettings.cost = Number(selectedDeviceCost)

    if (updatedDevice.dUUID) {
      await updateDeviceData(updatedDevice?.dUUID, DeviceIDTypes.dUUID, updatedDevice)
    }

    setDidDeviceInfoChange(false)
  }

  const downloadPdfDocument = () => {
    const tableHTML = tableRef.current
    if (!tableHTML) return

    buildDeviceCostsPdfFile(tableHTML).then((pdf: jsPDF) => {
      pdf.save(`Devices_Costs_${moment().format('DD_MM_YYYY')}`)
    })
  }

  const downloadCsvFile = () => {
    const costData = Object.values(waterUsageCosts)
      .map((cost) => {
        const device = devices.find((d) => d.deviceId === cost.deviceId)

        return {
          ...cost,
          device,
        }
      })
      .filter((cost) => !!cost?.device?.deviceName)
      .map((cost) => {
        return [
          cost?.device?.deviceName,
          cost.dailyUsageFormatted,
          cost.avgDailyFormatted,
          cost.weeklyUsageFormatted,
          cost.avgWeeklyFormatted,
          cost.monthlyUsageFormatted,
          cost.avgMonthlyFormatted,
          cost.yearlyUsageFormatted,
        ]
      })

    const totalData =
      usageTotalByCurency &&
      Object.entries(usageTotalByCurency).map(([currency, values]) => {
        return [
          `Total Cost (${currency})`,
          formatCurrency(roundToTwo(values.dailyUsage), currency),
          formatCurrency(roundToTwo(values.avgDaily), currency),
          formatCurrency(roundToTwo(values.weeklyUsage), currency),
          formatCurrency(roundToTwo(values.avgWeekly), currency),
          formatCurrency(roundToTwo(values.monthlyUsage), currency),
          formatCurrency(roundToTwo(values.avgMonthly), currency),
          formatCurrency(roundToTwo(values.yearlyUsage), currency),
        ]
      })

    const finalData = [
      [''],
      ['Devices Costs'],
      [''],
      [...Object.values(COLUMNS).map((column) => column.name)],
      ...costData,
      [''],
      ...totalData!,
    ].filter((v) => v !== null)
    setCsvData(finalData)
  }

  return (
    <div className="py-2 mb-4">
      <div className="card-body">
        <div className="row no-gutters align-items-center">
          <div className="col ml-3">
            <h1>Devices Costs</h1>
          </div>
        </div>
        {loading ? (
          <div className="row no-gutters align-items-center">
            <div className="d-flex justify-content-center m-auto h-100">
              <div className="spinner-border m-auto">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-5 d-flex justify-content-end">
              <div className="d-flex gap-3">
                <Button onClick={() => downloadPdfDocument()}>
                  <div>Export as PDF</div>
                </Button>
                <CSVLink
                  data={csvData}
                  onClick={downloadCsvFile}
                  className="btn btn-primary mr-lg-2"
                  filename={`Devices_Costs_${moment().format('DD_MM_YYYY')}.csv`}
                >
                  Export as CSV
                </CSVLink>
              </div>
            </div>
            <Table ref={tableRef}>
              <thead style={{ width: '15%', position: 'sticky', top: 0, zIndex: 2 }}>
                <tr>
                  {COLUMNS.map((column) => (
                    <th key={column.key}>{column.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.values(waterUsageCosts)
                  .map((cost) => {
                    const device = devices.find((d) => d.deviceId === cost.deviceId)

                    return {
                      ...cost,
                      device,
                    }
                  })
                  .filter((cost) => !!cost?.device?.deviceName)
                  .map((cost) => (
                    <tr
                      key={cost.deviceId}
                      onClick={() => cost.device && openModal(cost.device)}
                      style={{ cursor: 'pointer' }}
                    >
                      <td>
                        {hasPermissions(permissions, ['UPDATE:ACCOUNT']) ? (
                          <SectorIcon
                            sector={cost?.device?.deviceSettings?.sectorType}
                            occupants={cost?.device?.deviceSettings.occupants}
                          />
                        ) : null}{' '}
                        {cost?.device?.deviceName}
                      </td>

                      <td>{cost.dailyUsageFormatted}</td>
                      <td>{cost.avgDailyFormatted}</td>
                      <td>{cost.weeklyUsageFormatted}</td>
                      <td>{cost.avgWeeklyFormatted}</td>
                      <td>{cost.monthlyUsageFormatted}</td>
                      <td>{cost.avgMonthlyFormatted}</td>
                      <td>{cost.yearlyUsageFormatted}</td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                {usageTotalByCurency &&
                  Object.entries(usageTotalByCurency).map(([currency, values]) => (
                    <tr key={currency}>
                      <td>
                        <b>{`Total Cost (${currency})`}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.dailyUsage), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.avgDaily), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.weeklyUsage), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.avgWeekly), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.monthlyUsage), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.avgMonthly), currency)}</b>
                      </td>
                      <td>
                        <b>{formatCurrency(roundToTwo(values.yearlyUsage), currency)}</b>
                      </td>
                    </tr>
                  ))}
              </tfoot>
            </Table>
          </>
        )}
      </div>

      {isModalOpen && (
        <Modal
          show={isModalOpen}
          onHide={onHide}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="device-information-modal"
        >
          <Modal.Header className="modal-header" closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Device Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{selectedDevice?.deviceName}</h4>
            <hr />
            <Form className="device-information-form">
              <Row>
                <Form.Group as={Col} controlId="formGridCurrency">
                  <Form.Label>
                    Cost <span className="red">{selectedDeviceCost === 0 ? '*' : ''}</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    min="0.01"
                    placeholder="e.g. 1.93"
                    value={selectedDeviceCost}
                    onChange={(e) =>
                      isNaN(parseFloat(e.target.value))
                        ? 0
                        : setSelectedDeviceCost(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridCurrency">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    value={selectedDevice?.deviceSettings.currency}
                    readOnly
                  ></Form.Control>
                </Form.Group>
              </Row>
              <div className="d-flex justify-content-between">
                <Button
                  id="update-device-info"
                  className="mt-5"
                  variant="primary"
                  type="button"
                  disabled={!didDeviceInfoChange || deviceUpdating}
                  onClick={handleUpdateDeviceInfo}
                >
                  {deviceUpdating ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
                <Button
                  className="mt-5"
                  variant="outline"
                  type="button"
                  disabled={deviceUpdating}
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

import React from "react";
import {
    get30DaysReportAction, ReportQuery
} from "./30days.action";
import { initialState, reducer } from "./30days.reducer";

export default function use30DaysReport() {

    const [
        {
            report30days,
            loading,
            error,
        },
        dispatch,
    ] = React.useReducer(reducer, initialState);

    const get30DaysReport = React.useCallback(
        async (query: ReportQuery) => {
            await get30DaysReportAction(query, dispatch)
        }, [dispatch]);


    return {
        report30days,
        loading,
        error,
        get30DaysReport,
    };
};

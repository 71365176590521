import { useEffect, useState } from 'react'
import axios from 'axios'
import { useIdleTimer } from 'react-idle-timer'
import { Button, Modal } from 'react-bootstrap'

import { useUserDispatch } from '../../../context/user/context/user.context'
import { Action, ActionTypes } from '../../../context/user/context/user.reducer'
import { refreshAction } from '../../../context/user/action/login.action'

const GlobalDetectIdle = ({ children }: { children: JSX.Element }) => {
  const { logout } = useUserDispatch()

  const [remaining, setRemaining] = useState<number>(10)
  const [disaplyIdleModal, setDisplayIdleModal] = useState<boolean>(false)

  const [minutes, setMinutes] = useState<number>(5)
  const [seconds, setSeconds] = useState<number>(0)

  const refreshToken = localStorage.getItem('refresh-token')

  useEffect(() => {
    if (remaining === 0) setDisplayIdleModal(true)
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [remaining])

  useEffect(() => {
    if (disaplyIdleModal) {
      const myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1)
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval)
            handleLogout()
          } else {
            setMinutes(minutes - 1)
            setSeconds(59)
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval)
      }
    }
  }, [disaplyIdleModal, minutes, seconds])

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 30, //30 minutes
    throttle: 500,
  })

  const handleCancel = async () => {
    setDisplayIdleModal(false)
    setMinutes(2)
    setSeconds(0)

    if (refreshToken) {
      await refreshAction(refreshToken, (action: Action) => {
        if (action.type === ActionTypes.REFRESH) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + localStorage.getItem('jwt-token')
        }
      })
    }
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <div>
      {children}

      {disaplyIdleModal && (
        <Modal show={true} onHide={handleCancel} fullscreeen="true" size="lg" backdrop="static">
          <Modal.Header className="modal-header" closeButton></Modal.Header>
          <Modal.Body>
            <div className="container d-flex flex-column align-items-center">
              <h2>Your session is idle</h2>
              <img
                className="idle-img"
                src="assets/img/idle-session.png"
                alt="idle session"
                height={200}
                width={200}
              />

              <div className="mt-5">
                Automatically logout in{' '}
                <span className="text-danger font-weight-bold">
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </div>

              <div className="mt-5">
                Session timeouts are required by PCI and GDPR compliance rules to protect your data.
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="d-flex justify-content-sm-between w-100">
              <Button onClick={handleCancel}>Restore Session</Button>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default GlobalDetectIdle

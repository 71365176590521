import React from 'react';
import { Table } from 'react-bootstrap';
import { useCustomerManagerState } from '../../../../context/customerManager/customer-manager.context';

import './PermissionsComponent.scss';

const PermissionsComponent = () => {

	const {loading, permissions} = useCustomerManagerState();

	return (
		<div>
	{
				loading ? "Loading..." : 
				<>
					<Table>
					<thead>
						<tr>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						{permissions.map((permission) => (
						<tr key={permission.name}>
							<td>{permission.description}</td>
						</tr>))}
					</tbody>
					</Table>
				</>
			}
		</div>
	);
};

export default PermissionsComponent;




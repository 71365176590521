import { ReportQuery } from "./30days.action";
import { Report30Days, DailyUsage, ProjectedDailyFlow, ProjectedMonthlyCost } from "./30days.model";

interface ReportState {
    report30days?: Report30Days,
    loading: boolean,
    error: boolean,
}

export const initialState: ReportState = {
    loading: false,
    error: false,
}


export enum ActionTypes {
    LOADING,
    LOADED_30_DAYS_REPORTS,
    ERROR,
}
export interface Action {
    type: ActionTypes;
    payload?: { query: ReportQuery, data: any };
}

export const reducer = (state: ReportState, action: Action): ReportState => {
    switch (action.type) {
        case ActionTypes.LOADED_30_DAYS_REPORTS:
            if (!action.payload) return state;
            const query = action.payload.query;
            const response = action.payload.data;
            const tableHeaders = ["1am","2am","3am","4am","5am","6am","7am","8am","9am","10am","11am","Midday", "1pm","2pm","3pm","4pm","5pm","6pm","7pm","8pm","9pm","10pm","11pm","Midnight"];
            const report30days: Report30Days = {
                deviceId: query.deviceId,
                currency: response.currency,
                tableHeaders,
                actualUsage: response.daily_usage_30_day.map((u: any) => {
                    const daily: DailyUsage = {
                        date: u.Date,
                        total: parseInt(u.DailyUsage),
                        hours: tableHeaders.map(key => u[key] === "-" ? null : parseInt(u[key])),
                    }
                    return daily ;
                }),
                projected30Day: response.est_30_day.map((u: any) => {
                    const est: ProjectedDailyFlow = {
                        date: u.date,
                        flow: u.flow,
                        wasteEstimated: u.waste_est,
                        flowLessWasteEstimated: u.flow_less_waste_est,
                        flowLessWasteEstimatedCost: u.flow_less_waste_est_cost,
                    }
                    return est;
                }),
                projected30DayHourly: response.est_7_day.map((u: any) => {
                    const est: ProjectedDailyFlow = {
                        date: u.date,
                        wasteEstimated: u.waste_est,
                        flowLessWasteEstimated: u.flow_less_waste_est,
                    }
                    return est;
                }),
                projectedYearlyCost: response.est_12_month.map((u: any) => {
                    const est: ProjectedMonthlyCost = {
                        date: u.month_year,
                        cost: u.cost_waste_cum,
                        costLessWaste: u.cost_less_waste_est_cum,
                    }
                    return est;
                }),
                stats: {
                    monthlyCost: response.summary_30_day.cost,
                    monthlyWasteCost: response.summary_30_day.waste_est_cost,
                    monthlyWaste: response.summary_30_day.flow_less_waste_est,
                    monthlyFlow: response.summary_30_day.flow,
                    yearlyCost: response.summary_12_month.cost,
                    yearlyCostEstimated: response.summary_12_month.waste_est_cost,
                    yearlyEstimatedSaving: response.summary_12_month.saving,
                    yearlyFlow: response.summary_12_month.flow,
                    yearlyWaste: response.summary_12_month.flow_less_waste_est,
                    yearlySaving: response.summary_12_month.flow_saving,
                }
            };
            return { report30days, loading: false, error: false };
     
        case ActionTypes.LOADING:
            return { report30days: undefined, loading: true, error: false };
        case ActionTypes.ERROR:
            return { report30days: undefined, loading: false, error: true };
        default:
            return state
    }
}
import moment from 'moment-timezone'
import backend from '../../api/backend'
import { Aggregator } from './waterUsage.model'
import { ActionTypes } from './waterUsage.reducer'
import { AggUsageQuery } from './model/usageStats.model'
import { displayToast } from '../../common/utils/appToast'

export type WaterUsageQuery = {
  deviceId: string
  to: string
  from: string
  dateType?: string
  aggregators?: Aggregator[]
}

export type WaterUsageQueryMultipleDevices = Omit<WaterUsageQuery, 'deviceId'> & {
  deviceIds: string[]
}

type IDailyUsage = {
  device_id: number
  date: string
  hour: number
  flow: number
}

export const getWaterUsageStatsAction = async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const responseHourly = await backend.get(`/water_usage`, {
      params: {
        //last_n_days: 1,
        start_date: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
        end_date: moment().format('YYYY-MM-DDTHH:mm:ss'),
      },
    })

    const responseStats = await backend.get(`/water_usage/stats`)
    const responseCosts = await backend.get(`/water_usage/cost`)
    dispatch({
      type: ActionTypes.LOADED_WATER_USAGE_STATS,
      payload: { query: null, data: [responseStats.data, responseHourly.data, responseCosts.data] },
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const getAlertWaterUsageAction = async (query: WaterUsageQuery, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const response = await backend.get(`/water_usage/${query.deviceId}/alert_average`, {
      params: {
        start_date: query.from,
        end_date: query.to,
        date_type: query.dateType,
      },
    })
    dispatch({
      type: ActionTypes.LOADED_ALERT_WATER_USAGE,
      payload: { query, data: response.data },
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const getSingleDeviceWaterUsageAction = async (query: WaterUsageQuery, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const response = await backend.get(`/water_usage/${query.deviceId}`, {
      params: {
        start_date: query.from,
        end_date: query.to,
      },
    })
    dispatch({
      type: ActionTypes.LOADED_SINGLE_DEVICE_WATER_USAGE,
      payload: { query, data: response.data },
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const getMultipleDeviceWaterUsageAction = async (
  query: WaterUsageQueryMultipleDevices,
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const payload: IDailyUsage[] = []
    await Promise.all(
      query.deviceIds.map(async (deviceId: string) => {
        const response = await backend.get(`/water_usage/${deviceId}`, {
          params: {
            start_date: query.from,
            end_date: query.to,
          },
        })
        payload.push(response.data)
      }),
    )

    dispatch({
      type: ActionTypes.LOADED_MULTIPLE_DEVICE_WATER_USAGE,
      payload: { query, data: payload },
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const getAggUsageAction = async (query: AggUsageQuery, dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const response = await backend.get(`/water_usage/aggregated/month`, {
      params: {
        start_date: query.queryStart,
        end_date: query.queryEnd,
        agg_type: query.aggType,
      },
    })
    dispatch({
      type: ActionTypes.LOADED_AGG_USAGE,
      // payload: {query: query, data: data}
      payload: { query: query, data: response.data },
    })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const refreshSingleDeviceWaterUsageAction = async (
  query: WaterUsageQuery,
  dispatch: any,
) => {
  try {
    dispatch({ type: ActionTypes.UPDATING })
    const response = await backend.get(`/devices/${query.deviceId}/daily_flow`, {
      params: {
        req_date: moment(query.from).format('YYYY-MM-DD'),
      },
    })
    dispatch({
      type: ActionTypes.LOADED_SINGLE_DEVICE_WATER_USAGE,
      payload: { query, data: response.data },
    })

    displayToast({
      type: 'success',
      message: 'Refreshed successfully',
    })
  } catch (error: any) {
    console.log(error)
    displayToast({
      type: 'error',
      message: error?.response?.data?.detail || 'Something went wrong',
    })
    dispatch({ type: ActionTypes.ERROR })
  }
}

export const clearSingleDeviceWaterUsageAction = (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.CLEAR_SINGLE_DEVICE_WATER_USAGE })
  } catch (error) {
    console.log(error)
    dispatch({ type: ActionTypes.ERROR })
  }
}

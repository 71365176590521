import { createElement } from 'react'
import {
  Beer,
  Hotel,
  Building2,
  Utensils,
  Cog,
  Cross,
  Dumbbell,
  Factory,
  ShoppingBag,
  Tv2,
  Store,
  Construction,
  Waves,
  GraduationCap,
  School,
  Dot,
  KeySquare,
} from 'lucide-react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

interface Props {
  sector?: string
  color?: string
  size?: number
  occupants?: number
}

interface IconProps {
  [key: string]: any
}

const SectorIcon = ({ sector = 'default', color = '#0d6efd', size = 20, occupants = 0 }: Props) => {
  const ICONS: IconProps = {
    bar: Beer,
    hotel: Hotel,
    office: Building2,
    restaurant: Utensils,
    service_station: Cog,
    nursing_home: Cross,
    gym: Dumbbell,
    manufacturing: Factory,
    shopping_center: ShoppingBag,
    cinema: Tv2,
    shop: Store,
    construction_site: Construction,
    pool: Waves,
    student_accommodation: GraduationCap,
    school: School,
    real_estate: KeySquare,
    default: Dot,
  }

  const iconProps = {
    color,
    size,
  }

  const formatedSector = sector.replace(' ', '_').toLowerCase()

  const iconName = Object.keys(ICONS)
    .filter((icon): boolean => icon === formatedSector)
    .toString()

  return iconName && iconName !== 'default' ? (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props: any) => (
        <Tooltip {...props}>{`${sector} ${occupants > 0 ? '(' + occupants : ''}  ${
          occupants && occupants > 1 ? 'occupants)' : ''
        } ${occupants && occupants === 1 ? 'occupant)' : ''}`}</Tooltip>
      )}
      placement="bottom"
    >
      {createElement(ICONS[iconName], iconProps)}
    </OverlayTrigger>
  ) : (
    <></>
  )
}

export default SectorIcon

import moment from 'moment-timezone'

export const COLUMNS = [
  {
    name: 'Device',
    key: 'device',
  },
  {
    name: `Today (${moment().format('DD/MM/YYYY')})`,
    key: 'today',
  },
  {
    name: 'Avg Daily',
    key: 'avg_daily',
  },
  {
    name: 'WTD',
    key: 'wtd',
  },
  {
    name: 'Avg Weekly',
    key: 'avg_weekly',
  },
  {
    name: 'MTD',
    key: 'mtd',
  },
  {
    name: 'Avg Monthly',
    key: 'avg_monthly',
  },
  {
    name: 'YTD',
    key: 'ytd',
  },
]

import React from 'react'

import { useDeviceState } from '../../../context/device/context/device.context'
import Invoice from '../Components/Invoice/Invoice'

const InvoiceReport: React.FC = () => {
  const { devices } = useDeviceState()

  return (
    <div>
      <Invoice devices={devices} />
    </div>
  )
}

export default InvoiceReport

import React, { useEffect, useReducer, createContext, useContext } from 'react'

import { getWaterUsageStatsAction } from './waterUsage.action'
import { initialState, reducer, ActionTypes } from './waterUsage.reducer'
import { useDeviceState } from '../device/context/device.context'

const StateContext = createContext(initialState)
const DispatchContext = createContext(undefined as any)

export const WaterUsageStatsContextProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { waterUsageStats } = state
  const { devices } = useDeviceState()

  useEffect(() => {
    if (Object.keys(waterUsageStats).length === 0) getWaterUsageStatsAction(dispatch)
  }, [])

  useEffect(() => {
    dispatch({ type: ActionTypes.FILTER_WATER_USAGE_STATS, payload: { devices: devices } })
    dispatch({ type: ActionTypes.FILTER_WATER_USAGE_COST, payload: { devices: devices } })
  }, [waterUsageStats, devices])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export const useWaterUsageState = () => {
  return useContext(StateContext)
}
/*
export const useDeviceDispatch = () => {
  return React.useMemo(
    () => ({}),
    []
  )
};
*/

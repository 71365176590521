import React from 'react';

import { useDeviceState } from '../../../context/device/context/device.context';
import ESGComponent from "../Components/ESG/ESGComponent";

const ESGReport: React.FC = () => {

  const { devices } = useDeviceState();

  return (
    <div>
      <ESGComponent devices={ devices }/>
    </div>
  )
};

export default ESGReport;
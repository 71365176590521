import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useCustomerManagerState } from '../../../../context/customerManager/customer-manager.context';
import { Permission, Role } from '../../../../context/user/model/user';
import DeleteModal from '../../../general/Modals/DeleteModal';


import './RolesComponent.scss';

const RolesComponent = () => {

	const {loading, roles} = useCustomerManagerState();
	const [deletePermission, setDeletePermission] = useState<{role: Role, permission: Permission}>()

  return (
		<div>
			{
				loading ? "Loading..." : 
				<>
				<DeleteModal
					show={!!deletePermission}
					data={deletePermission}
					onHide={() => setDeletePermission(undefined)}
					toString={(data: any) => `${data.permission.description} from ${data.role.description}`}
					onDelete={(data: any) => console.log(data)}
				/>
				<Table>
				<thead>
					<tr>
						<th>Description</th>
						<th>Roles</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{roles.map((role) => (
					<tr key={role.name}>
						<td>{role.description}</td>
						<td>
							<ul>
								{role.permissions.map(p => <li key={p.name}>{p.description} 
								<i className="fas fa-times" style={{color:"red", cursor: "pointer"}} onClick={() => setDeletePermission({role, permission: p})}>
								</i></li>)}
							</ul>
								
						</td>

					</tr>))}
				</tbody>
				</Table></>
			}
		</div>
	);
};

export default RolesComponent;




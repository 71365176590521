import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useCustomerManagerState } from '../../../../context/customerManager/customer-manager.context';
import { Group, Role } from '../../../../context/user/model/user';
import DeleteModal from '../../../general/Modals/DeleteModal';


import './GroupsComponent.scss';

const GroupsComponent = () => {

	const {loading, groups} = useCustomerManagerState();
	const [deleteRole, setDeleteRole] = useState<{group: Group, role: Role}>()

  	return (
		<>
			{loading ? "Loading..." : <>
				<DeleteModal
					show={!!deleteRole}
					data={deleteRole}
					onHide={() => setDeleteRole(undefined)}
					toString={(data: any) => `${data.role.description} from ${data.group.description}`}
					onDelete={(data: any) => console.log(data)}
				/>
				<Table>
				<thead>
					<tr>
						<th>Name</th>
						<th>Description</th>
						<th>Roles</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{groups.map((group) => (
					<tr key={group.id}>
						<td>{group.name}</td>
						<td>{group.description}</td>
						<td>
							<ul>
								{group.roles.map(r => <li key={r.name}>{r.description} 
								<i className="fas fa-times" style={{color:"red", cursor: "pointer"}} onClick={() => setDeleteRole({group, role: r})}>
								</i></li>)}
							</ul>
								
						</td>

					</tr>))}
				</tbody>
				</Table></>
			}
		</>
	);
};

export default GroupsComponent;




import { useEffect, useState, useRef } from 'react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { CostSummary } from './Components/CostComponent'
import { UsageSummary } from './Components/UsageComponent'
import { ESGSummary } from './Components/ESGComponent'
import { ActiveIssueSummary, LeakSummary, TotalIssueSummary } from './Components/LeakComponent'
import { UsageBarChart, UsagePieChart } from './Components/GraphComponent'
import useWaterUsage from '../../../context/waterUsage/waterUsage.hook'

import moment from 'moment-timezone'
import { AggUsageQuery, TrendData } from '../../../context/waterUsage/model/usageStats.model'
import Device from '../../../context/device/model/device'
import { useDeviceState } from '../../../context/device/context/device.context'
import { useAccountState } from '../../../context/account/context/account.context'
import { useUserState } from '../../../context/user/context/user.context'
import { hasPermissions } from '../../../common/utils/helperFunctions'
import HomeTacticalEmailModal from './HomeTacticalEmailModal'

function HomeTactical() {
  const { loadAggUsageAllDevices, aggStats } = useWaterUsage()
  const { devices } = useDeviceState()
  const { selectedAccounts } = useAccountState()
  const { permissions } = useUserState()

  const inputRef = useRef(null)

  const [filteredAggData, setFilteredAggData] = useState<any>({})
  const [displaySendEmailModal, setDisplaySendEmailModal] = useState(false)
  const [pdfBase64, setPdfBase64] = useState('')
  const [trendData, setTrendData] = useState<TrendData>({
    total: 0,
    currMonth: 0,
    prevMonth: 0,
    currMonthHot: null,
    prevMonthHot: null,
    currMonthCold: null,
    prevMonthCold: null,
    minMonth: null,
    maxMonth: null,
    maxMinDiff: 0,
    maxMinDiffCurrency: {},
  })

  useEffect(() => {
    const utcDate = moment.utc()
    const queryEnd: string = utcDate.format('YYYY-MM-DDTHH:mm:ss')
    const queryStart: string = utcDate
      .subtract(12, 'months')
      .month(0)
      .date(1)
      .hour(0)
      .minute(0)
      .second(0)
      .format('YYYY-MM-DDTHH:mm:ss')
    const queryParams: AggUsageQuery = {
      queryStart: queryStart,
      queryEnd: queryEnd,
      aggType: 'Month',
    }
    loadAggUsageAllDevices(queryParams)
  }, [])

  useEffect(() => {
    if (Object.keys(aggStats).length === 0) return

    const filteredData = Object.keys(aggStats)
      .filter((deviceId) => {
        const d: Device | undefined = devices.find((d: Device) => d.dlId?.toString() === deviceId)
        if (d) return deviceId
      })
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: aggStats[key] })
      }, {})
    setFilteredAggData(filteredData)
  }, [devices, aggStats])

  useEffect(() => {
    const currentYear: number = moment.utc().year()
    const previousMonth: number = moment.utc().month()
    const currentMonth: number = moment.utc().month() + 1

    const trendData: TrendData = Object.keys(filteredAggData).reduce(
      (acc: TrendData, currentValue: string) => {
        const device = devices.find((d) => d.dlId?.toString() === currentValue)
        const yearData: { [key: string]: { [key: string]: number } } = filteredAggData[currentValue]
        const yearKeys: string[] = Object.keys(yearData)
        yearKeys.forEach((year) => {
          const monthData: { [key: string]: number } = yearData[year]
          const yearTotal: TrendData = Object.keys(monthData).reduce(
            (acc: TrendData, monthNum: string) => {
              const monthValue = monthData[monthNum]
              if (year === currentYear.toString()) {
                if (acc.maxMonth === null || monthValue > acc.maxMonth) {
                  acc.maxMonth = monthValue
                }

                if (acc.minMonth === null || monthValue < acc.minMonth) {
                  acc.minMonth = monthValue
                }
              }

              return {
                ...acc,
                total: acc.total + monthValue,
                currMonth:
                  year === currentYear.toString() && monthNum === currentMonth.toString()
                    ? acc.currMonth + monthValue
                    : acc.currMonth,
                prevMonth:
                  year === currentYear.toString() && monthNum === previousMonth.toString()
                    ? acc.prevMonth + monthValue
                    : acc.prevMonth,
                currMonthHot:
                  year === currentYear.toString() &&
                  monthNum === currentMonth.toString() &&
                  device?.deviceSettings.hot
                    ? acc.currMonthHot === null
                      ? monthValue
                      : acc.currMonthHot + monthValue
                    : acc.currMonthHot,
                prevMonthHot:
                  year === currentYear.toString() &&
                  monthNum === previousMonth.toString() &&
                  device?.deviceSettings.hot
                    ? acc.prevMonthHot === null
                      ? monthValue
                      : acc.prevMonthHot + monthValue
                    : acc.prevMonthHot,
                currMonthCold:
                  year === currentYear.toString() &&
                  monthNum === currentMonth.toString() &&
                  !device?.deviceSettings.hot
                    ? acc.currMonthCold === null
                      ? monthValue
                      : acc.currMonthCold + monthValue
                    : acc.currMonthHot,
                prevMonthCold:
                  year === currentYear.toString() &&
                  monthNum === previousMonth.toString() &&
                  !device?.deviceSettings.hot
                    ? acc.prevMonthCold === null
                      ? monthValue
                      : acc.prevMonthCold + monthValue
                    : acc.prevMonthCold,
              }
            },
            {
              total: 0,
              currMonth: 0,
              prevMonth: 0,
              currMonthHot: null,
              prevMonthHot: null,
              currMonthCold: null,
              prevMonthCold: null,
              maxMonth: null,
              minMonth: null,
              maxMinDiff: 0,
              maxMinDiffCurrency: {},
            },
          )
          if (yearTotal.minMonth && yearTotal.maxMonth) {
            const diff = (yearTotal.maxMonth - yearTotal.minMonth) / 1000
            acc.maxMinDiff += diff

            if (device && !acc.maxMinDiffCurrency[device?.deviceSettings.currency]) {
              acc.maxMinDiffCurrency[device?.deviceSettings.currency] =
                diff * device.deviceSettings.cost
            } else if (device && acc.maxMinDiffCurrency[device?.deviceSettings.currency]) {
              acc.maxMinDiffCurrency[device?.deviceSettings.currency] +=
                diff * device.deviceSettings.cost
            }
          }

          acc.total += yearTotal.total
          acc.prevMonth += yearTotal.prevMonth
          acc.currMonth += yearTotal.currMonth

          acc.currMonthHot =
            acc.currMonthHot && yearTotal.currMonthHot
              ? acc.currMonthHot + yearTotal.currMonthHot
              : yearTotal.currMonthHot === null
                ? acc.currMonthHot
                : yearTotal.currMonthHot
          acc.prevMonthHot =
            acc.prevMonthHot && yearTotal.prevMonthHot
              ? acc.prevMonthHot + yearTotal.prevMonthHot
              : yearTotal.prevMonthHot === null
                ? acc.prevMonthHot
                : yearTotal.prevMonthHot

          acc.currMonthCold =
            acc.currMonthCold && yearTotal.currMonthCold
              ? acc.currMonthCold + yearTotal.currMonthCold
              : yearTotal.currMonthCold === null
                ? acc.currMonthCold
                : yearTotal.currMonthCold
          acc.prevMonthCold =
            acc.prevMonthCold && yearTotal.prevMonthCold
              ? acc.prevMonthCold + yearTotal.prevMonthCold
              : yearTotal.prevMonthCold === null
                ? acc.prevMonthCold
                : yearTotal.prevMonthCold
          acc.minMonth = yearTotal.minMonth
          acc.maxMonth = yearTotal.maxMonth
        })
        return acc
      },
      {
        total: 0,
        currMonth: 0,
        prevMonth: 0,
        currMonthHot: null,
        prevMonthHot: null,
        currMonthCold: null,
        prevMonthCold: null,
        maxMonth: null,
        minMonth: null,
        maxMinDiff: 0,
        maxMinDiffCurrency: {},
      },
    )
    setTrendData(trendData)
  }, [filteredAggData])

  const exportPageAsPdf = (sendByEmail = false) => {
    if (inputRef.current) {
      html2canvas(inputRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const smartFlowLogo = new Image()
        smartFlowLogo.src = '/logo.png'
        const accountName = selectedAccounts[0].name

        const pdf = new jsPDF('landscape', 'mm', [297, 210])

        pdf.text(accountName, 5, 10)
        pdf.addImage(
          smartFlowLogo,
          'PNG',
          pdf.internal.pageSize.getWidth() - 60,
          1,
          50,
          15,
          undefined,
          'FAST',
        )
        pdf.addImage(
          imgData,
          'JPEG',
          5,
          17,
          pdf.internal.pageSize.getWidth() - 10,
          200,
          undefined,
          'FAST',
        )
        if (!sendByEmail) {
          pdf.save(`SmartFlow_year_to_date_summary_${moment().format('DD_MM_YYYY')}.pdf`)
        } else {
          setPdfBase64(pdf.output('datauristring'))
          setDisplaySendEmailModal(true)
        }
      })
    }
  }

  return (
    <div className="home">
      {selectedAccounts.length === 1 && (
        <div className={'d-flex justify-content-end mb-3 d-grid gap-3'}>
          <button className="btn btn-primary" onClick={() => exportPageAsPdf()}>
            Export page as PDF
          </button>
          {hasPermissions(permissions, ['USER:ADMIN:SMARTFLOW']) && (
            <button className="btn btn-primary" onClick={() => exportPageAsPdf(true)}>
              Send report by email
            </button>
          )}
        </div>
      )}
      <Card className="shadow" ref={inputRef}>
        <Card.Header>
          <Container fluid>
            <Row>
              <Col md={6}>
                <div className={'d-flex justify-content-center'}>
                  <h3>Year To Date Summary</h3>
                </div>
              </Col>
              <Col md={6}>
                <div className={'d-flex justify-content-center'}>
                  <h3>{moment().format('dddd, MMMM Do YYYY')}</h3>
                </div>
              </Col>
            </Row>
          </Container>
        </Card.Header>
        <Card.Body>
          <Container fluid>
            <Row>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <CostSummary />
              </Col>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <UsageSummary filteredAggStats={filteredAggData} trendData={trendData} />
              </Col>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <ESGSummary trendData={trendData} />
              </Col>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <LeakSummary trendData={trendData} />
              </Col>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <TotalIssueSummary />
              </Col>
              <Col lg="auto" className={'mx-auto mt-sm-1'}>
                <ActiveIssueSummary />
              </Col>
            </Row>
            <Row className={'py-4'}>
              <Col lg={8}>
                <UsageBarChart filteredAggStats={filteredAggData} />
              </Col>
              <Col lg={4}>
                <UsagePieChart filteredAggStats={filteredAggData} />
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>

      {displaySendEmailModal && (
        <HomeTacticalEmailModal
          onHide={() => setDisplaySendEmailModal(false)}
          selectedAccount={selectedAccounts[0]}
          pdfAttachment={pdfBase64}
        />
      )}
    </div>
  )
}

export default HomeTactical

import Account, { AccountComments, AccountCommentsRaw } from '../account/model/Account'
import CustomerLocation, { AlertSub } from '../account/model/CustomerLocation'
import Device, { TestDevice } from '../device/model/device'
import { mapDevice } from '../device/context/device.reducer'
import User, { Group, Permission, Role, UserInvite, UserInviteAccounts } from '../user/model/user'
import { UserInviteLocation } from './model/customer-manager'
import { displayToast } from "../../common/utils/appToast";

export enum ActionTypes {
  LOADING,
  ERROR,
  INIT_ACCOUNTS,
  INIT_DEVICES,
  INIT_USERS,
  INIT_GROUPS,
  INIT_ROLES,
  INIT_PERMISSIONS,
  DEVICE_SWAPPED,
  DEVICE_ADDED,
  ACCOUNT_ADDED,
  COMMENT_ADDED,
  ACCOUNT_UPDATED,
  USER_ADDED,
  USER_UPDATED,
  USER_UPDATING,
  USER_REMOVED,
  USER_INVITED,
  DEVICE_REMOVED,
  LOCATION_REMOVED,
  LOCATION_UPDATED,
  LOCATION_ADDED,
  INIT_ACCOUNT_USERS,
  INIT_ACCOUNT_LOCATIONS,
  INIT_ACCOUNT_COMMENTS,
  // INIT_ALL_USERS,
  INIT_INVITATIONS_LOADING,
  INVITATION_UPDATED,
  INIT_USER_ALERT_SUBS_LOADING,
  USER_ALERT_SUBS_UPDATED,
  USER_SUBS_UPDATING,
}

interface State {
  accounts: Account[]
  users: User[]
  usersByAccount: { [key: string]: User[] }
  devicesByLocation: { [key: string]: Device[] }
  locationsByAccount: { [key: string]: CustomerLocation[] }
  invitationsByAccount: { [key: string]: any }
  commentsByAccount: AccountComments[]
  userAlertSubs: AlertSub[]
  roles: Role[]
  permissions: Permission[]
  groups: Group[]
  loading: boolean
  error: boolean
  errorMsg: { error_code?: string; message?: string }
  subsUpdating: boolean
  userUpdating: boolean
}

export interface Action {
  type: ActionTypes
  payload?: any
}

export const initialState: State = {
  accounts: [],
  users: [],
  devicesByLocation: {},
  usersByAccount: {},
  locationsByAccount: {},
  invitationsByAccount: [],
  commentsByAccount: [],
  userAlertSubs: [],
  roles: [],
  permissions: [],
  groups: [],
  loading: false,
  error: false,
  errorMsg: {},
  subsUpdating: false,
  userUpdating: false,
}

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return { ...state, loading: true, error: false, errorMsg: {} }
    case ActionTypes.INIT_ACCOUNTS:
      const accounts: Account[] = action.payload?.accounts
      return { ...state, accounts, loading: false, error: false, errorMsg: {} }
    case ActionTypes.INIT_DEVICES: {
      const devices: Device[] = action.payload?.devices
      const devicesByAccount = devices.reduce(
        (acc, device) => {
          if (!acc[device.accountId]) acc[device.accountId] = []
          acc[device.accountId].push(device)
          return acc
        },
        {} as { [key: string]: Device[] },
      )
      const devicesByLocation = devices.reduce(
        (acc, device) => {
          if (!acc[device.deviceLocationId]) acc[device.deviceLocationId] = []
          acc[device.deviceLocationId].push(device)
          return acc
        },
        {} as { [key: string]: Device[] },
      )
      return {
        ...state,
        devicesByAccount,
        devicesByLocation,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.INIT_ACCOUNT_USERS: {
      const accountId: string = action.payload?.account.id
      const users = action.payload?.users.map((u: any) => mapUser(u))
      return {
        ...state,
        usersByAccount: { ...state.usersByAccount, [accountId]: users },
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.INIT_ACCOUNT_LOCATIONS: {
      const accountId: string = action.payload?.account.id
      const locations = action.payload?.locations.map((loc: any) => {
        return {
          id: String(loc.id),
          addressLine1: loc.address_line_1,
          addressLine2: loc.address_line_2,
          town: loc.town,
          postcode: loc.postcode,
          country: loc.country,
          county: loc.county,
          name: loc.location_name,
          companyAlias: loc.company_alias,
        } as CustomerLocation
      })
      return {
        ...state,
        locationsByAccount: { ...state.locationsByAccount, [accountId]: locations },
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.INIT_ACCOUNT_COMMENTS: {
      const { comments } = action.payload

      comments.sort((a: AccountCommentsRaw, b: AccountCommentsRaw) => b.id - a.id)

      const commentsByAccount = comments.map((comment: AccountCommentsRaw) => {
        return {
          id: comment.id,
          comment: comment.comment,
          createdAt: comment.date,
          updatedAt: comment.valid_from,
          author: comment.user_name,
          history: comment.history.map((history: AccountCommentsRaw) => {
            return {
              id: history.id,
              comment: history.comment,
              author: history.user_name,
              createdAt: history.valid_from,
            }
          }),
        }
      })

      return {
        ...state,
        commentsByAccount: commentsByAccount,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.INIT_INVITATIONS_LOADING: {
      const { invitations } = action.payload
      const invitationsByAccount = invitations.map((i: any) => {
        const invite: UserInvite = {
          id: i.id,
          email: i.email,
          active: i.active,
          createAt: i.created_at,
          updatedAt: i.updated_at,
          accounts: i.accounts.map((a: any) => {
            return {
              accountId: a.account_id_ref,
              admin: a.admin,
              // viewAllLocations: a.view_all_locations,
              autoAddLocations: a.auto_add_locations,
              invitationIdRef: a.invitation_id_ref,
              autoAddAlerts: a.auto_add_alerts,
              invoice: a.invoice,
            } as UserInviteAccounts
          }),
          locations: i.locations.map((l: any) => {
            return {
              locationId: String(l.location_id_ref),
              admin: l.admin,
              alerts: l.alerts,
              invitationIdRef: l.invitation_id_ref,
            } as UserInviteLocation
          }),
          groupIds: i.group_ids,
          roleIds: i.role_ids,
          permissionIds: i.permission_ids,
          welcomeEmail: i.welcome_email,
          expiryDate: i.expiry_date,
          accountCreated: i.account_created,
        }
        return invite
      })
      return {
        ...state,
        invitationsByAccount: invitationsByAccount,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.USER_SUBS_UPDATING: {
      return { ...state, subsUpdating: true }
    }
    case ActionTypes.INVITATION_UPDATED: {
      const { invitation } = action.payload
      const { invitationsByAccount } = state
      const itemIndex = invitationsByAccount.findIndex((i: UserInvite) => i.id === invitation.id)
      invitationsByAccount[itemIndex] = {
        id: invitation.id,
        email: invitation.email,
        active: invitation.active,
        createAt: invitation.created_at,
        updatedAt: invitation.updated_at,
        accounts: invitation.accounts.map((a: any) => {
          return {
            accountId: a.account_id_ref,
            admin: a.admin,
            // viewAllLocations: a.view_all_locations,
            autoAddLocations: a.auto_add_locations,
            invitationIdRef: a.invitation_id_ref,
            autoAddAlerts: a.auto_add_alerts,
            invoice: a.invoice,
          } as UserInviteAccounts
        }),
        locations: invitation.locations.map((l: any) => {
          return {
            locationId: String(l.location_id_ref),
            admin: l.admin,
            alerts: l.alerts,
            invitationIdRef: l.invitation_id_ref,
          } as UserInviteLocation
        }),
        groupIds: invitation.group_ids,
        roleIds: invitation.role_ids,
        permissionIds: invitation.permission_ids,
        welcomeEmail: invitation.welcome_email,
        expiryDate: invitation.expiry_date,
        accountCreated: invitation.account_created,
      } as UserInvite
      return {
        ...state,
        invitationsByAccount: invitationsByAccount,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }

    case ActionTypes.COMMENT_ADDED: {
      const { commentsByAccount } = state
      const newComment = action.payload.newComment

      return {
        ...state,
        commentsByAccount: [...commentsByAccount, newComment],
        loading: false,
        error: false,
        errorMsg: {},
      }
    }

    case ActionTypes.INIT_USERS: {
      const users = action.payload?.users.map(mapUser)
      return { ...state, users, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.INIT_GROUPS: {
      const groups: Group[] = action.payload.groups.map((r: any) => {
        return {
          id: r.id,
          name: r.name,
          description: r.description,
          roles: r.roles.map((rr: any) => {
            return { id: String(rr.id), name: rr.name, description: rr.description }
          }),
        }
      })
      return { ...state, groups, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.INIT_ROLES: {
      const roles: Role[] = action.payload.roles.map((r: any) => {
        return {
          id: r.id,
          name: r.name,
          description: r.description,
          permissions: r.permissions.map((rr: any) => {
            return { id: String(rr.id), name: rr.name, description: rr.description }
          }),
        }
      })
      return { ...state, roles, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.INIT_PERMISSIONS: {
      const permissions: Permission[] = action.payload.permissions.map((r: any) => {
        return { id: r.id, name: r.name, description: r.description }
      })
      return { ...state, permissions, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.DEVICE_SWAPPED: {
      const origin: Device = action.payload.origin
      const destination: TestDevice = action.payload.destination
      const devicesByLocation = Object.values(state.devicesByLocation)
        .map((devices) =>
          devices.map((d) =>
            d.deviceId === origin.deviceId ? { ...d, deviceId: destination.deviceId } : d,
          ),
        )
        .reduce((acc, devices) => {acc[devices[0].deviceLocationId] = [...devices]
            return acc
          },
          {} as { [key: string]: Device[] },
        )

      return { ...state, devicesByLocation, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.DEVICE_ADDED: {
      const { deviceData, permissions } = action.payload;
      const { devicesByLocation: newDevicesByLocation } = state;
      const newDevice: Device = { ...mapDevice(deviceData, permissions) };
      const stateLocation = Object.keys(state.devicesByLocation).find(
        (locationId) => locationId === newDevice.deviceLocationId,
      )
      if (stateLocation) {
        const deviceInLocationState = newDevicesByLocation[newDevice.deviceLocationId].find((d) => {
          return d.dUUID === newDevice.dUUID
        })
        if (!deviceInLocationState) {
          newDevicesByLocation[newDevice.deviceLocationId].push(newDevice)
        }

      } else {
        const a = Object.entries(state.locationsByAccount)
          .find(([accountId, locations]) => {
            return locations.find((l) => l.id === newDevice.deviceLocationId)
          })
        if (a) {
          newDevicesByLocation[newDevice.deviceLocationId] = [newDevice]
        }
      }

      return {
        ...state,
        devicesByLocation: newDevicesByLocation,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.DEVICE_REMOVED: {
      const newDevice: Device = action.payload.device
      const devicesByLocation = Object.entries(state.devicesByLocation).reduce(
        (acc, [locationId, devices]) => {
          acc[locationId] = [...devices]
          if (locationId === newDevice.deviceLocationId)
            acc[locationId] = acc[locationId].filter((d) => d.deviceId !== newDevice.deviceId)
          return acc
        },
        {} as { [key: string]: Device[] },
      )

      return { ...state, devicesByLocation, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.ACCOUNT_ADDED: {
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.ACCOUNT_UPDATED: {
      const account: Account = action.payload
      const accounts = state.accounts.map((a) => (account.id === a.id ? account : a))
      return { ...state, accounts, loading: false, error: false }
    }
    case ActionTypes.USER_REMOVED: {
      const user: User = action.payload.user
      const updatedAccountId = action.payload.accountId
      const usersByAccount = Object.entries(state.usersByAccount).reduce(
        (acc, [accountId, users]) => {
          acc[accountId] = [...users]
          if (accountId === updatedAccountId)
            acc[accountId] = acc[accountId].filter((u) => u.id !== user.id)
          return acc
        },
        {} as { [key: string]: User[] },
      )
      const users = state.users.filter((u) => u.id !== user.id)
      return { ...state, users, usersByAccount, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.LOCATION_REMOVED: {
      const location: CustomerLocation = action.payload.location
      const updatedAccountId = action.payload.account.id
      const locationsByAccount = Object.entries(state.locationsByAccount).reduce(
        (acc, [accountId, locations]) => {
          acc[accountId] = [...locations]
          if (accountId === updatedAccountId)
            acc[accountId] = acc[accountId].filter((u) => u.id !== location.id)
          return acc
        },
        {} as { [key: string]: CustomerLocation[] },
      )
      const users = state.users.filter((u) => u.id !== location.id)
      return { ...state, users, locationsByAccount, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.LOCATION_ADDED: {
      const newLocation: CustomerLocation = action.payload.location
      const { resp: newLocationResp } = action.payload
      if (newLocation && newLocationResp) {
        newLocation.id = newLocationResp.id
      }
      const newAccountId = action.payload.account.id
      const locationsByAccount = Object.entries(state.locationsByAccount).reduce(
        (acc, [accountId, locations]) => {
          acc[accountId] = [...locations]
          if (accountId === newAccountId) acc[accountId].push(newLocation)
          return acc
        },
        {} as { [key: string]: CustomerLocation[] },
      )
      return { ...state, locationsByAccount, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.LOCATION_UPDATED: {
      // const location: CustomerLocation = action.payload.location;
      const resp = action.payload.resp
      const updatedLocation: CustomerLocation = {
        id: resp.id.toString(),
        name: resp.location_name,
        companyAlias: resp.company_alias,
        addressLine1: resp.address_line_1,
        addressLine2: resp.address_line_2,
        town: resp.town,
        county: resp.county,
        postcode: resp.postcode,
        country: resp.country,
      }
      const locationsByAccount = Object.entries(state.locationsByAccount).reduce(
        (acc, [accountId, locations]) => {
          acc[accountId] = locations.map((u) => (u.id === updatedLocation.id ? updatedLocation : u))
          return acc
        },
        {} as { [key: string]: CustomerLocation[] },
      )
      return { ...state, locationsByAccount, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.USER_ADDED: {
      const newUser: User = action.payload.user
      const newAccountIds = action.payload.accountIds
      const usersByAccount = Object.entries(state.usersByAccount).reduce(
        (acc, [accountId, users]) => {
          acc[accountId] = [...users]
          if (newAccountIds.includes(accountId)) acc[accountId].push(newUser)
          return acc
        },
        {} as { [key: string]: User[] },
      )
      const users = [...state.users, newUser]
      return { ...state, users, usersByAccount, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.USER_UPDATED: {
      const updatedState: State = { ...state }
      if (action.payload) {
        const { updatedUser } = action.payload
        const user = mapUser(updatedUser)
        updatedState.usersByAccount = Object.entries(state.usersByAccount).reduce(
          (acc, [accountId, users]) => {
            acc[accountId] = users.map((u) => (u.id === user.id ? user : u))
            return acc
          },
          {} as { [key: string]: User[] },
        )
        updatedState.users = state.users.map((u) => (u.id === user.id ? user : u))
      }
      return { ...updatedState, userUpdating: false, loading: false, error: false, errorMsg: {} }
    }
    case ActionTypes.USER_INVITED: {
      return { ...state, loading: false, error: false }
    }
    case ActionTypes.INIT_USER_ALERT_SUBS_LOADING: {
      const { subs } = action.payload
      const userAlertSubs: AlertSub[] = subs.map(
        (s: {
          id: string
          user_id: string
          email: string
          location_id: string
          recipient_type: string
        }) => {
          return {
            id: s.id,
            userId: s.user_id,
            email: s.email,
            locationId: s.location_id,
            recipientType: s.recipient_type,
          } as AlertSub
        },
      )
      return { ...state, userAlertSubs: userAlertSubs, loading: false, error: false }
    }
    case ActionTypes.USER_ALERT_SUBS_UPDATED: {
      const { resp, operation, locationId, userId } = action.payload
      const { userAlertSubs } = state
      let updatedUserAlertSubs = [...userAlertSubs]

      if (operation === 'add') {
        resp.map(
          (r: {
            id: string
            user_id: string
            location_id: string
            email: string
            recipient_type: string
          }) => {
            const itemIndex = userAlertSubs.findIndex(
              (a) => a.userId === userId && a.locationId.toString() === locationId,
            )
            if (itemIndex !== -1) {
              userAlertSubs[itemIndex] = {
                ...userAlertSubs[itemIndex],
                id: r.id,
                userId: r.user_id,
                email: r.email,
                locationId: r.location_id,
                recipientType: r.recipient_type,
              }
            } else {
              const a: AlertSub = {
                id: r.id,
                userId: r.user_id,
                email: r.email,
                locationId: r.location_id,
                recipientType: r.recipient_type,
              }
              updatedUserAlertSubs.push(a)
            }
          },
        )
      } else if (operation === 'remove') {
        updatedUserAlertSubs = updatedUserAlertSubs.filter(
          (a) => a.locationId.toString() !== locationId && a.userId === userId,
        )
      }

      return {
        ...state,
        userAlertSubs: updatedUserAlertSubs,
        subsUpdating: false,
        loading: false,
        error: false,
        errorMsg: {},
      }
    }
    case ActionTypes.USER_UPDATING: {
      return { ...state, userUpdating: true, loading: false, error: false }
    }
    case ActionTypes.ERROR:
      const { errorMsg } = action.payload;
      displayToast({
          type: 'error',
          message: errorMsg?.detail || errorMsg?.message || 'Something went wrong',
        })
      return { ...state, loading: false, error: true, errorMsg: errorMsg, subsUpdating: false }
    default:
      return state
  }
}

export function mapUser(u: any) {
  const iFirstname = u.first_name ? u.first_name[0].toUpperCase() : ''
  const iLastname = u.last_name ? u.last_name[0].toUpperCase() : ''
  return {
    id: u.id,
    firstname: u.first_name,
    lastname: u.last_name,
    initials: iFirstname + iLastname,
    mobile: u.mobile,
    email: u.email,
    active: u.active,
    groups: u.groups,
    accountIds: u.account_ids?.map((i: number) => String(i)) || [],
    adminAccountIds: u.admin_account_ids?.map((i: number) => String(i)) || [],
    customerLocationIds: u.customer_location_ids?.map((i: number) => String(i)) || [],
    viewAllLocationsAccountIds:
      u.view_all_locations_account_ids?.map((i: number) => String(i)) || [],
    adminCustomerLocationIds: u.admin_customer_location_ids?.map((i: number) => String(i)) || [],
    alertLocationIds: u.alert_location_ids?.map((i: number) => String(i)) || [],
    customerLocations:
      u.customer_location?.map((l: { id: number; admin: boolean }) => {
        return { locationId: String(l.id), admin: l.admin }
      }) || [],
    accounts:
      u.accounts?.map(
        (a: {
          id: number
          admin: boolean
          view_all_locations: boolean
          auto_add_locations: boolean
          auto_add_alerts: boolean
          invoice: boolean
        }) => ({
          accountId: String(a.id),
          admin: a.admin,
          autoAddLocations: a.auto_add_locations,
          autoAddAlerts: a.auto_add_alerts,
          invoice: a.invoice,
        }),
      ) || [],
  }
}
